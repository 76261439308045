import "../../Section/RichTextSection.css";
import ProjectStatusOverviewNavigation from "./ProjectStatusOverviewNavigation";
import TotalItemsOverviewNavigation from "./TotalItemsOverviewNavigation";

const OverviewNavigationBuilder = ({ items, section, parentSections }) => {
  const { options } = section;
  const overviewType =
    options?.find(({ name }) => name === "type")?.value ??
    parentSections[0]?.options?.find(({ name }) => name === "type")?.value;

  switch (overviewType) {
    case "project_status":
      return (
        <ProjectStatusOverviewNavigation
          items={items}
          parentSections={parentSections}
        />
      );
    case "total_items":
      return <TotalItemsOverviewNavigation items={items} />;

    default:
      throw new Error(
        `Unknown overview type in OverviewNavigationBuilder: ${overviewType}`
      );
  }
};

export default OverviewNavigationBuilder;
