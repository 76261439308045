import React, { useContext, useState } from "react";

const LayoutContext = React.createContext(undefined);

function useLayout() {
  const context = useContext(LayoutContext);
  return context;
}

function LayoutContextProvider({ children }: any) {
  const [isBreakdownCollapsed, setIsBreakdownCollapsed] = useState(true);

  const [isUncategorizedCollapsed, setIsUncategorizedCollapsed] =
    useState(true);
  const [isNavBarCollapsed, setIsNavBarCollapsed] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(64);

  const handleIsUncategorizeCollapsed = () => {
    setIsUncategorizedCollapsed((prevState) => !prevState);
  };
  const value = React.useMemo(() => {
    const navBarSpace = isNavBarCollapsed ? "75px" : "196px";
    const uncategorizedHeight = isUncategorizedCollapsed ? "64px" : "48vh";
    const breakdownSpace = isBreakdownCollapsed ? "8px" : "316px";
    const normalizedHeaderHeight = Math.max(headerHeight, 64);
    return {
      isBreakdownCollapsed,
      setIsBreakdownCollapsed,
      isUncategorizedCollapsed,
      setIsUncategorizedCollapsed: handleIsUncategorizeCollapsed,
      isNavBarCollapsed,
      setIsNavBarCollapsed,
      breakdownSpace,
      navBarSpace,
      uncategorizedHeight,
      headerHeight,
      setHeaderHeight,
      normalizedHeaderHeight,
    };
  }, [
    isBreakdownCollapsed,
    isUncategorizedCollapsed,
    isNavBarCollapsed,
    headerHeight,
  ]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}

export { useLayout, LayoutContextProvider };
