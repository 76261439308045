import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";
import {
  allProjectStatuses,
  defaultProjectStatus,
} from "src/v3/utils/ProjectStatus/allProjectStatuses";
import {
  getColor,
  getLegendColor,
  getTextColor,
} from "src/v3/utils/ProjectStatus/colors";
import * as Sentry from "@sentry/react";
import { ActionFactory } from "src/v3/components/ActionMenu/Actions/Factory";
import { ActionType } from "src/v3/components/ActionMenu/Actions/Factory/types";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export class ProjectStatusField extends CustomFieldBase {
  private projectStatus: any;
  private previousProjectStatus: any;

  constructor(field) {
    super(field);
    const fieldValueProjectStatus = field.value.project_status;

    this.projectStatus = fieldValueProjectStatus || defaultProjectStatus;
    this.previousProjectStatus =
      fieldValueProjectStatus?.previous_status || null;
  }
  getProjectStatus() {
    const override = this.getLastAddedOverride();
    const value = override?.value?.projectStatus ?? this.projectStatus;
    const foundStatus = allProjectStatuses.find(
      ({ name }) => name === value?.name
    );

    if (foundStatus) {
      return foundStatus;
    }

    Sentry.captureException(
      `Project status with name ${value?.name} not found in allProjectStatuses`
    );

    return {
      name: value?.name || defaultProjectStatus.name,
      type: value?.type || defaultProjectStatus.type,
      position: defaultProjectStatus.position,
      sort_position: defaultProjectStatus.sort_position,
    };
  }

  getPreviousProjectStatus() {
    return this.previousProjectStatus;
  }

  getValue() {
    return this.getProjectStatus();
  }

  getStatusType() {
    return this.getProjectStatus()?.type;
  }

  getStatusName() {
    return this.getProjectStatus()?.name;
  }

  getTextColor() {
    const status = this.getProjectStatus();
    return getTextColor(status?.type);
  }

  getBackgroundColor() {
    const status = this.getProjectStatus();
    return getColor(status?.type);
  }

  getLegendColor() {
    const status = this.getProjectStatus();
    return getLegendColor(status?.type);
  }

  getPosition() {
    return this.getProjectStatus()?.position || 9999;
  }

  getSortPosition() {
    return this.getProjectStatus()?.sort_position || 9999;
  }

  getTooltip() {
    return "";
  }

  getValueForSort(valueProperty) {
    if (this.getProjectStatus()?.[valueProperty]) {
      return this.getProjectStatus()[valueProperty];
    }
    return "";
  }

  getValueForProperty(valueProperty: "type" | "name") {
    if (this.getProjectStatus()?.[valueProperty]) {
      return this.getProjectStatus()[valueProperty];
    }
    return "";
  }

  hasOverride() {
    const override = this.getLastAddedOverride();
    // TODO: After deprecate the old views, change this to only check override.value.
    return (
      override?.value?.projectStatus && override?.value?.projectStatus !== ""
    );
  }
  getEditStatusAction = (item: BlockItem) =>
    ActionFactory(ActionType.EditProjectStatus, { item });
}
