import WidgetConfig from "./WidgetConfig";

export enum WidgetNames {
  TeamsList = "teams_list",
  ProductList = "product_list",
}

class Widget {
  constructor(
    private id: string,
    private configs: WidgetConfig[],
    public name: string
  ) {
    this.id = id;
    this.configs = configs;
    this.name = name;
  }

  public getId(): string {
    return this.id;
  }

  public getConfigs(): WidgetConfig[] {
    return this.configs;
  }

  public getConfigByName(_name: string): WidgetConfig {
    return this.configs.find(({ name }) => name === _name);
  }
}

export default Widget;
