import theme from "src/v3/theme/theme";
import {
  CloseIcon,
  CustomDialogContent,
  CustomDialogTitle,
  DialogCloseButton,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogSaveButton,
} from "./styles";
import { CustomDialogProps } from "./types";
import { CircularProgress } from "@material-ui/core";

export default function CustomDialog({
  open,
  title,
  children,
  isSaving,
  enableSaveButton,
  onClose,
  onSave,
}: CustomDialogProps) {
  return (
    <DialogRoot
      onClose={onClose}
      aria-labelledby="custom-dialog-root"
      open={open}
      maxWidth="sm"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogHeader>
        <CustomDialogTitle id="custom-dialog-title">{title}</CustomDialogTitle>
        <CloseIcon aria-label="close" onClick={onClose} />
      </DialogHeader>

      <CustomDialogContent>{children}</CustomDialogContent>

      <DialogFooter>
        <DialogCloseButton onClick={onClose}>Close</DialogCloseButton>
        <DialogSaveButton
          disabled={!enableSaveButton}
          onClick={onSave}
          enableSaveButton={enableSaveButton}
        >
          {isSaving ? (
            <CircularProgress
              size={24}
              color={theme.palette.primary.contrastText}
            />
          ) : (
            "Save"
          )}
        </DialogSaveButton>
      </DialogFooter>
    </DialogRoot>
  );
}
