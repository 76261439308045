import { Box, Typography } from "@material-ui/core";
import { CustomFieldBase } from "./CustomFieldBase";
import { transformDate } from "src/v2/utils/date";

export class RiskComparisonDateField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = [field.value ?? {}];
    this.riskComparisonDate = field.value?.date ?? "";
    this.isDefaultDate = field.value?.default ?? false;
  }

  public riskComparisonDate: string;
  public isDefaultDate: boolean;

  getRiskComparisonDate() {
    return this.getLastAddedOverride()?.value ?? this.riskComparisonDate;
  }

  getTargetDate() {
    return this.getLastAddedOverride()?.value ?? this.riskComparisonDate;
  }

  getValue() {
    return this.value[0];
  }

  hasOverride() {
    const override = this.getLastAddedOverride();
    return override?.value && override?.value !== "";
  }

  getComponentForGrid(_) {
    return (
      <Box
        data-testid="date-field"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          minWidth: "120px",
          backgroundColor:
            this.getRiskComparisonDate() === "" ? null : "#C2DCFC",
          padding: 4,
          textAlign: "center",
          borderRadius: 4,
          color: "#395E77",
          zIndex: -1,
        }}
      >
        <Typography style={{ fontSize: 14 }}>
          {transformDate(this.getRiskComparisonDate())}
        </Typography>
      </Box>
    );
  }
}
