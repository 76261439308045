import { Divider, Grid, Typography } from "@material-ui/core";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import theme from "src/v3/theme/theme";
import { Fragment, useEffect, useState } from "react";
import { pluralize } from "src/utils/stringUtils";
import { useEventListener, EventTypes } from "src/providers/EventContext";
import { LastUpdatedAuthor } from "src/v3/components/commomStyles/LastUpdatedAuthor";
import { ItemDetailAccordion } from "../../commonStyles/ItemDetailAccordion";

export const SummaryOfWorkAccordion = ({
  title = "Summary of Work",
  item,
}: {
  title?: string;
  item: BlockItem;
}) => {
  const aiSummaryOverride = useEventListener(
    EventTypes.AI_SUMMARY_OVERRIDE,
    "SummaryOfWorkAccordion"
  );

  const summaryOfWorkField = item.getSummaryOfWorkField();
  const commentsField = item.getCommentsField();
  const assigneeField = item.getAssigneeField();
  const dependencyField = item.getDependencyField();
  // const slackMessageField = item?.getSlackMessageField();

  const override = summaryOfWorkField?.getLastAddedOverride();
  const aiSummary = summaryOfWorkField?.getAiSummary();

  const commentSummary = commentsField?.getSummary();
  const assignee = assigneeField?.getAssignee();

  // const slackMessages = slackMessageField?.getMessages();
  const slackMessages = [];

  const blockedBy = dependencyField?.getBlockedBy();
  const blocks = dependencyField?.getAllItemsThatCurrentIssueBlocks();

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const handleToggleAccordion = () => {
    setIsAccordionExpanded((prev) => !prev);
  };

  const hasOverride = summaryOfWorkField?.hasOverride();

  const hasAdditionalContextValue =
    hasOverride || slackMessages?.length > 0 || !!commentSummary;

  const [hasAdditionalContext, setHasAdditionalContext] = useState(
    hasAdditionalContextValue
  );

  const hasDataValue =
    hasAdditionalContext ||
    !!aiSummary ||
    !!assignee ||
    !!blockedBy ||
    !!blocks;

  const [hasData, setHasData] = useState(hasDataValue);

  useEffect(() => {
    setHasAdditionalContext(hasAdditionalContextValue);
    setHasData(hasDataValue);
  }, [aiSummaryOverride]);

  const findProjectTeamMemberNames = (items: BlockItem[]): string[] => {
    const assignees: string[] = [];

    items.forEach((item) => {
      const assigneeName = item.getAssigneeField()?.getAssignee()?.displayName;
      if (assigneeName) {
        assignees.push(assigneeName);
      }

      if (item.children) {
        assignees.push(...findProjectTeamMemberNames(item.children));
      }
    });

    return assignees;
  };

  const projectTeam = findProjectTeamMemberNames([item] || [])
    .filter((name, index, self) => name && self.indexOf(name) === index)
    .sort((a, b) => a.localeCompare(b));

  return (
    <ItemDetailAccordion
      title={title}
      expanded={hasData && isAccordionExpanded}
      setExpanded={handleToggleAccordion}
      actions={[summaryOfWorkField.getDetailViewEditSummaryOfWorkAction(item)]}
    >
      <Grid
        container
        direction="column"
        style={{
          display: "flex",
          padding: theme.spacing(5),
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: theme.spacing(4),
          alignSelf: "stretch",
          width: "100%",
          color: theme.palette.grey[900],
          wordBreak: "break-word",
          overflowWrap: "break-word",
          ...theme.typography.body1,
        }}
      >
        <>
          {hasAdditionalContext && (
            <Fragment>
              <div
                style={{
                  color: theme.palette.grey[800],
                  ...theme.typography.h2,
                  fontStyle: "normalize",
                  lineHeight: "normalize",
                }}
              >
                Additional context
                {summaryOfWorkField.hasOverride() && (
                  <LastUpdatedAuthor
                    author={override?.value?.author?.name}
                    lastUpdatedDate={override?.value?.created_at}
                  />
                )}
              </div>

              {override?.value && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: override.value.context,
                  }}
                />
              )}

              {commentSummary && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: commentSummary,
                  }}
                />
              )}

              {slackMessages?.length > 0 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: slackMessages
                      .map(
                        (message) =>
                          `<ul class='arrowed'><li>${message.summary}</li></ul>`
                      )
                      .join("<br>"),
                  }}
                />
              )}

              <Divider
                style={{
                  width: "100%",
                }}
              />
            </Fragment>
          )}
          {aiSummary && (
            <Fragment>
              {hasAdditionalContext && (
                <>
                  <div
                    style={{
                      color: theme.palette.grey[800],
                      ...theme.typography.h2,
                      fontStyle: "normalize",
                      lineHeight: "normalize",
                    }}
                  >
                    Velma summary
                  </div>
                </>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: aiSummary,
                }}
              />
            </Fragment>
          )}
          {aiSummary && (
            <Divider
              style={{
                width: "100%",
              }}
            />
          )}
        </>
        <div
          style={{
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(3),
              alignSelf: "stretch",
              color: theme.palette.grey[900],
              ...theme.typography.body2,
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                gap: theme.spacing(1),
                alignSelf: "stretch",
                alignItems: "center",
              }}
            >
              <>Owner: </>
              <Typography
                style={{
                  color: theme.palette.grey[900],
                  ...theme.typography.body2,
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                {assignee.displayName || "Unassigned"}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div>
              <>Project team: </>
              <b>
                {projectTeam
                  .slice(0, 3)
                  .map((name) => name)
                  .join(", ") || "--"}
              </b>
              <b>
                {/* TODO: Add tooltip with names of other team members */}
                {projectTeam.length > 3 && (
                  <> + {projectTeam.length - 3} more </>
                )}
              </b>
            </div>
            <Divider orientation="vertical" flexItem />
            <div>
              <>Blocked by: </>
              {
                <b>
                  {blockedBy?.length || 0}{" "}
                  {pluralize({
                    word: "issue",
                    count: blockedBy?.length || 0,
                  })}
                </b>
              }
            </div>
            <Divider orientation="vertical" flexItem />
            <div>
              <>Is blocking: </>
              {
                <b>
                  {blocks?.length || 0}{" "}
                  {pluralize({
                    word: "issue",
                    count: blockedBy?.length || 0,
                  })}
                </b>
              }
            </div>
          </div>
        </div>
      </Grid>
    </ItemDetailAccordion>
  );
};
