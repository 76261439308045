import React from "react";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useDataSection } from "../../hooks/useDataSection";
import { BarChart } from "./BarChart";
import { getBarDataSet } from "./buildBarChartData";

export const BarChartBuilder: React.FC<any> = ({ section, items, config }) => {
  const { groupBy, filters } = section;
  const groupByField = groupBy.fieldKey;
  const subtype = config.subtype;

  const { groupedItems } = useDataSection({ filters, groupBy, items });

  const itemsWithoutField = items.filter(
    (item: BlockItem) =>
      !groupedItems.find((group) =>
        group.items.some(
          (groupItem: BlockItem) => groupItem.getKey() === item.getKey()
        )
      )
  );

  const labels = groupedItems.map((item) => item.fieldValue);

  if (itemsWithoutField.length) {
    labels.push(`${groupByField} Not Set`);
    groupedItems.push({
      fieldValue: `${groupByField} Not Set`,
      items: itemsWithoutField,
    });
  }

  const dataset = getBarDataSet({
    groupedItems,
    subtype,
    labels,
  });

  const data = {
    labels,
    datasets: dataset,
  };

  return <BarChart data={data} />;
};
