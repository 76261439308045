import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import OutcomeProgressBar from "./OutcomeProgressBar";
import PlannedCompletionProgressBar from "./PlannedCompletionProgressBar";
import { useWidgetContext } from "../../Widget/WidgetContext";

interface ProgressBarSectionProps {
  items: BlockItem[];
}

const ProgressBarSectionFactory = (props: ProgressBarSectionProps) => {
  const { _section } = useWidgetContext();
  const { options } = _section;
  const type = options?.find(({ name }) => name === "progress_bar_type")?.value;
  switch (type) {
    case "outcome":
      return <OutcomeProgressBar {...props} />;
    case "planned_completion":
      return <PlannedCompletionProgressBar {...props} />;
    default:
      throw new Error(
        `Unknown progress bar type: ${type} for Progress Bar Section`
      );
  }
};

export default ProgressBarSectionFactory;
