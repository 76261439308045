import { isObjectEmpty } from "src/utils/objectUtils";
import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";

class BySimilarText extends SectionFilter {
  public filterItem(item: BlockItem): boolean {
    const field = item.getFieldByFieldKey(this.fieldKey);
    if (!field) {
      console.warn(`Field ${this.fieldKey} not found in item ${item.getKey()}`);
      return false;
    }

    const objectProperty = this.config?.object_property;
    const fieldValue = Array.isArray(field.value)
      ? field.value
      : typeof field.value === "object"
      ? [field.value[objectProperty]]
      : [field.value];

    if (fieldValue.includes(null)) {
      console.warn(
        `Null value found in field ${this.fieldKey} in item ${item.getKey()}`
      );
      return false;
    }

    const similarTextToCheck = this.values[0];
    return fieldValue.some((value) =>
      value?.toLowerCase().includes(similarTextToCheck.toLowerCase())
    );
  }
}
export default BySimilarText;
