import theme from "src/v3/theme/theme";
import { FakeAccordionProps } from "./types";
import { ChevronDown, ChevronUp } from "src/v3/theme/icons";
import {
  CustomAccordionRoot,
  CustomAccordionSummary,
} from "../Accordion/styles";

export const FakeAccordion = ({
  header,
  callback,
  customStyle,
  isExpanded,
}: FakeAccordionProps) => {
  return (
    <CustomAccordionRoot
      expanded={isExpanded}
      onChange={callback}
      style={{
        boxShadow: "none",
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(2),
        width: "100%",
        ...customStyle,
      }}
    >
      <CustomAccordionSummary
        expandIcon={isExpanded ? <ChevronUp /> : <ChevronDown />}
      >
        {header}
      </CustomAccordionSummary>
    </CustomAccordionRoot>
  );
};
