import { useEffect, useState } from "react";
import { ProjectStatusInsightConfig } from "src/v2/domain/entities/insights/projectStatusInsightConfig";
import { ProjectStatusInsightCalculator } from "src/v2/utils/projectStatusInsightCalculator";
import { transformDate } from "src/v2/utils/date";
import {
  blackTextColor,
  primaryTextColor,
  widgetTitleColor,
} from "src/v2/helpers/colors";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";
import { useWidgetContext } from "../../Widget/WidgetContext";
import { ProjectStatus } from "../../insights/ProjectStatus";
import Insight from "./Insight";
import { CostInsightAggregation } from "./CostAggregation";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { EventTypes, useEventListener } from "src/providers/EventContext";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr",
    marginLeft: 16,
    maxWidth: 254,
    border: "2px solid #e0e0e0",
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  title: { color: widgetTitleColor, gridColumn: "1 / 2" },
  insightHeader: {
    color: "#395e77",
    textAlign: "center",
    width: "100%",
    backgroundColor: "rgb(222, 233, 244)",
    padding: "4px 8px",
  },
  insightContainer: {
    maxWidth: 254,
    gridColumn: "span 1",
    backgroundColor: "#f8fcff",
    borderRadius: 8,
  },
  projectedFinishDateText: {
    textAlign: "center",
    color: blackTextColor,
  },
});

const InsightsAggregationSection = ({ items }) => {
  const [aggregatedProjectStatus, setAggregatedProjectStatus] = useState(null);
  const [projectedDate, setProjectedDate] = useState(null);
  const [engineerAllocation, setEngineerAllocation] = useState(null);
  const [uniqueAssignees, setUniqueAssignees] = useState(null);
  const { _section } = useWidgetContext();

  const updateComponent = useEventListener(
    EventTypes.PROJECT_STATUS_OVERRIDE,
    "InsightsAggregationSection"
  );

  const showProjectedFinishDate =
    _section?.options?.find(({ name }) => name === "show_projected_finish_date")
      ?.value ?? false;

  const classes = useStyles();
  const getEngineersAllocation = (items) => {
    let engineerAllocation = 0;
    const uniqueAssignees = new Set();
    items.forEach((item) => {
      if (!isAcceptableProjectStatusType(item)) {
        return;
      }
      const allocationField = item.getAllocationField();
      const allocationFieldValue = allocationField?.allocation;
      const itemAssignees = allocationField?.uniqueAssigneesList;

      if (allocationFieldValue) {
        engineerAllocation += allocationFieldValue;
        itemAssignees?.forEach((assignee) => {
          uniqueAssignees.add(assignee);
        });
      }
    });
    return {
      engineerAllocation,
      uniqueAssignees: uniqueAssignees.size,
    };
  };
  const getProjectedFinishDate = (items) => {
    let projectedFinishDate = "Completed";

    items.forEach((item) => {
      if (!isAcceptableProjectStatusType(item)) {
        return;
      }
      const projectedDateField = item.getProjectedDateField();
      const projectedDate = projectedDateField.getProjectedDate();
      if (projectedDate) {
        if (
          projectedFinishDate === "Completed" ||
          projectedDate > projectedFinishDate
        ) {
          projectedFinishDate = projectedDate;
        }
      }
    });
    return projectedFinishDate;
  };
  const isAcceptableProjectStatusType = (item: BlockItem) => {
    // const ACCEPTABLE_PROJECT_STATUS_TYPES = [
    //   ProjectStatusType.ON_TRACK,
    //   ProjectStatusType.AT_RISK,
    //   ProjectStatusType.OFF_TRACK,
    // ];
    // const projectStatusType = item
    //   ?.getProjectStatusField()
    //   ?.getProjectStatus()?.type;
    // if (!ACCEPTABLE_PROJECT_STATUS_TYPES.includes(projectStatusType)) {
    //   return false;
    // }
    return true;
  };
  const { getConfigValueByName } = useWidgetContext();
  const extraInsights = getConfigValueByName(WidgetConfigs.extraInsights);

  useEffect(() => {
    const config = new ProjectStatusInsightConfig({});
    const calculator = new ProjectStatusInsightCalculator(config);
    const projectStatus = calculator.getProjectStatusForListOfBlockItem(items);
    setAggregatedProjectStatus(projectStatus);
    const projectedFinishDate = getProjectedFinishDate(items);
    const _preProcessedDate = transformDate(projectedFinishDate);
    if (_preProcessedDate) {
      setProjectedDate(_preProcessedDate);
    } else {
      setProjectedDate("Not Predicted Yet");
    }
    const allocation = getEngineersAllocation(items);

    setEngineerAllocation(
      Math.round(allocation.engineerAllocation * 100) / 100
    );
    setUniqueAssignees(allocation.uniqueAssignees);
  }, [items, updateComponent]);

  const hasNoAllacation = !uniqueAssignees && !engineerAllocation;

  return (
    <Box className={classes.root}>
      {extraInsights?.map((insight) => {
        return (
          <Insight title={insight.title}>
            <ProjectStatus projectStatus={insight.data} />
          </Insight>
        );
      })}

      <Insight title="Roadmap Health">
        <ProjectStatus projectStatus={aggregatedProjectStatus} />
      </Insight>
      {showProjectedFinishDate && (
        <Insight title="Projected Finish Date">
          <Box style={{ padding: "8px 0" }}>
            <Typography
              variant="h6"
              className={classes.projectedFinishDateText}
            >
              {projectedDate}
            </Typography>
            <Typography
              style={{ textAlign: "center", fontSize: "11px", marginTop: 4 }}
            >
              * Paused Projects not included
            </Typography>
          </Box>
        </Insight>
      )}
      <Insight title="Allocation">
        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          style={{ padding: "8px 0" }}
        >
          <Typography style={{ color: primaryTextColor }} noWrap>
            {engineerAllocation > 0
              ? `Total hours/week:  ${engineerAllocation}`
              : ""}
          </Typography>
          <Typography style={{ color: primaryTextColor }} noWrap>
            {uniqueAssignees ? `Unique engineers: ${uniqueAssignees}` : ""}
            {hasNoAllacation && "--"}
          </Typography>
        </Box>
      </Insight>
      <CostInsightAggregation items={items}></CostInsightAggregation>
    </Box>
  );
};

export default InsightsAggregationSection;
