import { Typography } from "@material-ui/core";
import { EditSummaryOfWorkActionV2Props } from "./types";
import { SummaryOfWorkEdit } from "src/v3/components/Dialogs/SummaryOfWorkEdit";
import { useState } from "react";

export const EditSummaryOfWorkActionV2 = ({
  item,
  label = "additional details",
}: EditSummaryOfWorkActionV2Props) => {
  const [open, setOpen] = useState(false);
  const field = item.getAISummaryField();
  const editedExtraContext = field?.hasOverride();
  const _label = editedExtraContext ? `Edit ${label}` : `Add ${label}`;
  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <Typography
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleOpen();
        }}
        style={{
          color: `#1A7BBD`,
          textAlign: "center",
          marginTop: 8,
          marginBottom: 8,
          textUnderlineOffset: 4,
          textDecoration: "underline",
          fontWeight: 600,
          fontSize: "15px",
          cursor: "pointer",
          lineHeight: "16px",
          font: "inherit",
          display: "flex",
        }}
      >
        {_label}
      </Typography>
      <SummaryOfWorkEdit item={item} open={open} handleOpen={handleOpen} />
    </>
  );
};
