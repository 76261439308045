import { useEffect, useRef, useState } from "react";
import { ProjectStatusInsightConfig } from "src/v2/domain/entities/insights/projectStatusInsightConfig";
import { ProjectStatusInsightCalculator } from "src/v2/utils/projectStatusInsightCalculator";
import { Box, Typography, Grid } from "@material-ui/core";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";
import { useWidgetContext } from "../../Widget/WidgetContext";
import { ProjectStatus } from "../../insights/ProjectStatus";
import "../../Section/RichTextSection.css";

import { makeStyles } from "@material-ui/core";
import ProjectStatusReasonHTML from "./ProjectStatusReasonHTML";
import BoxToScrollToWidgetName from "./BoxToScrollToWidgetName";
import { useProjectStatusChanges } from "src/v2/hooks/useRecentChanges";
import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";
import ProjectsListOnHover from "./ProjectsListOnHover";
import { EventTypes, useEventListener } from "src/providers/EventContext";

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
  },
  container: {
    width: "fit-content",
    cursor: "pointer",
    display: "flex",
    transition: "transform 0.3s ease", // Smooth transition for the text transform
    "&:hover": {
      transform: "scale(1.05)", // Slightly increase the size on hover
    },
  },
  project_name: {
    backgroundColor: "rgb(25, 123, 189)",
    color: "white",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: 16,
    minWidth: 120,
  },
}));

/**
 * `ProjectStatusOverviewNavigation` Component
 *
 * This component provides a detailed view and navigation interface for project statuses.
 * It utilizes various configurations provided through its props to customize its behavior and display.
 *
 * Props:
 *  - `items`: Array of project items, where each item represents a project with its respective data.
 *  - `parentSections`: Array of objects providing additional configurations. Specifically, configurations are read from `parentSections[0]?.options`.
 *
 * Configuration Options in `parentSections[0]?.options`:
 *  - `has_automatic_changes`: A flag that determines if the component should automatically update based on certain conditions or changes in the data.
 *      If set to true, the component will react to changes in the project data and update its display accordingly.
 *
 *  - `show_items_on_hover`: A flag that controls the visibility of a detailed project list when hovering over a specific area of the component.
 *      If true, hovering over the designated area will display a list of projects with additional details.
 *
 *  - `html_reason`: A custom HTML content to display additional reasons or explanations for the project status.
 *      This allows for rich text formatting and customization of the message shown alongside the project status.
 *
 * Usage:
 * The component should be used in contexts where an overview of project statuses is required.
 * It's particularly useful for dashboards or summary views where project statuses need to be quickly assessed.
 * The component's behavior and display can be extensively customized through the `parentSections` prop,
 * allowing it to adapt to various use cases and presentation requirements.
 */

const ProjectStatusOverviewNavigation = ({ items, parentSections }) => {
  const [aggregatedProjectStatus, setAggregatedProjectStatus] = useState(null);
  const [reason, setReason] = useState(null);
  const ref = useRef(null);
  const classes = useStyles();
  const { getConfigValueByName } = useWidgetContext();

  const customSectionData = getConfigValueByName(
    WidgetConfigs.customSectionData
  );
  const hasAutomaticChanges = parentSections[0]?.options?.find(
    ({ name }) => name === "has_automatic_changes"
  )?.value;
  const showItemsOnHover = parentSections[0]?.options?.find(
    ({ name }) => name === "show_items_on_hover"
  )?.value;

  const showTargetDateChanges = parentSections[0]?.options?.find(
    ({ name }) => name === "show_changes_in_teams_target_date"
  )?.value;

  const { changesHTML } = useProjectStatusChanges({
    items,
    hasAutomaticChanges,
    showChangesInTeamsTargetDate: showTargetDateChanges,
  });

  const projectStatusUpdated = useEventListener(
    EventTypes.PROJECT_STATUS_OVERRIDE,
    "ProjectStatusOverviewNavigation"
  );

  useEffect(() => {
    const config = new ProjectStatusInsightConfig({});
    const calculator = new ProjectStatusInsightCalculator(config);
    const projectStatus = calculator.getProjectStatusForListOfBlockItem(items);
    let html = changesHTML;

    setAggregatedProjectStatus(projectStatus);

    const customReason = parentSections[0]?.options?.find(
      ({ name }) => name === "html_reason"
    )?.value;

    if (customReason?.length > 0) {
      html += customReason;
    }
    setReason(html);
  }, [items, changesHTML, projectStatusUpdated]);

  function ProjectName({ title, children }) {
    return (
      <Box style={{ display: "flex" }}>
        <Typography
          className={classes.project_name}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "max-content",
            fontSize: 14,
          }}
        >
          {title}
        </Typography>
        {children}
      </Box>
    );
  }
  return (
    <Grid data-test-id="overview_navigation">
      <div className={classes.root}>
        <BoxToScrollToWidgetName>
          <span ref={ref} className={classes.container}>
            <ProjectName title={customSectionData.title}>
              <ProjectStatus
                projectStatus={aggregatedProjectStatus}
                customStyles={{
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              />
            </ProjectName>
          </span>
        </BoxToScrollToWidgetName>
      </div>
      <ProjectStatusReasonHTML reason={reason} />
      {showItemsOnHover && (
        <ProjectsListOnHover
          parentRef={ref}
          items={items}
          projectStatusTypes={[
            ProjectStatusType.OFF_TRACK,
            ProjectStatusType.AT_RISK,
          ]}
        />
      )}
    </Grid>
  );
};

export default ProjectStatusOverviewNavigation;
