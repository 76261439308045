import { Box } from "@material-ui/core";
import { useBlockContext } from "src/v2/providers/block/BlockContext";
import LastUpdatedText from "./LastUpdatedText";
import FilteredByText from "./FilteredByText";
import GettingData from "./GettingData";

const LastUpdatedFilteredBy = ({ section }) => {
  const { isGettingUpdatedBlocks } = useBlockContext();
  const { options } = section;
  const _showSyncingDataBar =
    options?.find(({ name }) => name === "show_syncing_data_bar")?.value ??
    true;

  const _showUpdates =
    options?.find(({ name }) => name === "show_updates")?.value ?? true;

  return (
    <Box
      display="flex"
      flexDirection="column"
      data-test-id="last_updated_section"
    >
      {_showUpdates && (
        <div>
          {isGettingUpdatedBlocks && (
            <GettingData showLinearProgress={_showSyncingDataBar} />
          )}
          <LastUpdatedText />
        </div>
      )}
      <FilteredByText />
    </Box>
  );
};

export default LastUpdatedFilteredBy;
