import { StatusField } from "../domain/entities/customField/StatusField";

// Summary Table Colors
export const borderColor = "#F1F1F1";
export const primaryTextColor = "#395e77";
export const secondaryTextColor = "#d5dae3";
export const blackTextColor = "#0f344d";
export const clearFilterColor = "#A8BDCE";
export const applyFilterColor = "#197BBD";
export const filterButtonColor = "#5F8097";
export const tableHeaderColor = "#f8f8f8";
export const teamBackground = "#EEF0F4";
export const insightsBackground = "#f8fcff";
export const insightsHeaderBackground = "#dee9f4";
export const changesSubtitle = "#939191";
export const loginErrorBackground = "rgb(253, 236, 234)";

// Table row background colors by item target
export const deliverableLevelRowBackgroundColor = "#FFFFFF";
export const bigRockLevelRowBackgroundColor = "#F1F1F1";
export const projectLevelRowBackgroundColor = "#F1F1F1";
export const taskRowBackgroundColor = "#EAF2FB";

export const expandTableTagColorsByItemType = {
  project: "#ffd098",
  deliverable: "#8abaf3",
  task: "#56ac6b",
  bug: "#c72d22",
  default: primaryTextColor,
};

// Filter Colors
export const menuBackgroundColor = "white";

// Generic view components, progress bar by status colors
export const todoStatusProgressBarColor = "#9AAEC8";
export const inProgressStatusProgressBarColor = "#4E729F";
export const doneStatusProgressBarColor = "#033677";
export const todoStatusProgressBarColorText = "#033677";
export const inProgressStatusProgressBarColorText = "#033677";
export const doneStatusProgressBarColorText = "#9AAEC8";
export const ignoredStatusProgressBarColor = "#F1F1F1";
export const defaultProgressBarBackgroundColor = "lightgray";

// Progress bar colors
export const progressBarTitleColor = "#696969";

// Project Status colors
export const statusNotSetColor = "#aeaeae";

// widget colors
export const widgetTitleColor = "#5F8097";
export const widgetBorderColor = "#e0e0e0";
export const dateBackgroundColor = "#3b5e778f";
export const assigneeBackgroundColor = "#0064ab8f";

// Generic View Title Color
export const genericViewTitleColor = "rgb(57, 94, 119)";
function hexToRgb(hex) {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}

function rgbToHex(r, g, b) {
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

function lightenColor(rgb, factor) {
  return rgb.map((value) =>
    Math.min(255, Math.round(value + (255 - value) * factor))
  );
}

function darkenColor(rgb, factor) {
  return rgb.map((value) => Math.max(0, Math.round(value * (1 - factor))));
}

export function getAccessibleColors(
  hexColor,
  lightnessFactor = 0.9,
  darknessFactor = 0.3
) {
  const rgb = hexToRgb(hexColor);

  const lightenedRgb = lightenColor(rgb, lightnessFactor);
  const darkenedRgb = darkenColor(rgb, darknessFactor);

  return {
    text: rgbToHex(...darkenedRgb),
    background: rgbToHex(...lightenedRgb),
    originalColor: hexColor,
  };
}

export function getStatusCategoryColor(category) {
  switch (category) {
    case StatusField.CATEGORY_IN_PROGRESS:
      return {
        backgroundColor: "var(--color-in-progress-background)",
        color: "var(--color-in-progress-status)",
      };
    case StatusField.CATEGORY_DONE:
      return {
        backgroundColor: "var(--color-done-background)",
        color: "var(--color-done-status)",
      };
    case StatusField.CATEGORY_TODO:
      return {
        backgroundColor: "var(--color-todo-background)",
        color: "var(--color-todo-status)",
      };
    default:
      return {
        backgroundColor: "var(--color-todo-background)",
        color: "var(--color-todo-status)",
      };
  }
}
