import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";

export const getPlannedCompletionConfig = () => {
  return plannedCompletionConfig.statuses.sort(
    (a, b) => a.position - b.position
  );
};

const plannedCompletionConfig = {
  name: "planned_completion",
  type: "select",
  statuses: [
    {
      name: "Done",
      type: ProjectStatusType.COMPLETE,
      color: "#18801d",
      position: 1,
    },
    {
      name: "On Track",
      type: ProjectStatusType.ON_TRACK,
      color: "#4CAF50",
      position: 2,
    },
    {
      name: "At Risk",
      type: ProjectStatusType.AT_RISK,
      color: "#f1ca00",
      position: 3,
    },
    {
      name: "Off Track",
      type: ProjectStatusType.OFF_TRACK,
      color: "#ff3407",
      position: 4,
    },
    {
      name: "To Do",
      type: ProjectStatusType.PAUSED,
      color: "#aeaeae",
      position: 5,
    },
    {
      name: "Not Set",
      type: ProjectStatusType.NOT_SET,
      color: "#aeaeae",
      position: 999,
    },
  ],
};
