import { SectionFilterFactory } from "src/v2/domain/entities/section/sectionFilters/SectionFilterFactory";

export const getFilteredItems = ({ filters, items }) => {
  /*
    'filters' is an array of arrays.
    It was made like that in order to filter OR and AND conditions in the data section
    The first array layer represents the OR conditions
    The second layer is the AND conditions.
    In the following example we are filtering following the logic:

      (project status insight -> On Track AND status category -> In Progress)
      OR 'Labels' -> Q3-2023

    Example:
    const filters = [
      [
        {
          type: "by_project_status_insight",
          values: ["On Track"],
          fieldKey: "project_status_insight",
        },
        {
          type: "by_status_category",
          values: ["In Progress"],
          fieldKey: "status",
        },
      ],
      [
        {
          type: "by_label",
          values: ["Q3-2023"],
          fieldKey: "Labels",
        },
      ],
    ];
    
  */

  if (!filters || !filters.length) {
    return items;
  }

  return items?.filter((item) => {
    return filters.some((or_filter) => {
      return or_filter.every((and_filter) => {
        const filterObject = SectionFilterFactory.createFilter(and_filter);
        return filterObject.filterItem(item);
      });
    });
  });
};
