import { Row } from "src/components/GlobalComponents";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import {
  buildRedirectURLWithListOfFilters,
  getAllKeyValueFiltersFromURLWithoutExtraParams,
} from "src/v2/utils/url";
import { SectionViewType } from "./types";
import { useDataSection } from "./hooks/useDataSection";

const useStyles = makeStyles((theme) => ({
  textTotal: {
    fontSize: 14,
    color: "#6F6C99",
  },
  projectsComplete: {
    fontSize: 16,
    color: "#055A92",
    fontWeight: 600,
  },
  projectsCompleteValue: {
    fontSize: 16,
    color: "#055A92",
    fontWeight: 600,
  },
  textTotalValue: {
    fontSize: 16,
    color: "#6F6C99",
  },
  linkedItem: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));
const ProjectsCompletedSection: React.FC<SectionViewType> = ({
  section,
  items,
}) => {
  const { filters, groupBy, options } = section;

  const parentType = options.find((option) => option.name === "parent_type");
  const parentTypeString = `${
    parentType?.value ? `${parentType?.value}s` : `Projects`
  }`;
  const childrenType = options.find(
    (option) => option.name === "children_type"
  );
  const childrenTypeString = `${
    childrenType?.value ? `${childrenType?.value}s` : `Epics`
  }`;

  const redirectView = options.find(
    (option) => option.name === "redirect_view"
  )?.value;

  const _filterFields = getAllKeyValueFiltersFromURLWithoutExtraParams();
  const summaryUrl = buildRedirectURLWithListOfFilters({
    filters: _filterFields,
    redirectView,
  });

  const { filteredItems } = useDataSection({
    filters,
    groupBy,
    items: [...items],
  });

  const totalItems = filteredItems.length;
  let totalDeliverables = 0;
  let totalItemsCompleted = 0;

  filteredItems.forEach((item) => {
    if (item.isCompleted()) totalItemsCompleted++;
    item.children.forEach((child) => {
      if (
        child.isDeliverable() ||
        child
          .getField({ fieldKey: "Issue Type" })
          ?.value.includes(childrenType?.value || "Epic")
      )
        totalDeliverables++;
    });
  });

  const classes = useStyles();
  return (
    <div>
      <Row>
        {
          <Box mt={1} style={{ width: 180 }}>
            <Box mt={1}>
              <Typography className={classes.projectsComplete}>
                {parentTypeString} Completed
              </Typography>
              <Typography className={classes.projectsCompleteValue}>
                {totalItemsCompleted}&nbsp;&nbsp;/&nbsp;&nbsp;
                {((totalItemsCompleted / totalItems) * 100).toFixed(1)}%
              </Typography>
            </Box>
            <Grid direction="column">
              {parentType && (
                <Link to={summaryUrl}>
                  <Box mt={2}>
                    <Typography className={classes.textTotal}>
                      Total {parentTypeString}
                    </Typography>
                    <Typography className={classes.textTotalValue}>
                      {totalItems}
                    </Typography>
                  </Box>
                </Link>
              )}
              {childrenType && (
                <Link to={summaryUrl}>
                  <Box mt={2}>
                    <Typography className={classes.textTotal}>
                      Total {childrenTypeString}
                    </Typography>
                    <Typography className={classes.textTotalValue}>
                      {totalDeliverables}
                    </Typography>
                  </Box>
                </Link>
              )}
            </Grid>
          </Box>
        }
      </Row>
    </div>
  );
};
export default ProjectsCompletedSection;
