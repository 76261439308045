import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Theme,
} from "@material-ui/core";

export const Details = styled(AccordionDetails)(({}) => ({
  display: "flex",
  width: "100%",
}));

export const NoExpandIcon = styled("div")(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(3),
}));

export const CustomAccordionRoot = styled(Accordion)({
  "&.Mui-expanded": {
    margin: 0, // Remove margin when expanded
  },
  "&:before": {
    content: "none",
  },
});

export const CustomAccordionSummary = styled(AccordionSummary)({
  "&.Mui-expanded": {
    minHeight: 0, // Remove minHeight when expanded
    margin: 0, // Remove margin when expanded
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0, // Remove inner content margin when expanded
  },
  "& .MuiAccordionSummary-content-214.Mui-expanded": {
    margin: 0, // Remove inner content margin when expanded
  },
  "& .MuiAccordionSummary-content-214": {
    margin: 0, // Remove inner content margin
  },
  padding: "0px 12px",
});

export const CustomAccordionDetails = styled(AccordionDetails)({
  padding: "0px 7px 8px 8px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "none",
  width: "100%",
});
