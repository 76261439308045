export const SWO_OCT_2023_DoughnutChart = {
  data: {
    labels: ["Bugs (Major & above)", "Client Issues", "Release"],
    datasets: [
      {
        data: [68, 32, 0], // Percentages
        quantity: [46, 22, 0], // TODO: Get quantity to roadmap
        backgroundColor: [
          "rgb(204, 93, 19)",
          "rgb(8, 135, 134)",
          "rgb(28, 84, 149)",
        ],
      },
    ],
  },
  title: "Composition of Work",
  info: "SWO Oct Release (Sep 27 - Oct 24)",
};

// Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
export const SWO_OCT_2023_Release = {
  project_status: {
    status: {
      type: "not_set_status",
      name: "No Projects",
    },
    title: "Release",
    titleBackgroundColor: "#197BBD",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 0,
    inProgressPercentage: 0,
    toDoPercentage: 0,
  },
  ai_summary: "",
};

export const SWO_OCT_2023_ClientIssues = {
  project_status: {
    status: {
      type: "off_track_status",
      name: "Off Track",
    },
    title: "Client Issues",
    titleBackgroundColor: "#088786",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 27,
    inProgressPercentage: 5,
    toDoPercentage: 68,
  },
  ai_summary: `<div class="root"><h4><a target="_blank" href="https://swicloud.atlassian.net/issues/?jql=issuetype%3D%22Customer%20Issue%22%20AND%20project%3D%22NH%22%20AND%20created%20%3E%3D%20%222023-9-27%22">22 total Client Issues in Oct Release</a></h4><ul class="arrowed"><br /><b>Callouts</b><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-65030)" target="_blank">1 Critical issue</a> unstarted for 33 days</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-6503,NH-64016,NH-62711,NH-65233,NH-63289,NH-62834,NH-62691,NH-62469,NH-64706)" target="_blank">Blocker, Critical and Major issues</a> NOT meeting SLAs </li><br /><b>Stats By Priority</b><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-63235)" target="_blank">Blocker (1 issue):</a> Completed - 4 days to resolve</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-65031,NH-63862,NH-65030)" target="_blank">Critical (3 issues):</a> ~10 days to resolve, 1 issue (33%) ~33 days in To Do</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-65392,NH-64016,NH-62711,NH-65233,NH-63289,NH-62834,NH-62691,NH-62469,NH-64706)" target="_blank">Major (9 issues):</a> ~30 days to resolve, 5 issues (55%) ~45 days in To Do</li><li>Other (9 issues): 100% Unstarted ~42 days in To Do</li></ul></div>`,
};

// Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
export const SWO_OCT_2023_Bugs = {
  project_status: {
    status: {
      type: "off_track_status",
      name: "Off Track",
    },
    title: "Bugs",
    titleBackgroundColor: "#CC5D13",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 79,
    inProgressPercentage: 13,
    toDoPercentage: 8,
  },
  ai_summary: `<div class="root"><h4><a target="_blank" href="https://swicloud.atlassian.net/issues/?jql=issuetype%3D%22Customer%20Issue%22%20AND%20project%3D%22NH%22%20AND%20created%20%3E%3D%20%222023-9-27%22">46 priority Bugs in Oct Release</a></h4><ul class="arrowed"><br /><b>Callouts</b><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20()" target="_blank">60% of bugs</a> NOT meeting SLAs</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-64647)" target="_blank">1 Blocker bug</a> In Progress for 15 days</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-64636,NH-64545)" target="_blank">2 Major bugs</a> In Progress are NOT meeting SLAs (~19 days to start) (~11 days In Progress)</li><br /><b>Stats By Priority</b><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-64647,NH-64385)" target="_blank">Blocker (2 issues):</a> ~0 days to resolve, 1 bug In Progress - 1 day In Progress</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-64791,NH-64704,NH-64355,NH-64273,NH-64132,NH-64032,NH-63900,NH-63377,NH-63351,NH-63113,NH-62697,NH-62571,NH-62528,NH-62444,NH-62370,NH-62325,NH-62323,NH-62322,NH-64353)" target="_blank">Critical (19 issues):</a> ~14 days to resolve, 1 Unstarted bug (5%) ~39 days in To Do</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-65230,NH-65005,NH-64785,NH-64320,NH-62830,NH-62752,NH-65216,NH-65136,NH-65021,NH-64946,NH-64703,NH-64626,NH-64611,NH-64545,NH-64382,NH-64275,NH-64212,NH-63864,NH-62685,NH-62450,NH-62388,NH-62367,NH-61661,NH-61642,NH-64636)" target="_blank">Major (25 issues):</a> ~16 days to resolve, 6 Unstarted bugs (24%) ~39 days in To Do</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-64777,NH-62795,NH-62446)" target="_blank">High (3 issues):</a> ~25 days to resolve</li><li>Other (120 issues) ~11 days to resolve, 45 Unstarted bugs (37%) ~40 days in To Do</li></ul></div>`,
};

export const SWO_OCT_2023_Applications = {
  progressBarData: {
    items: 0,
    bugs: 0,
    itemType: "Epic",
    donePercentage: 0,
    inProgressPercentage: 0,
    toDoPercentage: 0,
  },
  // Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
  sprintHealthStatus: {
    type: "on_track_status",
    name: "On Track",
  },
  roadmapHealthStatus: {
    type: "not_set_status",
    name: "No Projects",
  },
  sprintHealthReasoningHTML: ``,
  roadmapHealthReasoningHTML:
    "<div class='root'> <ul class='arrowed'> <li> There are no epics tagged with SWO Oct 2023 release on the supported boards: Alpha, Dolphins, Instrumentation, TheOthers. All epics were moved to the Nov 2023 release.</li> <li> Client Issues and Bugs are Off Track</li></ul> </div> ",
  widgetTitle: "Applications",
  widgetTitleColor: "rgb(25, 123, 189)",
  itemName: "APM",
  redirectURL: null,
  projectedFinishDate: "",
};

export const SWO_OCT_2023_Database = {
  progressBarData: {
    items: 0,
    bugs: 0,
    itemType: "Epic",
    donePercentage: 0,
    inProgressPercentage: 0,
    toDoPercentage: 0,
  },
  // Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
  sprintHealthStatus: {
    type: "not_set_status",
    name: "On Track",
  },
  roadmapHealthStatus: {
    type: "not_set_status",
    name: "No Projects",
  },
  sprintHealthReasoningHTML: "",
  roadmapHealthReasoningHTML:
    "<div class='root'> <ul class='arrowed'> <li> There are no epics tagged with SWO Oct 2023 release on the supported board: DBO Crew. All epics were moved to the Nov 2023 release.</li> <li> Client Issues and Bugs are Off Track</li></ul> </div> ",
  widgetTitle: "Database",
  widgetTitleColor: "rgb(25, 123, 189)",
  itemName: "DBO",
  redirectURL: null,
  projectedFinishDate: "",
};
