import ProgressBar from "./ProgressBar";

const LargeProgressBar = ({
  backgroundColor,
  percentages,
  showProgressBarLegends,
  title,
}) => (
  <ProgressBar
    customHeight="18px"
    customBorderRadius="15px"
    backgroundColor={backgroundColor}
    percentages={percentages}
    title={title}
  />
);
export default LargeProgressBar;
