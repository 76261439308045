import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import SectionGroupBy from "src/v2/domain/entities/section/SectionGroupBy";
import { isEmpty } from "src/v2/utils/object";

const getGroupedItems = ({
  config,
  items,
}: {
  config: any;
  items: BlockItem[];
}) => {
  const groupedItems = {};
  if (isEmpty(config) || items[0].isTask()) {
    return items;
  }
  const groupByObject = new SectionGroupBy(config);

  items?.forEach((item) => {
    const itemTypesToGroup = groupByObject.config?.itemTypesToGroup || [];
    if (
      itemTypesToGroup.length > 0 &&
      !itemTypesToGroup.includes(item.type?.value)
    ) {
      if (!groupedItems["Other"]) {
        groupedItems["Other"] = { items: [], position: 999 };
      }
      groupedItems["Other"].items.push(item);
    } else {
      groupByObject.groupItem({
        groupedItems,
        item,
      });
    }
  });

  if (!isEmpty(config.child)) {
    Object.keys(groupedItems).forEach((key) => {
      groupedItems[key].items = getGroupedItems({
        config: config.child,
        items: groupedItems[key].items,
      });
    });
  }
  return groupedItems;
};

export const useGroupBy = ({
  groupByConfig,
  items,
}: {
  groupByConfig: any;
  items: BlockItem[];
}) => {
  const groupedItemsByConfig = getGroupedItems({
    config: groupByConfig,
    items,
  });

  return {
    groupedItemsByConfig,
  };
};
