import { BlockItemType } from "src/v2/domain/entities/block/BlockItemType";
import { colors } from "src/v3/theme/colors";
import { mapIssueTypeToIcon } from "src/v3/theme/icons";

export enum ValidIssueTypes {
  Product = "Product",
  Initiative = "Initiative",
  Epic = "Epic",
  Team = "Team",
  Project = "Project",
  SubTask = "SubTask",
  Task = "Task",
  QuickWins = "Quick Wins",
  Investigation = "Investigation",
  Suggestion = "Suggestion",
  Bug = "Bug",
  Story = "Story",
  SupportRequest = "Support Request",
}

const mapLevelToType = {
  task: ValidIssueTypes.Task,
  deliverable: ValidIssueTypes.Epic,
  project: ValidIssueTypes.Initiative,
};

export const getIssueTypeIconProperties = (
  issueType: BlockItemType,
  size: "large" | "medium" | "small"
) => {
  const level = issueType.block_type;
  const levelType = mapLevelToType[level];
  const typeName = issueType.displayName;

  const icon =
    mapIssueTypeToIcon[typeName]?.[size] ||
    mapIssueTypeToIcon[levelType]?.[size];

  const backgroundColor = colors.icons[typeName] || colors.icons[levelType];

  return {
    icon,
    backgroundColor,
  };
};
