import React from "react";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";
import { ReactComponent as PlanningIcon } from "src/assets/icons/planning.svg";
import { ReactComponent as RoadmapIcon } from "src/assets/icons/roadmap.svg";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useLayout } from "src/providers/LayoutContext";
import { Collapse } from "@material-ui/core";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import {
  BarChart,
  Code,
  Email,
  ExitToApp,
  ExplicitRounded,
  Feedback,
  Group,
  MonetizationOn,
  SyncAltRounded,
  TrendingUp,
} from "@material-ui/icons";
import setErrorSnackbar from "src/services/setErrorSnackbar";
import { useAuth0 } from "@auth0/auth0-react";
import * as sessionService from "src/services/sessionService";
import { AlignedRow, BootstrapTooltip } from "../GlobalComponents";
import { CompanyTitle, MenuItemContainer, MenuItemText } from "./styles";
import { getAdminParamFromURLAsString } from "src/v2/utils/url";
import "./index.css";
const drawerWidth = 196;

const getNavBarIcon = (viewName) => {
  switch (viewName) {
    case "By Epic":
    case "Executive":
    case "Executive All":
    case "For Executives":
    case "PED Roadmap Epics":
      return <ExplicitRounded style={{ width: 20, height: 20 }} />;
    case "By Initiatives":
    case "Initiatives":
    case "By Capabilities":
    case "PED Roadmap Initiatives":
      return <BarChart style={{ width: 20, height: 20 }} />;
    case "By Teams":
    case "For Teams":
    case "Teams":
    case "Team Roadmap Status":
      return <Group style={{ width: 20, height: 20 }} />;
    case "Development":
      return <Code style={{ width: 20, height: 20 }} />;
    case "Cost":
      return <MonetizationOn style={{ width: 20, height: 20 }} />;
    case "Releases":
      return <SyncAltRounded style={{ width: 20, height: 20 }} />;
    case "Weekly Reports":
      return <Email style={{ width: 20, height: 20 }} />;
    case "Retro":
      return <Feedback style={{ width: 20, height: 20 }} />;
    case "Product Ops":
    case "PED Goals by Epic":
      return <TrendingUp style={{ width: 20, height: 20 }} />;
    case "Director":
    case "Report":
      return viewName[0].toUpperCase();

    default:
      return viewName[0].toUpperCase();
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1.8),
      ...theme.mixins.toolbar,
    },
    logo: {
      fontSize: "3.8em",
      fontFamily: "PTSerif-Bold",
      marginLeft: "14px",
      color: theme.palette.primary.main,
    },
  })
);
interface MenuItemProps {
  open: boolean;
  children: React.ReactNode;
  text: string;
  route: string;
}

const MenuItem = ({ open, children, text, route }: MenuItemProps) => {
  const { pathname, hash } = useLocation();
  const routeWithoutAdminParam = route.split("?")[0];
  const fullPath = pathname + hash;
  const isSelected = fullPath.includes(routeWithoutAdminParam);
  return (
    <a className="navbar-item" href={route}>
      <MenuItemContainer isSelected={isSelected}>
        {children}
        {open && <MenuItemText>{text}</MenuItemText>}
      </MenuItemContainer>
    </a>
  );
};
export interface LogoProps {
  children: React.ReactNode;
}
const Logo = ({ children }: LogoProps) => {
  const classes = useStyles();
  return <Typography className={classes.logo}>{children}</Typography>;
};

const RoadmapMenu = ({ isNavBarCollapsed }) => {
  const { genericViews } = useGenericViewContext();
  const availableGenericViews = genericViews.filter(({ enabled }) => enabled);
  const { pathname, hash } = useLocation();
  const fullPath = pathname + hash;

  return (
    <div>
      <RoadmapIcon />
      <MenuItem open={!isNavBarCollapsed} route="" text="Roadmap">
        <RoadmapIcon />
      </MenuItem>
      <Collapse
        in
        timeout="auto"
        unmountOnExit
        style={{ marginLeft: "20px", borderLeft: "1px solid #BFCDDD" }}
      >
        {availableGenericViews
          .filter(({ enabled }) => enabled)
          .map((view) => {
            const route = `/v2/roadmap/${view.key}`;
            const adminParam = getAdminParamFromURLAsString();
            const fullRoute = adminParam ? `${route}?${adminParam}` : route;
            const isSelected = fullPath.includes(route);
            return (
              <MenuItem
                key={view.id}
                open={!isNavBarCollapsed}
                route={fullRoute}
                text={view.displayName}
              >
                {isNavBarCollapsed && (
                  <BootstrapTooltip
                    placement="right-end"
                    title={view.displayName}
                  >
                    <a
                      style={{ color: "var(--color-semi-light-grey)" }}
                      className={
                        isSelected ? "navbar-item selected" : "navbar-item"
                      }
                      href={fullRoute}
                    >
                      {getNavBarIcon(view.displayName)}
                    </a>
                  </BootstrapTooltip>
                )}
              </MenuItem>
            );
          })}
      </Collapse>
    </div>
  );
};

export const NavBar: React.FC = () => {
  const classes = useStyles();
  const { isNavBarCollapsed, setIsNavBarCollapsed } = useLayout();
  const handleDrawer = () => {
    setIsNavBarCollapsed((prevState) => !prevState);
    setTimeout(() => window.dispatchEvent(new Event("resize")), 600);
  };
  const customer = new URLSearchParams(window.location.search).get("admin");
  const location = useLocation().pathname;
  const canDisplayNavigationOptions = location !== "/login";

  const isPlanningViewAllowed = customer === "heyvelma";

  const { logout } = useAuth0();

  async function doLogout() {
    try {
      await sessionService.logout();
      logout({ returnTo: window.location.origin });
    } catch (error) {
      setErrorSnackbar(
        "An error has happened in logout. If you continue to see this error, please contact support@heyvelma.com."
      );
    }
  }
  return (
    <Drawer
      id="nav-bar"
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: !isNavBarCollapsed,
        [classes.drawerClose]: isNavBarCollapsed,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: !isNavBarCollapsed,
          [classes.drawerClose]: isNavBarCollapsed,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawer}>
          {isNavBarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <AlignedRow
        style={{
          width: "100%",
          margin: "2em 0 3em 0",
        }}
      >
        <Logo>V</Logo>
        {!isNavBarCollapsed && <CompanyTitle>Velma</CompanyTitle>}
      </AlignedRow>
      {canDisplayNavigationOptions && (
        <>
          <RoadmapMenu isNavBarCollapsed={isNavBarCollapsed} />
          {isPlanningViewAllowed && (
            <MenuItem open={!isNavBarCollapsed} route="/sprint" text="Planning">
              <PlanningIcon />
            </MenuItem>
          )}

          <MenuItemContainer onClick={doLogout}>
            <ExitToApp style={{ width: 23, height: 23 }} onClick={doLogout} />
            {!isNavBarCollapsed && <MenuItemText>Logout</MenuItemText>}
          </MenuItemContainer>
        </>
      )}
    </Drawer>
  );
};
