import { isObjectEmpty } from "src/utils/objectUtils";
import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";

class FieldNotNullFilter extends SectionFilter {
  public filterItem(item: BlockItem): boolean {
    const consider_children = this.config.consider_children;
    if (
      consider_children &&
      this.filterChildren(item, this.itemWithValue.bind(this))
    ) {
      return true;
    }
    return this.itemWithValue(item);
  }

  private itemWithValue(item: BlockItem): boolean {
    const field = item.getFieldByFieldKey(this.fieldKey);
    if (!field) {
      console.log(`Field ${this.fieldKey} not found in item ${item.getKey()}`);
      return false;
    }
    if (Array.isArray(field.value)) {
      // Checks if there is any null value in the array
      if (field.value.includes(null)) {
        console.log(
          `Null value found in field ${this.fieldKey} in item ${item.getKey()}`
        );
      }

      return field.value.some((value) => value && value !== "");
    }
    if (typeof field.value === "object") {
      return !isObjectEmpty(field.value);
    }
  }
}
export default FieldNotNullFilter;
