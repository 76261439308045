import { Box, Typography } from "@material-ui/core";
import { OutcomeField } from "./OutcomeField";
import { isEmpty } from "src/v2/utils/object";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";

export class OutcomeReason extends OutcomeField {
  constructor(field) {
    super(field);
    this.value =
      field.value && !isEmpty(field.value) && field.value["reason"]
        ? [field.value["reason"]]
        : [""];
  }

  getOutcomeReason() {
    return this.value[0];
  }

  getComponentForGrid() {
    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "200px",
          minWidth: 72,
          padding: 8,
        }}
      >
        <BootstrapTooltipWithoutPortal title={this.getOutcomeReason()}>
          <Typography style={{ color: primaryTextColor }} noWrap>
            {this.getOutcomeReason()}
          </Typography>
        </BootstrapTooltipWithoutPortal>
      </Box>
    );
  }
}
