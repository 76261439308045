import { Box, styled, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const ItemRowRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  padding: theme.spacing(3),
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  alignSelf: "stretch",
  borderRadius: theme.spacing(2),
}));

export const Info = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.body2,
  textAlign: "right",
  alignSelf: "stretch",
  color: theme.palette.text.secondary,
}));

export const SideInfosRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: theme.spacing(0.5),
}));

export const ItemRowHeader = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  alignSelf: "stretch",
}));

export const ItemNameRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
  flex: "1 0 0",
}));

export const StatusActionRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
}));

export const ItemRowMeddle = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  paddingLeft: theme.spacing(10),
  alignItems: "flex-start",
  alignSelf: "stretch",
}));

export const ItemRowFooter = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  flexDirection: "row",
  maxWidth: "80%",
  alignSelf: "stretch",
  gap: theme.spacing(2),
  ...theme.typography.body1,
  color: theme.palette.text.secondary,
}));

export const ItemSummaryInfosRoot = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    flex: "1 0 0",
  })
);

export const useStyles = makeStyles((theme: Theme) => ({
  showMoreButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    padding: 0,
    backgroundColor: "transparent",
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    ...theme.typography.body1,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
