import { Box, makeStyles } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { UpdateRounded } from "@material-ui/icons";
import { useState } from "react";
import { primaryTextColor } from "src/v2/helpers/colors";

const useStyles = makeStyles((theme) => ({
  badge: {
    color: theme.palette.info.main,
    "& .MuiBadge-badge": {
      color: theme.palette.common.white,
      backgroundColor: primaryTextColor,
      fontSize: "10px",
      height: 14,
      width: 14,
      minWidth: 14,
      borderRadius: "50%",
    },
  },
}));

export default function UpdatesEpicInfoBadge({ updates, item }) {
  const classes = useStyles();

  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Badge
        overlap="circular"
        badgeContent={updates.length}
        className={classes.badge}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <UpdateRounded style={{ height: 20 }} />
      </Badge>
      {visible && (
        <Box
          display="flex"
          justifyContent="right"
          style={{
            position: "absolute",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            zIndex: 100,
            backgroundColor: "white",
            padding: 10,
            marginLeft: 30,
          }}
        >
          {updates.map((update) => update.getComponent({ item }))}
        </Box>
      )}
    </div>
  );
}
