import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import theme from "src/v3/theme/theme";

import CustomDialog from "src/v3/components/Dialogs/Base";
import CustomDatePicker from "src/v3/components/DatePicker";
import { ProjectStatusSelector } from "src/v3/components/Dialogs/ProjectStatusEdit/ProjectStatusSelector";
import { SelectorGrid, SelectorTitles, StatusSelector } from "./styles";
import { useProjectStatusEdit } from "src/v3/components/Dialogs/ProjectStatusEdit/useProjectStatusEdit";
import { RetainOverride } from "src/v3/components/commomStyles/RetainOverride";

export const EditProjectStatusDialogV2 = ({
  item,
  open,
  handleOpen,
}: {
  item: BlockItem;
  open: boolean;
  handleOpen: () => void;
}) => {
  const statusField = item.getProjectStatusFieldV2();
  const targetDateField = item.getRiskComparisonDateField();
  const {
    onSave,
    resetState,
    handleStateChange,
    state,
    enableSaveButton,
    stateChanged,
    isSaving,
  } = useProjectStatusEdit({ item, targetDateField, statusField });

  const { status, targetDate, justification, retainOverride, expirationDate } =
    state;

  const disabledStyle = {
    opacity: 0.5,
    pointerEvents: "none",
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    resetState();
    handleOpen();
  };

  const handleSave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onSave();
    handleOpen();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      onSave={handleSave}
      isSaving={isSaving}
      title="Edit Predicted Status"
      enableSaveButton={enableSaveButton}
    >
      <Grid
        container
        direction="column"
        style={{
          gap: theme.spacing(6),
        }}
      >
        <SelectorGrid>
          <SelectorTitles>Predicted Status</SelectorTitles>
          <StatusSelector>
            <ProjectStatusSelector
              handleStatusChange={(status) =>
                handleStateChange("status", status)
              }
              status={status}
            />
          </StatusSelector>
        </SelectorGrid>

        <SelectorGrid>
          <SelectorTitles>Target Date</SelectorTitles>
          <CustomDatePicker
            selectedDate={targetDate}
            handleDateChange={(date) => handleStateChange("targetDate", date)}
          />
        </SelectorGrid>

        <SelectorGrid style={stateChanged ? {} : disabledStyle} width="100%">
          <SelectorTitles>Justification</SelectorTitles>
          <FormControl variant="outlined" required>
            <TextField
              id="outlined-multiline-static"
              multiline
              placeholder="Let reviewers know why you are changing the target date or the status."
              variant="outlined"
              value={justification}
              onChange={(e) =>
                handleStateChange("justification", e.target.value)
              }
              inputProps={{ maxLength: 250 }}
              required
              error={!justification && stateChanged}
              helperText={!justification && stateChanged && "Required"}
              disabled={!stateChanged}
            />
          </FormControl>
        </SelectorGrid>

        <Grid style={stateChanged ? {} : disabledStyle}>
          <FormControlLabel
            control={
              <Checkbox
                key={`retainStatus-${Math.random()}`}
                checked={retainOverride}
                onChange={(e) =>
                  handleStateChange("retainOverride", e.target.checked)
                }
                color="primary"
                disabled={!stateChanged}
              />
            }
            label={
              <Typography>Retain this status for a certain duration</Typography>
            }
          />

          {retainOverride && (
            <RetainOverride
              stateChanged={stateChanged}
              expirationDate={expirationDate}
              handleExpirationDateChange={(e) =>
                handleStateChange("expirationDate", e.target.value)
              }
            />
          )}
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
