import { Grid, Link, Typography } from "@material-ui/core";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class DependencyField extends CustomFieldBase {
  private blocks: any[];

  private blockedBy: any[];

  private childDependenciesList: any[];

  private childrenWithDependenciesCount: number;

  constructor(field) {
    super(field);
    this.blocks = field.value?.view_block_dependencies?.blocks ?? [];
    this.blockedBy = field.value?.view_block_dependencies?.blocked_by ?? [];
    this.childDependenciesList = field.value?.child_dependencies ?? [];
    this.childrenWithDependenciesCount = 0;
    this.value = [field.value];
  }

  getChildrenWithDependenciesCount() {
    return this.childrenWithDependenciesCount;
  }

  getBlockedBy() {
    return this.blockedBy;
  }

  getAllItemsThatCurrentIssueBlocks() {
    return this.blocks;
  }

  getComponentForGrid({ setShowChildren, showChildren }) {
    const childrenWithDependencies = this.childDependenciesList.filter(
      (child) => {
        return (
          child?.view_block_dependencies?.blocked_by?.length > 0 ||
          child?.child_dependencies?.filter((child) => {
            console;
            return child?.view_block_dependencies?.blocked_by?.length > 0;
          }).length > 0
        );
      }
    );
    this.childrenWithDependenciesCount = childrenWithDependencies.length;

    return this.getComponent({ setShowChildren, showChildren });
  }

  getComponent({ setShowChildren, showChildren }) {
    return (
      <Grid
        container
        direction="column"
        data-testid="dependency-field"
        style={{ minWidth: 80, width: "fit-content" }}
      >
        {this.getBlockers()}
        {this.childrenWithDependenciesCount > 0 && (
          <Grid
            item
            style={{
              borderRadius: "2px",
              maxWidth: 250,
              marginTop: "24px",
            }}
          >
            <BootstrapTooltipWithoutPortal
              title={`Click to ${showChildren ? "collapse" : "expand"}`}
            >
              <Typography
                style={{ color: `${primaryTextColor}`, fontSize: "16px" }}
                onClick={() => {
                  setShowChildren(!showChildren);
                }}
              >
                {`+ ${this.childrenWithDependenciesCount} ${
                  this.childrenWithDependenciesCount > 1 ? "children" : "child"
                } with dependencies`}
              </Typography>
            </BootstrapTooltipWithoutPortal>
          </Grid>
        )}
      </Grid>
    );
  }

  getBlocksComponent() {
    return (
      <div>
        {this.blocks.length > 0 && (
          <Grid item style={{ marginBottom: "24px", maxWidth: 250 }}>
            <Typography style={{ color: `${primaryTextColor}` }}>
              Blocks
            </Typography>
            {this.blocks?.map((block) => (
              <BootstrapTooltipWithoutPortal title={block.display_name}>
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="body2"
                  href={block.url}
                >
                  <Typography
                    style={{
                      color: `${primaryTextColor}`,
                    }}
                  >
                    {`\u29BF ${block.display_name}`}
                  </Typography>
                </Link>
              </BootstrapTooltipWithoutPortal>
            ))}
          </Grid>
        )}
      </div>
    );
  }

  getBlockedByComponent() {
    return (
      <div>
        {this.blockedBy.length > 0 && (
          <Grid item style={{ maxWidth: 180 }}>
            <Typography
              style={{ color: `${primaryTextColor}`, fontSize: "16px" }}
            >
              Blocked By
            </Typography>
            {this.blockedBy?.map((block) => (
              <BootstrapTooltipWithoutPortal title={block.display_name}>
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="body2"
                  href={block.url}
                >
                  <Typography noWrap style={{ color: `${primaryTextColor}` }}>
                    {`\u29BF ${block.display_name}`}
                  </Typography>
                </Link>
              </BootstrapTooltipWithoutPortal>
            ))}
          </Grid>
        )}
      </div>
    );
  }

  getBlockers() {
    return (
      <div>
        {this.getBlocksComponent()}
        {this.getBlockedByComponent()}
      </div>
    );
  }
}
