import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  matchPath,
} from "react-router-dom";
import { Login } from "src/pages/Login";
import { CompanyLevelFeatures } from "src/providers/FeaturePermissionContext";
import { BlockContextProvider } from "src/v2/providers/block/BlockContext";
import { FieldsContextProvider } from "src/v2/providers/fields/FieldsContext";
import { PrivateRouteV2 } from "src/v2/routes/PrivateRoute";
import { RoadmapContextProvider } from "src/providers/RoadmapContext/RoadmapContext";
import { FilterContextProvider } from "src/v2/providers/filter/FilterContext";
import { GenericViewProvider } from "src/v2/providers/genericView/GenericViewContext";
import { RoadmapGenericViewBuilder } from "src/v2/views/GenericView/RoadmapGenericViewBuilder";
import { SummaryContextProvider } from "src/v2/providers/summary/SummaryContext";
import { useClientConfigContext } from "src/providers/ClientConfigContext";
import * as sessionService from "src/services/sessionService";
import { UserPreferenceProvider } from "src/v2/providers/userPreference/UserPreferenceContext";
import LoginRoute from "./LoginRoute";
import { ItemDetails } from "src/v3/widgets/ItemDetails";
import { LoadBlockContextProvider } from "src/v2/providers/block/LoadBlockContext";

interface RoutesProps {}

export const Routes: React.FC<RoutesProps> = () => {
  const { defaultUrlConfig } = useClientConfigContext();
  const user = sessionService.getLoggedUser();
  const [_defaultUrl, setDefaultUrl] = useState("");

  useEffect(() => {
    if (defaultUrlConfig && user) {
      const defaultUrlForUser =
        Object.keys(defaultUrlConfig ?? {})?.find((url) => {
          if (Array.isArray(defaultUrlConfig[url])) {
            return defaultUrlConfig[url].includes(user.email);
          }
          return false;
        }) ?? defaultUrlConfig?.default_url;

      setDefaultUrl(defaultUrlForUser);
    }
  }, [defaultUrlConfig, user]);

  const location = useLocation();

  const match = matchPath<{ view: string }>(location.pathname, {
    path: "/v2/roadmap/:view",
    exact: false,
    strict: false,
  });

  const view = match?.params.view;

  return (
    <>
      <Switch location={location}>
        <LoginRoute path="/login">
          <Login redirectURL={window.location.href} />
        </LoginRoute>
        <Route exact path="/">
          <Redirect from="/" to={user ? _defaultUrl : "/login"} />
        </Route>
        <PrivateRouteV2
          featureNameForPermissions={CompanyLevelFeatures.roadmapSummaryV2}
          path="/v2/roadmap/:view"
        >
          {view && (
            <GenericViewProvider view={view}>
              <BlockContextProvider>
                <FieldsContextProvider>
                  <FilterContextProvider>
                    <LoadBlockContextProvider>
                      <UserPreferenceProvider>
                        <RoadmapContextProvider>
                          <SummaryContextProvider>
                            <RoadmapGenericViewBuilder />
                            <Switch>
                              <PrivateRouteV2
                                path="/v2/roadmap/:view/details/item/*"
                                featureNameForPermissions={
                                  CompanyLevelFeatures.itemDetails
                                }
                              >
                                <ItemDetails />
                              </PrivateRouteV2>
                            </Switch>
                          </SummaryContextProvider>
                        </RoadmapContextProvider>
                      </UserPreferenceProvider>
                    </LoadBlockContextProvider>
                  </FilterContextProvider>
                </FieldsContextProvider>
              </BlockContextProvider>
            </GenericViewProvider>
          )}
        </PrivateRouteV2>
      </Switch>
    </>
  );
};
