import {
  Button,
  Checkbox,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useSummaryContext } from "src/v2/providers/summary/SummaryContext";
import { DownloadCsvButton } from "../buttons/DownloadCsvButton";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";

export default function MenuListComposition() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { hideShowInsightsButton } = useGenericViewContext();
  const { showInsights, handleShowInsights } = useGenericViewContext();
  const { expandAllItems, handleExpandAllItems } = useSummaryContext();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleInsights = () => handleShowInsights();
  const handleExpandAllItemsTable = () => handleExpandAllItems();
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div
      style={{ display: "flex", alignItems: "center", marginLeft: 16 }}
      data-test-id="options_bar"
    >
      <Button
        variant="outlined"
        color="primary"
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        data-test-id="options_button"
      >
        Options
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ marginTop: 8 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    disabled={expandAllItems}
                    onClick={handleExpandAllItemsTable}
                    data-test-id="expand_all_button"
                  >
                    Expand All
                  </MenuItem>
                  <MenuItem
                    disabled={!expandAllItems}
                    onClick={handleExpandAllItemsTable}
                  >
                    Collapse All
                  </MenuItem>
                  {!hideShowInsightsButton && (
                    <MenuItem onClick={handleInsights}>
                      <Typography>Show Insights</Typography>
                      <Checkbox
                        checked={showInsights}
                        data-test-id="show_insights_button"
                      />
                    </MenuItem>
                  )}

                  <DownloadCsvButton />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
