import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    position: "fixed",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1000,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
    width: 40,
    height: 40,
    borderRadius: "50%",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "transform 2s",
  },
  pulse: {
    animation: "$pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%, 100%": {
      transform: "translateX(-50%) scale(1)",
    },
    "50%": {
      transform: "translateX(-50%) scale(1.01)",
    },
  },
  arrow: {
    position: "absolute",
    transition: "transform 0.8s, opacity 0.8s",
    opacity: 1,
    transform: "translateX(0) rotate(0deg)",
  },
  animatedRight: {
    animation: "$animateArrowRight 1.5s infinite",
  },
  animatedLeft: {
    animation: "$animateArrowLeft 1.5s infinite",
  },
  "@keyframes animateArrowRight": {
    "0%, 100%": {
      opacity: 1,
      transform: "translateX(1px) rotate(0deg)",
    },
    "50%": {
      opacity: 0.3,
      transform: "translateX(-1px) rotate(0deg)",
    },
  },
  "@keyframes animateArrowLeft": {
    "0%, 100%": {
      opacity: 1,
      transform: "translateX(-1px) rotate(180deg)",
    },
    "50%": {
      opacity: 0.5,
      transform: "translateX(1px) rotate(180deg)",
    },
  },
}));

const ScrollHorizontallyButton = ({ tableRef }) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("right");

  const checkTableVisibility = () => {
    if (tableRef.current) {
      const rect = tableRef.current.getBoundingClientRect();
      const isTableVisible =
        rect.top + 96 < window.innerHeight && rect.bottom - 256 > 0;
      const hasHorizontalOverflow = rect.width > window.innerWidth;
      setIsVisible(isTableVisible && hasHorizontalOverflow);
    }
  };

  const handleScroll = () => {
    if (scrollDirection === "right") {
      window.scrollBy({ left: window.innerWidth, behavior: "smooth" });
    } else {
      window.scrollBy({ left: -window.innerWidth, behavior: "smooth" });
    }
  };

  const handleManualScroll = () => {
    const scrollPosition = window.scrollX + window.innerWidth;
    const maxScrollPosition = document.documentElement.scrollWidth;

    if (scrollPosition >= maxScrollPosition) {
      setScrollDirection("left");
    } else {
      setScrollDirection("right");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkTableVisibility);
    window.addEventListener("scroll", handleManualScroll);

    checkTableVisibility();

    return () => {
      window.removeEventListener("scroll", checkTableVisibility);
      window.removeEventListener("scroll", handleManualScroll);
    };
  }, [tableRef]);

  const renderArrow = () => {
    const isLeftDirection = scrollDirection === "left";
    const arrowClasses = `${classes.arrow} ${
      isLeftDirection ? classes.animatedLeft : classes.animatedRight
    }`;

    return (
      <ArrowForwardIosIcon
        fontSize="small"
        className={arrowClasses}
        style={isLeftDirection ? { transform: "rotate(180deg)" } : {}}
      />
    );
  };

  return (
    isVisible && (
      <IconButton
        className={`${classes.button} ${classes.pulse}`}
        onClick={handleScroll}
      >
        {renderArrow()}
      </IconButton>
    )
  );
};

export default ScrollHorizontallyButton;
