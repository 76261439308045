import { Grid, Typography, IconButton, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { borderColor, primaryTextColor } from "src/v2/helpers/colors";

const useStyles = makeStyles(() => ({
  root: {
    border: `1px solid ${borderColor}`,
    marginBottom: "20px",
    marginTop: "20px",
  },
  header: {
    "&:hover": {
      backgroundColor: "var(--color-light-blue)",
    },
    cursor: "pointer",
    padding: 8,
  },
}));

export const FilterExpandableField = ({ field, shouldExpandAll, children }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(shouldExpandAll);

  useEffect(() => {
    setIsExpanded(shouldExpandAll);
  }, [shouldExpandAll]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const ExpandIcon = isExpanded ? <ExpandLess /> : <ExpandMore />;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleExpand}
        className={classes.header}
      >
        <Typography style={{ color: `${primaryTextColor}` }}>
          {field.displayName}
        </Typography>
        <IconButton>{ExpandIcon}</IconButton>
      </Grid>
      {isExpanded && children}
    </Grid>
  );
};
