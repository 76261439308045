import { Grid, makeStyles } from "@material-ui/core";
import { CustomFieldBase } from "../CustomFieldBase";
import { getAccessibleColors, primaryTextColor } from "src/v2/helpers/colors";
import { groupArrayOfObjectsByKeys } from "src/v2/utils/array";
import { Dropdown } from "src/v2/components/Dropdown";
import "../../../../components/Section/RichTextSection.css";
import * as Sentry from "@sentry/react";
import { ArchiveRiskModal } from "./ArchiveRiskModal";
import { UserOverrideFields } from "src/v2/domain/interfaces/UserOverrideFields";
import { AddRiskModal } from "./AddRiskModal";
import { ActionType } from "src/v3/components/ActionMenu/Actions/Factory/types";
import { BlockItem } from "../../block/BlockItem";
import { ActionFactory } from "src/v3/components/ActionMenu/Actions/Factory";

const getColorBySeverity = (severity) => {
  if (severity >= 10 && severity < 20) {
    return "#ff1818";
  } else if (severity >= 20 && severity < 30) {
    return "#ffb703";
  } else if (severity >= 30 && severity < 40) {
    return "#67f502";
  }
  // default to blue
  return "#197bbd";
};

const risks_group_config = { 1: "severity", 0: "message" };

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(0.5),
    borderRadius: 6,
  },
  headerText: {
    fontSize: 14,
    marginLeft: theme.spacing(1),
  },
  mainRoot: {
    width: 320,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  riskReason: {
    padding: theme.spacing(2),
  },
}));

export class RisksField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = field.value ?? {};
  }

  getActions = ({ item, risk }: { item: BlockItem; risk: any }) =>
    [ActionType.ResolveChallenge].map((type) =>
      ActionFactory(type, { item, challenge: risk })
    );

  getValue() {
    const isSameRisk = (risk1, risk2) => {
      const stripNumber = (title) => title.replace(/\s*\(\d+\)$/, "");
      if (risk1.id && risk2.id) {
        return risk1.id === risk2.id;
      }
      return (
        risk1.group === risk2.group &&
        stripNumber(risk1.title) === stripNumber(risk2.title)
      );
    };

    const overrideValues =
      this.getOverrides()?.map((override: UserOverrideFields) => {
        return { ...override.value };
      }) ?? [];

    const addedRisks = overrideValues.filter(({ action }) => action === "add");
    const deletedRisks = overrideValues.filter(
      ({ action }) => action === "delete"
    );

    const filteredAddedRisks = addedRisks.filter(
      (addedRisk) =>
        !deletedRisks.some((deletedRisk) => isSameRisk(addedRisk, deletedRisk))
    );

    const filteredRisks = this.value.filter(
      (risk) =>
        !deletedRisks.some((deletedRisk) => isSameRisk(risk, deletedRisk))
    );

    return [...filteredAddedRisks, ...filteredRisks];
  }

  getComponentForGrid({ item, riskDeletedOverride, riskAddedOverride }) {
    const classes = useStyles();
    let risksGroupedByConfig = {};
    try {
      risksGroupedByConfig = groupArrayOfObjectsByKeys(
        this.getValue(),
        risks_group_config
      );
    } catch (error) {
      Sentry.captureMessage(
        `Error in RisksField - groupArrayOfObjectsByKeys - ${error} - ${this.getValue()} - ${item.getKey()}`
      );
    }

    function processAtLastLevel(
      groupedRisks,
      config = risks_group_config,
      currentLevel = 0
    ) {
      const isLastLevel = currentLevel === Object.keys(config).length - 1;

      if (isLastLevel) {
        return Object.keys(groupedRisks).reduce((acc, key) => {
          acc[key] = mergeOptionsAtLastLevel(groupedRisks[key]);
          return acc;
        }, {});
      } else {
        return Object.keys(groupedRisks).reduce((acc, key) => {
          acc[key] = processAtLastLevel(
            groupedRisks[key],
            config,
            currentLevel + 1
          );
          return acc;
        }, {});
      }
    }

    function mergeOptionsAtLastLevel(items) {
      if (items.length > 1) {
        const mergedOptions = items.reduce((acc, item) => {
          if (item.options && Array.isArray(item.options)) {
            return acc.concat(item.options);
          }
          return acc;
        }, []);

        return [{ ...items[0], options: mergedOptions }];
      }
      return items;
    }

    function renderRisks(risks, level = 0) {
      const isLastLevel = level === Object.keys(risks_group_config).length - 1;
      const isFirstLevel = level === 0;

      const findItem = (data) => {
        if (Array.isArray(data)) {
          return data[0];
        }
        return findItem(Object.values(data)[0]);
      };

      return Object.keys(risks).map((key, index) => {
        const riskData = risks[key];
        const riskItem = findItem(riskData);
        const label = riskItem.message ?? riskItem.title;
        if (!label) {
          return null;
        }

        if (isFirstLevel) {
          const isExpandable =
            riskItem.options &&
            Array.isArray(riskItem.options) &&
            riskItem.options.find(({ type }) => type === "html");
          return (
            <Dropdown
              item={{
                title: label,
              }}
              expandable={isExpandable}
              classesStyle={classes}
              customHeaderStyle={{
                backgroundColor: getAccessibleColors(
                  getColorBySeverity(riskItem.severity)
                ).background,
                color: getAccessibleColors(
                  getColorBySeverity(riskItem.severity)
                ).text,
              }}
            >
              {renderRisks(riskData, level + 1)}
            </Dropdown>
          );
        }

        if (isLastLevel) {
          return (
            <div
              style={{
                backgroundColor: "white",
              }}
              key={index}
            >
              {riskData.map((risk) => (
                <div className={classes.riskReason}>
                  {risk.options &&
                    Array.isArray(risk.options) &&
                    risk.options.map((option, optionIndex) => (
                      <Grid container direction="column">
                        <div
                          key={optionIndex}
                          style={{ color: primaryTextColor }}
                          dangerouslySetInnerHTML={{ __html: option.value }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <ArchiveRiskModal risk={risk} item={item} />
                        </div>
                      </Grid>
                    ))}
                </div>
              ))}
            </div>
          );
        }

        return renderRisks(riskData, level + 1);
      });
    }

    const risks = processAtLastLevel(risksGroupedByConfig);

    return (
      <Grid container direction="column">
        <div className={classes.mainRoot}>{renderRisks(risks)}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "10px",
          }}
        >
          <AddRiskModal item={item} />
        </div>
      </Grid>
    );
  }
}
