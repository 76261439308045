import { Column, Row } from "src/components/GlobalComponents";
import { Box, Typography } from "@material-ui/core";
import { insightsHeaderBackground } from "src/v2/helpers/colors";
import { ReactComponent as VelmaIcon } from "src/assets/icons/velma.svg";
import { useFieldsContext } from "src/v2/providers/fields/FieldsContext";

const VelmaInsightsLegendSection = () => {
  const { velmaInsightsFields } = useFieldsContext();
  const insights = new Set(
    velmaInsightsFields.map(({ displayName }) => displayName)
  );
  return (
    velmaInsightsFields.length > 0 && (
      <div style={{ minWidth: 176, maxWidth: 200 }}>
        <Row>
          <h3>Velma Insights</h3>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <VelmaIcon style={{ width: 56, height: 56 }} />
        </Row>
        <Row style={{ marginTop: 8 }}>
          <Box
            style={{
              width: 24,
              height: 24,
              backgroundColor: insightsHeaderBackground,
              marginRight: 8,
            }}
          />
          <Typography>Blue Columns</Typography>
        </Row>
        <Column style={{ paddingTop: 16 }}>
          {Array.from(insights).map((insight) => (
            <div>
              {">"} {insight}
            </div>
          ))}
        </Column>
      </div>
    )
  );
};

export default VelmaInsightsLegendSection;
