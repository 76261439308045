import { Box, Typography, makeStyles } from "@material-ui/core";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";
import { useWidgetContext } from "../../Widget/WidgetContext";
import "../../Section/RichTextSection.css";
import { primaryTextColor } from "src/v2/helpers/colors";
import BoxToScrollToWidgetName from "./BoxToScrollToWidgetName";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    transition: "transform 0.3s ease", // Smooth transition for the text transform
    "&:hover": {
      transform: "scale(1.05)", // Slightly increase the size on hover
    },
    cursor: "pointer",
  },
  project_name: {
    backgroundColor: "rgb(25, 123, 189)",
    color: "white",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: 16,
    minWidth: 120,
  },
  items: {
    fontSize: "16px",
    fontWeight: "bold",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    height: "100%",
    alignItems: "center",
    padding: 16,
    backgroundColor: "#E8F2F8",
    color: primaryTextColor,
  },
}));

const TotalItemsOverviewNavigation = ({ items }) => {
  const classes = useStyles();

  const { getConfigValueByName } = useWidgetContext();
  const customSectionData = getConfigValueByName(
    WidgetConfigs.customSectionData
  );

  function ProjectName({ title, children }) {
    return (
      <Box style={{ display: "flex" }}>
        <Typography className={classes.project_name}>{title}</Typography>
        {children}
      </Box>
    );
  }

  const totalItems = items.length;
  const label = totalItems > 1 ? "items" : "item";

  return (
    <div className={classes.root}>
      <BoxToScrollToWidgetName>
        <ProjectName title={customSectionData.title}>
          <div className={classes.items}>
            {totalItems} {label}
          </div>
        </ProjectName>
      </BoxToScrollToWidgetName>
    </div>
  );
};

export default TotalItemsOverviewNavigation;
