import { Box, Grid, Typography } from "@material-ui/core";
import { progressBarTitleColor } from "src/v2/helpers/colors";
import { ProgressBarByStatus } from "src/v2/components/progressBar/ProgressBarByStatus";
import { ProgressField } from "./ProgressField";

export class DetailedProgressField extends ProgressField {
  constructor(field) {
    super(field);
  }

  getComponentForGrid({ fieldsState }) {
    const donePercentage = this.percentages?.done;
    const inProgressPercentage = this.percentages?.inProgress;
    const statuses = fieldsState.statuses;

    return (
      <Box
        style={{
          paddingLeft: "5px",
          minWidth: "160px",
          flexDirection: "column",
          maxWidth: 200,
        }}
      >
        <Grid container direction="row" style={{}}>
          {this.totalBugs === undefined ? (
            <Typography
              style={{
                color: `${progressBarTitleColor}`,
              }}
            >
              {`${this.total}`} item{this.total === 1 ? "" : "s"}
            </Typography>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ color: `${progressBarTitleColor}` }}>
                {`${this.totalNotBugs}`} item
                {this.totalNotBugs === 1 ? "" : "s"}
              </Typography>
              {this.totalBugs > 0 && (
                <Typography style={{ color: `${progressBarTitleColor}` }}>
                  {`${this.totalBugs}`} bug{this.totalBugs === 1 ? "" : "s"}
                </Typography>
              )}
            </div>
          )}
        </Grid>
        <ProgressBarByStatus
          itemsGroupedByStatus={this.childrenGroupedByStatus}
          style={{ height: 16, marginTop: 24, marginBottom: 24 }}
          statuses={statuses}
          showPercentages={false}
        />
        <Typography
          style={{ color: `${progressBarTitleColor}` }}
        >{`Complete: ${donePercentage}%`}</Typography>
        <Typography
          style={{ color: `${progressBarTitleColor}` }}
        >{`In Progress: ${inProgressPercentage}%`}</Typography>
      </Box>
    );
  }
}
