import { useEffect, useState } from "react";
import { Snackbar, ThemeProvider } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import {
  BrowserRouter,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";

import ErrorFallback from "./components/ErrorFallback";
import { Row } from "./components/GlobalComponents";
import { NavBar } from "./components/NavBar";
import { Routes } from "./routes/Routes";
import CustomHistory from "./utils/history";
import theme from "./theme";
import SnackbarNotification from "./components/SnackBarNotification";
import { useLayout } from "./providers/LayoutContext";
import { FeaturePermissionsContextProvider } from "./providers/FeaturePermissionContext";
import { ClientConfigContextProvider } from "./providers/ClientConfigContext";
import { GenericViewProvider } from "./v2/providers/genericView/GenericViewContext";
import { Auth0ProviderWithHistory } from "./providers/Auth0Provider";
import { ReAuth } from "./providers/ReAuth";
import { ClientStatusConfigContextProvider } from "./providers/v2/Business/ClientStatusConfigContext";
import { FieldsContextProvider } from "./v2/providers/fields/FieldsContext";
import { URLContextProvider } from "./providers/URLContext";
import { EventProvider } from "./providers/EventContext";
import { OverrideProvider } from "./providers/OverrideContext";

function Alert(props) {
  return <MuiAlert elevation={1} {...props} />;
}

function App() {
  const [snackBarEvent, setSnackBarEvent] = useState<{
    message: string;
    type: "error" | "success" | "info";
    duration?: number;
  }>(undefined);

  const { navBarSpace } = useLayout();

  function SetHistoryInstance() {
    CustomHistory.historyInstance = useHistory();
    CustomHistory.paramsInstance = useParams();
    CustomHistory.locationInstance = useLocation();
    return null;
  }

  useEffect(() => {
    const handleCustomAlert = (e) => {
      setSnackBarEvent({
        message: e.detail.message,
        type: e.detail.type,
        duration: e.detail.duration,
      });
    };

    window.addEventListener("custom_aphrodite_alert", handleCustomAlert);

    return () => {
      window.removeEventListener("custom_aphrodite_alert", handleCustomAlert);
    };
  }, []);

  const mainContentWidth = `calc(100% - ${navBarSpace})`;
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          <SetHistoryInstance />
          <Row id="app-container" style={{ minHeight: "100vh" }}>
            {snackBarEvent && (
              <Snackbar
                open={snackBarEvent !== undefined}
                autoHideDuration={snackBarEvent?.duration ?? 6000}
                onClose={() => setSnackBarEvent(undefined)}
              >
                <Alert
                  onClose={() => setSnackBarEvent(undefined)}
                  severity={snackBarEvent?.type}
                >
                  {snackBarEvent?.message || ""}
                </Alert>
              </Snackbar>
            )}
            <Auth0ProviderWithHistory>
              <ReAuth />
              <ClientConfigContextProvider>
                <ClientStatusConfigContextProvider>
                  <EventProvider>
                    <OverrideProvider>
                      <GenericViewProvider>
                        <URLContextProvider>
                          <FieldsContextProvider>
                            <FeaturePermissionsContextProvider>
                              <NavBar />
                              <div
                                id="main-content"
                                style={{
                                  width: mainContentWidth,
                                  padding: "0",
                                }}
                              >
                                <Routes />
                              </div>
                            </FeaturePermissionsContextProvider>
                          </FieldsContextProvider>
                        </URLContextProvider>
                      </GenericViewProvider>
                    </OverrideProvider>
                  </EventProvider>
                </ClientStatusConfigContextProvider>
              </ClientConfigContextProvider>
            </Auth0ProviderWithHistory>
            <SnackbarNotification />
          </Row>
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
