import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import {
  CloseIconButton,
  DrawerHeader,
  HeaderContentBox,
  HeaderItemTitle,
  HeaderSideInfos,
  InfoGrid,
  ProgressBarRoot,
  useStyles,
} from "./styles";
import { ItemSummary } from "src/v3/components/commomStyles/ItemSummary";
import { Grid, Divider, Link } from "@material-ui/core";
import { truncateStringInTheEnd } from "src/utils/stringUtils";
import { BreadCrumbsBuilder } from "src/v3/components/Breadcrumbs/builder";
import { IssueTypeIcon } from "src/v3/components/IssueTypeIcon";
import { DateSideInfo } from "src/v3/components/ItemRow";
import { ProgressBar } from "src/v3/components/ProgressBar/index";
import { StatusChipBuilder } from "src/v3/components/StatusChip/builder";
import { StatusChipTypes } from "src/v3/components/StatusChip/types";
import { CloseButton } from "src/v3/theme/icons";

export const ItemDetailsDrawerHeader = ({
  item,
  handleClose,
  breadCrumbPaths,
  childrenStatusAggregation,
}: {
  item: BlockItem;
  handleClose: () => void;
  breadCrumbPaths: any[];
  childrenStatusAggregation: any[];
}) => {
  const classes = useStyles();
  const itemURL = item.getNameField()?.getURL();

  return (
    <DrawerHeader>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <BreadCrumbsBuilder paths={breadCrumbPaths} type={"navigation"} />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          direction="column"
          className={classes.statusCloseIconGrid}
        >
          <CloseIconButton onClick={handleClose} size="small">
            <CloseButton />
          </CloseIconButton>

          <StatusChipBuilder
            type={StatusChipTypes.ProjectStatus}
            status={
              item.getProjectStatusField() || item.getWorkflowStatusField()
            }
            size={"medium"}
          />
        </Grid>
      </Grid>

      <HeaderContentBox>
        <IssueTypeIcon
          issueType={item.type}
          size="large"
          customClassName={classes.issueTypeIcon}
        />
        <HeaderItemTitle>
          {itemURL && itemURL !== "" ? (
            <Link href={itemURL} target="_blank">
              {truncateStringInTheEnd(item.displayName, 55)}
            </Link>
          ) : (
            truncateStringInTheEnd(item.displayName, 55)
          )}
          <ItemSummary>{item.summary}</ItemSummary>
        </HeaderItemTitle>

        <HeaderSideInfos>
          <ProgressBarRoot>
            <ProgressBar aggregation={childrenStatusAggregation} />
          </ProgressBarRoot>
          <InfoGrid>
            <DateSideInfo
              label={"Projected"}
              date={item.getProjectedDateField()?.getProjectedDate()}
            />
            <Divider orientation="vertical" />
            <DateSideInfo
              label={"Team's target"}
              date={item.getTargetDateField()?.getTargetDate()}
            />
          </InfoGrid>
        </HeaderSideInfos>
      </HeaderContentBox>
    </DrawerHeader>
  );
};
