import { Box, makeStyles } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { WarningRounded } from "@material-ui/icons";
import { useState } from "react";
import EpicInfoBase from "src/v2/domain/entities/epicInfo/EpicInfoBase";
import { primaryTextColor } from "src/v2/helpers/colors";

const useStyles = makeStyles((theme) => ({
  badge: {
    color: theme.palette.warning.main,
    "& .MuiBadge-badge": {
      color: theme.palette.common.white,
      backgroundColor: primaryTextColor,
      fontSize: "10px",
      height: 12,
      width: 12,
      minWidth: 12,
      borderRadius: "50%",
    },
  },
}));
export default function WarningEpicInfoBadge({
  warnings,
}: {
  warnings: EpicInfoBase[];
}) {
  const classes = useStyles();

  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Badge
        overlap="circular"
        badgeContent={warnings.length}
        className={classes.badge}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <WarningRounded style={{ height: 20 }} />
      </Badge>
      {visible && (
        <Box
          display="flex"
          justifyContent="right"
          style={{
            position: "absolute",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            zIndex: 100,
            backgroundColor: "white",
            padding: 10,
            marginLeft: 30,
          }}
        >
          {warnings.map((warning) => warning.getComponent({}))}
        </Box>
      )}
    </div>
  );
}
