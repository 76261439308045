import React, { Fragment, useState, useCallback, useEffect } from "react";
import {
  RenderItemRowProps,
  TeamAccordionProps,
  TeamListProps,
  TeamsListAccordionHeaderProps,
} from "./types";
import { CustomAccordion } from "src/v3/components/Accordion";
import {
  TeamHeaderProgressBar,
  TeamListFakeAccordion,
  TeamsListRoot,
} from "./styles";
import { IssueTypeIcon } from "src/v3/components/IssueTypeIcon";
import { ItemName } from "src/v3/components/commomStyles/ItemName";
import { FakeAccordion } from "src/v3/components/FakeAccordion";
import {
  HideShowNonRoadmapItems,
  InfoNonRoadmapItems,
  NonRoadmapHeader,
} from "src/v3/components/RoadmapNonRoadmapChip/styles";
import theme from "src/v3/theme/theme";
import { AccordionItemsPadding } from "src/v3/components/commomStyles/AccordionItemsPadding";
import {
  ItemBandwidthSideInfo,
  ItemRow,
  DateSideInfo,
} from "src/v3/components/ItemRow";
import { StatusChipTypes } from "src/v3/components/StatusChip/types";
import { SortContainer } from "src/v3/dataContainers/SortContainer";
import { InfoFilled, InfoFilledWarning } from "src/v3/theme/icons";
import EmptyState from "src/v3/components/EmptyState";
import { useGroupByTeam } from "./useGroupByTeam";
import { AccordionHeaderRoot } from "src/v3/components/commomStyles/AccordionHeaderRoot";
import { BreadCrumbsBuilder } from "src/v3/components/Breadcrumbs/builder";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useItemDetailNavigation } from "src/v3/providers/useItemDetailNavigation";
import { ProgressBar } from "src/v3/components/ProgressBar";
import { EventTypes, useEventListener } from "src/providers/EventContext";
import { BlockItemType } from "src/v2/domain/entities/block/BlockItemType";
import { useTeamItemList } from "./useTeamItemList";
import { RoadmapNonRoadmapChip } from "src/v3/components/RoadmapNonRoadmapChip";

const RenderItemRows = ({ items, bandwidthMap }) => {
  return SortContainer({ items }).map(
    (child: BlockItem, childIndex: number) => (
      <RenderItemRow
        key={child.getKey()}
        item={child}
        index={childIndex}
        bandwidthMap={bandwidthMap}
      />
    )
  );
};

const RenderItemRow: React.FC<RenderItemRowProps> = ({
  item,
  index,
  bandwidthMap,
}) => {
  const { handleOpenItemDetails } = useItemDetailNavigation({ item });
  const bandwidth = bandwidthMap[item.getKey()] || 0;
  const targetDate = item.getTargetDateField()?.getTargetDate();
  const actions = [];
  const statusField = item.getProjectStatusField();
  if (statusField) {
    actions.push(statusField.getEditStatusAction(item));
  }

  const summaryOfWorkField = item.getSummaryOfWorkField();
  if (summaryOfWorkField) {
    actions.push(summaryOfWorkField.getEditSummaryOfWorkAction(item));
  }

  return (
    <ItemRow
      key={index}
      displayName={item.displayName}
      summaryField={summaryOfWorkField}
      statusField={statusField}
      issueType={item.type}
      statusChipType={StatusChipTypes.ProjectStatus}
      sideInfos={[
        <DateSideInfo key="date" label="Team's target" date={targetDate} />,
        <ItemBandwidthSideInfo key="bandwidth" bandwidth={bandwidth} />,
      ]}
      actions={actions}
      onNameClick={handleOpenItemDetails}
    />
  );
};

const TeamsListAccordionHeader: React.FC<TeamsListAccordionHeaderProps> = ({
  team,
  projectStatusAggregation,
  roadmapPercentages: { onRoadmap, notRoadmap },
}) => {
  const teamType = new BlockItemType({
    display_name: "Team",
    view_block_type: "team",
    color: "",
    origin: "velma",
    desc_order: 0,
    parent_type: "",
  });

  return (
    <AccordionHeaderRoot>
      <IssueTypeIcon issueType={teamType} size="small" />
      <ItemName>{team}</ItemName>
      {/* <RoadmapNonRoadmapChip
        percentages={{
          onRoadmap,
          notRoadmap,
        }}
      /> */}
      <TeamHeaderProgressBar>
        <ProgressBar aggregation={projectStatusAggregation} />
      </TeamHeaderProgressBar>
    </AccordionHeaderRoot>
  );
};

const TeamItems: React.FC<TeamAccordionProps> = React.memo(
  ({ items, team }) => {
    const {
      roadmapItems,
      nonRoadmapItems,
      bandwidthMap,
      projectStatusAggregation,
      roadmapBandwidthPercentage,
      nonRoadmapBandwidthPercentage,
    } = useTeamItemList({ items, team });

    const projectStatusUpdate = useEventListener(
      EventTypes.PROJECT_STATUS_OVERRIDE,
      "TeamItems"
    );

    const summaryOfWorkUpdate = useEventListener(
      EventTypes.AI_SUMMARY_OVERRIDE,
      "TeamItems"
    );

    useEffect(() => {
      if (projectStatusUpdate) {
        console.log("TeamItems - EventTypes.PROJECT_STATUS_OVERRIDE");
      }
      console.log("TeamItems - EventTypes.AI_SUMMARY_OVERRIDE");
    }, [projectStatusUpdate, summaryOfWorkUpdate]);

    const [showNonRoadmap, setShowNonRoadmap] = useState(false);
    const handleShowNonRoadmap = useCallback(() => {
      setShowNonRoadmap((prev) => !prev);
    }, []);

    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

    const showNonRoadmapAlert = nonRoadmapBandwidthPercentage > 80;
    const InfoIcon = showNonRoadmapAlert ? InfoFilledWarning : InfoFilled;

    const handleToggleAccordion = useCallback(() => {
      setIsAccordionExpanded((prev) => !prev);
    }, []);

    return (
      <TeamsListRoot>
        <CustomAccordion
          header={
            <TeamsListAccordionHeader
              team={team}
              projectStatusAggregation={projectStatusAggregation}
              roadmapPercentages={{
                onRoadmap: roadmapBandwidthPercentage,
                notRoadmap: nonRoadmapBandwidthPercentage,
              }}
            />
          }
          isExpanded={isAccordionExpanded}
          onToggle={handleToggleAccordion}
          hasChildren={roadmapItems.length > 0 || nonRoadmapItems.length > 0}
        >
          {isAccordionExpanded && (
            <AccordionItemsPadding>
              {roadmapItems.length > 0 && (
                <RenderItemRows
                  items={roadmapItems}
                  bandwidthMap={bandwidthMap}
                />
              )}
              {nonRoadmapItems.length > 0 && (
                <TeamListFakeAccordion onClick={handleShowNonRoadmap}>
                  <FakeAccordion
                    header={
                      <NonRoadmapHeader>
                        <InfoNonRoadmapItems>
                          <InfoIcon />
                          {nonRoadmapBandwidthPercentage}% of this team’s
                          bandwidth is spent on non-roadmap projects
                        </InfoNonRoadmapItems>
                        <HideShowNonRoadmapItems>
                          {`${showNonRoadmap ? "Hide" : "Show"}`} non-roadmap
                          projects
                        </HideShowNonRoadmapItems>
                      </NonRoadmapHeader>
                    }
                    isExpanded={showNonRoadmap}
                    customStyle={{
                      backgroundColor: showNonRoadmapAlert
                        ? theme.palette.error.lighter
                        : undefined,
                    }}
                  />
                </TeamListFakeAccordion>
              )}

              {showNonRoadmap && (
                <RenderItemRows
                  items={nonRoadmapItems}
                  bandwidthMap={bandwidthMap}
                />
              )}
            </AccordionItemsPadding>
          )}
        </CustomAccordion>
      </TeamsListRoot>
    );
  }
);

export const TeamsList: React.FC<TeamListProps> = React.memo(
  ({ items }: { items: BlockItem[] }) => {
    const { groupedItems } = useGroupByTeam({ items });
    if (!groupedItems?.length) {
      return (
        <EmptyState
          message="No items match the given filter"
          icon={undefined}
        />
      );
    }

    return (
      <>
        {groupedItems.map(
          ({
            name,
            groupedItems,
          }: {
            name: string;
            groupedItems: { items: BlockItem[]; fieldValue: string }[];
          }) => (
            <Fragment key={name}>
              <BreadCrumbsBuilder type="group" groups={[name]} />
              {groupedItems.map(({ items, fieldValue }) => (
                <TeamItems key={fieldValue} items={items} team={fieldValue} />
              ))}
            </Fragment>
          )
        )}
      </>
    );
  }
);
