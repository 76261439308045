import { FilterFieldBase } from "src/v2/domain/entities/filter/FilterFieldBase";
import { useHistory } from "react-router-dom";
import EmailReportsKeys from "src/v2/domain/entities/emailReports/EmailReportsKeys";
import { FilterLogic } from "src/v2/domain/enum/FilterLogic";

export const useURLFilterHook = () => {
  const history = useHistory();

  const setSelectedFiltersInURL = (selectedFilters: FilterFieldBase[]) => {
    const extraAllowedParams = [
      "admin",
      EmailReportsKeys.emailReportType,
      EmailReportsKeys.useReportsFilter,
      EmailReportsKeys.email,
      EmailReportsKeys.summary_width,
    ]
      .map((parameterKey) => ({
        parameterKey,
        parameterValue: new URLSearchParams(window.location.search).get(
          parameterKey
        ),
      }))
      .filter(({ parameterValue }) => !!parameterValue);

    const ANDfilterFieldsURLParams = selectedFilters
      .filter(({ filterLogic }) => filterLogic !== FilterLogic.OR)
      .map((filterField) => filterField.getUrlSearchParams())
      .reduce((acc, curr) => `${curr}&${acc}`, "")
      .toString();

    const ORfilterFieldsURLParams = selectedFilters
      .filter(({ filterLogic }) => filterLogic === FilterLogic.OR)
      .map((filterField) => filterField.getUrlSearchParams())
      .reduce((acc, curr) => `${curr}~${acc}`, "")
      .toString();

    const urlParams = ANDfilterFieldsURLParams.concat(
      ORfilterFieldsURLParams !== "" ? `OR=${ORfilterFieldsURLParams}` : ""
    );

    const urlSearchParams = new URLSearchParams(urlParams);

    if (extraAllowedParams.length) {
      extraAllowedParams.forEach(({ parameterKey, parameterValue }) => {
        urlSearchParams.set(parameterKey, parameterValue);
      });
    }

    history.replace({
      pathname: location.pathname,
      search: `?${urlSearchParams}`,
    });
  };

  return {
    setSelectedFiltersInURL,
  };
};
