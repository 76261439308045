export const NOTIFICATION_TYPES = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const Features = {
  trackingToRetro: "tracking_to_retro",
  retroToTracking: "retro_to_tracking",
  finishPlanning: "finish_planning",
  closeSprint: "close_sprint",
  pushJiraChanges: "push_jira_changes",
};

export const RoadmapSummaryItemsToHide = {
  progressBar: "progress_bar",
};
