import { Grid, Link, Typography } from "@material-ui/core";
import { getAccessibleColors, primaryTextColor } from "src/v2/helpers/colors";
import { useEffect, useState } from "react";
import { useWidgetContext } from "../../Widget/WidgetContext";
import { getPlannedCompletionConfig } from "../../Widget/plannedCompletion";
import ProgressBar from "../../progressBar/ProgressBar";
import { pluralize } from "src/utils/stringUtils";
import { useProjectStatusProgressData } from "../ProjectStatusProgressSection/useProjectStatusProgressData";
import { useClientConfigContext } from "src/providers/ClientConfigContext";

function PlannedCompletionProgressBar({ items }) {
  const { _section } = useWidgetContext();
  const { jiraURL } = useClientConfigContext();

  const { progressStatusProgress } = useProjectStatusProgressData({
    items,
    section: _section,
    parentSections: [],
  });

  const [percentages, setPercentages] = useState([]);

  useEffect(() => {
    if (progressStatusProgress?.aggregatedValues?.length) {
      const data = progressStatusProgress.aggregatedValues
        .sort((a, b) => a.position - b.position)
        .map(({ type, percentage, totalItems, itemKeys }) => {
          const config = getPlannedCompletionConfig().find(
            ({ type: configType }) => configType === type
          );

          let url = "";
          if (jiraURL && itemKeys.length > 0) {
            url = `https://${jiraURL}/issues/?jql=key in (${itemKeys.join(",")})`;
          }

          return {
            totalItems,
            percentage: Math.round(percentage),
            name: config?.name,
            color: config?.color,
            position: config?.position,
            issue_keys: itemKeys,
            url,
          };
        });
      setPercentages(data.sort((a, b) => a.position - b.position));
    }
  }, [progressStatusProgress]);

  const customStyles = _section.options?.find(
    ({ name }) => name === "styles"
  )?.value;

  const title = _section.options?.find(
    ({ name }) => name === "child-title"
  )?.value;

  const { root, childTitle } = customStyles || {};

  const getColor = (color) => {
    const accessibleColors = getAccessibleColors(color, 0.5);
    return {
      textColor: accessibleColors.text,
      backgroundColor: accessibleColors.background,
      ballColor: color,
    };
  };

  function renderLegends() {
    return (
      <Grid
        container
        direction="row"
        style={{
          gap: 20,
        }}
      >
        {percentages.map((percentage) => {
          const { text, background } = getAccessibleColors(percentage.color);
          return (
            <div
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                display: "flex",
                borderRadius: 5,
                color: `${text}`,
                backgroundColor: `${background}`,
                padding: "8px",
              }}
            >
              {percentage.url && percentage.url !== "" ? (
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  href={percentage.url}
                >
                  <div
                    style={{
                      color: `${text}`,
                      backgroundColor: `${background}`,
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    <div>
                      {`${percentage.name}: ${percentage.totalItems} ${pluralize({ count: percentage.totalItems, word: "item" })} (${percentage.percentage.toFixed(0)}%)`}
                    </div>
                  </div>
                </Link>
              ) : (
                <div
                  style={{
                    color: `${text}`,
                    backgroundColor: `${background}`,
                    display: "flex",
                    gap: 8,
                  }}
                >
                  <div>
                    {`${percentage.name}: ${percentage.totalItems} ${pluralize({ count: percentage.totalItems, word: "item" })} (${percentage.percentage.toFixed(0)}%)`}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Grid>
    );
  }

  return (
    percentages?.length > 0 && (
      <Grid
        container
        direction="column"
        style={{ ...root }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {title && (
            <Typography
              variant="h6"
              style={{
                color: `${primaryTextColor}`,
                marginBottom: 8,
                marginRight: 16,
                ...childTitle,
              }}
            >
              {title}
            </Typography>
          )}
          <ProgressBar
            percentages={percentages.map((item) => ({
              ...item,
              color: getColor(item.color).backgroundColor,
            }))}
            backgroundColor={"lightGrey"}
            customHeight="32px"
            showProgressBarLegends={false}
            customBarsGridStyle={{
              flexWrap: "nowrap",
            }}
            customBoxStyle={{
              marginBottom: 20,
              width: "50%",
            }}
          />
        </Grid>
        <div style={{ display: "flex" }}>{renderLegends()}</div>
      </Grid>
    )
  );
}

export default PlannedCompletionProgressBar;
