import { FC, useRef } from "react";
import { Table, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import {
  tableHeaderColor,
  primaryTextColor,
  insightsBackground,
} from "src/v2/helpers/colors";
import { useLayout } from "src/providers/LayoutContext";
import { useFieldsContext } from "src/v2/providers/fields/FieldsContext";
import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";
import useVisibility from "src/v2/hooks/useVisibility";
import { getCurrentRowBackgroundColor } from "./tableLayout.utils";
import { useSummaryContext } from "src/v2/providers/summary/SummaryContext";

const useStyles = makeStyles({
  thead: {
    backgroundColor: tableHeaderColor,
    borderRadius: "0",
    boxShadow: "0px 1px 4px 1px rgb(212 212 212 / 67%)",
    transition: "all 0.3s ease-out",
    position: "sticky",
    top: 64,
    zIndex: 2,
  },
  tr: {
    height: "42px",
  },
  thText: {
    color: `${primaryTextColor}`,
    fontSize: "15px",
    margin: "0 16px",
    textTransform: "capitalize",
  },
});

interface SecondLevelTableProps {
  item: BlockItem;
  columns: CustomFieldBase[];
  gridProps;
}

export const SecondLevelTable: FC<SecondLevelTableProps> = (props) => {
  const { item, columns, gridProps } = props;
  const tableRef = useRef(null);

  const classes = useStyles();
  const { normalizedHeaderHeight } = useLayout();

  const { isFieldInVelmaInsights, getFieldStyles } = useFieldsContext();

  const [isVisible, currentElement] = useVisibility(2 * window.innerHeight);
  const rowBackgroundColor = getCurrentRowBackgroundColor(item.type?.value);

  const { getHeaderStyle } = useSummaryContext();

  return (
    <Table data-test-id="summary_table" ref={tableRef}>
      <Thead
        style={{ top: normalizedHeaderHeight }}
        className={classes.thead}
        data-test-id="summary_table_header"
      >
        <Tr className={classes.tr}>
          {columns?.map((header, index) => (
            <Th style={getHeaderStyle(header, index)}>
              <Grid
                container
                direction="row"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography align="left" className={classes.thText}>
                  {header.displayName}
                </Typography>
              </Grid>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tr
        id={item.getKey()}
        className="fade-in"
        style={{
          background: `${rowBackgroundColor}`,
          boxShadow: "0px 0px 30px 4px #0000000D",
        }}
      >
        {columns?.map((field: CustomFieldBase, index) => (
          <Td
            style={{
              width: "auto",
              backgroundColor:
                isFieldInVelmaInsights(field) && insightsBackground,
              ...getFieldStyles({ field }),
            }}
            key={`${field?.type}+${index}`}
          >
            <div
              style={{
                ...getFieldStyles({ field }),
                padding: "15px",
                display: isVisible ? "block" : "none",
              }}
              ref={currentElement}
            >
              {field?.getComponentForGrid(gridProps)}
            </div>
          </Td>
        ))}
      </Tr>
    </Table>
  );
};
