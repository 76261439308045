import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import SectionBuilder from "./SectionBuilder";

export const ParentDeconstruct = ({
  items,
  section,
  parentSections,
}: {
  items: BlockItem[];
  section: any;
  parentSections: any;
}) => {
  const allChildrenOfItems = items.map((item) => item.children).flat();
  const { child } = section;
  return (
    <SectionBuilder
      section={child}
      items={allChildrenOfItems}
      parentSections={parentSections}
      setShowWidget={undefined}
    />
  );
};
