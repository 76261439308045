import { useState, useRef, useCallback } from "react";

const useClickDragToggle = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });

  const handleMouseDown = useCallback((e) => {
    dragStartPos.current = { x: e.clientX, y: e.clientY };
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (
      Math.abs(e.clientX - dragStartPos.current.x) > 5 ||
      Math.abs(e.clientY - dragStartPos.current.y) > 5
    ) {
      setIsDragging(true);
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    if (!isDragging) {
      setIsToggled((prevState) => !prevState);
    }
  }, [isDragging]);

  return {
    isToggled,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
};

export default useClickDragToggle;
