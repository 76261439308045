import * as Sentry from "@sentry/react";
import { log } from "@fullstory/browser";
import setErrorSnackbar from "src/services/setErrorSnackbar";

export const logError = (error, errorMessage, snackBarDuration = 6000) => {
  Sentry.captureException(error);
  log(error);
  setErrorSnackbar(errorMessage, snackBarDuration);
  return Promise.reject(error);
};

export const sentryCaptureException = (errorMessage) => {
  Sentry.captureException(errorMessage);
  console.error(errorMessage);
};
