import { Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  loadingWidget: {
    zoom: 0.9,
    display: "grid",
    alignContent: "center",
    justifyItems: "center",
    paddingTop: 128,
  },
  velmaIcon: {
    width: 128,
    height: 128,
  },
  "@keyframes pulse": {
    "0%, 100%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.1)",
      opacity: 0.5,
    },
  },
  mainText: {
    fontWeight: 600,
    lineHeight: 1.6,
    textAlign: "center",
    color: "#4a4a4a",
    marginBottom: 64,
  },
  tipText: {
    marginTop: 16,
    color: "#333",
    textAlign: "center",
    fontSize: 22,
  },
}));

const calculateTimeLeft = (endDate) => {
  const difference = +new Date(endDate) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const UnderMaintenanceView = ({ viewData }) => {
  const classes = useStyles();
  const { options, key } = viewData;
  const endDate = options?.find(
    (option) => option.name === "date_maintenance_end"
  )?.value;
  const isForcedMaintenance = options?.find(
    (option) => option.name === "is_forced_maintenance"
  )?.value;

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      if (new Date() >= new Date(endDate) && !isForcedMaintenance) {
        window.location.reload(); // Reload the page when current time is equal or greater than end date
      } else {
        setTimeLeft(calculateTimeLeft(endDate));
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [endDate, timeLeft]); // Add endDate as a dependency
  const countdownDisplay =
    timeLeft.hours || timeLeft.minutes || timeLeft.seconds
      ? `Time Left - ${timeLeft.hours || 0}h ${timeLeft.minutes || 0}m ${
          timeLeft.seconds || 0
        }s`
      : "Maintenance is over. Please refresh the page.";

  return (
    <div
      data-test-id={`generic-view-root-${key}`}
      style={{ backgroundColor: "rgb(248, 252, 255)", height: "100vh" }}
    >
      <div className={classes.loadingWidget}>
        <Typography variant="h1">🚧</Typography>
        <Typography variant="h4" className={classes.mainText}>
          Page Under Maintenance
        </Typography>
        <Typography variant="body1" className={classes.tipText}>
          We are currently performing scheduled maintenance to improve your
          experience. <br />
          We apologize for any inconvenience this may cause.
        </Typography>
        {!isForcedMaintenance && (
          <Typography
            variant="body1"
            className={classes.tipText}
            style={{ fontWeight: 600 }}
          >
            {countdownDisplay}
          </Typography>
        )}
        <Typography
          variant="body1"
          className={classes.tipText}
          style={{ marginTop: 64 }}
        >
          Thank you for your patience and understanding. We look forward to
          welcoming you back soon!
        </Typography>
      </div>
    </div>
  );
};
