import { CustomFieldBase } from "./CustomFieldBase";

export class OnlyReadOnFilter extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = [field.value ?? {}];
  }

  getComponentForGrid(_) {
    return null;
  }
}
