import {
  AppBar,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { ManualUpdateDoughnutChart } from "src/v2/components/ManualUpdatedComponents/ManualUpdateDoughnutChart";
import { ManualUpdateProjectStatus } from "src/v2/components/ManualUpdatedComponents/ManualUpdateProjectStatus";
import { ManualUpdateAISummary } from "src/v2/components/ManualUpdatedComponents/ManualUpdateAISummary";
import { ManualUpdateProgressbar } from "src/v2/components/ManualUpdatedComponents/ManualUpdateProgressBar";
import { ManualUpdateTable } from "src/v2/components/ManualUpdatedComponents/ManualUpdateTable";

import { useState } from "react";
import { manualUpdateProjectStatusMapper } from "src/v2/components/ManualUpdatedComponents/ProjectStatusMapper";
import {
  SWO_OCT_2023_DoughnutChart,
  SWO_OCT_2023_Release,
  SWO_OCT_2023_ClientIssues,
  SWO_OCT_2023_Bugs,
  SWO_OCT_2023_Applications,
  SWO_OCT_2023_Database,
} from "./SWO OCT 2023 - Data";
import {
  SWO_NOV_2023_DoughnutChart,
  SWO_NOV_2023_Release,
  SWO_NOV_2023_ClientIssues,
  SWO_NOV_2023_Bugs,
  SWO_NOV_2023_Applications,
  SWO_NOV_2023_Database,
} from "./SWO NOV 2023 - Data";
import {
  HCO_2023_DoughnutChart,
  HCO_2023_Release,
  HCO_2023_ClientIssues,
  HCO_2023_Bugs,
  HCO_2023_Table,
} from "./HCO 2023 - Data";
import { primaryTextColor } from "src/v2/helpers/colors";
import {
  HCO_Regression_DoughnutChart,
  HCO_Regression_Release,
  HCO_Regression_ClientIssues,
  HCO_Regression_Bugs,
  HCO_Regression_Table,
} from "./HCO 2023 Regression - Data";

export const SolarwindsExecutive = () => {
  const [expanded, setExpanded] = useState({
    SWO_October: true,
    SWO_November: false,
    HCO: false,
  });

  const handleExpand = (key) => {
    setExpanded((prevState) => {
      const updatedExpanded = { ...prevState };

      // Set the clicked key to true
      updatedExpanded[key] = true;

      // Set all other keys to false
      for (const k in updatedExpanded) {
        if (k !== key) {
          updatedExpanded[k] = false;
        }
      }

      return updatedExpanded;
    });
  };

  const tabsData = [
    {
      key: "SWO_October",
      title: "SWO October 2023",
      color: "grey",
      status: {
        name: "No Projects",
        type: "not_set_status",
      },
      showHeader: true,
      data: {
        doughnutChart: SWO_OCT_2023_DoughnutChart,
        release: SWO_OCT_2023_Release,
        clientIssues: SWO_OCT_2023_ClientIssues,
        bugs: SWO_OCT_2023_Bugs,
        applications: SWO_OCT_2023_Applications,
        database: SWO_OCT_2023_Database,
      },
    },
    {
      key: "SWO_November",
      title: "SWO November 2023",
      color: "orange",
      status: {
        name: "At Risk",
        type: "at_risk_status",
      },
      showHeader: true,
      data: {
        doughnutChart: SWO_NOV_2023_DoughnutChart,
        release: SWO_NOV_2023_Release,
        clientIssues: SWO_NOV_2023_ClientIssues,
        bugs: SWO_NOV_2023_Bugs,
        applications: SWO_NOV_2023_Applications,
        database: SWO_NOV_2023_Database,
      },
    },
    {
      key: "HCO",
      title: "HCO Release",
      color: "red",
      status: {
        name: "Off Track",
        type: "at_risk_status",
      },
      data: {
        doughnutChart: HCO_2023_DoughnutChart,
        release: HCO_2023_Release,
        clientIssues: HCO_2023_ClientIssues,
        bugs: HCO_2023_Bugs,
        applications: HCO_2023_Table,
        database: null,
      },
    },
    {
      key: "HCO Regression",
      title: "HCO Regression",
      color: "red",
      status: {
        name: "Off Track",
        type: "at_risk_status",
      },
      data: {
        doughnutChart: HCO_Regression_DoughnutChart,
        release: HCO_Regression_Release,
        clientIssues: HCO_Regression_ClientIssues,
        bugs: HCO_Regression_Bugs,
        applications: HCO_Regression_Table,
        database: null,
      },
    },
  ];

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const renderTabs = () => {
    return (
      <div
        style={{
          marginTop: -50,
          maxWidth: "100%",
          width: "fit-content",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <AppBar position="static" color="default">
          <Tabs variant="scrollable" scrollButtons="auto">
            {tabsData.map((tab) => (
              <div
                style={{
                  margin: "0px 16px",
                  transform: expanded[tab.key] ? "translateY(-2px)" : "",
                  transition: "transform 0.3s ease-in-out",
                  borderBottom: expanded[tab.key]
                    ? `1px solid ${tab.color}`
                    : "",
                }}
              >
                <Tab
                  label={tab.title}
                  value={tab.key}
                  icon={
                    <div
                      style={{
                        height: 15,
                        backgroundColor: tab.color,
                        width: "100%",
                        borderRadius: 5,
                        fontSize: 12,
                        color: "white",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {manualUpdateProjectStatusMapper(tab.status).name}
                    </div>
                  }
                  {...a11yProps(tab.key)}
                  onClick={() => handleExpand(tab.key)}
                />
              </div>
            ))}
          </Tabs>
        </AppBar>
      </div>
    );
  };

  const renderContent = () => {
    return tabsData.map(
      (tab) => expanded[tab.key] && <SectionContent tab={tab} />
    );
  };

  return (
    <Grid container justifyContent={"center"}>
      {renderTabs()}
      <Typography
        style={{ width: "100%", marginTop: 50, color: "grey", fontSize: 14 }}
      >
        Last Updated: Nov 21, 2023
      </Typography>
      <div
        style={{
          height: 1,
          backgroundColor: "lightGrey",
          width: "100%",
        }}
      ></div>
      {<div style={{ width: "100%" }}>{renderContent()}</div>}
    </Grid>
  );
};

const SectionContent = ({ tab }) => {
  const { data, title, showHeader } = tab;
  const { doughnutChart, release, clientIssues, bugs, applications, database } =
    data;
  return (
    <div>
      <div
        style={{
          margin: "50px 0 50px 24px",
          fontSize: 30,
          color: primaryTextColor,
        }}
      >
        {title}
      </div>
      {showHeader && (
        <div style={{ marginBottom: 80 }}>
          <Grid container>
            <div
              style={{
                display: "flex",
                width: "20%",
                justifyContent: "center",
              }}
            >
              <ManualUpdateDoughnutChart {...doughnutChart} />
            </div>
            <div
              style={{
                display: "flex",
                width: "75%",
                height: "100%",
                justifyContent: "center",
                gap: 30,
              }}
            >
              {/* Release */}
              <Grid container direction="column">
                <ManualUpdateProjectStatus {...release.project_status} />

                <ManualUpdateProgressbar
                  {...release.progress}
                ></ManualUpdateProgressbar>
                <div style={{ padding: 16 }}>
                  <ManualUpdateAISummary html={release.ai_summary} />
                </div>
              </Grid>
              {/* Client Issues */}
              <Grid container direction="column">
                <ManualUpdateProjectStatus {...clientIssues.project_status} />
                <ManualUpdateProgressbar
                  {...clientIssues.progress}
                ></ManualUpdateProgressbar>
                <div style={{ padding: 16 }}>
                  <ManualUpdateAISummary html={clientIssues.ai_summary} />
                </div>
              </Grid>
              {/* Bugs */}
              <Grid container direction="column">
                <ManualUpdateProjectStatus {...bugs.project_status} />
                <ManualUpdateProgressbar
                  {...bugs.progress}
                ></ManualUpdateProgressbar>
                <div style={{ padding: 16 }}>
                  <ManualUpdateAISummary html={bugs.ai_summary} />
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      )}
      {applications && (
        <div style={{ marginBottom: 80 }}>
          <ManualUpdateTable {...applications}></ManualUpdateTable>
        </div>
      )}
      {database && <ManualUpdateTable {...database}></ManualUpdateTable>}
    </div>
  );
};
