import * as Sentry from "@sentry/react";

import { useBlockContext } from "src/v2/providers/block/BlockContext";
import DataSection from "./DataSection";
import FilterSection from "./FilterSection";
import ProjectStatusLegendSection from "./ProjectStatusLegendSection";
import VelmaInsightsLegendSection from "./VelmaInsightsLegendSection";
import StatusLegend from "./StatusLegendSection";
import TableSection from "./TableSection";
import TotalItemsSection from "./TotalItemsSection";
import OriginalItemsSection from "./OriginalItemsSection";
import ProjectStatusProgressSection from "./ProjectStatusProgressSection/ProjectStatusProgressSection";
import ProjectsCompletedSection from "./ProjectsCompletedSection";
import ChartBuilder from "./ChartFactory/ChartBuilder";
import DaysInStatus from "./DaysInStatus";
import { SolarwindsExecutive } from "src/v2/ManualUpdatedViews/Solarwinds/views/Executive/SolarwindsExecutive";
import TeamAISummarySection from "./TeamAISummary/TeamAISummarySection";
import TeamSummaryProjectStatusProgressCard from "./ProjectStatusProgressSection/TeamSummaryProjectStatusProgressSection";
import ItemsFilteredTeamAISummarySection from "./TeamAISummary/ItemsFilteredTeamAISummarySection";
import CostByTeamCard from "./CostByTeamCard";
import ScrollTop from "./ScrollTop";
import HorizontalScrollNavigator from "./HorizontalScrollNavigator";
import InsightsAggregationSection from "./InsightAggregation/InsightsAggregationSection";
import OverviewNavigationBuilder from "./OverviewNavigation/OverviewNavigationSection";
import AutoGeneratedChangesSection from "./AutoGeneratedChanges";
import ProgressBarSectionFactory from "./ProgressBar/progressBarFactory";
import { ParentDeconstruct } from "./ParentDeconstruct";
import RichTextSection from "./RichTextSection/RichTextSection";

const SectionBuilder = ({ section, parentSections, items, setShowWidget }) => {
  const { originalItems } = useBlockContext();
  const { sectionType } = section;
  switch (sectionType) {
    case "original_items":
      return (
        <OriginalItemsSection
          section={section}
          items={originalItems}
          parentSections={parentSections}
        />
      );
    case "data":
      return (
        <DataSection
          section={section}
          items={items}
          parentSections={parentSections}
          setShowWidget={setShowWidget}
        />
      );
    case "chart":
      return (
        <ChartBuilder
          section={section}
          items={items}
          parentSections={parentSections}
        />
      );
    case "days_in_status":
      return (
        <DaysInStatus
          section={section}
          items={items}
          parentSections={parentSections}
        ></DaysInStatus>
      );
    case "projects_completed":
      return (
        <ProjectsCompletedSection
          section={section}
          items={items}
          parentSections={parentSections}
        ></ProjectsCompletedSection>
      );
    case "project_status_progress":
      return (
        <ProjectStatusProgressSection
          section={section}
          items={items}
          parentSections={parentSections}
        />
      );
    case "team_summary_project_status_progress":
      return (
        <TeamSummaryProjectStatusProgressCard
          section={section}
          items={items}
          parentSections={parentSections}
        />
      );
    case "cost_by_team":
      return <CostByTeamCard section={section} items={items} />;
    case "project_status_legend":
      return <ProjectStatusLegendSection section={section} items={items} />;
    case "status_legend":
      return <StatusLegend />;
    case "total_items":
      return (
        <TotalItemsSection
          section={section}
          items={items}
          parentSections={[]}
        />
      );
    case "table":
      return <TableSection section={section} items={items} />;
    case "filter":
      return (
        <FilterSection section={section} items={items} parentSections={[]} />
      );
    case "velma_insights_legend":
      return <VelmaInsightsLegendSection />;
    case "rich_text":
      return <RichTextSection />;
    case "team_ai_summary":
      return <TeamAISummarySection />;
    case "items_filter_team_ai_summary":
      return (
        <ItemsFilteredTeamAISummarySection items={items} showTeamBoardName />
      );
    case "empty":
      return <span />;
    case "insights_aggregation":
      return <InsightsAggregationSection items={items} />;
    case "overview_navigation":
      return (
        <OverviewNavigationBuilder
          items={items}
          section={section}
          parentSections={parentSections}
        />
      );
    case "solarwinds_executive":
      return <SolarwindsExecutive />;
    case "scroll_top":
      return <ScrollTop />;
    case "horizontal_scroll_map":
      return <HorizontalScrollNavigator />;
    case "auto_generated_changes":
      return (
        <AutoGeneratedChangesSection
          items={items}
          parentSections={parentSections}
        />
      );
    case "progress_bar":
      return (
        <ProgressBarSectionFactory items={items}></ProgressBarSectionFactory>
      );
    case "parent_deconstruct":
      return (
        <ParentDeconstruct
          items={items}
          section={section}
          parentSections={parentSections}
        ></ParentDeconstruct>
      );

    default:
      Sentry.captureException(`Section type not found: ${sectionType}`);
      console.error("Section type not found: ", sectionType);
      return null;
  }
};
export default SectionBuilder;
