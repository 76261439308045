import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const TableSkeleton = () => {
  return (
    <Box p={2}>
      <Box p={2}>
        <Skeleton variant="rect" width="100%" height={250} />
      </Box>
      <Box p={2}>
        <Skeleton
          variant="rect"
          width="100%"
          height={50}
          style={{ marginBottom: 8 }}
        />
        <Skeleton
          variant="rect"
          width="100%"
          height={150}
          style={{ marginBottom: 2 }}
        />
        <Skeleton
          variant="rect"
          width="100%"
          height={150}
          style={{ marginBottom: 2 }}
        />{" "}
        <Skeleton
          variant="rect"
          width="100%"
          height={150}
          style={{ marginBottom: 2 }}
        />{" "}
        <Skeleton
          variant="rect"
          width="100%"
          height={150}
          style={{ marginBottom: 2 }}
        />{" "}
        <Skeleton
          variant="rect"
          width="100%"
          height={150}
          style={{ marginBottom: 2 }}
        />{" "}
      </Box>
    </Box>
  );
};

export default TableSkeleton;
