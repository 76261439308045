import { Grid, Typography } from "@material-ui/core";
import ChangelogByDateOptions from "src/v2/components/filter/components/ChangelogByDateOptions";
import { primaryTextColor } from "src/v2/helpers/colors";
import { FilterFieldBase } from "./FilterFieldBase";

export class ChangelogByDateFilterField extends FilterFieldBase {
  constructor(field) {
    super(field);
    this.values = field.values ?? ["1 week"];
    this.valuesCopy = field.values ?? ["1 week"];
  }

  matchItem() {
    return true;
  }

  setPossibleValuesBasedOnItems() {
    return this.values;
  }

  getFilterComponent() {
    return (
      <Grid
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        style={{ padding: "10px 0" }}
        container
      >
        <Typography style={{ color: primaryTextColor }}>
          Changes from
        </Typography>
        <ChangelogByDateOptions field={this} />
      </Grid>
    );
  }
}
