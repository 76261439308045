import { ProjectStatusField } from "src/v3/entities/CustomFields/ProjectStatusField";
import { StatusField } from "src/v3/entities/CustomFields/StatusField";
import theme from "src/v3/theme/theme";
import { mapTypeToLegendName } from "src/v3/utils/ProjectStatus/allProjectStatuses";

export const getAggregatedData = (aggregation) =>
  aggregation.map(({ status, percentage, itemKeys }) => ({
    color: status?.getLegendColor() || theme.palette.grey[300],
    percentage,
    name: getStatusName(status),
    itemKeys,
  }));

const getStatusName = (status) => {
  if (status instanceof ProjectStatusField) {
    return mapTypeToLegendName[status.getStatusType()];
  }
  if (status instanceof StatusField) {
    return status.getStatusCategory();
  }
  return "";
};
