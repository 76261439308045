import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";
import * as Sentry from "@sentry/react";

class ByAllocationFilter extends SectionFilter {
  public filterItem(item: BlockItem): boolean {
    const allocationField = item.getAllocationField();
    if (!allocationField) return false;
    const allocation = allocationField.getAllocation();
    const minAllocationValue = this.config["min_allocation_value"];
    if (!minAllocationValue) {
      Sentry.captureException(
        "minAllocationValue is not set in ByAllocationFilter config"
      );
      return false;
    }
    return allocation >= minAllocationValue;
  }
}

export default ByAllocationFilter;
