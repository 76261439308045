import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelectFilterOptionHook } from "src/v2/hooks/filter/useSelectFilterOptionHook";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";
import { getFilterFieldFromURLByKey } from "src/v2/utils/url";

export default function ChangelogByDateOptions({ field }) {
  const { handleSelectChangelogDateOption } = useSelectFilterOptionHook();
  const { selectedFilters } = useFilterContext();

  function getFilterFieldValue() {
    const urlField = getFilterFieldFromURLByKey(field.fieldKey);
    if (urlField?.values?.length) return urlField.values[0];

    return field.getDefaultValues()[0];
  }

  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    const defaultValue = getFilterFieldValue();
    setSelectedOption(defaultValue || "");
  }, [selectedFilters]);

  function handleSelect(selectedValues) {
    handleSelectChangelogDateOption({
      field,
      selectedValues,
    });
    setSelectedOption(selectedValues);
  }

  return (
    <FormControl size="small" variant="outlined" style={{ width: "136px" }}>
      <Select
        value={selectedOption || ""}
        onChange={(e) => handleSelect(e.target.value)}
      >
        {[
          "1 day",
          "1 week",
          "2 weeks",
          "3 weeks",
          "4 weeks",
          "1 month",
          "2 months",
          "3 months",
          "12 months",
        ].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
