import { Menu } from "@material-ui/core";
import {
  getLegendColor,
  getTextColor,
} from "src/v3/utils/ProjectStatus/colors";
import { SmallStatusChip, StatusName } from "../../StatusChip/styles";
import { useState } from "react";
import {
  ProjectStatusSelectorMenuItem,
  ProjectStatusSelectorRoot,
  useStyles,
} from "./styles";
import { ChevronLeft } from "@material-ui/icons";

export const ProjectStatusSelector = ({
  status,
  handleStatusChange,
}: {
  status;
  handleStatusChange;
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const _options = ["Off Track", "At Risk", "On Track", "Done", "Paused"];
  return (
    <>
      <ProjectStatusSelectorRoot
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        backgroundColor={getLegendColor(status)}
        endIcon={
          <ChevronLeft
            style={{
              fill: getTextColor(status),
              transform: Boolean(anchorEl) ? "rotate(90deg)" : "rotate(-90deg)",
            }}
          />
        }
      >
        <SmallStatusChip>
          <StatusName color={getTextColor(status)}>{status}</StatusName>
        </SmallStatusChip>
      </ProjectStatusSelectorRoot>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuOpen }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {_options.map((option, index) => (
          <ProjectStatusSelectorMenuItem
            key={index}
            value={option}
            onClick={() => {
              handleStatusChange(option);
              handleClose();
            }}
          >
            <SmallStatusChip backgroundColor={getLegendColor(option)}>
              <StatusName color={getTextColor(option)}>{option}</StatusName>
            </SmallStatusChip>
          </ProjectStatusSelectorMenuItem>
        ))}
      </Menu>
    </>
  );
};
