import axios from "axios";
import {
  requestInterceptor,
  responseErrorInterceptor,
} from "src/services/interceptors";

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL_V2,
  withCredentials: true,
  timeout: 300000,
});

instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export default instance;
