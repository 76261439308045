import { ChevronDown, ChevronUp } from "src/v3/theme/icons";
import theme from "src/v3/theme/theme";

export const ItemDetailAccordion = ({
  title,
  actions,
  children,
  expanded,
  footer,
  setExpanded,
}: {
  title: string;
  actions?: JSX.Element[];
  children: React.ReactNode;
  expanded: boolean;
  footer?: React.ReactNode;
  setExpanded: () => void;
}) => {
  const expandIcon = !!children ? (
    expanded ? (
      <ChevronUp />
    ) : (
      <ChevronDown />
    )
  ) : null;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      {/* Header */}
      <div
        onClick={setExpanded}
        style={{
          display: "flex",
          padding: theme.spacing(3),
          alignItems: "center",
          gap: theme.spacing(3),
          alignSelf: "stretch",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            overflow: "hidden",
            color: theme.palette.grey[900],
            textOverflow: "ellipsis",
            ...theme.typography.h2,
          }}
        >
          {title}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: theme.spacing(3),
            flex: "1 0 0",
          }}
        >
          {actions?.length > 0 && actions.map((action) => action)}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: theme.spacing(1),
              cursor: "pointer",
            }}
          >
            {expandIcon}
          </div>
        </div>
      </div>
      {expanded && children && (
        <div
          style={{
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            alignSelf: "stretch",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
