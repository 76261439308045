import { useMemo } from "react";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

const calculateTotalAllocation = (items: BlockItem[]): number =>
  items.reduce(
    (acc, item) => acc + (item?.getAllocationField()?.getAllocation() || 0),
    0
  );

const calculateItemBandwidth = (
  item: BlockItem,
  totalAllocation: number
): number => {
  const itemAllocation = item.getAllocationField()?.getAllocation() || 0;
  return totalAllocation === 0
    ? 0
    : Math.round((itemAllocation / totalAllocation) * 100);
};

export const useBandwidth = (items?: BlockItem[]) => {
  if (!items?.length) return { bandwidthMap: {}, totalBandwidth: 0 };

  const { bandwidthMap, totalBandwidth } = useMemo(() => {
    const totalAllocation = calculateTotalAllocation(items);
    const bandwidthMap: Record<string, number> = {};

    if (totalAllocation > 0) {
      items.forEach((item) => {
        const bandwidth = calculateItemBandwidth(item, totalAllocation);
        bandwidthMap[item.getKey()] = bandwidth;
      });
    }

    const totalBandwidth = Object.values(bandwidthMap).reduce(
      (acc, bandwidth) => acc + bandwidth,
      0
    );

    return { bandwidthMap, totalBandwidth };
  }, [items]);

  return {
    bandwidthMap,
    totalBandwidth,
  };
};
