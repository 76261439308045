import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import ItemsFilteredTeamAISummarySection from "src/v2/components/Section/TeamAISummary/ItemsFilteredTeamAISummarySection";
import CustomDialog from "src/v2/components/CustomDialog";
import "../../../../components/Section/RichTextSection.css";
import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { BlockItem } from "../../block/BlockItem";
import { CustomFieldBase } from "../CustomFieldBase";
import { formatDate } from "src/utils/stringUtils";
import { changesSubtitle, primaryTextColor } from "src/v2/helpers/colors";
import { useState } from "react";
import { AISummaryDetailsModal } from "./AISummaryDetailsModal";
import { getAllKeyValueFiltersFromURL } from "src/v2/utils/url";
import { EditSummaryOfWorkActionV2 } from "src/v2/components/EditSummaryOfWorkActionV2";

const useStyles = makeStyles({
  additionDetailButton: {
    color: primaryTextColor,
    fontSize: "14px",
    fontWeight: 400,
    textDecoration: "underline",
  },
});

export class HTMLField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = field.value ?? "";
    this.value = this.makeTeamSummaryClickable();
  }

  hasOverride(): boolean {
    const override = this.getLastAddedOverride();
    return override?.value?.context && override?.value?.context !== "";
  }

  makeTeamSummaryClickable() {
    const clickableText =
      '<span key="team_summary_link" style="color: #197bbd; cursor: pointer; text-decoration: underline;">Team Summary</span>';
    const clickableHtml = this.value.replace(/team summary/gi, clickableText);

    return clickableHtml;
  }
  getComponentForGrid({
    item,
    aiSummaryExpands,
    aiSummaryOverride,
  }: {
    item: BlockItem;
    aiSummaryExpands: any;
    aiSummaryOverride: any;
  }) {
    const { openTeamSummaryModal, setOpenTeamSummaryModal } = aiSummaryExpands;
    const _isEmail = getAllKeyValueFiltersFromURL()?.find(
      ({ fieldKey }) => fieldKey === "email"
    )?.values;
    const isEmail = _isEmail && _isEmail[0] === "false" ? false : true;
    const summaryWidth =
      getAllKeyValueFiltersFromURL()?.find(
        ({ fieldKey }) => fieldKey === "summary_width"
      )?.values[0] ?? 600;

    const override = this.getLastAddedOverride();
    const overrideValue = override?.value;

    function handleClick(e) {
      const clickedElement = e.target;
      e.stopPropagation();
      if (clickedElement.getAttribute("key") === "team_summary_link") {
        setOpenTeamSummaryModal(true);
      }
    }

    // Define the truncateHTML function that takes an HTML string and a character limit
    function truncateHTML(html, truncateLimit) {
      let count = 0;
      let isTag = false;
      let truncated = "";

      if (html?.length === 0) {
        return "";
      }

      if (html?.length <= truncateLimit) {
        return html;
      }

      for (let i = 0; i < html.length; i++) {
        const char = html[i];

        if (char === "<") {
          isTag = true;
        } else if (char === ">") {
          isTag = false;
        } else if (!isTag) {
          count++;
        }

        truncated += char;

        if (count >= truncateLimit) {
          // Find the next closing tag to avoid truncating in the middle of a tag
          const nextCloseTagPos = html.indexOf(">", i);
          if (nextCloseTagPos !== -1) {
            // Include the entire tag in the truncated string
            truncated += html.slice(i + 1, nextCloseTagPos + 1);
          }

          // Insert ellipsis before the last closing tag if we're at the end of truncated content
          const lastCloseTagPos = truncated.lastIndexOf(">");
          const isLastCharClosingTag =
            lastCloseTagPos !== -1 && lastCloseTagPos === truncated.length - 1;
          if (isLastCharClosingTag) {
            // Place the ellipsis before the closing tag
            truncated =
              truncated.substring(0, lastCloseTagPos) +
              "..." +
              truncated.substring(lastCloseTagPos);
          } else {
            // Append the ellipsis at the end if there's no closing tag
            truncated += "...";
          }

          break;
        }
      }

      return truncated;
    }
    const showVelmaSummary = overrideValue?.showVelmaSummary ?? true;
    const truncatedHTML = truncateHTML(showVelmaSummary ? this.value : "", 70);

    const TeamSummaryModal = ({ item }) => {
      const team = item.getFieldByPossibleTypes([
        FieldConfigType.team,
        FieldConfigType.team_link,
      ]);

      if (!team) {
        return null;
      }

      const teamName = team.value[0] ?? "";

      return (
        <CustomDialog
          open={openTeamSummaryModal}
          onClose={(e) => {
            e.stopPropagation();
            setOpenTeamSummaryModal(false);
          }}
          title={`${teamName} Team Summary`}
        >
          <div id="team-summary-portal">
            <ItemsFilteredTeamAISummarySection
              items={[item]}
              showTeamBoardName={false}
              boardName={
                item.getFieldByPossibleTypes([
                  FieldConfigType.team,
                  FieldConfigType.team_link,
                ])?.value[0] ?? ""
              }
            ></ItemsFilteredTeamAISummarySection>
          </div>
        </CustomDialog>
      );
    };

    const hasOverride = overrideValue?.context && overrideValue?.context !== "";

    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    const EmailSummary = () => {
      return (
        <Grid
          container
          direction="column"
          style={{
            width: summaryWidth,
          }}
        >
          {hasOverride && (
            <Grid container direction="column">
              <TeamAdditionalContextHeader />
              <div
                onClick={(e) => handleClick(e)}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "rgb(57, 94, 119)",
                  fontSize: "1rem",
                  lineHeight: "1.5",
                  textAlign: "left",
                  letterSpacing: "0.00938em",
                  marginTop: 16,
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
                dangerouslySetInnerHTML={{
                  __html: override.value.context,
                }}
              />
            </Grid>
          )}

          {this.value && hasOverride && showVelmaSummary && (
            <Grid container direction="column">
              <Divider
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                }}
              ></Divider>
              <Typography
                onClick={(e) => handleClick(e)}
                style={{
                  color: primaryTextColor,
                  fontSize: "16px",
                  marginBottom: 8,
                }}
              >
                Velma Summary Details
              </Typography>
            </Grid>
          )}
          {this.value && showVelmaSummary && (
            <div
              onClick={(e) => handleClick(e)}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "rgb(57, 94, 119)",
                fontSize: "1rem",
                lineHeight: "1.5",
                textAlign: "left",
                letterSpacing: "0.00938em",
              }}
              dangerouslySetInnerHTML={{
                __html: this.value,
              }}
            />
          )}
          <EditSummaryOfWorkActionV2 item={item} />
          <TeamSummaryModal item={item}></TeamSummaryModal>
        </Grid>
      );
    };

    const TeamAdditionalContextHeader = () => {
      return (
        <Grid container direction="column">
          <Typography
            onClick={(e) => handleClick(e)}
            style={{
              color: primaryTextColor,
              fontSize: "16px",
            }}
          >
            Team Additional Details
          </Typography>
          <Typography
            style={{
              color: changesSubtitle,
              fontSize: "12px",
              lineHeight: "1.5",
              textAlign: "left",
              letterSpacing: "0.00938em",
            }}
          >
            Last updated by {overrideValue?.author?.name} on{" "}
            {formatDate(overrideValue?.created_at)}
          </Typography>
        </Grid>
      );
    };

    if (isEmail) {
      return <EmailSummary />;
    }

    return (
      <Grid
        container
        direction="column"
        style={{
          minWidth: "250px",
        }}
      >
        {hasOverride ? (
          <TeamAdditionalContextHeader />
        ) : (
          truncatedHTML !== "" && (
            <div
              onClick={(e) => handleClick(e)}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "rgb(57, 94, 119)",
                fontSize: "1rem",
                lineHeight: "1.5",
                textAlign: "left",
                width: 300,
                letterSpacing: "0.00938em",
              }}
              dangerouslySetInnerHTML={{
                __html: truncatedHTML,
              }}
            />
          )
        )}

        <Grid
          container
          alignContent="center"
          justifyContent="space-between"
          alignItems="center"
          style={{
            marginTop: "16px",
          }}
        >
          {(hasOverride || truncatedHTML !== "") && (
            <div>
              <Typography
                onClick={(e) => {
                  e.stopPropagation();
                  setDetailsModalOpen(true);
                }}
                key="expand_ai_summary"
                style={{
                  color: "#197bbd",
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: "14px",
                }}
              >
                See More
              </Typography>
              <AISummaryDetailsModal
                item={item}
                override={override}
                fieldValue={showVelmaSummary ? this.value : ""}
                open={detailsModalOpen}
                onClose={(e) => {
                  e.stopPropagation();
                  setDetailsModalOpen(false);
                }}
              />
            </div>
          )}
          <EditSummaryOfWorkActionV2 item={item} />
        </Grid>
        <TeamSummaryModal item={item}></TeamSummaryModal>
      </Grid>
    );
  }
}
