import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDataSection } from "./hooks/useDataSection";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { MoneyField } from "src/v2/domain/entities/customField/MoneyField";

const CostByTeamCard = ({ section, items }) => {
  const { groupedByValue, options, filters, groupBy } = section;

  const optionsStyles =
    options?.find((option) => option.name === "styles")?.value || {};

  const titleStyle = optionsStyles?.title || {};

  const { filteredItems } = useDataSection({
    filters,
    groupBy,
    items,
  });

  const hasProjectedTotalCostField =
    filteredItems[0]?.getProjectedTotalCostField();

  const hasCostToDateField = filteredItems[0]?.getCostToDateField();

  const getValueByFieldKey = (fieldKey: string) => {
    return filteredItems
      .reduce((acc, item: BlockItem) => {
        const cost = item.getFieldByFieldKey(fieldKey) as MoneyField;
        const value = cost?.getIntegerValue() || 0;
        return acc + value;
      }, 0)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const projectedTotalCostAggregation = getValueByFieldKey(
    "projected_total_cost"
  );
  const costToDateAggregation = getValueByFieldKey("cost_to_date");

  return (
    <Card style={{ padding: 16 }}>
      <Typography style={{ ...titleStyle }}>{groupedByValue}</Typography>
      <Divider style={{ margin: 16 }} />
      <Grid style={{ fontSize: 15, color: "rgb(57, 94, 119)" }}>
        {hasCostToDateField && (
          <Grid container alignItems="center">
            <div style={{ marginRight: 8, fontSize: 15 }}>Cost to date: </div>
            <Typography variant={"body1"}>${costToDateAggregation}</Typography>
          </Grid>
        )}
        {hasProjectedTotalCostField && (
          <Grid container alignItems="center">
            <div style={{ marginRight: 8, fontSize: 15 }}>
              Projected Total Cost:{" "}
            </div>
            <Typography variant={"body1"}>
              ${projectedTotalCostAggregation}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default CostByTeamCard;
