import { useState } from "react";
import { InfoOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    cursor: "pointer",
    position: "relative",
    display: "flex",
  },
  legend: {
    transform: "translateX(-50%)",
    left: "50%",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "6px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    position: "absolute",
    visibility: "hidden", // Hide the tooltip initially
    opacity: 0,
    transition: "visibility 0s, opacity 0.3s ease-in-out",
  },
  visible: {
    visibility: "visible", // Show the tooltip when visible class is added
    opacity: 1,
  },
}));

const IconWithLegend = ({
  content,
  iconStyle = {},
  containerStyle = {},
  contentStyle = {},
}) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className={classes.iconContainer} style={{ ...containerStyle }}>
      <InfoOutlined
        style={{ width: 16, marginLeft: 4, color: "#395e77", ...iconStyle }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <div
        className={`${classes.legend} ${isHovered ? classes.visible : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ ...contentStyle }}
      >
        {content}
      </div>
    </div>
  );
};

export default IconWithLegend;
