/* eslint-disable react/prop-types */
import { Collapse, Divider, Grid, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useState } from "react";
import jiraFormatting from "jira2md";
import { changesSubtitle, primaryTextColor } from "src/v2/helpers/colors";

export default function ChangeInField({ fieldChange }) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  function getUsersListChangeInString(changeInString) {
    if (changeInString) {
      return changeInString.replace("[", "").replace("]", "");
    }
    return null;
  }
  function isValidDate(str) {
    // Regular expression patterns for each valid date format
    const patterns = [
      /^\d{1,2}\/[a-zA-Z]{3}\/\d{2}$/,
      /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{1,3}$/,
    ];

    let isValid = false;
    patterns.forEach((pattern) => {
      if (pattern.test(str)) {
        isValid = true;
      }
    });

    return isValid;
  }
  function formatToStandardDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  function getFromAndToStrings(fieldChange) {
    const fieldDataSchema = fieldChange.data_schema;
    const { at, change } = fieldChange;
    const { fromString, toString, from, to } = change;
    const defaultChange = {
      from: fromString,
      to: toString,
      label: fieldChange.key,
      at: formatToStandardDate(at),
    };
    if (fieldDataSchema === "dependencies") {
      if (
        fromString?.includes("is blocked by") ||
        toString?.includes("is blocked by")
      ) {
        defaultChange.from = from;
        defaultChange.to = to;
        defaultChange.label = "Dependencies";
      }
      if (fromString?.includes("is blocked by") && !toString) {
        defaultChange.from = fromString;
        defaultChange.to = "None";
        defaultChange.label = "Dependencies";
      }
      return defaultChange;
    }
    if (fieldDataSchema === "users_list") {
      defaultChange.from = getUsersListChangeInString(fromString);
      defaultChange.to = getUsersListChangeInString(toString);
    }
    if (fieldChange.type === "expandable_text") {
      defaultChange.from = fromString && (
        <span
          dangerouslySetInnerHTML={{
            __html: jiraFormatting.jira_to_html(fromString),
          }}
        />
      );
      defaultChange.to = toString && (
        <span
          dangerouslySetInnerHTML={{
            __html: jiraFormatting.jira_to_html(toString),
          }}
        />
      );
    }
    if (isValidDate(fromString)) {
      defaultChange.from = formatToStandardDate(fromString);
    }
    if (isValidDate(toString)) {
      defaultChange.to = formatToStandardDate(toString);
    }
    return defaultChange;
  }

  const { from, to, label, at } = getFromAndToStrings(fieldChange);
  return (
    <Grid
      style={{
        marginTop: "16px",
        color: primaryTextColor,
        maxWidth: 350,
        minWidth: 200,
      }}
    >
      <Grid
        direction="row"
        alignItems="center"
        container
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ cursor: "pointer", zIndex: 1 }}
      >
        <div
          style={{
            height: 8,
            width: 8,
            borderRadius: "100%",
            background: "black",
            marginRight: 8,
          }}
        />
        <Typography>
          <b>{label}</b> <small>({at})</small>
        </Typography>
        {isExpanded ? (
          <ExpandMore />
        ) : (
          <ExpandLess style={{ transform: "rotate(90deg)" }} />
        )}
      </Grid>
      <Collapse in={isExpanded}>
        <Grid style={{ marginLeft: 16 }}>
          <Typography
            style={{
              color: changesSubtitle,
              lineHeight: 1,
              marginTop: 8,
              marginBottom: 4,
              fontSize: "0.8rem",
            }}
          >
            FROM
          </Typography>
          <Typography
            style={{
              marginBottom: 8,
            }}
          >
            {from || "None"}
          </Typography>
          <Divider />
          <Typography
            style={{
              color: changesSubtitle,
              marginTop: "12px",
              lineHeight: 1,
              marginBottom: 4,
              fontSize: "0.8rem",
            }}
          >
            TO
          </Typography>
          <Typography>{to}</Typography>
        </Grid>
      </Collapse>
    </Grid>
  );
}
