import { FC, useEffect, useState, useRef } from "react";
import { Tbody } from "react-super-responsive-table";
import { useGroupBy } from "src/v2/hooks/groupBy/useGroupByHook";
import { useSort } from "src/v2/hooks/sort/useSort";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useWidgetContext } from "src/v2/components/Widget/WidgetContext";
import { WidgetConfigs } from "src/v2/components/Widget/WidgetConfigs";
import { TableRow } from "./TableRow";

interface TableBodyProps {
  items: BlockItem[];
}

export const TableBody: FC<TableBodyProps> = (props) => {
  const { items } = props;
  const { getConfigValueByName } = useWidgetContext();
  const sortConfig = getConfigValueByName(WidgetConfigs.sortConfig);
  const [sortedItems, setSortedItems] = useState([]);
  const [itemsToRender, setItemsToRender] = useState([]);
  const indexRef = useRef(0);
  const timeoutIdRef = useRef(null);

  const timeoutNameRef = useRef(null);
  const { groupedItemsByConfig } = useGroupBy({
    items,
    groupByConfig: sortConfig,
  });

  const {
    sortItemsByProgressCompletion,
    sortObjectKeysByPosition,
    sortItemsByStatusPriorityRank,
    sortItemsByRecentlyAdded,
  } = useSort();

  const renderItemRow = (groupedItems) => {
    if (!Array.isArray(groupedItems)) {
      const keysOrderedByPosition = sortObjectKeysByPosition(groupedItems);
      return keysOrderedByPosition.map((key) =>
        renderItemRow(groupedItems[key].items)
      );
    }
    let sortedItems = sortItemsByProgressCompletion(groupedItems);
    if (groupedItems[0].isTask()) {
      sortedItems = sortItemsByStatusPriorityRank(sortedItems);
      sortedItems = sortItemsByRecentlyAdded(sortedItems);
    }

    return sortedItems.map((item) => (
      <TableRow key={`${item.id}` || "No initiative"} item={item} />
    ));
  };

  const updateItemsToRender = (props) => () => {
    const totalMissingItems = Math.min(
      sortedItems.length - indexRef.current,
      10
    );
    const newItemsToRender = sortedItems.slice(
      indexRef.current,
      indexRef.current + totalMissingItems
    );
    if (indexRef.current === 0) {
      setItemsToRender(newItemsToRender);
    } else {
      setItemsToRender((_itemsToRender) => [
        ..._itemsToRender,
        ...newItemsToRender,
      ]);
    }
    indexRef.current += totalMissingItems;
    if (indexRef.current < sortedItems.length) {
      timeoutIdRef.current = setTimeout(updateItemsToRender(props), 200);
    }
  };

  function shouldRerenderItems() {
    // stringify sortedItems and itemsToRender to compare them
    const stringfiedSortedItems = JSON.stringify(sortedItems);
    const stringfiedItemsToRender = JSON.stringify(itemsToRender);
    return stringfiedSortedItems !== stringfiedItemsToRender;
  }

  useEffect(() => {
    const _sortedItems = renderItemRow(groupedItemsByConfig).flat(Infinity);
    setSortedItems(_sortedItems);
  }, [items]);

  useEffect(() => {
    if (!shouldRerenderItems()) {
      return;
    }
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutNameRef.current = null;
    }
    indexRef.current = 0;
    timeoutNameRef.current = sortedItems.length;
    timeoutIdRef.current = setTimeout(
      updateItemsToRender(sortedItems.length),
      50
    );
  }, [sortedItems]);

  return <Tbody data-test-id="summary_table_row">{itemsToRender}</Tbody>;
};
