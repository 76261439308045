import { filterDuplicatedInList } from "src/utils/listUtils";
import { FieldConfig } from "src/v2/domain/entities/fieldConfig/FieldConfig";
import { useFieldsContext } from "./FieldsContext";

export const useFieldsLogic = () => {
  const { fieldsConfig } = useFieldsContext();
  function getUniqueListOfConfigWithDefaultValues(
    fieldsConfigWithDefaultValues: FieldConfig[]
  ) {
    // Getting unique list of fields with default values
    const uniqueListOfConfigWithValues = [];
    fieldsConfigWithDefaultValues.forEach((field: FieldConfig) => {
      // Check if key already exists
      const keyFound = uniqueListOfConfigWithValues.find(
        ({ fieldKey }) => fieldKey === field.fieldKey
      );
      // If key already exists, add the default values to the existing list
      // filtering out duplicates
      if (keyFound) {
        keyFound.values = filterDuplicatedInList({
          items: [...keyFound.values, ...field.getDefaultFilterValues()],
        });
        // If key does not exist, add the field to the list with default values
      } else {
        uniqueListOfConfigWithValues.push({
          fieldKey: field.fieldKey,
          values: field.getDefaultFilterValues(),
        });
      }
    });
    return uniqueListOfConfigWithValues;
  }

  const getFieldsWithDefaultValues = () => {
    const fieldsConfigWithDefaultValues = fieldsConfig.filter((field) =>
      field.hasDefaultFilterValues()
    );
    return getUniqueListOfConfigWithDefaultValues(
      fieldsConfigWithDefaultValues
    );
  };
  return {
    getUniqueListOfConfigWithDefaultValues,
    getFieldsWithDefaultValues,
  };
};
