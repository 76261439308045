import theme from "src/v3/theme/theme";
import { allProjectStatuses, defaultProjectStatus } from "./allProjectStatuses";

const backgroundColors = {
  complete: theme.palette.success.main,
  on_track_status: theme.palette.success.light,
  at_risk_status: theme.palette.warning.light,
  blocker: theme.palette.error.light,
  off_track_status: theme.palette.error.main,
  paused: theme.palette.grey[300],
  not_set_status: theme.palette.text.primary,
};

const textColors = {
  complete: theme.palette.primary.contrastText,
  on_track_status: theme.palette.success.dark,
  at_risk_status: theme.palette.warning.dark,
  blocker: theme.palette.error.dark,
  off_track_status: theme.palette.primary.contrastText,
  paused: theme.palette.grey[900],
  not_set_status: theme.palette.primary.contrastText,
};

const legendColor = {
  complete: theme.palette.success.main,
  on_track_status: theme.palette.success.light,
  at_risk_status: theme.palette.warning.main,
  blocker: theme.palette.error.light,
  off_track_status: theme.palette.error.main,
  paused: theme.palette.grey[300],
  not_set_status: theme.palette.text.primary,
};

export const getLegendColor = (status): string => {
  return (
    legendColor[status] ||
    legendColor[getStatusByName(status)?.type] ||
    theme.palette.text.primary
  );
};

export const getColor = (status): string => {
  return (
    backgroundColors[status] ||
    backgroundColors[getStatusByName(status)?.type] ||
    theme.palette.primary.contrastText
  );
};

export const getTextColor = (status): string => {
  return (
    textColors[status] ||
    textColors[getStatusByName(status)?.type] ||
    theme.palette.text.primary
  );
};

export const getStatusByName = (name: string) => {
  return (
    allProjectStatuses.find((status) => status.name === name) ||
    defaultProjectStatus
  );
};
