import { Typography } from "@material-ui/core";
import { ManualUpdateAISummary } from "./ManualUpdateAISummary";
import { ManualUpdateProgressbar } from "./ManualUpdateProgressBar";
import { manualUpdateProjectStatusMapper } from "./ProjectStatusMapper";
import { primaryTextColor } from "src/v2/helpers/colors";

export const ManualUpdateTable = ({
  widgetTitle = "",
  widgetTitleColor,
  itemName,
  progressBarData,
  sprintHealthStatus,
  sprintHealthReasoningHTML,
  roadmapHealthStatus,
  roadmapHealthReasoningHTML,
  redirectURL,
  projectedFinishDate = null,
}) => {
  return (
    <div data-testid="section-container">
      {widgetTitle && (
        <div
          className="jss14"
          data-test-id="widget-title"
          style={{
            border: "0px",
            gridArea: "1 / span 5 / auto / auto",
            paddingLeft: "0px",
          }}
        >
          <div className="MuiBox-root jss43">
            <h5
              className="MuiTypography-root jss15 MuiTypography-h5"
              style={{
                background: widgetTitleColor,
                color: "white",
                fontSize: "16px",
                fontWeight: 700,
                padding: "16px",
                textAlign: "left",
                textTransform: "uppercase",
                width: "200px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {widgetTitle}
            </h5>
          </div>
          <span></span>
        </div>
      )}
      <table
        data-testid="table"
        data-test-id="summary_table"
        className="responsiveTable"
        style={{ marginTop: "16px" }}
      >
        <thead
          data-testid="thead"
          className="jss84"
          data-test-id="summary_table_header"
          style={{ backgroundColor: "rgb(248, 248, 248)", color: "#395e77" }}
        >
          <tr data-testid="tr" className="jss85">
            <th
              data-testid="th"
              data-test-id="false"
              style={{ padding: 8, width: "15%" }}
            >
              <p
                className="MuiTypography-root jss86 MuiTypography-body1 MuiTypography-alignLeft"
                data-test-id="project_expandable_header_text"
              >
                Name
              </p>
            </th>
            <th
              data-testid="th"
              data-test-id="false"
              style={{ padding: 8, width: "25%" }}
            >
              <p
                className="MuiTypography-root jss86 MuiTypography-body1 MuiTypography-alignLeft"
                data-test-id="project_expandable_header_text"
              >
                Progress
              </p>
            </th>
            <th
              data-testid="th"
              data-test-id="false"
              style={{
                backgroundColor: "rgb(222, 233, 244)",
                padding: 8,
                width: "15%",
              }}
            >
              <p
                className="MuiTypography-root jss86 MuiTypography-body1 MuiTypography-alignLeft"
                data-test-id="project_expandable_header_text"
              >
                Roadmap Health
              </p>
            </th>

            {projectedFinishDate && (
              <th
                data-testid="th"
                data-test-id="false"
                style={{
                  backgroundColor: "rgb(222, 233, 244)",
                  padding: 8,
                  width: "15%",
                }}
              >
                <p
                  className="MuiTypography-root jss86 MuiTypography-body1 MuiTypography-alignLeft"
                  data-test-id="project_expandable_header_text"
                >
                  Projected Finish Date
                </p>
              </th>
            )}

            <th
              data-testid="th"
              data-test-id="false"
              style={{ backgroundColor: "rgb(222, 233, 244)" }}
            >
              <p
                style={{ backgroundColor: "rgb(222, 233, 244)", padding: 8 }}
                className="MuiTypography-root jss86 MuiTypography-body1 MuiTypography-alignLeft"
                data-test-id="project_expandable_header_text"
              >
                Roadmap Health Reasoning
              </p>
            </th>
          </tr>
        </thead>
        <tbody data-testid="tbody" data-test-id="summary_table_row">
          <tr
            data-testid="tr"
            data-test-id="false"
            className="fade-in"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 30px 4px",
            }}
          >
            <td data-testid="td" data-test-id="false" className="pivoted">
              <div data-testid="td-before" className="tdBefore"></div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div className="MuiGrid-root">
                  <div
                    className="MuiGrid-root MuiGrid-container MuiGrid-direction-xs-column MuiGrid-align-items-xs-flex-start MuiGrid-align-content-xs-center MuiGrid-justify-content-xs-center"
                    style={{
                      padding: "16px",
                    }}
                  >
                    <div
                      className="MuiBox-root jss110"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <a
                        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-body2 MuiTypography-colorPrimary"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={redirectURL}
                      >
                        <p
                          className="MuiTypography-root MuiTypography-body1"
                          data-test-id="deliverable_name"
                          style={{
                            color: "rgb(57, 94, 119)",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {itemName}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td data-testid="td" data-test-id="false" className="pivoted">
              <ManualUpdateProgressbar
                {...progressBarData}
              ></ManualUpdateProgressbar>
            </td>

            <ProjectStatusColumn {...roadmapHealthStatus} />
            {projectedFinishDate && (
              <div
                data-test-id="false"
                style={{
                  padding: "16px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <div
                  className="MuiBox-root jss714"
                  data-testid="date-field"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "120px",
                    backgroundColor: "rgb(194, 220, 252)",
                    padding: 4,
                    textAlign: "center",
                    borderRadius: "4px",
                    color: "rgb(57, 94, 119); z-index: -1",
                  }}
                >
                  <Typography style={{ fontSize: 14, color: primaryTextColor }}>
                    {projectedFinishDate}
                  </Typography>
                </div>
              </div>
            )}
            <td
              data-testid="td"
              data-test-id="false"
              className="pivoted"
              style={{
                width: "50%",
                padding: 24,
                backgroundColor: "rgb(248, 252, 255)",
              }}
            >
              <div>
                <ManualUpdateAISummary html={roadmapHealthReasoningHTML} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div>
        <table
          data-testid="table"
          data-test-id="summary_table"
          className="responsiveTable"
        >
          <thead
            data-testid="thead"
            className="jss84"
            data-test-id="summary_table_header"
            style={{ color: "#395e77" }}
          >
            <tr data-testid="tr" className="jss85">
              <th
                data-testid="th"
                data-test-id="false"
                style={{
                  backgroundColor: "rgb(222, 233, 244)",
                  padding: 8,
                }}
              >
                <p
                  style={{ fontSize: 16 }}
                  className="MuiTypography-root jss86 MuiTypography-body1 MuiTypography-alignLeft"
                  data-test-id="project_expandable_header_text"
                >
                  Sprint Health Reasoning
                </p>
              </th>
              <th
                data-testid="th"
                data-test-id="false"
                style={{ backgroundColor: "rgb(222, 233, 244)" }}
              >
                <p
                  style={{ backgroundColor: "rgb(222, 233, 244)", padding: 8 }}
                  className="MuiTypography-root jss86 MuiTypography-body1 MuiTypography-alignLeft"
                  data-test-id="project_expandable_header_text"
                >
                  Roadmap Health Reasoning
                </p>
              </th>
            </tr>
          </thead>
          <tbody data-testid="tbody" data-test-id="summary_table_row">
            <tr
              data-testid="tr"
              data-test-id="false"
              className="fade-in"
              style={{
                background: "rgb(241, 241, 241)",
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 30px 4px",
              }}
            >
              <td
                data-testid="td"
                data-test-id="false"
                className="pivoted"
                style={{
                  width: "50%",
                  padding: 24,
                  backgroundColor: "rgb(248, 252, 255)",
                }}
              >
                <ManualUpdateAISummary html={sprintHealthReasoningHTML} />
              </td>
              <td
                data-testid="td"
                data-test-id="false"
                className="pivoted"
                style={{
                  width: "50%",
                  padding: 24,
                  backgroundColor: "rgb(248, 252, 255)",
                }}
              >
                <div>
                  <ManualUpdateAISummary html={roadmapHealthReasoningHTML} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

const ProjectStatusColumn = (status) => {
  const mappedStatus = manualUpdateProjectStatusMapper(status);
  const { name, ballColor, textColor, background } = mappedStatus;
  return (
    <td
      data-testid="td"
      data-test-id="false"
      style={{
        backgroundColor: background,
      }}
    >
      <div data-testid="td-before" className="tdBefore"></div>
      <div data-test-id="false">
        <div
          className="MuiGrid-root MuiGrid-container MuiGrid-align-items-xs-center MuiGrid-align-content-xs-center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            data-test-id="project_status_ball"
            style={{
              backgroundColor: ballColor,
              borderRadius: "100px",
              height: "18px",
              width: "18px",
              marginRight: "4px",
            }}
          ></div>
          <p
            className="MuiTypography-root MuiTypography-body1"
            style={{
              color: textColor,
              textAlign: "center",
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "uppercase",
            }}
          >
            {name}
          </p>
        </div>
      </div>
    </td>
  );
};
