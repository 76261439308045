import { TargetDateField } from "src/v3/entities/CustomFields/TargetDateField";
import { useState, useCallback } from "react";
import { EventTypes } from "src/providers/EventContext";
import { setSuccessSnackbar } from "src/services/setErrorSnackbar";
import {
  updateProjectStatus,
  updateRiskComparisonDate,
} from "src/services/v2/insightsService";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { allProjectStatuses } from "src/v3/utils/ProjectStatus/allProjectStatuses";
import { mapStringDateToWeeks, mapWeeksToDate } from "./utils";
import { useOverrideContext } from "src/providers/OverrideContext";
import { useBlockContext } from "src/v2/providers/block/BlockContext";
import { ProjectStatusField as ProjectStatusFieldV2 } from "src/v2/domain/entities/customField/ProjectStatusField";
import { ProjectStatusField } from "src/v3/entities/CustomFields/ProjectStatusField";
import { RiskComparisonDateField } from "src/v2/domain/entities/customField/RiskComparisonDateField";
import { parseDateToStringDate } from "src/v3/utils/date";
import { logError } from "src/utils/error";
import { findItemByKey } from "src/v3/utils/block_item";

const getOverrideValues = (statusField, targetDateField) => {
  const statusOverride = statusField?.getLastAddedOverride();
  const targetDateOverride = targetDateField?.getLastAddedOverride();

  const justification =
    statusOverride?.justification || targetDateOverride?.justification || "";
  const retainOverride =
    statusOverride?.retainOverride ||
    targetDateOverride?.retainOverride ||
    false;
  const expirationDate =
    statusOverride?.expirationDate || targetDateOverride?.expirationDate || "";

  return {
    justification,
    retainOverride,
    expirationDate,
  };
};

export const useProjectStatusEdit = ({
  item,
  targetDateField,
  statusField,
}: {
  item: BlockItem;
  targetDateField: TargetDateField | RiskComparisonDateField;
  statusField: ProjectStatusField | ProjectStatusFieldV2;
}) => {
  const { originalItems } = useBlockContext();
  const { saveOverride } = useOverrideContext();

  // TODO: After deprecate the old views, stop getting the statuses as props
  // const statusField = item.getProjectStatusField();
  // const targetDateField = item.getTargetDateField();
  const isOverride =
    statusField?.hasOverride() || targetDateField?.hasOverride();

  const { justification, retainOverride, expirationDate } = getOverrideValues(
    statusField,
    targetDateField
  );

  const [state, setState] = useState({
    status: statusField?.getStatusName(),
    targetDate: new Date(targetDateField?.getTargetDate() || ""),
    justification: justification,
    retainOverride: retainOverride,
    expirationDate: mapStringDateToWeeks(expirationDate),
  });

  const [isSaving, setIsSaving] = useState(false);

  const handleStateChange = useCallback(
    (
      key:
        | "status"
        | "targetDate"
        | "justification"
        | "retainOverride"
        | "expirationDate",

      value
    ) => {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [setState]
  );

  const resetState = useCallback(() => {
    setState({
      status: statusField?.getStatusName(),
      targetDate: new Date(targetDateField?.getTargetDate()),
      justification,
      retainOverride,
      expirationDate: mapStringDateToWeeks(expirationDate),
    });
  }, [statusField, targetDateField]);

  const noStatusChange = useCallback(
    () => state.status === statusField?.getStatusName(),
    [state, statusField]
  );

  const noTargetDateChange = useCallback(() => {
    const originalTargetDateString = targetDateField?.getTargetDate();
    if (originalTargetDateString === "") {
      return isNaN(state.targetDate.getTime());
    } else {
      const currentStateTime = state.targetDate.getTime();
      const targetDateFieldTime = new Date(originalTargetDateString).getTime();
      return currentStateTime === targetDateFieldTime;
    }
  }, [state, targetDateField]);

  const updateStatus = async (override, itemKey) => {
    // TODO: After deprecate the old views, enable this check
    // if (
    //   noStatusChange() ||
    //   (!statusField.hasOverride() && noJustificationAndExpirationDateChange())
    // ) {
    //   return;
    // }

    await updateProjectStatus(override, itemKey);
  };

  const updateTargetDate = async (override, itemKey) => {
    // TODO: After deprecate the old views, enable this check
    // if (
    //   noTargetDateChange() ||
    //   (!targetDateField.hasOverride() &&
    //     noJustificationAndExpirationDateChange())
    // ) {
    //   return;
    // }

    await updateRiskComparisonDate(override, itemKey);
  };

  const onSave = async () => {
    setIsSaving(true);

    const overrideValues = {
      justification: state.justification,
      retainOverride: state.retainOverride,
      expirationDate: mapWeeksToDate(state.expirationDate),
      createdAt: new Date(),
    };

    const foundItem = findItemByKey(originalItems, item.getKey());

    const projectStatus = allProjectStatuses.find(
      ({ name }) => name === state.status
    );

    const targetDate = parseDateToStringDate(state.targetDate);
    const projectStatusOverride = {
      value: {
        projectStatus,
        teamsTargetDate: targetDate,
      },
      ...overrideValues,
      fieldKey: statusField?.fieldKey,
      blockColumn: "project_status_insights",
    };

    const targetDateOverride = {
      value: targetDate,
      ...overrideValues,
      fieldKey: targetDateField?.fieldKey,
      jiraUpdate: true,
      blockColumn: "risk_comparison_date",
    };

    try {
      await updateStatus(projectStatusOverride, foundItem.getKey());
      await updateTargetDate(targetDateOverride, foundItem.getKey());

      saveOverride({
        item: foundItem,
        overrides: [targetDateOverride, projectStatusOverride],
        event: {
          type: EventTypes.PROJECT_STATUS_OVERRIDE,
          message: `Project status overridden to ${state.status}`,
        },
      });
      saveOverride({
        item,
        overrides: [targetDateOverride, projectStatusOverride],
        event: {
          type: EventTypes.PROJECT_STATUS_OVERRIDE,
          message: `Project status overridden to ${state.status}`,
        },
      });
      setSuccessSnackbar("Item updated successfully!", 3000);
    } catch (error) {
      logError(
        error,
        "Failed to update item, please try again later, if the issue persists, contact us.",
        3000
      );
      resetState();
    } finally {
      setIsSaving(false);
    }
  };

  const noJustificationAndExpirationDateChange = useCallback(
    () =>
      state.justification === justification &&
      state.retainOverride === retainOverride &&
      state.expirationDate === mapStringDateToWeeks(expirationDate),
    [state, justification, retainOverride, expirationDate]
  );

  /**
   * Determines whether the save button should be enabled based on the current state.
   * Logic:
   * 1. If the status or target date are overridden, the save button should be enabled if there are changes in justification, retain checkbox or expiration date.
   * 2. If the status or target date are not overridden, the save button should be enabled if there are changes in status OR target date and in justification/retain checkbox/expiration date.
   * @returns {boolean} True if the save button should be enabled, false otherwise.
   */
  const enableSaveButton = useCallback(() => {
    if (isOverride) {
      return (
        !noStatusChange() ||
        !noTargetDateChange() ||
        (!noJustificationAndExpirationDateChange() &&
          state.justification?.trim() !== "")
      );
    }

    return (
      (!noStatusChange() || !noTargetDateChange()) &&
      !noJustificationAndExpirationDateChange() &&
      state.justification?.trim() !== ""
    );
  }, [state]);

  return {
    state,
    enableSaveButton: enableSaveButton(),
    isSaving,
    stateChanged:
      isOverride ||
      !(
        noStatusChange() &&
        noTargetDateChange() &&
        noJustificationAndExpirationDateChange()
      ),
    onSave,
    resetState,
    handleStateChange,
  };
};
