import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { aggregateItemsByStatus, StatusConfig } from "./aggregateByStatus";
import theme from "../theme/theme";

export enum StatusCategory {
  ToDo = "To Do",
  InProgress = "In Progress",
  Blocker = "Execution Blocker",
  Done = "Done",
  Ignored = "Ignored",
}

export const workflowStatuses = [
  {
    name: StatusCategory.ToDo,
    type: StatusCategory.ToDo,
    category: StatusCategory.ToDo,
    color: theme.palette.grey[300],
    textColor: theme.palette.grey[900],
    position: 4,
    sort_position: 1,
    tooltip: StatusCategory.ToDo,
  },
  {
    name: StatusCategory.InProgress,
    type: StatusCategory.InProgress,
    category: StatusCategory.InProgress,
    color: theme.palette.primary.main,
    textColor: theme.palette.primary.contrastText,
    position: 3,
    sort_position: 2,
    tooltip: StatusCategory.InProgress,
  },
  {
    name: StatusCategory.Blocker,
    type: StatusCategory.Blocker,
    category: StatusCategory.Blocker,
    color: theme.palette.error.light,
    textColor: theme.palette.error.dark,
    position: 2,
    sort_position: 3,
    tooltip: StatusCategory.Blocker,
  },
  {
    name: StatusCategory.Done,
    type: StatusCategory.Done,
    category: StatusCategory.Done,
    color: theme.palette.success.main,
    textColor: theme.palette.primary.contrastText,
    position: 1,
    sort_position: 4,
    tooltip: StatusCategory.Done,
  },
];

export const aggregateByWorkflowStatus = ({
  items,
  property = "category",
}: {
  items: BlockItem[];
  property?: "name" | "category";
}) => {
  const workflowStatusConfig: StatusConfig = {
    getStatusField: (item: BlockItem) => item.getWorkflowStatusField(),
    property,
  };

  const aggregation = aggregateItemsByStatus({
    items,
    statusConfig: workflowStatusConfig,
  });

  return aggregation.sort(
    (a, b) => a.status.getPosition() - b.status.getPosition()
  );
};
