import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";

class NotIncludedInFilter extends SectionFilter {
  public filterItem(item: BlockItem): boolean {
    const field = item.getFieldByFieldKey(this.fieldKey);
    if (!field) return false;

    const fieldValue = field.value;

    if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
      return this.config?.consider_empty_field_value || false;
    }

    const lowercaseValues = new Set(
      this.values?.map((value) => value.toString().toLowerCase())
    );

    if (Array.isArray(fieldValue)) {
      return !fieldValue.some((value) =>
        lowercaseValues.has(value.toString().toLowerCase())
      );
    }

    if (typeof fieldValue === "object") {
      const objectKey = this.config?.objectKey;
      const objectValue = objectKey ? fieldValue[objectKey] : null;
      return !lowercaseValues.has(objectValue?.toString().toLowerCase());
    }

    return !lowercaseValues.has(fieldValue.toString().toLowerCase());
  }
}

export default NotIncludedInFilter;
