import { Grid } from "@material-ui/core";
import { DoughnutChart } from "./DoughnutChart";
import { useBuildDoughtChartData } from "./useDoughnutChartData";
import { useDataSection } from "../../hooks/useDataSection";

export class DoughnutChartData {
  dataSet;
  title;
  info;
  constructor({ dataSet, title, info }) {
    this.dataSet = dataSet;
    this.title = title;
    this.info = info;
  }
}

const DoughnutChartBuilder = ({ section, items, config }) => {
  const { groupBy, filters, options } = section;
  const chartConfigs = config.chartConfigs;

  if (!chartConfigs) {
    throw new Error("You need to specify the Doughnut chart config");
  }

  const { groupedItems } = useDataSection({
    filters,
    groupBy,
    items,
  });

  const { dataSet, childrenDataSet } = useBuildDoughtChartData({
    section,
    items,
    groupedItems,
  });

  const chartData = chartConfigs.map(({ title, info, dataSetRef }) => {
    const dataSetConfig = dataSetRef === "children" ? childrenDataSet : dataSet;
    return new DoughnutChartData({ dataSet: dataSetConfig, title, info });
  });

  const customStyle =
    options?.find((option) => option.name === "styles")?.value || {};

  return (
    <Grid container direction="row" justifyContent="center">
      {chartData.map((data) => (
        <div style={{ ...customStyle }}>
          <DoughnutChart {...data}></DoughnutChart>
        </div>
      ))}
    </Grid>
  );
};

export default DoughnutChartBuilder;
