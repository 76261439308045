import { identify, log } from "@fullstory/browser";
import * as Sentry from "@sentry/react";

import api from "./api";

const userKeyInLocalStorage = "user";

export function loginLocally(user) {
  window.localStorage.setItem(userKeyInLocalStorage, JSON.stringify(user));
}

export function getLoggedUser() {
  return JSON.parse(localStorage.getItem(userKeyInLocalStorage));
}
export function isDevMode() {
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
  if (audience === "heyvelma-dev") {
    return true;
  }

  const user = getLoggedUser();
  if (
    user?.email.includes("@heyvelma.com") &&
    !["velma@heyvelma.com", "lisa@heyvelma.com"].includes(user?.email)
  ) {
    return true;
  }
}

export function isLogged() {
  // Note: This function should only very if the user is logged
  // but instead it does a lot of other things
  // Break it down into different functions.
  try {
    const user = getLoggedUser();
    if (user) {
      Sentry.setUser({ email: user.email, username: user.name });

      if (!user.email) {
        // path to add email to localStorage
        // required due to missing email in localStorage
        const fetchUserData = async () => api.get("/planning/me");
        fetchUserData().then(({ data }) => {
          loginLocally(data);
          identify(user.identifier, {
            displayName: user.name,
            email: data.email,
            company: data.company,
          });
        });
      } else {
        identify(user.identifier, {
          displayName: user.name,
          email: user.email,
          company: user.company,
        });
      }

      log("info", `${user.name} just logged in`);
      return true;
    }
  } catch (error) {
    log("error", `Error when logging in ${error}`);
  }
  return false;
}

export async function authenticate(userClaims: any) {
  await api.post("/authenticate", { userClaims });
  loginLocally({
    name: userClaims.name,
    identifier: userClaims.sub,
    email: userClaims.email,
  });
}

export async function createSession(accessToken) {
  await api.post("/authenticate/session", { accessToken });
}

export async function logout() {
  await api.patch("authenticate/logout");
  localStorage.removeItem(userKeyInLocalStorage);
  localStorage.removeItem("last_session_date");
}

export async function getUserAllowedFeatures(boardId: number) {
  const { data } = await api.get("/permissions/all", {
    params: {
      boardId,
    },
  });
  return data;
}
