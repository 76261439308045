import {
  StatusName,
  SmallStatusChip,
  MediumStatusChip,
  EmptyStatusChip,
} from "./styles";
import {
  ProjectStatusChipProps,
  StatusChipBaseProps,
  WorkflowStatusChipProps,
} from "./types";

const StatusChipBase = ({
  textColor,
  backgroundColor,
  label,
  size,
}: StatusChipBaseProps) => {
  // TODO: Add a tooltip with the status description

  const StatusChipComponent =
    size === "small" ? SmallStatusChip : MediumStatusChip;

  return (
    <StatusChipComponent backgroundColor={backgroundColor}>
      <StatusName color={textColor}>{label}</StatusName>
    </StatusChipComponent>
  );
};

export const ProjectStatusChip = ({ status, size }: ProjectStatusChipProps) => {
  if (!status) {
    return <EmptyStatusChip />;
  }
  const textColor = status.getTextColor();
  const backgroundColor = status.getBackgroundColor();
  const label = status.getStatusName();
  return (
    <StatusChipBase
      textColor={textColor}
      backgroundColor={backgroundColor}
      label={label}
      size={size}
    />
  );
};

export const WorkflowStatusChip = ({
  status,
  size,
}: WorkflowStatusChipProps) => {
  if (!status) {
    return <EmptyStatusChip />;
  }
  const textColor = status.getTextColor();
  const backgroundColor = status.getBackgroundColor();
  const label = status.getStatusName();
  return (
    <StatusChipBase
      textColor={textColor}
      backgroundColor={backgroundColor}
      label={label}
      size={size}
    />
  );
};
