import { getFilteredItems } from "src/v2/components/Section/hooks/useDataSection";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { ReactNode } from "react";
import { isDevMode } from "src/services/sessionService";

type ViewFilterContainerProps = {
  filters: any[];
  items: BlockItem[];
  children: (props: { items: BlockItem[] }) => ReactNode;
};

export const ViewFilterContainer = ({
  filters,
  items,
  children,
}: ViewFilterContainerProps) => {
  if (isDevMode()) {
    console.debug("ViewFilterContainer - input - items", {
      items: items.length,
      filters,
    });
  }

  const filteredItems = getFilteredItems({
    items,
    filters,
  });

  if (isDevMode()) {
    console.debug("ViewFilterContainer - output - filteredItems", {
      items: filteredItems,
      filters,
    });
  }
  return <>{children({ items: filteredItems })}</>;
};
