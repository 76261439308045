import { SummaryOfWorkEdit } from "src/v3/components/Dialogs/SummaryOfWorkEdit";
import { ExtraSummaryOfWorkActionProps } from "./types";
import { useDialog } from "src/v3/components/Dialogs/Base/useDialog";
import { CustomMenuItem, ActionLabel } from "../../style";
import { Typography } from "@material-ui/core";
import { useSummaryOfWorkStyles } from "src/v3/widgets/ItemDetails/widgets/SummaryOfWork/styles";

// TODO: after deprecate v2, remove this ExtraSummaryOfWorkAction from HTMLField
export const ExtraSummaryOfWorkAction = ({
  item,
}: ExtraSummaryOfWorkActionProps) => {
  const { open, toggle } = useDialog();

  // TODO: after deprecate v2, remove getAISummaryField
  const field = item.getSummaryOfWorkField() || item.getAISummaryField();
  const editedExtraContext = field?.hasOverride();
  const _label = editedExtraContext ? `Edit summary` : `Add summary`;

  return (
    <CustomMenuItem onClick={toggle}>
      <ActionLabel>{_label}</ActionLabel>
      <SummaryOfWorkEdit item={item} open={open} handleOpen={toggle} />
    </CustomMenuItem>
  );
};

export const EditSummaryOfWorkDetailsView = ({
  item,
}: ExtraSummaryOfWorkActionProps) => {
  const { open, toggle } = useDialog();
  const classes = useSummaryOfWorkStyles();

  const field = item.getSummaryOfWorkField();
  const editedExtraContext = field?.hasOverride();
  const _label = editedExtraContext
    ? `Edit additional details`
    : `Add additional details`;

  return (
    <>
      <Typography
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
        className={classes.actionLabel}
      >
        {_label}
      </Typography>
      <SummaryOfWorkEdit item={item} open={open} handleOpen={toggle} />
    </>
  );
};
