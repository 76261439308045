import { Column, Row } from "src/components/GlobalComponents";
import { Box, Typography } from "@material-ui/core";
import {
  doneStatusProgressBarColor,
  inProgressStatusProgressBarColor,
  todoStatusProgressBarColor,
} from "src/v2/helpers/colors";

const StatusLegend = () => {
  const statuses = [
    { name: "Complete", color: doneStatusProgressBarColor, position: 1 },
    {
      name: "In Progress",
      color: inProgressStatusProgressBarColor,
      position: 2,
    },
    { name: "To Do", color: todoStatusProgressBarColor, position: 3 },
  ];

  return (
    <div style={{ width: 132 }}>
      <Row>
        <Typography style={{ fontSize: 16 }}>Workflow Status</Typography>
      </Row>
      <Column style={{ paddingTop: 8 }}>
        {statuses.map((status, index) => (
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: index === 0 ? 0 : 4 }}
          >
            <div
              style={{
                backgroundColor: status.color,
                width: 12,
                height: 12,
                borderRadius: "50%",
                marginRight: 8,
              }}
            />
            <div>{status.name}</div>
          </Box>
        ))}
      </Column>
    </div>
  );
};

export default StatusLegend;
