import api from "./api/apiV2";

export async function getGenericViews() {
  const { data } = await api.get(`/v2/genericViews`, {});
  return data;
}

export async function getViewTeamSummary(viewId) {
  const { data } = await api.get(`/v2/genericViews/teamSummary`, {
    params: { viewId },
  });
  return data;
}
