import {
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { ArchiveOutlined } from "@material-ui/icons";
import * as React from "react";
import BaseButton from "src/components/Buttons/BaseButton";
import { useUserPreferenceContext } from "src/v2/providers/userPreference/UserPreferenceContext";
import { todoStatusProgressBarColor } from "src/v2/helpers/colors";
import { FilterMenu } from "../filter/FilterMenu/FilterMenu";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";

export default function MenuListComposition() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { userSavedFilters, deleteUserFilter, velmaSavedFilters } =
    useUserPreferenceContext();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const [advancedFilterAnchorEl, setAdvancedFilterAnchorEl] =
    React.useState(null);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const { handleClearFilters } = useFilterContext();

  const clearFilters = () => {
    handleClearFilters();
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div
      style={{ display: "flex", alignItems: "center", marginLeft: 16 }}
      data-test-id="options_bar"
    >
      <BaseButton
        style={{
          textTransform: "uppercase",
          height: 36,
        }}
        propRef={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        data-test-id="saved_filters"
      >
        <Typography style={{ width: 128 }}>Filters</Typography>
      </BaseButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        style={{ marginTop: 8 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {velmaSavedFilters.length > 0 && (
                    <div>
                      <Typography
                        align="right"
                        style={{ color: "lightgrey", marginRight: 16 }}
                      >
                        Velma Filters
                      </Typography>
                      {velmaSavedFilters.map((filter) => (
                        <MenuItem>
                          <BaseButton
                            onClick={() => {
                              window.location = filter.url;
                            }}
                          >
                            <Typography>{filter.name}</Typography>
                          </BaseButton>
                        </MenuItem>
                      ))}
                    </div>
                  )}
                  {userSavedFilters.length > 0 && (
                    <div>
                      <Typography
                        align="right"
                        style={{
                          color: "lightgrey",
                          marginRight: 16,
                          marginTop: 8,
                        }}
                      >
                        My Filters
                      </Typography>
                      {userSavedFilters.map((filter) => (
                        <MenuItem style={{ width: "100%", padding: 8 }}>
                          <BaseButton
                            style={{
                              marginLeft: 8,
                              height: 32,
                              borderRadius: "4px 0 0 4px",
                              minWidth: 36,
                            }}
                            tooltipText="Archive Filter"
                            onClick={() => deleteUserFilter(filter.id)}
                          >
                            <ArchiveOutlined />
                          </BaseButton>
                          <BaseButton
                            style={{
                              borderRadius: "0 4px 4px 0",
                              marginLeft: 4,
                              marginRight: 8,
                              width: "100%",
                            }}
                            onClick={() => {
                              window.location = filter.url;
                            }}
                          >
                            <Typography>{filter.name}</Typography>
                          </BaseButton>
                        </MenuItem>
                      ))}
                    </div>
                  )}
                  {userSavedFilters.length === 0 &&
                    velmaSavedFilters.length === 0 && (
                      <MenuItem disabled style={{ marginTop: 16 }}>
                        <Typography>No saved filters</Typography>
                      </MenuItem>
                    )}
                </MenuList>
              </ClickAwayListener>
              <Divider style={{ margin: 8 }} />
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justifyContent="space-around"
                style={{
                  padding: 8,
                }}
              >
                <BaseButton
                  onClick={() => {
                    setAdvancedFilterAnchorEl(anchorRef.current);
                  }}
                  id="filter-menu"
                  data-test-id="filter_menu"
                  style={{
                    border: `1px solid ${todoStatusProgressBarColor}`,
                    backgroundColor: "transparent",
                    marginRight: 4,
                    color: todoStatusProgressBarColor,
                    zIndex: 998,
                    boxShadow: "none",
                  }}
                >
                  <Typography>Advanced Filter</Typography>
                </BaseButton>
                <BaseButton
                  onClick={() => clearFilters()}
                  id="filter-menu"
                  data-test-id="filter_menu"
                  style={{
                    border: `1px solid ${todoStatusProgressBarColor}`,
                    zIndex: 998,
                    marginLeft: 4,
                    boxShadow: "none",
                  }}
                >
                  <Typography>Clear</Typography>
                </BaseButton>
              </Grid>
            </Paper>
          </Grow>
        )}
      </Popper>

      <FilterMenu
        anchorEl={advancedFilterAnchorEl}
        setAnchorEl={setAdvancedFilterAnchorEl}
      ></FilterMenu>
    </div>
  );
}
