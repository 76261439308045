import { createTheme } from "@material-ui/core/styles";
import { colors } from "./colors";
import typography from "./typography";
import { breakpoints } from "./breakpoints";
import { spacing } from "./spacing";
import { shadows } from "./shadows";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue[200],
      light: colors.blue[100],
      dark: colors.blue[200],
      contrastText: colors.white[100],
    },
    secondary: {
      main: colors.purple[200],
      light: colors.purple[100],
      contrastText: colors.white[100],
    },
    error: {
      main: colors.red[200],
      light: colors.red[100],
      lighter: colors.red[50],
      dark: colors.red[400],
      contrastText: colors.white[100],
    },
    warning: {
      main: colors.yellow[200],
      light: colors.yellow[100],
      dark: colors.yellow[300],
      contrastText: colors.white[100],
    },
    info: {
      main: colors.gray[400],
      contrastText: colors.white[100],
    },
    success: {
      main: colors.green[300],
      light: colors.green[100],
      dark: colors.green[500],
      contrastText: colors.white[100],
    },
    text: {
      primary: colors.blue[200],
      secondary: colors.gray[500],
      hint: colors.gray[400],
    },
    background: {
      default: colors.white[100],
      paper: colors.white[200],
    },
    grey: {
      100: colors.gray[100],
      200: colors.gray[200],
      300: colors.gray[300],
      400: colors.gray[400],
      500: colors.gray[500],
      600: colors.gray[600],
      900: colors.gray[900],
    },
    indigo: {
      light: colors.indigo[100],
      main: colors.indigo[200],
    },
  },
  typography,
  breakpoints,
  shadows,
  shape: {
    borderRadiusSmall: spacing.unit,
    borderRadius: spacing.unit * 2,
    borderRadiusLarge: spacing.unit * 3,
  },
  spacing: (factor) => `${spacing.unit * factor}px`,
});

export default theme;
