import { Chip, withStyles } from "@material-ui/core";
import { StatusCategory } from "../../enum/StatusCategory";
import { CustomFieldBase } from "./CustomFieldBase";
import { getStatusCategoryColor } from "src/v2/helpers/colors";

const StyledChip = withStyles({
  label: {
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "12px",
  },
})(Chip);

export class StatusField extends CustomFieldBase {
  public category: StatusCategory;

  public static CATEGORY_VALUE = "category";

  public static CATEGORY_IN_PROGRESS = "In Progress";

  public static CATEGORY_DONE = "Done";

  public static CATEGORY_TODO = "To Do";

  public static TYPE = "status";

  constructor(field) {
    super(field);
    this.category = field.value?.category ?? "";
    this.value = [field.value?.display_name ?? ""];
  }

  getValueForProperty(property: string) {
    if (property === StatusField.CATEGORY_VALUE) {
      return [this.category];
    }
    return super.getValueForProperty(property);
  }

  getStatusCategory() {
    return this.category;
  }

  getStatusValue() {
    return this.value[0];
  }

  getComponentForGrid(_) {
    return (
      <StyledChip
        data-test-id="status"
        size="small"
        style={{
          ...getStatusCategoryColor(this.category),
          borderRadius: "4px",
          height: "unset",
          padding: "5px 1px",
        }}
        label={this.value[0].toUpperCase()}
      />
    );
  }
}
