import { extractItemKeysFromUrl } from "./url";

export const handleScrollToItemInTable = (
  clickedElement: HTMLElement,
  scrollToElementByIdCallback: (
    key: string | string[],
    scrollOptions: any
  ) => void
) => {
  const key =
    clickedElement.getAttribute("data-item-key") ??
    extractItemKeysFromUrl(clickedElement.getAttribute("href")) ??
    null;

  const element = document.getElementById(key);
  if (element) {
    highlightHTMLElementWithBoxShadow(element);
    scrollToElementByIdCallback(key, { padding: 150 });
  }
};

export const highlightHTMLElementWithBoxShadow = (element: HTMLElement) => {
  // Highlight the element for 3 seconds after clicking on the AI Summary link
  const highlightStyle =
    "box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); transition: box-shadow 0.3s ease-in-out;";

  const previousStyle = element.style.cssText;
  element.style.cssText = highlightStyle;
  setTimeout(() => {
    element.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0)";
    element.style.cssText = previousStyle;
  }, 3000);
};
