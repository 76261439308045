import { Grid, Link, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";
import {
  buildRedirectURLWithListOfFilters,
  buildURLForItemWithoutKey,
} from "src/v2/utils/url";
import { FilterFieldsFactory } from "../filter/factory";
import { CondensedProgressField } from "./CondensedProgressField";
import { truncateStringInTheMiddle } from "src/utils/stringUtils";

export class NameField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.url = field.value?.url ?? "";
    this.displayName = field.value?.display_name ?? "";
    this.key = field.value?.key ?? "";
    this.value = [field.value?.display_name ?? ""];
    this.issueType = field.value?.issue_type ?? {};
    this.progress = field.value?.progress ?? {};
    if (this.progress) {
      this.progressField = new CondensedProgressField({ value: this.progress });
    }
  }

  key: string;
  issueType: {
    display_name: string;
    icon_url: string;
  };
  url: string;
  progress: {};
  progressField: CondensedProgressField;

  buildURL(getConfigValueByName, item, customURL) {
    const keyFilter = FilterFieldsFactory.buildBaseFilterField({
      fieldKey: "key",
      values: [item.getKey()],
    });

    return customURL != ""
      ? buildRedirectURLWithListOfFilters({
          filters: [keyFilter],
          redirectView: customURL,
        })
      : this.getURL() !== ""
        ? this.url
        : buildURLForItemWithoutKey(getConfigValueByName, item);
  }

  getDefaultIssueType() {
    const issueTypeIcon = this.issueType?.icon_url;
    const issueTypeDisplayName = this.issueType?.display_name;
    const issueTypeDefaultImageMap = {
      epic: "https://heyvelma.atlassian.net/images/icons/issuetypes/epic.svg",
      default:
        "https://heyvelma.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10320?size=small",
      story:
        "https://heyvelma.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10315?size=medium",
      task: "https://heyvelma.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium",
      bug: "https://heyvelma.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium",
      initiative:
        "https://heyvelma.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10300?size=medium",
    };

    return {
      issueTypeIcon,
      issueTypeDisplayName,
      issueTypeDefaultImageMap,
    };
  }

  issueTypeOnError = (e) => {
    const { issueTypeDisplayName, issueTypeDefaultImageMap } =
      this.getDefaultIssueType();
    e.target.onerror = null; // Prevents infinite loop in case defaultImage also has an error
    let defaultURL =
      issueTypeDefaultImageMap[issueTypeDisplayName?.toLocaleLowerCase()];
    if (!defaultURL) {
      defaultURL = issueTypeDefaultImageMap["default"];
    }
    e.target.src = defaultURL;
  };

  getKeyComponentForGrid({ customStyle = {} }) {
    return (
      <Link
        rel="noopener noreferrer"
        target="_blank"
        variant="body2"
        href={this.url}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography
          style={{
            color: `${primaryTextColor}`,
            fontWeight: 500,
            ...customStyle,
          }}
        >
          {truncateStringInTheMiddle(this.key, 25)}
        </Typography>
      </Link>
    );
  }

  getURL = () => {
    if (this.displayName === "No Initiative") return "";
    if (!this.url || this.url === "") return "";
    return this.url;
  };

  getComponentForGrid({ props }) {
    return <div></div>;
  }

  getFirstColumnComponent({ props }) {
    return <div></div>;
  }
}
