import { useEffect, useState } from "react";
import useClientStatusConfigContext from "src/providers/v2/Business/ClientStatusConfigContext";
import {
  groupItemsByStatus,
  groupItemsByStatusCategory,
} from "src/v2/utils/status";
import { useWidgetContext } from "../../Widget/WidgetContext";
import { useDataSection } from "../hooks/useDataSection";
import {
  getProjectStatusProgress,
  aggregateByStatusCategory,
  getURLForFiltersFromParentSections,
} from "./utils";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { FilterFieldsFactory } from "src/v2/domain/entities/filter/factory";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";
import { EventTypes, useEventListener } from "src/providers/EventContext";

export const useProjectStatusProgressData = ({
  items,
  section,
  parentSections,
}) => {
  const { filters, groupBy, options, groupedByValue } = section;

  const updateColumn = useEventListener(
    EventTypes.PROJECT_STATUS_OVERRIDE,
    "useProjectStatusProgressData"
  );

  const [progressStatusProgress, setProjectStatusProgress] = useState({
    total: 0,
    aggregatedValues: [],
  });
  const [taskCompletion, setTaskCompletion] = useState({
    total: 0,
    aggregatedValues: [],
  });

  const optionsStyles =
    options?.find((option) => option.name === "styles")?.value || {};

  const showPercentages = options.find(
    (option) => option.name === "show_percentages"
  )?.value;
  const redirectView = options.find(
    (option) => option.name === "redirect_view"
  )?.value;

  const titleStyle = optionsStyles?.title || {};

  const showProgressBarTitle =
    options?.find((option) => option.name === "show_progress_bar_title")
      ?.value ?? true;

  const { filteredItems } = useDataSection({
    filters,
    groupBy,
    items,
  });

  const { getConfigValueByName } = useWidgetContext();
  const [itemsGroupedByStatus, setItemsGroupedByStatus] = useState({});
  const [redirectURL, setRedirectURL] = useState("");
  const [itemsGroupedByStatusCategory, setItemsGroupedByStatusCategory] =
    useState({});

  const { statuses } = useClientStatusConfigContext();
  useEffect(() => {
    const projectStatusConfig = getConfigValueByName(
      WidgetConfigs.projectStatusInsights
    );
    const projectStatusAggregation = getProjectStatusProgress({
      items: filteredItems,
      config: projectStatusConfig,
    });
    const statusCategoryAggregation = aggregateByStatusCategory({
      items: filteredItems,
    });

    const groupedStatus = groupItemsByStatus(filteredItems);
    const groupedStatusCategory = groupItemsByStatusCategory(filteredItems);
    Object.keys(groupedStatusCategory).forEach((statusCategory) => {
      const totalItems = groupedStatusCategory[statusCategory].length;
      const percentage = Math.round((totalItems / filteredItems.length) * 100);

      groupedStatusCategory[statusCategory] = percentage;
    });
    const itemKeys = FilterFieldsFactory.buildBaseFilterField({
      fieldKey: "key",
      values: filteredItems.map((item: BlockItem) => item.getKey()),
    });

    const redirectURL = getURLForFiltersFromParentSections({
      parentSections,
      groupedByValue,
      redirectView,
      extraFilters: [itemKeys],
    });
    setRedirectURL(redirectURL);
    setProjectStatusProgress(projectStatusAggregation);
    setTaskCompletion(statusCategoryAggregation);
    setItemsGroupedByStatus(groupedStatus);
    setItemsGroupedByStatusCategory(groupedStatusCategory);
  }, [section, items, updateColumn]);

  return {
    titleStyle,
    filteredItems,
    redirectView,
    items,
    progressStatusProgress,
    showProgressBarTitle,
    itemsGroupedByStatus,
    showPercentages,
    itemsGroupedByStatusCategory,
    statuses,
    redirectURL,
  };
};
