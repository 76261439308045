import React, { useRef, useEffect } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getCurrentQuarterWeek } from "src/v2/utils/date";
import { Box, Grid, Typography } from "@material-ui/core";
import { SectionViewType } from "../types";
import { useWidgetContext } from "../../Widget/WidgetContext";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

const chartOptions = {
  plugins: {
    tooltip: {
      intersect: true,
      callbacks: {
        title: (context) => {
          const title = context[0].label;
          return `Week ${title}`;
        },
        label: function (context) {
          const label = context.dataset.label || "";
          const value = context.formattedValue || "";
          const items =
            context?.dataset?.totalData[context.dataIndex]
              ?.completed_issues_in_week;

          const itemsPlural = items === 1 ? "item" : "items";
          const itemsString = items ? `(${items} ${itemsPlural})` : "";
          return `${label}: ${value}% complete ` + itemsString;
        },
      },
    },
    legend: {
      display: false,
    },
  },
};

function triggerTooltip(chart, currentQuarterWeek) {
  const tooltip = chart?.tooltip;

  if (!tooltip) {
    return;
  }

  if (tooltip.getActiveElements().length > 0) {
    tooltip.setActiveElements([], { x: 0, y: 0 });
  } else {
    const { chartArea } = chart;

    tooltip.setActiveElements(
      [
        {
          datasetIndex: 0,
          index: currentQuarterWeek - 1,
        },
        {
          datasetIndex: 1,
          index: currentQuarterWeek - 1,
        },
      ],
      {
        x: (chartArea.left + chartArea.right) / 2,
        y: (chartArea.top + chartArea.bottom) / 2,
      }
    );
  }

  chart.update();
}

export const LineChart: React.FC<SectionViewType> = ({
  section,
  items,
  parentSections,
}) => {
  const { getConfigValueByName } = useWidgetContext();
  const chartConfig = getConfigValueByName(WidgetConfigs.linearChartConfig);
  if (!chartConfig) {
    throw new Error("Linear chart config not found in Widget Configs");
  }

  const data = buildDataByConfig(chartConfig);

  const chartRef = useRef<any>(null);
  const currentQuarterWeek = getCurrentQuarterWeek();
  useEffect(() => {
    const chart = chartRef.current;
    triggerTooltip(chart, currentQuarterWeek);
  }, []);

  const AISummaryHTML = chartConfig.summary_html || "";

  const { options } = section;

  const customStyles =
    options?.find(({ name }) => name === "styles")?.value || {};

  return (
    <Grid alignContent="center" alignItems="center" style={{ ...customStyles }}>
      <Typography style={{ fontSize: 12, color: "rgb(57, 94, 119)" }}>
        {`Current Week of Quarter: ${currentQuarterWeek}`}
      </Typography>
      <Line ref={chartRef} data={data} options={chartOptions} />
      {AISummaryHTML && (
        <Box
          style={{ marginTop: 24, marginLeft: 16 }}
          dangerouslySetInnerHTML={{ __html: AISummaryHTML }}
        />
      )}
    </Grid>
  );
};
function buildDataByConfig(chartConfig: any) {
  const { datasets, labels } = chartConfig;
  return {
    labels,
    datasets: datasets.map((dataset) => {
      const { label, color, data } = dataset;
      return {
        type: "line" as const,
        label,
        borderColor: color,
        borderWidth: 2,
        fill: false,
        data: data.map(({ percentage }) => percentage),
        totalData: data,
      };
    }),
  };
}
