import { Box } from "@material-ui/core";
import { ProgressBarByStatus } from "src/v2/components/progressBar/ProgressBarByStatus";
import { ProgressField } from "./ProgressField";

export class CondensedProgressField extends ProgressField {
  constructor(field) {
    super(field);
  }

  getComponentForGrid({ fieldsState }) {
    const { statuses } = fieldsState;

    return (
      <Box
        style={{
          width: "200px",
          flexDirection: "column",
        }}
      >
        {this.percentages !== undefined && (
          <ProgressBarByStatus
            itemsGroupedByStatus={this.childrenGroupedByStatus}
            style={{ height: 12, marginTop: 8 }}
            statuses={statuses}
            showPercentages={false}
          />
        )}
      </Box>
    );
  }
}
