import { useMemo } from "react";
import { isDevMode } from "src/services/sessionService";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useBlockContext } from "src/v2/providers/block/BlockContext";
import { getFilteredItems } from "src/v3/dataContainers/filterContainer";
import { WidgetConfigType } from "src/v3/entities/WidgetConfig";
import { useBandwidth } from "src/v3/hooks/useBandwidth";
import { useOnTheRoadmap } from "src/v3/hooks/useOnTheRoadmap";
import { useWidgetContext } from "src/v3/providers/WidgetContext";
import { aggregateItemsByProjectStatus } from "src/v3/utils/ProjectStatus/aggregateItemsByPS";

const filterItemsByConfig = ({ items, configs }) => {
  const config = configs?.[0];
  return config?.filters
    ? getFilteredItems({ items, filters: config.filters })
    : [...items];
};

const calculatePercentages = ({ items, totalBandwidth, bandwidthMap }) => {
  const roadmapItems = items.filter((item) => item.isRoadmapItem);
  const nonRoadmapItems = items.filter((item) => !item.isRoadmapItem);

  const roadmapBandwidth = roadmapItems.reduce(
    (acc, item) => acc + (bandwidthMap[item.getKey()] || 0),
    0
  );
  const nonRoadmapBandwidth = nonRoadmapItems.reduce(
    (acc, item) => acc + (bandwidthMap[item.getKey()] || 0),
    0
  );

  const roadmapBandwidthPercentage = Math.round(
    totalBandwidth ? (roadmapBandwidth / totalBandwidth) * 100 : 0
  );
  const nonRoadmapBandwidthPercentage = Math.round(
    totalBandwidth ? (nonRoadmapBandwidth / totalBandwidth) * 100 : 0
  );

  return {
    roadmapBandwidthPercentage,
    nonRoadmapBandwidthPercentage,
  };
};

export const useTeamItemList = ({
  items,
  team,
}: {
  items: BlockItem[];
  team: string;
}) => {
  const { roadmapItems, nonRoadmapItems } = useOnTheRoadmap({ items });

  const { getConfigsByType } = useWidgetContext();

  const { originalItems } = useBlockContext();
  const teamItemsInOriginal =
    originalItems?.filter((item): BlockItem[] =>
      item.getTeamField()?.getValue()?.includes(team)
    ) || [];

  const filteredItems = useMemo(
    () =>
      filterItemsByConfig({
        items: teamItemsInOriginal,
        configs: getConfigsByType(WidgetConfigType.currentQuarterFilter),
      }),
    [originalItems]
  );

  if (isDevMode()) {
    console.debug(
      `${team} - useTeamItemList hook. Progress bar and bandwidth items:`,
      filteredItems.map((item: BlockItem) => ({
        name: item.displayName,
        key: item.getKey(),
      }))
    );
  }

  const { totalBandwidth, bandwidthMap } = useBandwidth(filteredItems);
  const { roadmapBandwidthPercentage, nonRoadmapBandwidthPercentage } = useMemo(
    () =>
      calculatePercentages({
        items: filteredItems,
        totalBandwidth,
        bandwidthMap,
      }),
    [filteredItems, totalBandwidth, bandwidthMap]
  );

  const projectStatusAggregation = useMemo(
    () => aggregateItemsByProjectStatus({ items: filteredItems }),
    [filteredItems]
  );

  if (isDevMode()) {
    const aggregation = projectStatusAggregation.map(
      ({ identifier, totalItems, percentage }) => ({
        identifier,
        totalItems,
        percentage,
      })
    );

    console.debug(
      `${team} - useTeamItemList hook. Project status aggregation:`,
      aggregation
    );
  }

  return {
    roadmapItems,
    nonRoadmapItems,
    totalBandwidth,
    bandwidthMap,
    roadmapBandwidthPercentage,
    nonRoadmapBandwidthPercentage,
    projectStatusAggregation,
  };
};
