import React, { useContext, useState, useEffect } from "react";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { useClientConfigContext } from "./ClientConfigContext";
import { getAdminParamFromURLAsString } from "src/v2/utils/url";

const URLContext = React.createContext({});

function useURLContext() {
  return useContext(URLContext);
}

export function URLContextProvider({ children }: any) {
  const { changeFilterByLevelLabel } = useClientConfigContext();
  const { genericViews } = useGenericViewContext();
  const [urlParams, setUrlParams] = useState(new URLSearchParams(""));

  useEffect(() => {
    const roadmapView = window.location.pathname.includes("/v2/roadmap/");
    const noRoutToGo = !roadmapView || genericViews?.length === 0;
    if (noRoutToGo) {
      return;
    }

    const urlSegments = window.location.pathname.split("/v2/roadmap/");
    const urlViewKey = urlSegments[1]?.split("/")[0];

    const availableViews = genericViews
      .filter(({ enabled }) => enabled)
      .map(({ key, old_keys }) => ({
        key,
        old_keys,
      }));

    const urlViewExists = availableViews.find(({ key }) => key === urlViewKey);
    if (urlViewExists) {
      return;
    }

    const urlKeyIsDeprecated = availableViews.find((view) =>
      view.old_keys?.includes(urlViewKey)
    );

    const isItemDetails = window.location.pathname.includes("/item/");
    if (urlKeyIsDeprecated) {
      redirectToView(urlKeyIsDeprecated.key);
    } else if (!urlViewExists && !isItemDetails) {
      redirectToView(availableViews[0].key);
    }
  }, [window.location.pathname, genericViews]);

  function redirectToView(viewKey) {
    const redirectUrl = `${window.location.origin}/v2/roadmap/${viewKey}`;
    const adminParam = getAdminParamFromURLAsString();
    const redirectTo = adminParam
      ? `${redirectUrl}?${adminParam}`
      : redirectUrl;
    window.location.replace(redirectTo);
  }

  useEffect(() => {
    setUrlParams(new URLSearchParams(window.location.search));
  }, [window.location.search]);

  useEffect(() => {
    if (changeFilterByLevelLabel) {
      const changeFrom = changeFilterByLevelLabel.from;
      const changeTo = changeFilterByLevelLabel.to;
      const levels = urlParams
        .get("filter_level")
        ?.replace(`${changeFrom}`, `${changeTo}`);
      if (levels) {
        urlParams.delete("filter_level");
        urlParams.set("filter_level", levels);
        window.history.replaceState(
          null,
          document.title,
          `${window.location.pathname}?${urlParams.toString()}`
        );
      }
    }
  }, [changeFilterByLevelLabel]);

  const value = React.useMemo(() => ({}), []);

  return <URLContext.Provider value={value}>{children}</URLContext.Provider>;
}

export { useURLContext };
