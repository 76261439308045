import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";
import { statusNotSetColor } from "src/v2/helpers/colors";

export const outcomeConfig = {
  name: "outcome",
  type: "select",
  statuses: [
    {
      name: "Paused",
      type: "paused",
      color: "#868686",
      position: 4,
    },
    {
      name: "Still In Progress",
      type: "in_progress",
      color: "#197BBD",
      position: 3,
    },
    {
      name: "Delayed",
      type: "delayed",
      color: "#ff3407",
      position: 2,
    },
    {
      name: "On Time",
      type: "on_time",
      color: "#4CAF50",
      position: 1,
    },
    {
      name: "Not Doing",
      type: "not_doing",
      color: "#aeaeae",
      position: 5,
    },
    {
      name: "Not Set",
      type: ProjectStatusType.NOT_SET,
      color: "#aeaeae",
      position: 999,
    },
  ],
};
