import { EditProjectStatusAction } from "../EditProjectStatus";
import {
  EditSummaryOfWorkDetailsView,
  ExtraSummaryOfWorkAction,
} from "../ExtraSummaryOfWork";
import { ResolveChallengeAction } from "../ResolveChallengeAction";
import { ActionType } from "./types";

export const ActionFactory = (type: ActionType, props: any) => {
  switch (type) {
    case ActionType.EditProjectStatus:
      return EditProjectStatusAction({ ...props });
    case ActionType.EditSummaryOfWork:
      return ExtraSummaryOfWorkAction({ ...props });
    case ActionType.EditSummaryOfWorkDetailsView:
      return EditSummaryOfWorkDetailsView({ ...props });
    case ActionType.ResolveChallenge:
      return ResolveChallengeAction({ ...props });
    default:
      throw new Error(`Action type ${type} not found`);
  }
};
