import { Typography, Box } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { useBlockContext } from "src/v2/providers/block/BlockContext";

const LastUpdatedText = () => {
  const styles = {
    fontSize: "14px",
    color: `${primaryTextColor}`,
    fontFamily: "Overpass",
  };
  const { lastUpdated, lastAIGenerated } = useBlockContext();
  return (
    <Box data-test-id="last-updated-text">
      <Typography style={styles}>
        Last updated from Jira: {lastUpdated}
      </Typography>
      {lastAIGenerated && (
        <Typography style={styles}>
          Last AI predictions: {lastAIGenerated}
        </Typography>
      )}
    </Box>
  );
};

export default LastUpdatedText;
