import { useState } from "react";
import { EventTypes } from "src/providers/EventContext";
import { useOverrideContext } from "src/providers/OverrideContext";
import { setSuccessSnackbar } from "src/services/setErrorSnackbar";
import { updateAISummaryValue } from "src/services/v2/insightsService";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import * as sessionService from "src/services/sessionService";
import { processExtraContext } from "./utils";
import { logError } from "src/utils/error";
import { findItemByKey } from "src/v3/utils/block_item";
import { useBlockContext } from "src/v2/providers/block/BlockContext";

export const useSummaryOfWorkEdit = (item: BlockItem) => {
  const { saveOverride } = useOverrideContext();
  const { originalItems } = useBlockContext();

  // TODO: after deprecate the v2, remove getAISummaryField
  const summaryOfWorkField =
    item.getSummaryOfWorkField() || item.getAISummaryField();
  const override = summaryOfWorkField.getLastAddedOverride();
  const overrideValue = override?.value?.context ?? "";

  const [extraContext, setExtraContext] = useState(overrideValue);
  const [isSaving, setIsSaving] = useState(false);
  const enableSaveButton = extraContext.trim() !== overrideValue.trim();

  function handleUpdateExtraContext(e: any) {
    setExtraContext(e);
  }
  const resetState = () => setExtraContext(overrideValue);

  const onSave = async () => {
    setIsSaving(true);

    const formattedExtraContext = processExtraContext(extraContext);
    const user = sessionService.getLoggedUser();

    try {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      const override = {
        retainOverride: true,
        expirationDate,
        justification: `AI summary edited by ${user?.name} on ${new Date().toLocaleDateString()}`,
        value: {
          context: formattedExtraContext,
          created_at: new Date().toISOString(),
          author: {
            name: user?.name,
            email: user?.email,
          },
        },
        createdAt: new Date(),
        blockColumn: "ai_summary",
        fieldKey: summaryOfWorkField.fieldKey,
      };

      await updateAISummaryValue(override, item.getKey());

      saveOverride({
        item,
        overrides: [override],
        event: {
          type: EventTypes.AI_SUMMARY_OVERRIDE,
          message: `AI Summary updated by ${user?.name} on ${new Date().toLocaleDateString()} for ${item.getKey()}`,
        },
      });

      const foundItem = findItemByKey(originalItems, item.getKey());
      saveOverride({
        item: foundItem,
        overrides: [override],
        event: {
          type: EventTypes.AI_SUMMARY_OVERRIDE,
          message: `AI Summary updated by ${user?.name} on ${new Date().toLocaleDateString()} for ${item.getKey()}`,
        },
      });

      setExtraContext(formattedExtraContext);
      setSuccessSnackbar("Team Summary updated successfully!", 3000);
    } catch (error) {
      logError(
        error,
        "Failed to update team summary. Please try again later. If the issue persists, contact us.",
        3000
      );
      resetState();
    } finally {
      setIsSaving(false);
    }
  };

  return {
    extraContext,
    enableSaveButton,
    isSaving,
    handleUpdateExtraContext,
    resetState,
    onSave,
  };
};
