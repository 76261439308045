/* eslint-disable class-methods-use-this */
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import jiraFormatting from "jira2md";
import { FieldConfigType } from "../../enum/FieldConfigType";

export default class InitiativeEmailReports {
  public saveFormattedDataLocally(filteredItems) {
    localStorage.setItem(
      "initiatives_with_stakeholder",
      JSON.stringify(this.getInitiativesWithStakeholders(filteredItems))
    );
    document.dispatchEvent(new CustomEvent("local-storage-updated"));
    console.debug("[Event] local storage updated");
  }

  public handleFilter({ selectedFilters, items }) {
    const byKeyFilter = this.getInitiativeFilters(selectedFilters);
    const remainingFilters = this.getRemainingFilters(selectedFilters);
    const shouldFilter = selectedFilters && selectedFilters.length > 0;
    if (!shouldFilter) return items;
    const foundItemsByKey = items.filter((item) => {
      const result = byKeyFilter.some((field) => field.matchItem(item));
      return result;
    });
    foundItemsByKey.filter((item) =>
      item.setChildren(
        item.children.filter((child) =>
          remainingFilters.every((field) => field.matchItem(child))
        )
      )
    );

    return foundItemsByKey;
  }

  private getInitiativeFilters(filters) {
    return filters.filter(({ fieldKey }) => fieldKey === "key");
  }

  private getRemainingFilters(filters) {
    return filters.filter(({ fieldKey }) => fieldKey !== "key");
  }

  private findFieldValueByKey(initiativeCustomFields, key) {
    return initiativeCustomFields.find((field) => field.fieldKey === key)
      ?.value;
  }

  private getDateValueInString(initiativeCustomFields, dateKey) {
    const foundDate = this.findFieldValueByKey(initiativeCustomFields, dateKey);
    if (foundDate?.length && foundDate[0]?.length) {
      return foundDate[0][0];
    }
    return null;
  }

  private getStringValueFromField(field) {
    if (typeof field === "string") return field;
    if (Array.isArray(field) && field?.length) {
      return field[0];
    }
    return null;
  }

  private getStringFieldValue(initiativeCustomFields, fieldKey) {
    const foundDate = this.findFieldValueByKey(
      initiativeCustomFields,
      fieldKey
    );
    return this.getStringValueFromField(foundDate);
  }

  private removeSmartLink(input) {
    const smartLinkIndex = input.indexOf("smart-link");
    if (smartLinkIndex !== -1) {
      return input.substring(0, smartLinkIndex - 1);
    }
    return input;
  }

  private trimURL(input: string) {
    return input.split("|")[0];
  }

  private getNameFromURL(input) {
    const regex = /\/([\w\d+:%-]+)(?:#|\?|$)/i;

    const match = input.match(regex);

    if (match) {
      let name = match[1].replace(/\./g, "%2E") as string;
      const nameIsUrl = this.isURL(name);
      if (nameIsUrl) {
        const nameURLmatch = name.match(/\/pages\/\d+\/(.+?)#/);
        if (nameURLmatch && nameURLmatch[1]) {
          name = match[1];
        }
      }
      return decodeURIComponent(name).replace(/\+/g, " ");
    }
    return input;
  }

  private parseInput(str) {
    const inputWithoutWhiteSpaces = str.replace(/\s/g, "");
    const regex = /\[(.*?)\]/;
    const match = regex.exec(inputWithoutWhiteSpaces);
    const input = match ? match[1] : inputWithoutWhiteSpaces;
    if (this.isURL(input)) {
      let URL = input;
      if (URL.includes("smart-link")) {
        URL = this.removeSmartLink(URL);
      }
      if (URL.includes("|")) {
        URL = this.trimURL(URL);
      }
      let name = URL;
      if (URL.includes("+") || URL.includes("+-+")) {
        name = this.getNameFromURL(URL);
      }

      return { name, URL };
    }
    return null;
  }

  private isURL(str) {
    const pattern = new RegExp("^(https?:\\/\\/)");
    return pattern.test(str);
  }

  private buildHtml({ URL, name }) {
    return `<p><a href="${URL}">${name}</a></p>`;
  }

  private getFieldValueAsHTML(initiativeCustomFields, fieldKey) {
    const value = this.findFieldValueByKey(initiativeCustomFields, fieldKey)[0];
    if (!value || value === "") return "Project Plan not set";
    const valueAsString = Array.isArray(value) ? value[0] : value;
    const findUrlObject = this.parseInput(valueAsString);
    let html = "";
    if (findUrlObject) {
      html = this.buildHtml(findUrlObject);
    } else {
      html = jiraFormatting.jira_to_html(valueAsString);
    }
    return html;
  }

  private getInitiativesWithStakeholders(items: BlockItem[]) {
    const allInitiatives = items.filter((item) => item.isProject());
    return allInitiatives.map((initiative) => {
      const initiativeName = initiative.getFieldByType(FieldConfigType.name);
      return {
        stakeholders: this.findFieldValueByKey(
          initiative.customFields,
          "Stakeholders"
        ).map((fieldValue) => this.getStringValueFromField(fieldValue)),
        initiative_key: this.getStringFieldValue(
          initiative.customFields,
          "key"
        ),
        dueDate: this.getDateValueInString(
          initiative.customFields,
          "Target Date"
        ),
        targetDate: this.getDateValueInString(
          initiative.customFields,
          "Due date"
        ),
        initiative_name: initiativeName?.displayName ?? initiative.displayName,
        has_filtered_epics: initiative.children.length > 0,
        project_plan_html: this.getFieldValueAsHTML(
          initiative.customFields,
          "Project Plan"
        ),
      };
    });
  }
}
