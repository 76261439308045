import React from "react";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Legend, Tooltip, Chart as ChartJS } from "chart.js";
import { Grid, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { ContainerWithToolTip } from "src/components/GlobalComponents";

ChartJS.register(ArcElement, Tooltip, Legend);

function formatChartLabels(chart: any) {
  const data = chart.data;
  if (data.labels.length && data.datasets.length && data.datasets[0].quantity) {
    return data.labels.map(function (label: any, i: number) {
      const meta = chart.getDatasetMeta(0);
      const style = meta.controller.getStyle(i);
      return {
        text: `${label}: ${data.datasets[0].data[i]}% (${data.datasets[0].quantity[i]} items)`,
        fillStyle: style.backgroundColor,
        strokeStyle: style.borderColor,
        lineWidth: style.borderWidth,
      };
    });
  }
  return [];
}

const options = {
  plugins: {
    legend: {
      position: "bottom" as const,
      align: "center" as const,
      labels: {
        font: {
          size: 12,
        },
        generateLabels: formatChartLabels,
      },
    },
    tooltip: {
      callbacks: {
        label: ({ formattedValue }: { formattedValue: string }) =>
          `${formattedValue}%`,
      },
    },
  },
};

export const SolarwindsDoughnutChart: React.FC<any> = ({
  title,
  info,
  dataSet,
}) => {
  return (
    <div>
      {title && (
        <Grid direction="row" container>
          <Typography style={{ marginBottom: 30, fontSize: 16 }}>
            {title}
          </Typography>
          {info && (
            <ContainerWithToolTip tooltipText={info}>
              <InfoOutlined
                style={{ width: 18, marginLeft: 10 }}
              ></InfoOutlined>
            </ContainerWithToolTip>
          )}
        </Grid>
      )}
      <Doughnut data={dataSet} options={options}></Doughnut>
    </div>
  );
};
