import axios from "axios";
import { responseErrorInterceptor, requestInterceptor } from "./interceptors";

/*
  @deprecated - use the "../../v2/services/api/apiV2" instead;
*/

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
  timeout: 300000,
});

instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export default instance;
