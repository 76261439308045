import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { ChartData } from "../ChartData";
import {
  assigneeBackgroundColor,
  dateBackgroundColor,
  defaultProgressBarBackgroundColor,
  doneStatusProgressBarColor,
  genericViewTitleColor,
  ignoredStatusProgressBarColor,
  inProgressStatusProgressBarColor,
  todoStatusProgressBarColor,
  widgetBorderColor,
  widgetTitleColor,
} from "src/v2/helpers/colors";
import { isEmpty } from "src/v2/utils/object";

const defaultColors = [
  "#CC5D12",
  "#088786",
  "#83A515",
  "#BD408A",
  "#687ED1",
  "#968FD9",
  inProgressStatusProgressBarColor,
  todoStatusProgressBarColor,
  doneStatusProgressBarColor,
  ignoredStatusProgressBarColor,
  defaultProgressBarBackgroundColor,
  widgetTitleColor,
  widgetBorderColor,
  dateBackgroundColor,
  assigneeBackgroundColor,
  genericViewTitleColor,
];

const getItemsAggregationDataset = ({
  items,
  groupedItems,
  itemsWithoutField,
  labels,
  considerNotSet,
  colors,
}) => {
  const data = {
    quantity: [],
    percentages: [],
  };

  const allItemsQuantity = items.length;

  groupedItems.forEach((group) => {
    const length = group.items.length;
    const percentage = ((length * 100) / allItemsQuantity).toFixed(0);
    data.percentages.push(percentage);
    data.quantity.push(length);
  });

  if (itemsWithoutField.length > 0 && considerNotSet) {
    labels.unshift("Not Set");
    colors.unshift("grey");
    const notSetItems = itemsWithoutField.length;
    const notSetItemsPercentage = (notSetItems * 100) / allItemsQuantity;
    data.quantity.unshift(notSetItems);
    data.percentages.unshift(notSetItemsPercentage.toFixed(0));
  }
  const dataSet = new ChartData({
    labels,
    colors,
    percentages: data.percentages,
    quantity: data.quantity,
  });

  return dataSet.getFormattedChartData();
};

const getChildrenAggregationDataset = ({
  items,
  itemsWithoutField,
  groupedItems,
  labels,
  colors,
  considerNotSet,
}) => {
  const data = {
    quantity: [],
    percentages: [],
  };

  const allChildrenCount = items.reduce(
    (total, item) => (total += item.children.length),
    0
  );

  const childrenWithoutField = itemsWithoutField.reduce(
    (total, item) => (total += item.children.length),
    0
  );

  groupedItems.forEach((group) => {
    const childrenCount = group.items.reduce(
      (total, item) => (total += item.children.length),
      0
    );
    const percentage = ((childrenCount * 100) / allChildrenCount).toFixed(0);
    data.percentages.push(percentage);
    data.quantity.push(childrenCount);
  });

  if (itemsWithoutField.length > 0 && considerNotSet) {
    const notSetItemsPercentage = (
      (childrenWithoutField * 100) /
      allChildrenCount
    ).toFixed(0);
    data.percentages.unshift(notSetItemsPercentage);
    data.quantity.unshift(childrenWithoutField);
  }

  const dataSet = new ChartData({
    labels,
    colors,
    percentages: data.percentages,
    quantity: data.quantity,
  });

  return dataSet.getFormattedChartData();
};

export const useBuildDoughtChartData = ({ section, items, groupedItems }) => {
  const { options } = section;
  const mapLabels =
    options?.find(({ name }) => name === "doughnut_chart_labels_mapper")
      ?.value ?? {};

  if (isEmpty(mapLabels)) {
    console.debug(
      "doughnut chart labels mapper is empty, using the default set of colors and the original labels in the items "
    );
  }

  const labels = [
    ...groupedItems.map(
      (item) => mapLabels[item.fieldValue]?.name ?? item.fieldValue
    ),
  ];

  const colors = [
    ...groupedItems.map(
      (item, index) => mapLabels[item.fieldValue]?.color ?? defaultColors[index]
    ),
  ];

  const itemsWithoutField = items.filter(
    (item: BlockItem) =>
      !groupedItems.find((group) =>
        group.items.some(
          (groupItem: BlockItem) => groupItem.getKey() === item.getKey()
        )
      )
  );

  const considerNotSet =
    options?.find(({ name }) => name === "consider_not_set")?.value ?? true;

  const itemsAggregationDataSet = getItemsAggregationDataset({
    items,
    groupedItems,
    itemsWithoutField,
    labels,
    colors,
    considerNotSet: considerNotSet,
  });

  const childrenAggregationDataSet = getChildrenAggregationDataset({
    items,
    groupedItems,
    itemsWithoutField,
    labels,
    colors,
    considerNotSet: considerNotSet,
  });

  return {
    dataSet: itemsAggregationDataSet,
    childrenDataSet: childrenAggregationDataSet,
  };
};
