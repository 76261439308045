import { Box, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { widgetBorderColor, widgetTitleColor } from "src/v2/helpers/colors";
import { ContainerWithToolTip } from "src/components/GlobalComponents";
import { InfoOutlined } from "@material-ui/icons";
import { SectionBuilder } from "../Section";
import { WidgetProvider } from "./WidgetContext";
import { useState } from "react";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${widgetBorderColor}`,
    padding: 16,
  },
  noPadding: {
    border: `1px solid ${widgetBorderColor}`,
  },
  title: { color: widgetTitleColor },
});

export const WidgetTitle = ({ title, tooltipText, titleStyles }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.title}
      style={{
        ...titleStyles,
      }}
      variant="h5"
    >
      {title}{" "}
      {tooltipText && (
        <ContainerWithToolTip tooltipText={tooltipText}>
          <InfoOutlined style={{ color: "white", width: 16, height: 16 }} />
        </ContainerWithToolTip>
      )}
    </Typography>
  );
};

export const WidgetV2 = ({ widget, items }) => {
  const classes = useStyles();
  const { title, section, options, name, configs, id } = widget;
  const customStyles = options.find(({ name }) => name === "styles");
  const titleUrl = options.find(({ name }) => name === "title_url")?.value;
  const subtitles = options.find(({ name }) => name === "subtitles")?.value;
  const tooltipText = options.find(({ name }) => name === "widget_info")?.value;
  const rootStyle = customStyles?.value?.root || {};
  const titleStyles = customStyles?.value?.title || {};

  const [showWidget, setShowWidget] = useState(true);

  const addDisplay = showWidget ? {} : { display: "none" };
  return (
    <WidgetProvider configs={configs} items={items} section={section}>
      <div
        className={showWidget ? classes.root : classes.noPadding}
        style={{ ...rootStyle, ...addDisplay }}
        data-test-id={`widget-${name}-${id}`}
        id={`widget-${title}`}
      >
        <Box>
          {titleUrl ? (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              variant="body2"
              style={{ cursor: "pointer" }}
              href={titleUrl}
            >
              <WidgetTitle
                title={title}
                tooltipText={tooltipText}
                titleStyles={titleStyles}
              />
            </Link>
          ) : (
            <WidgetTitle
              title={title}
              tooltipText={tooltipText}
              titleStyles={titleStyles}
            />
          )}
        </Box>
        {subtitles && subtitles.length > 0 && (
          <div style={{ marginBottom: 24 }}>
            {subtitles.map((subtitle) => {
              const { link, style, text } = subtitle;
              return (
                <Grid data-test-id="widget_subtitle">
                  {link ? (
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      variant="body2"
                      style={{ cursor: "pointer" }}
                      href={link}
                    >
                      <Typography style={{ ...style }}>{text}</Typography>
                    </Link>
                  ) : (
                    <Typography style={{ ...style }}>{text}</Typography>
                  )}
                </Grid>
              );
            })}
          </div>
        )}

        <SectionBuilder
          section={section}
          items={items}
          parentSections={undefined}
          setShowWidget={setShowWidget}
        />
      </div>
    </WidgetProvider>
  );
};

export default WidgetV2;
