import styled from "styled-components";
import { Center } from "../GlobalComponents";

export const Container = styled(Center)`
  height: 100vh;
  padding: 5em;
  & p {
    margin-bottom: 2em;
    font-size: 2em;
  }
`;

export const BackToAppButton = styled.button`
  background-color: var(--color-dark-blue);
  color: white;
  border: none;
  padding: 0.5em;
  cursor: pointer;
  border-radius: 11px;
  font-size: 1.8em;
`;
