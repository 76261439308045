import { Grid } from "@material-ui/core";
import React from "react";
import { Bar } from "react-chartjs-2";

const options = {
  scales: {
    x: {
      grid: {
        display: false, // Remove the grid lines for the x-axis
      },
    },
    y: {
      grid: {
        display: false, // Remove the grid lines for the x-axis
      },
    },
  },
};

export const BarChart: React.FC<any> = ({ data, info, title }) => {
  return (
    <Grid xs={7}>
      <Bar data={data} options={options} />
    </Grid>
  );
};
