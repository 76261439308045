import { Grid, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";
import { StatusCategory } from "../../enum/StatusCategory";

export class ProjectedFinishField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.predictionType = field.value?.prediction_type;
    this.days = field.value?.prediction?.days;
    this.predictedText = field.value?.prediction?.predicted_text;
    this.projectedDateText =
      field.value?.prediction?.projected_date_text ||
      field.value?.prediction?.projected_date;
    this.createdAt = field.value?.created_at;
    this.hideContent = field.hide_content;
    this.value = Array.isArray(field.value)
      ? field.value
      : [field.value?.value ?? field.value ?? ""];
    this.project_status = field.value?.prediction?.project_status;
    this.overrideValue =
      field.value?.override && !field?.value?.prediction?.project_status;
  }

  predictionType: string;

  days: number;

  predictedText: string;

  projectedDateText: string;

  createdAt: Date;

  hideContent: boolean;

  project_status: any;

  overrideValue: any;

  getprojectedDateText() {
    if (this.projectedDateText) {
      return (
        <Grid>
          <Typography style={{ color: `${primaryTextColor}` }}>
            <div dangerouslySetInnerHTML={{ __html: this.projectedDateText }} />
          </Typography>
        </Grid>
      );
    }
    return null;
  }

  getPredictedText() {
    if (this.predictedText) {
      return (
        <Typography
          style={{
            color: `${primaryTextColor}`,
            marginBottom: "16px",
            minWidth: 196,
            whiteSpace: "pre-line",
          }}
        >
          {this.predictedText}
        </Typography>
      );
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getNotPredictedYetText() {
    return (
      <Typography style={{ color: `${primaryTextColor}` }} noWrap>
        Not predicted yet
      </Typography>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getAlreadyComplete() {
    return (
      <Typography style={{ color: `${primaryTextColor}` }} noWrap>
        Already Complete
      </Typography>
    );
  }

  getProjectedFinishText(statusCategory) {
    if (statusCategory === StatusCategory.Done)
      return this.getAlreadyComplete();
    if (this.hideContent) {
      return "";
    }
    if (!this.predictedText && !this.projectedDateText)
      return this.getNotPredictedYetText();

    return (
      <Grid direction="column">
        {this.getPredictedText()}
        {this.getprojectedDateText()}
      </Grid>
    );
  }
  getProjectedDate() {
    return this.value[0]?.prediction?.projected_date;
  }

  getComponentForGrid({ fieldsState }) {
    const { statusCategory } = fieldsState;
    if (this.overrideValue) {
      return <div />;
    }
    return (
      <Grid
        style={{
          minWidth: "200px !important",
          maxWidth: 200,
        }}
        direction="column"
        container
        justifyContent="center"
      >
        {this.getProjectedFinishText(statusCategory)}
      </Grid>
    );
  }
}
