import { Typography, Box } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";

const FilteredByText = () => {
  const { filteredByString } = useFilterContext();
  const boxStyles = {
    display: "flex",
    flexDirection: "row",
  };
  const stylesForFilterBy = {
    fontSize: "14px",
    backgroundColor: "rgb(255, 240, 210)",
    padding: "4px",
    fontFamily: "Overpass",
    color: `${primaryTextColor}`,
  };
  return (
    <Box style={boxStyles}>
      {filteredByString && (
        <Typography style={stylesForFilterBy}>{filteredByString}</Typography>
      )}
    </Box>
  );
};
export default FilteredByText;
