import { Box, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class AllocationField extends CustomFieldBase {
  allocation: number;
  uniqueAssignees: number;
  uniqueAssigneesList: string[];
  hasNoAllocation: boolean;
  bandwidth: number;
  constructor(field) {
    super(field);
    this.value = field.value ?? {};
    const allocation = this.value?.allocation ?? 0;
    this.allocation = allocation * 40 ?? 0;
    this.allocation = Math.round(this.allocation * 10) / 10;
    this.uniqueAssignees = this.value?.unique_assignees?.length ?? 0;
    this.uniqueAssigneesList = this.value?.unique_assignees ?? [];
    this.hasNoAllocation = !this.uniqueAssignees && !this.allocation;
  }

  getAllocation() {
    return this.allocation;
  }

  getValue() {
    return this.value;
  }

  setBandwidth(bandwidth) {
    this.bandwidth = bandwidth;
    this.value["bandwidth"] = bandwidth;
  }

  getBandwidth() {
    return this.bandwidth;
  }

  getComponentForGrid(_) {
    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "200px",
          minWidth: 72,
          padding: 8,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography style={{ color: primaryTextColor }} noWrap>
            {this.allocation > 0 ? `Total hours/week: ${this.allocation}` : ""}
          </Typography>
          <Typography style={{ color: primaryTextColor }} noWrap>
            {this.uniqueAssignees
              ? `Unique engineers: ${this.uniqueAssignees}`
              : ""}
            {this.hasNoAllocation && "No Allocation"}
          </Typography>
        </Box>
      </Box>
    );
  }
}
