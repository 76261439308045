import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export const mapWeeksToDate = (duration) => {
  switch (duration) {
    case "1 week":
      return new Date(new Date().setDate(new Date().getDate() + 7));
    case "2 weeks":
      return new Date(new Date().setDate(new Date().getDate() + 14));
    case "3 weeks":
      return new Date(new Date().setDate(new Date().getDate() + 21));
    case "4 weeks":
      return new Date(new Date().setDate(new Date().getDate() + 28));
    default:
      return null;
  }
};

/**
 * Maps a string date to a corresponding number of weeks.
 *
 * @param {string} stringDate - The string representation of a date.
 * @returns {string | null} - The number of weeks as a string, or null if the provided date is in the past. Defaults to "1 week".
 */
export const mapStringDateToWeeks = (stringDate) => {
  const futureDateString = new Date(stringDate);
  const currentDate = new Date();
  const timeDiff = futureDateString.getTime() - currentDate.getTime();

  if (timeDiff < 0) {
    return null; // The provided date is in the past
  }

  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  switch (
    true // Determine the duration based on the difference in days
  ) {
    case daysDiff <= 7:
      return "1 week";
    case daysDiff <= 14:
      return "2 weeks";
    case daysDiff <= 21:
      return "3 weeks";
    case daysDiff <= 28:
      return "4 weeks";
    default:
      return "1 week";
  }
};
