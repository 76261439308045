export default function setErrorSnackbar(message: string, duration = 6000) {
  window.dispatchEvent(
    new CustomEvent("custom_aphrodite_alert", {
      detail: {
        message,
        type: "error",
        duration,
      },
    })
  );
}

export function setSuccessSnackbar(message: string, duration = 6000) {
  window.dispatchEvent(
    new CustomEvent("custom_aphrodite_alert", {
      detail: {
        message,
        type: "success",
        duration,
      },
    })
  );
}
