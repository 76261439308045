import { DisplayConfigSection } from "../../enum/DisplayConfigSection";
import { FieldConfigType } from "../../enum/FieldConfigType";
import { FieldOrigin } from "../../enum/FieldOrigin";
import { BlockItemLevel } from "../../enum/BlockItemLevel";
import { FieldDisplayConfig } from "../../interfaces/FieldDisplayConfig";

export class FieldConfig {
  displayName: string;

  fieldKey: string;

  target: BlockItemLevel;

  type: FieldConfigType;

  origin: FieldOrigin;

  displayConfig: FieldDisplayConfig[];

  widgetIds: string[];

  constructor(field) {
    this.displayName = field.display_name;

    this.fieldKey = field.field_key;

    this.target = field.target;

    this.type = field.type;

    this.origin = field.origin;
    this.widgetIds = field.widget_ids;

    this.displayConfig = field.display_config.map(
      (config) => new FieldDisplayConfig(config)
    );

    if (
      this.type === FieldConfigType.changelog &&
      this.getDefaultFilterValues()?.length === 0
    ) {
      console.log({ field });
      throw new Error(
        `Field of type ${field.type} must have set default value`
      );
    }
  }

  hasDefaultFilterValues() {
    return this.getDefaultFilterValues()?.length > 0;
  }

  getDefaultFilterValues() {
    return this.getFilterConfig()?.defaultValues;
  }

  getFilterConfig() {
    return this.displayConfig.find(
      ({ section }) => section === DisplayConfigSection.filter
    );
  }

  getGridConfig() {
    return this.displayConfig.find(
      ({ section }) => section === DisplayConfigSection.grid
    );
  }

  showOnFilter() {
    return this.displayConfig.some(
      ({ section }) => section === DisplayConfigSection.filter
    );
  }

  isReadOnFilter() {
    return this.displayConfig.some(
      ({ section }) => section === DisplayConfigSection.only_read_on_filter
    );
  }

  showOnGrid() {
    return this.displayConfig.some(
      ({ section }) => section === DisplayConfigSection.grid
    );
  }
}
