import { Grid, Typography, Drawer } from "@material-ui/core";
import { Fragment, useState } from "react";
import { menuBackgroundColor } from "src/v2/helpers/colors";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";
import { ExpandAllButton } from "../../buttons/ExpandAllButton";
import { FilterMenuBottomButtons } from "./BottomButtons";
import { FilterMenuHeader } from "./FilterMenuHeader";
import { FilterByLevel } from "./FilterByLevel";
import { FilterMenuSaveFilter } from "./FilterMenuSaveFilter";
import { useWidgetContext } from "../../Widget/WidgetContext";

export const FilterMenu = ({ anchorEl, setAnchorEl }) => {
  const {
    handleUpdateFilters,
    filterFields,
    selectedFiltersBeforeApply,
    handleClearFilters,
  } = useFilterContext();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilters = () => {
    handleClearFilters();
  };

  const applyFilters = ({ closeFilter = true }) => {
    handleUpdateFilters(selectedFiltersBeforeApply);
    if (closeFilter) handleClose();
  };
  const { getConfigValueByName } = useWidgetContext();
  const [expandAll, setExpandAll] = useState(false);

  const filterFieldsToShowOnMenu = filterFields.filter(
    (field) => field.showOnFilter() && !field.isVelmaInsight()
  );
  const velmaInsightsToShowOnMenu = filterFields.filter(
    (field) => field.showOnFilter() && field.isVelmaInsight()
  );
  const [store, setStore] = useState({});

  return (
    <Drawer
      data-testid="menu"
      id="filter-menu"
      anchor="right"
      open={Boolean(anchorEl)}
      onClose={handleClose}
      variant="persistent"
    >
      <Grid style={{ padding: "16px" }}>
        <Grid
          container
          direction="column"
          style={{
            backgroundColor: `${menuBackgroundColor}`,
            paddingTop: "0",
            position: "sticky",
            top: 0,
            zIndex: 999,
            justifyContent: "center",
          }}
        >
          <FilterMenuHeader handleClose={handleClose} />
          <ExpandAllButton
            expanded={expandAll}
            onClick={() => setExpandAll(!expandAll)}
          />
          <FilterByLevel />
        </Grid>
        {filterFieldsToShowOnMenu.map((field) =>
          field.getFilterComponent({ expandAll, store, setStore })
        )}
        {velmaInsightsToShowOnMenu.length > 0 && (
          <Grid style={{ borderTop: "2px solid var(--color-grey2)" }}>
            <Typography
              style={{
                paddingTop: "8px",
                color: "var(--color-grey2)",
                textAlign: "right",
                fontWeight: "bold",
              }}
            >
              VELMA INSIGHTS
            </Typography>
            {velmaInsightsToShowOnMenu.map((field) => (
              <Fragment key={field.fieldKey}>
                {field.getFilterComponent({
                  expandAll,
                  store,
                  setStore,
                  getConfigValueByName,
                })}
              </Fragment>
            ))}
          </Grid>
        )}
        <Grid
          container
          direction="column"
          style={{
            backgroundColor: `${menuBackgroundColor}`,
            paddingBottom: "10px",
            position: "sticky",
            bottom: 0,
            zIndex: 999,
          }}
        >
          <FilterMenuBottomButtons
            applyFilters={applyFilters}
            clearFilters={clearFilters}
          />
          <FilterMenuSaveFilter applyFilters={applyFilters} />
        </Grid>
      </Grid>
    </Drawer>
  );
};
