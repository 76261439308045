import { Theme, makeStyles } from "@material-ui/core";

export const useStylesCustomTooltip = makeStyles((theme: Theme) => ({
  tooltip: {
    display: "flex",
    padding: theme.spacing(4),
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1),
    background: theme.palette.grey[900],
  },
  arrow: {
    color: theme.palette.grey[900],
  },
}));
