import { Box, Grid, styled, Theme } from "@material-ui/core";

export const TeamsListRoot = styled(Grid)(({ theme }: { theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2, 0),
}));

export const TeamListFakeAccordion = styled(Box)(({ theme }: { theme }) => ({
  width: "100%",
}));

export const TeamHeaderProgressBar = styled(Grid)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    width: "23%",
    height: "10px",
    justifyContent: "flex-end",
    alignItems: "center",
  })
);
