import { getAccessibleColors, statusNotSetColor } from "src/v2/helpers/colors";

export const manualUpdateProjectStatusMapper = (statusObj) => {
  const statusColorMapper = {
    at_risk_status: "#F1CA00",
    on_track_status: "#5aaf6d",
    off_track_status: "#F05A28",
    not_set_status: "#aeaeae",
    paused: "#aeaeae",
  };
  const color = statusColorMapper[statusObj.type] ?? statusNotSetColor;
  const accessibleColors = getAccessibleColors(color);
  return {
    textColor: accessibleColors.text,
    background: accessibleColors.background,
    ballColor: color,
    name: statusObj.name,
  };
};
