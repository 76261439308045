import { ContainerWithToolTip } from "src/components/GlobalComponents";
import {
  createStatusDataSetsForProgressBar,
  fillDatasetColors,
  sortDatasetsByStatusPosition,
} from "src/v2/utils/status";
import { Box, Typography } from "@material-ui/core";
import {
  defaultProgressBarBackgroundColor,
  doneStatusProgressBarColor,
  inProgressStatusProgressBarColor,
  progressBarTitleColor,
  todoStatusProgressBarColorText,
} from "src/v2/helpers/colors";

export const ProgressBarByStatus = ({
  itemsGroupedByStatus,
  style = {},
  statuses,
  title = "",
  showPercentages,
  itemsGroupedByStatusCategory = {},
}) => {
  const data = createStatusDataSetsForProgressBar(itemsGroupedByStatus);
  const sortedItems = sortDatasetsByStatusPosition({ data, statuses });
  const dataLength = sortedItems.length;
  const statusCategoryOrder = {
    Done: {
      backgroundColor: doneStatusProgressBarColor,
      color: "white",
    },
    "In Progress": {
      backgroundColor: inProgressStatusProgressBarColor,
      color: "white",
    },

    "To Do": {
      backgroundColor: "#9aaec880",
      color: todoStatusProgressBarColorText,
    },
  };
  return (
    <Box>
      {title && (
        <Typography
          style={{
            color: `${progressBarTitleColor}`,
            letterSpacing: 0.33,
            marginBottom: 12,
          }}
        >
          {title}
        </Typography>
      )}
      <div
        style={{
          borderRadius: 15,
          backgroundColor: defaultProgressBarBackgroundColor,
          overflow: "hidden",
          height: 18,
          ...style,
        }}
      >
        {sortedItems.map((item, index) => {
          const sum = sortedItems.reduce((a, b) => a + b.data[0], 0);
          const percentage = (item.data[0] / sum) * 100;
          const itemsLabel = item.data[0].length === 1 ? "item" : "items";
          const text = `${item.label}: ${percentage.toFixed(0)}% (${
            item.data[0]
          } ${itemsLabel})`;
          return (
            <ContainerWithToolTip tooltipText={text}>
              <div
                key={index}
                style={{
                  width: `${percentage}%`,
                  height: "100%",
                  float: "left",
                  background: fillDatasetColors(item, dataLength, index),
                }}
              />
            </ContainerWithToolTip>
          );
        })}
      </div>
      {showPercentages && (
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ padding: "0 8px" }}
        >
          {Object.keys(statusCategoryOrder).map((statusCategory) => (
            <Box
              style={{
                display: "flex",
                padding: "2px 6px",
                borderRadius: 3,
                backgroundColor:
                  statusCategoryOrder[statusCategory].backgroundColor,
                color: statusCategoryOrder[statusCategory].color,
              }}
            >
              <Typography
                style={{
                  fontSize: 11,
                }}
              >
                {statusCategory}:
              </Typography>
              <Typography
                style={{
                  marginLeft: 4,
                  fontSize: 11,
                }}
              >
                {itemsGroupedByStatusCategory[statusCategory] || 0}%
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
