import { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useLoadBlockContext } from "src/v2/providers/block/LoadBlockContext";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { buildBreadcrumbPaths, closeDrawer, shouldSkipLoading } from "./utils";
import { findItemByKey } from "src/v3/utils/block_item";

interface UseItemDetailsResult {
  item: BlockItem | null;
  paths: { label: string; url: string; navigate: () => void }[];
  handleClose: () => void;
  isLoading: boolean;
}

export const useItemDetails = (): UseItemDetailsResult => {
  const { "0": itemKeys }: { "0": string } = useParams();
  const [item, setItem] = useState<BlockItem>(null);
  const [paths, setPaths] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const { originalItems, isLoadingBlocks } = useLoadBlockContext();
  const { currentViewData } = useGenericViewContext();

  useEffect(() => {
    if (shouldSkipLoading(currentViewData, isLoadingBlocks, itemKeys)) return;

    const itemKeyList = itemKeys.split("/");
    const lastItemKey = itemKeyList[itemKeyList.length - 1];

    const foundItem = findItemByKey(originalItems, lastItemKey);
    setItem(foundItem);

    if (foundItem) {
      const constructedPaths = buildBreadcrumbPaths(
        itemKeys,
        currentViewData,
        history,
        location.state?.breadcrumbLabels || []
      );
      setPaths(constructedPaths);
    } else {
      setPaths([]);
    }
  }, [itemKeys, originalItems, isLoadingBlocks, currentViewData, history]);

  const handleClose = useMemo(() => () => closeDrawer(history), [history]);

  const isLoading = !currentViewData || isLoadingBlocks || !item;

  return {
    item,
    paths,
    handleClose,
    isLoading,
  };
};
