import { createContext, useContext, useEffect, useMemo } from "react";
import { useBlockContext } from "./BlockContext";
import { useFilterContext } from "../filter/FilterContext";
import { getAllKeyValueFiltersFromURL } from "src/v2/utils/url";
import { useFilterHook } from "src/v2/hooks/filter/useFilterHook";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

const LoadBlockContext = createContext<{
  filteredItems: BlockItem[];
  isLoadingBlocks: boolean;
  originalItems: BlockItem[];
}>({
  filteredItems: [],
  isLoadingBlocks: false,
  originalItems: [],
});

function useLoadBlockContext() {
  const context = useContext(LoadBlockContext);
  return context;
}

function LoadBlockContextProvider({ children }: any) {
  const {
    handleUpdateFilteredItems,
    originalItems,
    getCopyOriginalItems,
    filteredItems,
    isLoadingBlocks,
  } = useBlockContext();

  const {
    selectedFilters,
    filterFields,
    handleUpdateFilters,
    handleSetFilterLevelOptions,
  } = useFilterContext();

  const { handleFilterData } = useFilterHook();

  useEffect(() => {
    if (originalItems.length > 0 && filterFields.length > 0) {
      filterFields.forEach((field) => {
        field.setPossibleValuesBasedOnItems(originalItems);
      });

      handleSetFilterLevelOptions(originalItems);
      const selectedFiltersInURL = getAllKeyValueFiltersFromURL();
      handleUpdateFilters(selectedFiltersInURL);
    }
  }, [originalItems, filterFields]);

  useEffect(() => {
    if (!isLoadingBlocks) {
      const originalItemsCopy = getCopyOriginalItems();
      const filteredOriginalItems = handleFilterData({
        items: originalItemsCopy,
        selectedFilters,
      });
      handleUpdateFilteredItems(filteredOriginalItems);
    }
  }, [isLoadingBlocks, selectedFilters]);

  const value = useMemo(
    () => ({ filteredItems, isLoadingBlocks, originalItems }),
    [
      filteredItems,
      isLoadingBlocks,
      originalItems,
      filterFields,
      selectedFilters,
    ]
  );

  return (
    <LoadBlockContext.Provider value={value}>
      {children}
    </LoadBlockContext.Provider>
  );
}

export { LoadBlockContext, LoadBlockContextProvider, useLoadBlockContext };
