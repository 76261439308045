import { Box } from "@material-ui/core";
import { InsightsCheckboxFilterOption } from "src/v2/components/filter/components/InsightsCheckboxFilter";
import { SearchFieldOptions } from "src/v2/components/filter/components/SearchFieldOptions";
import { FilterExpandableField } from "src/v2/components/filter/FilterExpandableField";
import { BlockItem } from "../block/BlockItem";
import { FilterFieldBase } from "./FilterFieldBase";

export class OutcomeFilterField extends FilterFieldBase {
  matchItem(item: BlockItem) {
    const itemField = item.getFieldByFieldKey(this.fieldKey);
    if (!itemField) return false;

    const customFieldHasSelectedFilterValue = itemField.value?.some((it) => {
      return this.values.includes(it?.type);
    });

    return customFieldHasSelectedFilterValue;
  }

  getFilterComponent({ expandAll, setStore }) {
    return (
      <FilterExpandableField field={this} shouldExpandAll={expandAll}>
        {this.values.length > 6 && (
          <SearchFieldOptions field={this} setStore={setStore} />
        )}
        <Box style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {this.values.map((value) => (
            <InsightsCheckboxFilterOption value={value} field={this} />
          ))}
        </Box>
      </FilterExpandableField>
    );
  }
}
