import { Box, Card, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import { Column } from "src/components/GlobalComponents";
import { statusNotSetColor } from "src/v2/helpers/colors";

import LargeProgressBar from "../../progressBar/LargeProgressBar";
import { ProgressBarByStatus } from "../../progressBar/ProgressBarByStatus";

import { useProjectStatusProgressData } from "./useProjectStatusProgressData";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";
import { useWidgetContext } from "../../Widget/WidgetContext";

const useStyles = makeStyles({
  root: { padding: 8, width: 300 },
  legendText: { marginLeft: 8, marginRight: 8 },
  linkedItem: {
    cursor: "pointer",
    textDecoration: "underline",
  },
});

const ProjectStatusProgressSection = ({ section, items, parentSections }) => {
  const { groupedByValue } = section;
  const {
    titleStyle,
    filteredItems,
    redirectURL,
    progressStatusProgress,
    showProgressBarTitle,
    itemsGroupedByStatus,
    showPercentages,
    itemsGroupedByStatusCategory,
    statuses,
  } = useProjectStatusProgressData({ items, section, parentSections });
  const classes = useStyles();
  const { getConfigValueByName } = useWidgetContext();
  const projectStatusConfig = getConfigValueByName(
    WidgetConfigs.projectStatusInsights
  );

  return (
    <Card className={classes.root}>
      <Box p={1}>
        <Typography variant="h6" style={{ ...titleStyle }}>
          {groupedByValue}
        </Typography>
        <div role="button" tabIndex={0} onClick={() => {}}>
          {filteredItems.length > 0 && (
            <Link to={redirectURL} target="_blank">
              <Typography
                variant="body2"
                color="textSecondary"
                className={parentSections ? classes.linkedItem : null}
              >
                {filteredItems.length} item
                {filteredItems.length === 1 ? "" : "s"}
              </Typography>
            </Link>
          )}
        </div>
      </Box>
      <Column>
        {items.length > 0 ? (
          <>
            <Box mb={1} mt={1}>
              <LargeProgressBar
                backgroundColor={`${statusNotSetColor}`}
                percentages={progressStatusProgress.aggregatedValues}
                title={
                  showProgressBarTitle &&
                  (projectStatusConfig?.display || "Project Status")
                }
                showProgressBarLegends={false}
              />
            </Box>
            <Box mb={2} mt={1}>
              <Typography
                style={{
                  color: `grey`,
                  marginBottom: 12,
                  letterSpacing: 0.33,
                }}
              >
                Workflow Status
              </Typography>
              <ProgressBarByStatus
                itemsGroupedByStatus={itemsGroupedByStatus}
                style={{
                  height: 18,
                  marginTop: 8,
                  marginBottom: 8,
                }}
                showPercentages={showPercentages}
                itemsGroupedByStatusCategory={itemsGroupedByStatusCategory}
                statuses={statuses}
              />
            </Box>
          </>
        ) : (
          <Box p={1}>
            <Typography variant="body2" color="textSecondary">
              No Matching Work
            </Typography>
          </Box>
        )}
      </Column>
    </Card>
  );
};

export default ProjectStatusProgressSection;
