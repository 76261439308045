import { ProjectStatusType } from "../types";

// TODO: Add allStatues and defaultStatus in DB. Everytime we add a new status, we need to update this file.
export const defaultProjectStatus = {
  name: "Not Set",
  position: 999,
  sort_position: 999,
  type: ProjectStatusType.NOT_SET,
};

export const mapTypeToLegendName = {
  [ProjectStatusType.COMPLETE]: "Done",
  [ProjectStatusType.ON_TRACK]: "On Track",
  [ProjectStatusType.AT_RISK]: "At Risk",
  [ProjectStatusType.OFF_TRACK]: "Off Track",
  [ProjectStatusType.PAUSED]: "Limited Progress",
  [ProjectStatusType.NOT_SET]: "Not Set",
};

export const allProjectStatuses = [
  {
    name: "Done",
    position: 10,
    sort_position: 80,
    type: ProjectStatusType.COMPLETE,
  },
  {
    name: "Complete",
    position: 10,
    sort_position: 80,
    type: ProjectStatusType.COMPLETE,
  },
  {
    name: "Almost Complete",
    position: 11,
    sort_position: 72,
    type: ProjectStatusType.COMPLETE,
  },
  {
    name: "Likely Complete",
    position: 11,
    sort_position: 72,
    type: ProjectStatusType.COMPLETE,
  },
  {
    name: "Almost Done",
    position: 12,
    sort_position: 71,
    type: ProjectStatusType.COMPLETE,
  },
  {
    name: "On Track",
    position: 20,
    sort_position: 40,
    type: ProjectStatusType.ON_TRACK,
  },
  {
    name: "Planning",
    position: 21,
    sort_position: 41,
    type: ProjectStatusType.ON_TRACK,
  },
  {
    name: "Just Started",
    position: 21,
    sort_position: 43,
    type: ProjectStatusType.ON_TRACK,
  },
  {
    name: "Longterm Project",
    position: 22,
    sort_position: 44,
    type: ProjectStatusType.ON_TRACK,
  },
  {
    name: "Maintenance",
    position: 23,
    sort_position: 45,
    type: ProjectStatusType.ON_TRACK,
  },
  {
    name: "At Risk",
    position: 30,
    sort_position: 30,
    type: ProjectStatusType.AT_RISK,
  },
  {
    name: "Off Track",
    position: 40,
    sort_position: 10,
    type: ProjectStatusType.OFF_TRACK,
  },
  {
    name: "Blocked",
    position: 41,
    sort_position: 20,
    type: ProjectStatusType.OFF_TRACK,
  },
  {
    name: "Low Allocation",
    position: 48,
    sort_position: 48,
    type: ProjectStatusType.PAUSED,
  },
  {
    name: "Discovery",
    position: 49,
    sort_position: 49,
    type: ProjectStatusType.PAUSED,
  },
  {
    name: "Limited Progress",
    position: 50,
    sort_position: 50,
    type: ProjectStatusType.PAUSED,
  },
  {
    name: "Paused",
    position: 50,
    sort_position: 50,
    type: ProjectStatusType.PAUSED,
  },
  {
    name: "Not in Sprint",
    position: 51,
    sort_position: 51,
    type: ProjectStatusType.PAUSED,
  },
  {
    name: "Not Started",
    position: 52,
    sort_position: 52,
    type: ProjectStatusType.PAUSED,
  },
  {
    name: "Not In Progress",
    position: 53,
    sort_position: 53,
    type: ProjectStatusType.PAUSED,
  },
  {
    name: "Excluded",
    position: 60,
    sort_position: 90,
    type: ProjectStatusType.PAUSED,
  },
  defaultProjectStatus,
];
