import ProjectStatusLegendSection from "src/v2/components/Section/ProjectStatusLegendSection";
import StatusLegend from "src/v2/components/Section/StatusLegendSection";
import VelmaInsightsLegendSection from "src/v2/components/Section/VelmaInsightsLegendSection";
import { Row } from "./GlobalComponents";

export const GroupedLegends = ({ section, blocks }) => (
  <Row
    style={{
      padding: 32,
      fontSize: 14,
    }}
  >
    <ProjectStatusLegendSection section={section} items={blocks} />
    <div style={{ marginLeft: 48 }}>
      <StatusLegend />
    </div>
    <div style={{ marginLeft: 48 }}>
      <VelmaInsightsLegendSection />
    </div>
  </Row>
);
