import { Box, Typography, makeStyles } from "@material-ui/core";
import { widgetTitleColor, blackTextColor } from "src/v2/helpers/colors";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr",
    marginLeft: 16,
    maxWidth: 254,
    border: "2px solid #e0e0e0",
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  title: { color: widgetTitleColor, gridColumn: "1 / 2" },
  insightHeader: {
    color: "#395e77",
    textAlign: "center",
    width: "100%",
    backgroundColor: "rgb(222, 233, 244)",
    padding: "4px 8px",
  },
  insightContainer: {
    maxWidth: 254,
    gridColumn: "span 1",
    backgroundColor: "#f8fcff",
    borderRadius: 8,
  },
  projectedFinishDateText: {
    textAlign: "center",
    color: blackTextColor,
  },
});

export const InsightsTitle = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.title} variant="h5">
      Velma Insights
    </Typography>
  );
};

export const Insight = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.insightContainer}>
      <Typography className={classes.insightHeader} variant="h6">
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default Insight;
