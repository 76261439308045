import { Box, Typography } from "@material-ui/core";
import { WidgetConfigs } from "../Widget/WidgetConfigs";
import { useWidgetContext } from "../Widget/WidgetContext";
import { getAccessibleColors, statusNotSetColor } from "src/v2/helpers/colors";

export const ProjectStatus = ({ projectStatus, customStyles = {} }) => {
  const { getConfigValueByName } = useWidgetContext();
  const config = getConfigValueByName(WidgetConfigs.projectStatusInsights);
  const status = config.statuses.find(
    ({ type }) => type === projectStatus?.type
  );
  const color = status?.color || `${statusNotSetColor}`;
  const { text, background } = getAccessibleColors(color);
  const projectStatusDisplayText = status?.name;
  return (
    <Box
      style={{
        minWidth: 164,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `${background}`,
        padding: "16px 8px",
        ...customStyles,
      }}
    >
      <div
        data-test-id="project_status_ball"
        style={{
          backgroundColor: `${color}`,
          borderRadius: "100px",
          height: "20px",
          width: "20px",
          marginRight: "16px",
        }}
      />
      <Typography
        variant="h6"
        style={{
          fontSize: 16,
          color: text,
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        {projectStatusDisplayText}
      </Typography>
    </Box>
  );
};
