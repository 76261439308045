import { Box, Grid, styled, Theme } from "@material-ui/core";

export const RenderAccordionItemRoot = styled(Grid)(() => ({
  minHeight: "50px",
}));

export const EmptyChildDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "50px",
  backgroundColor: theme.palette.background.paper,
}));

export const ItemNameRoot = styled(Grid)(({ theme }: { theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  gap: theme.spacing(1.3),
  flex: "1 0 0",
  ...theme.typography.body1,
  fontWeight: 600,
  fontStyle: "normal",
  lineHeight: "normal",
  color: theme.palette.primary.main,
}));

export const ProductListRoot = styled(Grid)(({ theme }: { theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2, 0),
}));

export const TeamListFakeAccordion = styled(Box)(({ theme }: { theme }) => ({
  width: "100%",
}));

export const ProductHeaderProgressBar = styled(Grid)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    width: "25%",
    height: "28px",
    padding: theme.spacing(1.3, 0),
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(0.3),
  })
);
