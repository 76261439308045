export enum FieldConfigType {
  team = "team",
  name = "name",
  condensed_name = "condensed_name",
  dependency = "dependency",
  project_status = "project_status",
  project_status_insights = "project_status_insights",
  condensed_project_status_insights = "condensed_project_status_insights",
  detailed_project_status_insights = "detailed_project_status_insights",
  status = "status",
  progress = "progress",
  condensed_progress = "condensed_progress",
  changelog = "changelog",
  assignee = "assignee",
  text = "text",
  date = "date",
  priority = "priority",
  risks = "risks",
  expandable_text = "expandable_text",
  read_on_filter = "read_on_filter",
  dropdown = "dropdown",
  projected_finish = "projected_finish",
  label = "label",
  parentLink = "parent_link",
  money = "money",
  link = "link",
  html = "html",
  ai_slack_summary = "ai_slack_summary",
  allocation = "allocation",
  cost = "cost",
  risk_comparison_date = "risk_comparison_date",
  children_added_dates = "children_added_dates",
  parent_added_date = "parent_added_date",
  team_link = "team_link",
  comments = "comments",
  last_jira_updated_date = "last_jira_updated_date",
  outcome = "outcome",
  outcome_status = "outcome_status",
  outcome_expected_completion = "outcome_expected_completion",
  outcome_reason = "outcome_reason",

  // V3 Field Types
  summary_of_work = "summary_of_work",
  workflow_status = "workflow_status",
  project_status_v3 = "project_status_v3",
  target_date = "target_date",
}
