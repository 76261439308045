import { DisplayConfigSection } from "../enum/DisplayConfigSection";

export class FieldDisplayConfig {
  section: DisplayConfigSection;

  position: number;

  defaultValues: any[];

  style: any;

  config: { level };

  constructor(fieldDisplayConfig) {
    this.section = fieldDisplayConfig.section;
    this.position = fieldDisplayConfig.position;
    this.defaultValues = fieldDisplayConfig.default_values || [];
    this.style = fieldDisplayConfig.style || {};
    if (!fieldDisplayConfig.config) {
      fieldDisplayConfig.config = { level: 1 };
    } else if (!fieldDisplayConfig.config.level) {
      fieldDisplayConfig.config.level = 1;
    }

    this.config = fieldDisplayConfig.config;
  }
}
