import {
  Box,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { ChevronDown } from "src/v3/theme/icons";

export const useRetainStatusSelector = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 120,
  },
  expirationSelector: {
    height: "36px",
    borderRadius: theme.spacing(1),
  },
  justificationFormControl: {
    borderRadius: theme.spacing(1),
  },
  menuItem: {
    padding: theme.spacing(2, 3),
  },
  menuPaper: {
    borderRadius: 8,
    boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
}));

export const RetainOverride = ({
  stateChanged,
  expirationDate,
  handleExpirationDateChange,
}: {
  stateChanged: boolean;
  expirationDate: string;
  handleExpirationDateChange: (
    e: React.ChangeEvent<{ value: unknown }>
  ) => void;
}) => {
  const classes = useRetainStatusSelector();
  const expirationWeekOptions = ["1 week", "2 weeks", "3 weeks", "4 weeks"];
  return (
    <Box
      style={{
        height: "48px",
        backgroundColor: "transparent",
      }}
    >
      <FormControl
        variant="outlined"
        className={classes.formControl}
        disabled={!stateChanged}
      >
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={expirationDate}
          onChange={handleExpirationDateChange}
          className={classes.expirationSelector}
          required
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          IconComponent={ChevronDown}
        >
          {expirationWeekOptions.map((option, index) => (
            <MenuItem key={index} value={option} className={classes.menuItem}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
