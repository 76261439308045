import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { Row } from "src/components/GlobalComponents";
import { SectionBuilder } from ".";
import { useDataSection } from "./hooks/useDataSection";
import { SectionViewType } from "./types";

const DataSection: React.FC<SectionViewType> = ({
  section,
  items,
  parentSections,
  setShowWidget,
}) => {
  const { filters, groupBy, child, options } = section;

  const newParentSections = parentSections
    ? [...parentSections, section]
    : [section];
  const customStyles = options?.find((option) => option.name === "styles");
  const groupContainerStyles = customStyles?.value?.groupContainer || {};
  const titleStyle = customStyles?.value?.title || {};
  const extraText = options?.find(
    (option) => option.name === "extraText"
  )?.value;
  const sectionContainer = customStyles?.value?.sectionContainer || {};
  const title = options?.find(({ name }) => name === "title")?.value || "";

  const { filteredItems, groupedItems } = useDataSection({
    filters,
    groupBy,
    items,
  });

  const hideIfNoItems =
    options.find(({ name }) => name === "hide_if_no_items")?.value || false;

  const showSection = !(filteredItems.length === 0 && hideIfNoItems);

  useEffect(() => {
    if (setShowWidget) {
      setShowWidget(showSection);
    }
  }, [showSection, setShowWidget]);

  const getTitleForSubSection = (groupedItem) => {
    let _title = "";
    if (extraText?.subSectionTitlePrefix) {
      _title += `${extraText.subSectionTitlePrefix}`;
    }
    if (extraText?.subSectionTitleDisplayFieldName) {
      _title += `${groupedItem.fieldKey}: `;
    }
    if (extraText?.subSectionTitleDisplayFieldValue) {
      if (groupedItem.fieldValue?.length > 0) {
        _title += `${groupedItem.fieldValue}`;
      } else {
        _title += `No ${groupedItem.fieldKey}`;
      }
    }

    return _title;
  };

  const renderSubSection = () => {
    if (child) {
      if (groupBy?.acceptedValues?.length > 0 && groupedItems.length === 0) {
        // if goes here means there is no data for the groupBy field
        // so we need to render the child section with empty items for each accepted value
        return groupBy.acceptedValues.map((acceptedValue) => (
          <Box key={acceptedValue} style={groupContainerStyles}>
            <SectionBuilder
              section={{
                ...child,
                title: getTitleForSubSection({ fieldValue: acceptedValue }),
                groupedByValue: acceptedValue,
              }}
              items={[]}
              parentSections={newParentSections}
            />
          </Box>
        ));
      }
      if (groupedItems.length > 0) {
        return (
          <Box style={{ ...groupContainerStyles }}>
            {groupedItems.map((groupedItem) => (
              <SectionBuilder
                section={{
                  ...child,
                  title: getTitleForSubSection(groupedItem),
                  groupedByValue: groupedItem.fieldValue,
                }}
                items={groupedItem.items}
                parentSections={newParentSections}
              />
            ))}
          </Box>
        );
      }
      return (
        <SectionBuilder
          section={child}
          items={filteredItems}
          parentSections={newParentSections}
        />
      );
    }
    return null;
  };

  return (
    showSection && (
      <div data-testid="section-container" style={{ ...sectionContainer }}>
        <Row>
          <Typography style={{ ...titleStyle }}>{title}</Typography>
        </Row>
        {renderSubSection()}
      </div>
    )
  );
};

export default DataSection;
