import { Breadcrumbs, Theme, Typography, styled } from "@material-ui/core";

export const CustomBreadCrumb = styled(Breadcrumbs)(
  ({ theme, customStyle }: { theme: Theme; customStyle: {} }) => ({
    display: "flex",
    padding: theme.spacing(1.3, 6, 1.3, 3),
    alignItems: "center",
    gap: theme.spacing(3),
    borderLeft: `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
    borderTopLeftRadius: theme.spacing(1.25),
    borderBottomLeftRadius: theme.spacing(1.25),
    overflow: "hidden",
    color: theme.palette.primary.main,
    textOverflow: " ellipsis",
    backgroundColor: theme.palette.background.default,
    borderBottomRightRadius: theme.spacing(1.25),
    borderTopRightRadius: theme.spacing(1.25),
    ...customStyle,
  })
);

export const GroupBreadcrumbRoot = styled(CustomBreadCrumb)(({ theme }) => ({
  width: "30%",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(10),
}));

export const BreadCrumbItem = styled("span")(({ theme }: { theme: Theme }) => ({
  ...theme.typography.h2,
  color: theme.palette.primary.main,
}));

export const NavigationBreadcrumbRoot = styled(Breadcrumbs)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

export const CurrentNavigationBreadcrumbItem = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.body1,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    gap: theme.spacing(2),
    color: theme.palette.grey[900],
  })
);

export const NavigationBreadcrumbItem = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.grey[900],
    textDecoration: "underline",
    cursor: "pointer",
    gap: theme.spacing(2),
  })
);
