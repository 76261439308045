import { Box, Typography } from "@material-ui/core";
import { dateBackgroundColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class LabelField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = Array.isArray(field.value) ? field.value : [field.value ?? ""];
    this.value = this.value.map((item) =>
      typeof item === "object" ? item.display_name : item
    );
  }

  getComponentForGrid() {
    if (this.value?.length === 0) {
      return <div />;
    }
    return (
      <>
        {this.value.map((selection) => (
          <Box
            data-testid="label-field"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              minWidth: 100,
              marginBottom: 8,
              backgroundColor:
                this.value[0] === "" ? null : dateBackgroundColor,
              textAlign: "center",
              borderRadius: 4,
              color: "white",
              zIndex: -1,
            }}
          >
            <Typography style={{ margin: "4px 16px", fontSize: 14 }}>
              {selection}
            </Typography>
          </Box>
        ))}
      </>
    );
  }
}
