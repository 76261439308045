import {
  EmptyPercentage,
  Percentage,
  ProgressBarRoot,
  Divider,
  ProgressBarLegendColor,
  ProgressBarLegendText,
  TooltipContentRoot,
} from "./style";
import { CustomTooltipContainer } from "../Tooltip";
import { getAggregatedData } from "./utils";
import { Fragment } from "react";

const ProgressBarTooltipContent = ({ aggregation }) => (
  <>
    {aggregation.map(({ color, percentage, name, itemKeys }, index) => (
      <TooltipContentRoot key={index}>
        <ProgressBarLegendColor color={color} />
        <ProgressBarLegendText>
          {name} - {itemKeys?.length} ({Math.round(percentage)}%)
        </ProgressBarLegendText>
      </TooltipContentRoot>
    ))}
  </>
);

export const ProgressBar = ({ aggregation }) => {
  if (!aggregation?.length) {
    return (
      <ProgressBarRoot>
        <EmptyPercentage />
      </ProgressBarRoot>
    );
  }

  const percentages = getAggregatedData(aggregation);

  return (
    <CustomTooltipContainer
      tooltipContent={<ProgressBarTooltipContent aggregation={percentages} />}
    >
      <ProgressBarRoot>
        {percentages.map(({ color, percentage }, index) => (
          <Fragment key={index}>
            <Percentage color={color} value={percentage} />
            {index < percentages.length - 1 && <Divider />}
          </Fragment>
        ))}
      </ProgressBarRoot>
    </CustomTooltipContainer>
  );
};
