import { Typography } from "@material-ui/core";
import { filterDuplicatedInList } from "src/v2/utils/array";
import { DisplayConfigSection } from "../../enum/DisplayConfigSection";
import { FieldConfigType } from "../../enum/FieldConfigType";
import { FieldDisplayConfig } from "../../interfaces/FieldDisplayConfig";
import { BlockItem } from "../block/BlockItem";
import { FilterLogic } from "../../enum/FilterLogic";

export class FilterFieldBase {
  displayName: string;

  fieldKey: string;

  type: string;

  origin: string;

  values: any[];

  valuesCopy: any[];

  displayConfig: FieldDisplayConfig;

  filterLogic: FilterLogic;

  constructor(field) {
    this.displayName = field.displayName;
    this.type = field.type;
    this.fieldKey = field.fieldKey;
    this.origin = field.origin;
    this.values = field.values ?? [];
    this.filterLogic = field.filterLogic ?? FilterLogic.AND;
    this.valuesCopy = field.values ?? [];
    this.displayConfig = Array.isArray(field.displayConfig)
      ? field.displayConfig.find(({ section }) =>
          [
            DisplayConfigSection.filter,
            DisplayConfigSection.only_read_on_filter,
          ].includes(section)
        )
      : field.displayConfig;
  }

  setFilterLogic(filterLogic) {
    this.filterLogic = filterLogic;
  }

  setValuesByDefault() {
    const { defaultValues } = this.displayConfig;
    if (defaultValues) {
      this.setValues(defaultValues);
    }
  }

  hasDefaultValue() {
    const { defaultValues } = this.displayConfig;
    return defaultValues && defaultValues.length > 0;
  }

  getDefaultValues() {
    if (this.displayConfig) {
      const { defaultValues } = this.displayConfig;
      return defaultValues;
    }
    return [];
  }

  setValues(values) {
    this.values = values.sort((a, b) => a.localeCompare(b));
  }

  setValuesCopyForSearch(values) {
    this.valuesCopy = values.sort((a, b) => a.localeCompare(b));
  }

  getUrlSearchParams() {
    if (this.values && this.values.length > 0) {
      const valuesWithTransformedAmpersand = this.values.map((value) =>
        value.replace(/&/g, "%26")
      );
      return `${this.fieldKey}=${valuesWithTransformedAmpersand?.reduce(
        (pre, next) => `${pre}~${next}`
      )}`;
    }
    return "";
  }

  matchItem(item: BlockItem) {
    const itemField = item.getFieldByFieldKey(this.fieldKey);
    if (!itemField) return false;
    return true;
  }

  setPossibleValuesBasedOnItems(items) {
    const customFieldValues = [];
    items.forEach((item) => {
      const itemAndChildrenValues = item.getPossibleValueForField({
        fieldKey: this.fieldKey,
        includeItemValue: true,
      });
      customFieldValues.push(...itemAndChildrenValues);
    });
    this.setValues(filterDuplicatedInList({ items: customFieldValues }));
    this.setValuesCopyForSearch(
      filterDuplicatedInList({ items: customFieldValues })
    );
  }

  isTypeDate() {
    return this.type === FieldConfigType.date;
  }

  getValuesIfString() {
    return this.values[0];
  }

  getFilteredByString() {
    return `${this.displayName}: ${this.values.reduce((a, b) => `${a}, ${b}`)}`;
  }

  // TODO: this should be a static method OR be in the actual field class
  getFilterComponent(data: any): any {
    return <Typography>{this.values.map((it) => it)}</Typography>;
  }

  isFilterSection() {
    return this.displayConfig.section === DisplayConfigSection.filter;
  }

  showOnFilter() {
    return (
      this.displayConfig.section === DisplayConfigSection.filter &&
      this.type !== FieldConfigType.read_on_filter
    );
  }

  isVelmaInsight() {
    return this.origin === "velma_insights";
  }
}
