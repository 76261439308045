import { Typography } from "@material-ui/core";
import { formatDate } from "src/utils/stringUtils";
import theme from "src/v3/theme/theme";

export const LastUpdatedAuthor = ({ author, lastUpdatedDate }) => (
  <Typography
    style={{
      color: theme.palette.grey[400],
      fontSize: "12px",
      lineHeight: "1.5",
      textAlign: "left",
      letterSpacing: "0.00938em",
    }}
  >
    Last updated by {author} on {formatDate(lastUpdatedDate)}
  </Typography>
);
