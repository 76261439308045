import { Column, Row } from "src/components/GlobalComponents";
import { Box, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { statusNotSetColor } from "src/v2/helpers/colors";
import { useWidgetContext } from "../Widget/WidgetContext";
import { WidgetConfigs } from "../Widget/WidgetConfigs";

const ProjectStatusLegendSection = ({ section, items }) => {
  const [projectStatuses, setProjectStatuses] = useState([]);
  const { getConfigValueByName } = useWidgetContext();
  const projectStatusConfig = getConfigValueByName(
    WidgetConfigs.projectStatusInsights
  );

  useEffect(() => {
    const _projectStatuses = projectStatusConfig.statuses.sort(
      (a, b) => a.position - b.position
    );
    if (!_projectStatuses.find((status) => status.name === "Not Set")) {
      _projectStatuses.push({
        name: "Not Set",
        color: statusNotSetColor,
        position: 999,
      });
    }
    setProjectStatuses(_projectStatuses);
  }, [section, items]);

  return (
    <div style={{ minWidth: 120, maxWidth: 200 }}>
      <Row>
        <Typography style={{ fontSize: 16 }}>
          {projectStatusConfig?.display}
        </Typography>
      </Row>
      <Column style={{ paddingTop: 8 }}>
        {projectStatuses?.map((status, index) => (
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: index === 0 ? 0 : 4 }}
          >
            <div
              style={{
                backgroundColor: status.color,
                width: 12,
                height: 12,
                borderRadius: "50%",
                marginRight: 8,
              }}
            />
            <div>{status.name}</div>
          </Box>
        ))}
      </Column>
    </div>
  );
};

export default ProjectStatusLegendSection;
