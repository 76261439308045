import {
  Box,
  Divider,
  Grid,
  styled,
  Theme,
  Typography,
} from "@material-ui/core";

export const TooltipContentRoot = styled(Grid)(({ theme }: { theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(1),
}));

export const TooltipContentRow = styled(Grid)(({ theme }: { theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  width: "100%",
  gap: theme.spacing(2),
}));

export const ContentChip = styled(Grid)(
  ({ theme, color }: { theme: Theme; color: string }) => ({
    display: "inline-flex",
    height: theme.spacing(5),
    width: theme.spacing(9),
    padding: theme.spacing(0, 2),
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    flexShrink: 0,
    borderRadius: theme.spacing(1),
    backgroundColor: color ?? theme.palette.grey[200],
    color: theme.palette.info.main,
    ...theme.typography.body2,
  })
);

export const Chip = styled(Grid)(({ theme }: { theme }) => ({
  display: "flex",
  width: "70px",
  alignItems: "center",
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.info.main,
  ...theme.typography.body2,
}));

export const NonRoadmapLabel = styled("span")(
  ({ theme, backgroundColor }: { theme: Theme; backgroundColor: string }) => ({
    display: "flex",
    padding: theme.spacing(1),
    textAlign: "center",
    justifyContent: "center",
    width: "50%",
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    color: theme.palette.info.main,
    backgroundColor: backgroundColor,
  })
);

export const OnRoadmapLabel = styled("span")(({ theme }: { theme: Theme }) => ({
  display: "flex",
  padding: theme.spacing(1),
  textAlign: "center",
  justifyContent: "center",
  width: "50%",
  borderTopLeftRadius: theme.spacing(1),
  borderBottomLeftRadius: theme.spacing(1),
  color: theme.palette.info.main,
}));

export const NonRoadmapHeader = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    color: theme.palette.grey[900],
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: theme.spacing(1),
    width: "100%",
    ...theme.typography.body1,
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "normal",
  })
);

export const ChipDivider = styled(Divider)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

export const HideShowNonRoadmapItems = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(1.3),
    flex: "1 0 0",
    ...theme.typography.body1,
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "normal",
    color: theme.palette.primary.main,
  })
);

export const InfoNonRoadmapItems = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  })
);
