import { styled, Typography, Theme } from "@material-ui/core";

export const ItemSummary = styled(Typography)(
  ({ theme, onMouseUp }: { theme: Theme; onMouseUp: (e) => {} }) => ({
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    cursor: onMouseUp ? "pointer" : "default",
    alignSelf: "stretch",
    maxWidth: "90%",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  })
);
