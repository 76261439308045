import { Grid, Theme, styled } from "@material-ui/core";

export const AccordionHeaderRoot = styled(Grid)(
  ({ theme }: { theme: Theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3),
    alignSelf: "stretch",
  })
);
