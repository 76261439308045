import { useScroll } from "src/v2/hooks/useScroll";
import { useSummaryContext } from "src/v2/providers/summary/SummaryContext";
import { handleScrollToItemInTable } from "src/v2/utils/aiSummary";

const ProjectStatusReasonHTML = ({ reason, customStyles = {} }) => {
  if (!reason) {
    return null;
  }

  const { scrollToElementById } = useScroll();
  const { expandItemByKey } = useSummaryContext();

  /*
    @MateusMetzVelma: 
    A version of this function is also used in:
    OverviewNavigationSection.tsx, TeamAISummarySection.tsx, ItemsFilteredTeamAISummarySection.tsx and RichTextSection.tsx.
    All places that have HTML content that can contain links to items in the table.
    TODO: Refactor this function to a single place.
    */
  const handleClick = (e): void => {
    const clickedElement = e.target;
    const scrollToItemCallback = (key, options) => {
      e.preventDefault();
      expandItemByKey(key);
      scrollToElementById(key, options);
    };

    handleScrollToItemInTable(clickedElement, scrollToItemCallback);
  };

  return (
    <div
      className="root"
      onClick={handleClick}
      style={{
        marginTop: -8,
        backgroundColor: "rgb(248 252 255)",
        borderRadius: "0 6px 6px 6px",
        padding: 16,
        ...customStyles,
      }}
    >
      <div
        data-test-id="expandable-text-content"
        dangerouslySetInnerHTML={{
          __html: reason,
        }}
      />
    </div>
  );
};

export default ProjectStatusReasonHTML;
