import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "../RichTextSection.css";
import { useScroll } from "src/v2/hooks/useScroll";
import { useSummaryContext } from "src/v2/providers/summary/SummaryContext";
import { handleScrollToItemInTable } from "src/v2/utils/aiSummary";
import { WidgetTitle } from "../../Widget/Widget";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";
import { useWidgetContext } from "../../Widget/WidgetContext";
import { EditRichTextSection } from "./EditRichTextSection";

const RichTextSection = () => {
  const [localContent, setLocalContent] = useState(null);
  const [displayVelmaSummary, setDisplayVelmaSummary] = useState(true);
  const [localHeader, setLocalHeader] = useState(null);
  const [userInput, setUserInput] = useState(null);
  const { getConfigByName, handleSaveWidgetConfig } = useWidgetContext();
  const config = getConfigByName(WidgetConfigs.sectionContent);

  const { scrollToElementById } = useScroll();
  const { expandItemByKey } = useSummaryContext();
  const { _section } = useWidgetContext();

  /*
    @MateusMetzVelma: 
    A version of this function is also used in:
    OverviewNavigationSection.tsx, TeamAISummarySection.tsx, ItemsFilteredTeamAISummarySection.tsx and RichTextSection.tsx.
    All places that have HTML content that can contain links to items in the table.
    TODO: Refactor this function to a single place.
    */
  const handleClick = (e): void => {
    const clickedElement = e.target;
    const scrollToItemCallback = (key, options) => {
      e.preventDefault();
      expandItemByKey(key);
      scrollToElementById(key, options);
    };

    handleScrollToItemInTable(clickedElement, scrollToItemCallback);
  };

  useEffect(() => {
    if (!config) return;
    const { value } = config;
    const { content, header, user_input, display_velma_summary } = value;
    const isEmpty = (str) => str === "" || str === null || str === undefined;
    if (isEmpty(content) && isEmpty(user_input)) {
      return;
    }
    setDisplayVelmaSummary(display_velma_summary ?? true);
    setLocalHeader(header);
    setLocalContent(content);
    setUserInput(user_input);
  }, [config]);

  const defaultMessage =
    "Insights are still under construction. Velma is cleaning up, organizing, and training herself on historical data and current workflows for accurate results. Please come back later.";

  const handleEditRichText = async (newContent) => {
    const hasContext = newContent?.userInput?.context;
    try {
      handleSaveWidgetConfig({
        ...config,
        value: {
          ...config?.value,
          user_input: hasContext ? newContent?.userInput : {},
          display_velma_summary: newContent?.displayVelmaSummary ?? true,
        },
      });
    } catch (error) {
      console.error(error);
    }
    setUserInput(newContent.userInput);
    setDisplayVelmaSummary(newContent?.displayVelmaSummary);
  };

  const { options } = _section;
  const customStyles = options?.find(({ name }) => name === "styles")?.value;
  const headerStyle = customStyles?.title ?? {};
  const rootStyle = customStyles?.root ?? {};

  return (
    <div
      style={{
        ...rootStyle,
      }}
    >
      <WidgetTitle
        title={localHeader}
        tooltipText={undefined}
        titleStyles={headerStyle}
      />
      <div
        className="root"
        hidden={localContent || userInput}
        dangerouslySetInnerHTML={{ __html: defaultMessage }}
      />
      <div
        hidden={!localContent}
        style={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <EditRichTextSection
          localHeader={localHeader}
          extraContext={userInput}
          displayVelmaSummary={displayVelmaSummary}
          onSaveCallback={handleEditRichText}
        />
      </div>
      <Box className="root">
        <div
          hidden={!displayVelmaSummary}
          onClick={handleClick}
          dangerouslySetInnerHTML={{ __html: localContent }}
        />
      </Box>
    </div>
  );
};

export default RichTextSection;
