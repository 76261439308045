import { useContext, createContext, useMemo, useState } from "react";
import WidgetConfig, { WidgetConfigType } from "../entities/WidgetConfig";
import Widget from "../entities/Widget";

const WidgetContext = createContext({
  widget: new Widget("", [], ""),
  widgetConfigs: [] as WidgetConfig[],
  name: "",
  getConfigsByType: (type: WidgetConfigType): WidgetConfig[] => [],
});

function useWidgetContext() {
  const context = useContext(WidgetContext);
  if (!context) {
    throw new Error("useWidgetContext must be used within a WidgetProvider");
  }
  return context;
}

function WidgetProvider({ children, _widget }) {
  const { id, configs, name } = _widget;
  const initialConfigs = configs?.map((config): WidgetConfig => {
    return new WidgetConfig(
      config.id,
      config.widget,
      config.name,
      config.type,
      config.groupBy,
      config.filters,
      config.value
    );
  }) as WidgetConfig[];

  const [widget, setWidget] = useState(new Widget(id, initialConfigs, name));
  const [widgetConfigs, setConfigs] = useState(
    initialConfigs as WidgetConfig[]
  );

  const getConfigsByType = (type: string): any => {
    return widgetConfigs?.filter((config) => config.type === type) || [];
  };

  const value = useMemo(
    () => ({
      widget,
      widgetConfigs,
      name,
      getConfigsByType,
    }),
    [configs, widgetConfigs, name]
  );

  return (
    <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
  );
}

export { WidgetContext, WidgetProvider, useWidgetContext };
