export class BlockItemType {
  displayName: string;

  value: string;

  color: string;

  origin: { value: string };

  descOrder: number;

  parentType: string;

  block_type: string;

  constructor({
    display_name,
    view_block_type,
    color,
    origin,
    desc_order,
    parent_type,
  }) {
    this.displayName = display_name;
    this.value = view_block_type;
    this.color = color;
    this.origin = origin;
    this.descOrder = desc_order;
    this.parentType = parent_type;
    this.block_type = view_block_type;
  }
}
