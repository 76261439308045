import { Box, Drawer, Paper, styled, Theme } from "@material-ui/core";

export const DrawerRoot = styled(Drawer)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
}));

export const StyledDrawerPaper = styled(Paper)(({ theme }) => ({
  width: "80%",
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  position: "fixed",
  borderRadius: 0,
}));

export const DrawerContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: "auto",
  paddingBottom: "100px",
  display: "flex",
  padding: theme.spacing(5),
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(5),
}));
