import { Box, Grid, Link, Typography } from "@material-ui/core";
import {
  BootstrapTooltip,
  ContainerWithToolTip,
} from "src/components/GlobalComponents";
import {
  expandTableTagColorsByItemType,
  getAccessibleColors,
  primaryTextColor,
} from "src/v2/helpers/colors";
import { NameField } from "./NameField";
import { ParentLinkField } from "./ParentLinkField";
import { isEmpty } from "src/v2/utils/object";
import { dateIsGreaterThanDaysAgo, transformDate } from "src/v2/utils/date";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ExpandTableTag } from "src/v2/components/buttons/ExpandTableTag";

export class DetailedNameField extends NameField {
  getComponentForGrid({ props }) {
    const {
      firstColumnWidth,
      getConfigValueByName,
      item,
      sectionRedirectViewSlug = "",
    } = props;
    const twoLinesMaxStyle = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    };
    const url = this.buildURL(
      getConfigValueByName,
      item,
      sectionRedirectViewSlug
    );

    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: firstColumnWidth - 32,
          display: "flex",
        }}
      >
        <BootstrapTooltip title={this.displayName}>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            variant="body2"
            href={url}
          >
            <Typography
              style={{ color: `${primaryTextColor}`, ...twoLinesMaxStyle }}
              data-test-id="deliverable_name"
            >
              {this.displayName}
            </Typography>
          </Link>
        </BootstrapTooltip>
      </Box>
    );
  }

  getFirstColumnComponent({ props }: { props: any }): JSX.Element {
    const {
      item,
      expanded,
      isExpandable,
      firstColumnWidth,
      clientConfigs,
      viewSchema,
    } = props;
    const itemParentField = item.getParentLinkField() as ParentLinkField;
    const recentlyAddedToParent = dateIsGreaterThanDaysAgo({
      date: new Date(item.changelogInfo.addedToParentAt),
      daysToCompare: clientConfigs?.daysToShowRecentlyChildrenAddedFlag ?? 7,
    });
    const itemTypeSchema = viewSchema?.find(
      ({ view_block_type }) => view_block_type === item.type.value
    );

    const defaultColors = expandTableTagColorsByItemType;
    const colorByType = item.isBug()
      ? defaultColors["bug"]
      : itemTypeSchema?.color ?? defaultColors[item.type.value];

    const { background, originalColor } = getAccessibleColors(
      colorByType ?? defaultColors["default"]
    );

    return (
      <Grid
        style={{
          width: firstColumnWidth,
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <ContainerWithToolTip tooltipText={item?.type?.displayName ?? ""}>
            <div>
              <ExpandTableTag
                customStyle={{
                  width: "fit-content",
                  background: originalColor,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {
                  <Typography
                    style={{
                      color: background,
                      fontSize: 12,
                      fontWeight: "bold",
                      padding: "0px 4px",
                    }}
                  >
                    {item.type.displayName}
                  </Typography>
                }
                {isExpandable &&
                  (expanded ? (
                    <ExpandLess
                      style={{ color: background, height: 16, width: 16 }}
                    />
                  ) : (
                    <ExpandMore
                      style={{ color: background, height: 16, width: 16 }}
                    />
                  ))}
              </ExpandTableTag>
            </div>
          </ContainerWithToolTip>
          {recentlyAddedToParent && (
            <BootstrapTooltip
              title={`Added on ${transformDate(
                item.changelogInfo.addedToParentAt
              )}`}
            >
              <div
                style={{
                  width: "fit-content",
                  backgroundColor: "#197bbd",
                  color: "white",
                  padding: "0px 4px",
                  fontSize: 10,
                  display: "flex",
                  alignItems: "center",
                  borderBottomLeftRadius: 8,
                  boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
                }}
              >
                Added
              </div>
            </BootstrapTooltip>
          )}
        </Grid>
        <Grid container direction="column" style={{ padding: 16 }}>
          {this.getComponentForGrid({ props })}
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
            justifyContent="flex-start"
            style={{
              marginTop: 8,
            }}
          >
            <div
              style={{
                marginRight: 16,
              }}
            >
              {this.getKeyComponentForGrid({})}
            </div>
            {item.getPriorityField()?.getComponentForGrid({})}
          </Grid>
          <Grid
            container
            direction="row"
            style={{
              marginTop: 8,
              alignItems: "center",
            }}
          >
            {item.getStatusField()?.getComponentForGrid({})}
          </Grid>
          {itemParentField && !isEmpty(itemParentField.value) && (
            <Box
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 12,
                marginTop: 8,
              }}
            >
              {itemParentField.getFirstColumnComponent({ props })}
            </Box>
          )}
        </Grid>
      </Grid>
    );
  }
}
