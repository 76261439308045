import ReactDOM from "react-dom";
import * as FullStory from "@fullstory/browser";
import Gleap from "gleap";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { GlobalContextProviders } from "./providers/ContextProviders";

FullStory.init({
  orgId: "13YXFQ",
  devMode: import.meta.env.DEV,
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: import.meta.env.DEV ? "development" : "production",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
});

ReactDOM.render(
  <GlobalContextProviders>
    <App />
  </GlobalContextProviders>,
  document.getElementById("root")
);

declare global {
  interface Window {
    gapi: any;
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export {};
