import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";
import { RiskComparisonDateField } from "../../customField/RiskComparisonDateField";

class ByRiskComparisonDate extends SectionFilter {
  constructor({ type, config }) {
    super({ type, fieldKey: "", config, values: [] });
    this.acceptDefaultDate = config?.acceptDefaultDate ?? false;
  }
  acceptDefaultDate: boolean;
  public filterItem(item: BlockItem): boolean {
    const riskComparisonDateField = item.getFieldByFieldKey(
      FieldConfigType.risk_comparison_date
    ) as RiskComparisonDateField;

    if (!riskComparisonDateField) {
      return false;
    }

    if (!riskComparisonDateField?.getRiskComparisonDate()) {
      return false;
    }

    return this.acceptDefaultDate
      ? true
      : !riskComparisonDateField?.isDefaultDate;
  }
}

export default ByRiskComparisonDate;
