import { useCallback, useState } from "react";
import { EventTypes } from "src/providers/EventContext";
import { useOverrideContext } from "src/providers/OverrideContext";
import setErrorSnackbar, {
  setSuccessSnackbar,
} from "src/services/setErrorSnackbar";
import { updateRisks } from "src/services/v2/insightsService";
import { mapWeeksToDate } from "../../Dialogs/ProjectStatusEdit/utils";
import * as sessionService from "src/services/sessionService";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export const useChallengeAction = ({
  item,
  challenge,
}: {
  item: BlockItem;
  challenge: any;
}) => {
  const { saveOverride } = useOverrideContext();

  const user = sessionService.getLoggedUser();

  const [state, setState] = useState({
    justification: "",
    retainOverride: false,
    expirationDate: "2 weeks",
  });

  const handleStateChange = useCallback(
    (key: "justification" | "retainOverride" | "expirationDate", value) => {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [setState]
  );

  const resetState = useCallback(() => {
    setState({
      justification: "",
      retainOverride: false,
      expirationDate: "2 weeks",
    });
  }, [challenge, item]);

  const [isSaving, setIsSaving] = useState(false);

  const onSave = async () => {
    const updatedValue = {
      id: challenge.id,
      title: challenge.title?.replace(/\s*\(\d+\)$/, ""),
      group: challenge.group,
      severity: challenge.severity,
      action: "delete",
      author: {
        name: user?.name,
        email: user?.email,
      },
    };
    const riskOverride = {
      value: updatedValue,
      retainOverride: state.retainOverride,
      expirationDate: mapWeeksToDate(state.expirationDate),
      justification: state.justification,
      fieldKey: item.getRiskField()?.fieldKey,
      blockColumn: item.getRiskField()?.fieldKey,
      id: challenge.id,
      createdAt: new Date(),
    };

    try {
      await updateRisks(riskOverride, item.getKey());
      saveOverride({
        item,
        overrides: [riskOverride],
        event: {
          type: EventTypes.RISK_DELETED,
          message: `Challenge has being resolved by ${user?.name} on ${new Date().toLocaleDateString()} for ${item.getKey()}`,
        },
      });
      setSuccessSnackbar("Challenge resolved successfully!", 3000);
    } catch (error) {
      console.error(error);
      setErrorSnackbar(
        "Failed to resolve this challenge, please try again later, if the issue persists, contact us.",
        3000
      );
      resetState();
    } finally {
      setIsSaving(false);
    }
  };

  return {
    state,
    isSaving,
    handleStateChange,
    onSave,
    resetState,
  };
};
