import { WidgetConfigs } from "./WidgetConfigs";

export const getProjectStatusConfig = ({ configs }) => {
  return (
    configs?.find(({ name }) => name === WidgetConfigs.projectStatusInsights)
      ?.value || defaultProjectStatusConfig
  );
};
export const defaultProjectStatusConfig = {
  display: "Predicted Status",
  fieldKey: "project_status",
  statuses: [
    {
      name: "Complete",
      color: "#18801d",
      position: 10,
      type: "complete",
      tooltip: "",
    },
    {
      name: "Likely Complete",
      color: "#18801d",
      position: 11,
      type: "complete",
      tooltip: "Work is likely to be completed soon.",
    },
    {
      name: "Almost Done",
      color: "#18801d",
      position: 12,
      type: "complete",
      tooltip: "Work is almost completed.",
    },
    {
      name: "On Track",
      color: "#5aaf6d",
      position: 20,
      type: "on_track_status",
      tooltip: "Projected completion matches the team's target date",
    },
    {
      name: "Planning",
      color: "#5aaf6d",
      position: 21,
      type: "on_track_status",
      tooltip: "Currently in the planning phase.",
    },
    {
      name: "Longterm Project",
      color: "#5aaf6d",
      position: 22,
      type: "on_track_status",
    },
    {
      name: "Maintenance",
      color: "#5aaf6d",
      position: 23,
      type: "on_track_status",
      tooltip: "Ongoing maintenance activities.",
    },
    {
      name: "At Risk",
      color: "#f1ca00",
      position: 30,
      type: "at_risk_status",
      tooltip:
        "Projected completion is a few weeks after the team's target date",
    },
    {
      name: "Off Track",
      color: "#dc0202",
      position: 40,
      type: "off_track_status",
      tooltip: "Projected completion is a month after the team's target date.",
    },
    {
      name: "Blocked",
      color: "#dc0202",
      position: 41,
      type: "off_track_status",
      tooltip: "Progress is currently blocked by external factors.",
    },
    {
      name: "Paused",
      color: "#aeaeae",
      position: 50,
      type: "paused",
      tooltip: "Work has started but is currently paused.",
    },
    {
      name: "Not in Sprint",
      color: "#aeaeae",
      position: 51,
      type: "paused",
      tooltip: "Work has started, but no current or upcoming work planned.",
    },
    {
      name: "Not Started",
      color: "#aeaeae",
      position: 52,
      type: "paused",
      tooltip: "Work has not started yet.",
    },
    {
      name: "Not In Progress",
      color: "#aeaeae",
      position: 53,
      type: "paused",
      tooltip: "No progress currently being made.",
    },
    {
      name: "Excluded",
      color: "#aeaeae",
      position: 60,
      type: "paused",
      tooltip: "This item is excluded from the current scope of work.",
    },
    {
      name: "Not Set",
      color: "#aeaeae",
      position: 999,
      type: "not_set_status",
      tooltip: "Work on this has not yet started.",
    },
    {
      name: "",
      color: "#aeaeae",
      position: 1000,
      type: "not_set_status",
      tooltip: "",
    },
  ],
};
