import { BlockItem } from "../domain/entities/block/BlockItem";
import { ProjectStatusInsightConfig } from "../domain/entities/insights/projectStatusInsightConfig";
import { ProjectStatusType } from "../domain/enum/ProjectStatusInsightType";

export class ProjectStatusInsightCalculator {
  private config: ProjectStatusInsightConfig;

  constructor(config: ProjectStatusInsightConfig) {
    this.config = config ?? new ProjectStatusInsightConfig({});
  }

  public getProjectStatusForListOfBlockItem(items: BlockItem[]): any {
    const itemsStatusCount = this.countProjectStatus(items);

    if (items.length === itemsStatusCount[ProjectStatusType.PAUSED]) {
      return { type: ProjectStatusType.PAUSED };
    }

    return this.calculateProjectStatus(itemsStatusCount, items.length);
  }

  private countProjectStatus(items: BlockItem[]): any {
    const itemsStatusCount: { [key: string]: number } = {
      [ProjectStatusType.COMPLETE]: 0,
      [ProjectStatusType.ON_TRACK]: 0,
      [ProjectStatusType.OFF_TRACK]: 0,
      [ProjectStatusType.AT_RISK]: 0,
      [ProjectStatusType.PAUSED]: 0,
      [ProjectStatusType.NOT_SET]: 0,
    };

    for (const item of items) {
      const projectStatus =
        item.getProjectStatusField() || item.getProjectStatusFieldV2();
      if (!projectStatus) {
        itemsStatusCount[ProjectStatusType.NOT_SET] += 1;
        continue;
      }
      const projectStatusType = projectStatus.getProjectStatus()?.type;
      itemsStatusCount[projectStatusType] += 1;
    }

    return itemsStatusCount;
  }
  public getItemsByProjectStatus(items: BlockItem[]): any {
    const itemsStatusCount = {
      [ProjectStatusType.COMPLETE]: [],
      [ProjectStatusType.ON_TRACK]: [],
      [ProjectStatusType.OFF_TRACK]: [],
      [ProjectStatusType.AT_RISK]: [],
      [ProjectStatusType.PAUSED]: [],
      [ProjectStatusType.NOT_SET]: [],
    };

    for (const item of items) {
      const projectStatus =
        item.getProjectStatusField() || item.getProjectStatusFieldV2();
      if (!projectStatus) {
        // adds item to list of items with no project status
        itemsStatusCount[ProjectStatusType.NOT_SET].push(item);
        continue;
      }
      const projectStatusType = projectStatus.getProjectStatus()?.type;
      itemsStatusCount[projectStatusType]?.push(item);
    }

    return itemsStatusCount;
  }

  private calculateProjectStatus(
    itemsStatusCount: { [key: string]: number },
    totalItems: number
  ): any {
    const offTrackCount = itemsStatusCount[ProjectStatusType.OFF_TRACK];
    const atRiskCount = itemsStatusCount[ProjectStatusType.AT_RISK];
    const offTrackPercent = (offTrackCount / totalItems) * 100;
    const atRiskPercent = (atRiskCount / totalItems) * 100;
    const combinedPercent = offTrackPercent + atRiskPercent;

    if (
      (offTrackCount + atRiskCount >=
        this.config.getMinCombinedCountForOffTrack() &&
        offTrackCount >= 1) ||
      (combinedPercent >= this.config.getOffTrackThresholdPercent() &&
        offTrackCount >= 1)
    ) {
      return { type: ProjectStatusType.OFF_TRACK };
    }

    // At Risk Logic
    if (
      (offTrackCount + atRiskCount >= 1 &&
        offTrackCount + atRiskCount <=
          this.config.getMaxCombinedCountForAtRisk()) ||
      combinedPercent >= this.config.getMinPercentForAtRisk() ||
      atRiskPercent > this.config.getAtRiskThresholdPercent()
    ) {
      return { type: ProjectStatusType.AT_RISK };
    }

    return { type: ProjectStatusType.ON_TRACK };
  }
}
