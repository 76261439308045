import { EditProjectStatusActionProps } from "./types";
import { useDialog } from "src/v3/components/Dialogs/Base/useDialog";
import { EditProjectStatusEdit } from "src/v3/components/Dialogs/ProjectStatusEdit";
import { ActionLabel, CustomMenuItem } from "../../style";

export const EditProjectStatusAction = ({
  item,
}: EditProjectStatusActionProps) => {
  const { open, toggle } = useDialog();

  return (
    <CustomMenuItem onClick={toggle}>
      <ActionLabel>Edit status</ActionLabel>
      <EditProjectStatusEdit item={item} open={open} handleOpen={toggle} />
    </CustomMenuItem>
  );
};
