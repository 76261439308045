import { Grid, Typography, Checkbox, makeStyles } from "@material-ui/core";
import { FC } from "react";
import { primaryTextColor } from "src/v2/helpers/colors";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";

const useStyles = makeStyles(() => ({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: primaryTextColor,
  },
  title: {
    color: "grey",
    marginTop: 24,
  },
}));

export const FilterByLevel: FC = () => {
  const { selectedFilterLevels, handleSetFilterLevel, filterLevelOptions } =
    useFilterContext();
  const classes = useStyles();
  return (
    <Grid style={{ minWidth: 336 }}>
      <Typography className={classes.title}>Apply To</Typography>
      <Grid container direction="row">
        {filterLevelOptions.map((value) => (
          <Grid
            container
            xs={4}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Checkbox
              checked={selectedFilterLevels.includes(value)}
              onChange={() => handleSetFilterLevel(value)}
            />
            <Typography className={classes.text}>{value}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
