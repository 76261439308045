import { Box, Typography } from "@material-ui/core";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class MoneyField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = Array.isArray(field.value)
      ? field.value
      : [field.value?.value ?? field.value ?? ""];
  }

  hasValue() {
    const nullValue = !this.value[0];
    const emptyString = this.value[0] === "";
    const zero = [0, "0"].includes(this.value[0]);
    return (!nullValue && !emptyString) || zero;
  }

  getIntegerValue() {
    if (!this.hasValue()) return null;
    return parseInt(this.value[0], 10);
  }

  getComponentForGrid(_) {
    const moneyFormattedValue =
      this.hasValue() &&
      `$${this.value[0].toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;

    const noValueMessage =
      this.fieldKey === "cost_to_date"
        ? "Not calculated yet"
        : "Not predicted yet";

    const value = this.hasValue() ? moneyFormattedValue : noValueMessage;
    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: 200,
        }}
      >
        <BootstrapTooltipWithoutPortal title={value}>
          <Typography
            style={{
              color: primaryTextColor,
              borderRadius: 3,
              maxWidth: 150,
              padding: 4,
              margin: 4,
            }}
            noWrap
          >
            {value}
          </Typography>
        </BootstrapTooltipWithoutPortal>
      </Box>
    );
  }
}
