import { styled, Box, Theme, Typography } from "@material-ui/core";

export const EmptyStateRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  padding: theme.spacing(9),
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "stretch",
  gap: theme.spacing(4),
}));

export const MessageContainer = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    color: theme.palette.grey[900],
    ...theme.typography.body1,
    textAlign: "center",
  })
);

export const TitleContainer = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    color: theme.palette.grey[900],
    ...theme.typography.h1,
    fontWeight: theme.typography.weight.semiBold,
  })
);

export const IconContainer = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    width: "120px",
    height: "120px",
    padding: theme.spacing(2),
  })
);
