import { FC, useRef } from "react";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import ScrollHorizontallyButton from "./ScrollHorizontallyButton";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";

interface SummaryTableProps {
  items: BlockItem[];
  customStyles?: {};
}

export const SummaryTable: FC<SummaryTableProps> = (props) => {
  const { items, customStyles } = props;
  const tableRef = useRef(null);

  return (
    <>
      <ScrollHorizontallyButton tableRef={tableRef} />
      <Table
        data-test-id="summary_table"
        ref={tableRef}
        style={{
          ...customStyles,
        }}
      >
        <TableHeader items={items} />
        <TableBody items={items} />
      </Table>
    </>
  );
};
