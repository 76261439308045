import api from "./api";

export async function getRoadmaps() {
  const { data } = await api.get("/roadmap/latest");
  return data;
}

export async function getRawPriorities() {
  const { data } = await api.get("/roadmap/priorities");
  return data;
}

export async function fetchRoadmapTeams() {
  const { data } = await api.get("/roadmap/teams");
  return data;
}

export async function fetchRoadmapTeamsFreshData() {
  const { data } = await api.get("/roadmap/teams/fresh");
  return data;
}
