import {
  styled,
  Box,
  Theme,
  Grid,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";

export const DrawerHeader = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  padding: theme.spacing(6),
  gap: theme.spacing(1.3),
  flexDirection: "column",
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  background: theme.palette.background.default,
  zIndex: 2,
  position: "sticky",
  top: 0,
}));

export const ProgressBarRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  height: "10px",
  width: "80%",
}));

export const CloseIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  marginRight: "-8px",
  marginBottom: "12px",
}));

export const DrawerHeaderRoot = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const HeaderContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: "50px",
  gap: theme.spacing(4),
  alignSelf: "stretch",
}));

export const HeaderItemTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[900],
  ...theme.typography.h2,
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  display: "flex",
  flexDirection: "column",
  flex: "1 0 0",
  gap: theme.spacing(1),
}));

export const InfoGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  color: theme.palette.grey[400],
  ...theme.typography.body2,
}));

export const HeaderSideInfos = styled(Grid)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  minWidth: "290px",
  gap: theme.spacing(1.3),
}));

export const useStyles = makeStyles((theme) => ({
  issueTypeIcon: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  statusCloseIconGrid: {
    width: "30%",
    gap: "4px",
  },
}));
