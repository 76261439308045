import { WidgetConfigs } from "src/v2/components/Widget/WidgetConfigs";
import { useWidgetContext } from "src/v2/components/Widget/WidgetContext";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { ProgressField } from "src/v2/domain/entities/customField/ProgressField";
import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { StatusField } from "src/v2/domain/entities/customField/StatusField";

export const useSort = () => {
  /*
  This hook contains functions that are used to sort BlockItem objects.
  */
  const { getConfigValueByName } = useWidgetContext();
  const sortItemsByProgressCompletion = (items: BlockItem[]) =>
    items.sort((a, b) => {
      const aProgress = a.getFieldByType(
        FieldConfigType.progress
      ) as ProgressField;
      const bProgress = b.getFieldByType(
        FieldConfigType.progress
      ) as ProgressField;

      if (aProgress && bProgress) {
        const aProgressCompletion = aProgress.getDonePercentage();
        const bProgressCompletion = bProgress.getDonePercentage();
        const aProgressInProgress = aProgress.getInProgressPercentage();
        const bProgressInProgress = bProgress.getInProgressPercentage();
        return (
          bProgressCompletion - aProgressCompletion ||
          bProgressInProgress - aProgressInProgress
        );
      }
      return 0;
    });
  const sortObjectKeysByPosition = (object: any) =>
    Object.keys(object).sort((a, b) => {
      if (a === "" && b !== "") return 1;
      if (b === "" && a !== "") return -1;
      if (a === "" && b === "") return 0;
      return object[a].position - object[b].position || a.localeCompare(b);
    });

  function compareRank(rankOne, rankTwo) {
    /* Jira ranks are alphanumeric strings, so we need to compare them as alphanumeric strings.
     * This function compares two Jira ranks and returns -1 if rankOne is less than rankTwo, 1 if rankOne is greater than rankTwo, and 0 if they are equal.
     * @param {string} rankOne - The first Jira rank to be compared.
     * @param {string} rankTwo - The second Jira rank to be compared.
     * @returns {number} - The result of the comparison.
     * The comparison is best a 0, -1, or 1 because the localeCompare is used in  a sort function.
     */
    if (!rankOne && !rankTwo) return 0;
    if (!rankOne) return -1;
    if (!rankTwo) return 1;
    return rankOne.localeCompare(rankTwo, undefined, { sensitivity: "base" });
  }

  const sortItemsByJiraRank = (items: BlockItem[]) => {
    /*
  Sorts an array of BlockItem objects based on their Jira rank, which is determined by the RankField object associated with each BlockItem.
  @param {BlockItem[]} items - The array of BlockItem objects to be sorted.
  @returns {BlockItem[]} - The sorted array of BlockItem objects.
*/
    const jiraRankField = items[0].getFieldByFieldKey("Rank");
    if (jiraRankField) {
      const sortedItems = items.sort((a, b) => {
        const aJiraRank = a.getFieldByFieldKey("Rank")?.value[0];
        const bJiraRank = b.getFieldByFieldKey("Rank")?.value[0];
        return compareRank(aJiraRank, bJiraRank);
      });
      return sortedItems;
    }
    return items;
  };

  const getPriorityOrderFromSortConfig = (sortConfig) => {
    /*gets the priority order values from the sortConfig object in position order
     * @param {object} sortConfig - the sortConfig object
     * @returns {string[]} - the priority order values in position order
     * */
    if (sortConfig && sortConfig?.fieldKey === "Priority") {
      const priorityOrder = [];
      const acceptedValues = sortConfig?.acceptedValues;
      const sortedAcceptedValues = sortObjectKeysByPosition(acceptedValues);
      sortedAcceptedValues.forEach((key) => {
        priorityOrder.push(acceptedValues[key].value);
      });
      return priorityOrder;
    } else if (sortConfig && sortConfig?.child) {
      return getPriorityOrderFromSortConfig(sortConfig?.child);
    }
    return null;
  };

  const sortItemsByStatus = (items: BlockItem[]) => {
    /*
 Sorts an array of BlockItem objects based on their workflow status, which is determined by the StatusField object associated with each BlockItem.
 The workflow statuses are sorted in the order: "To Do", "In Progress", and "Done".
 @param {BlockItem[]} items - The array of BlockItem objects to be sorted.
 @returns {BlockItem[]} - The sorted array of BlockItem objects.
 */
    const sortedItems = items.sort((a, b) => {
      const aStatus = a.getFieldByType(FieldConfigType.status) as StatusField;
      const bStatus = b.getFieldByType(FieldConfigType.status) as StatusField;

      if (aStatus && bStatus) {
        const aStatusValue = aStatus.getStatusCategory();
        const bStatusValue = bStatus.getStatusCategory();

        if (
          aStatusValue === StatusField.CATEGORY_TODO &&
          bStatusValue !== StatusField.CATEGORY_TODO
        ) {
          return -1;
        } else if (
          bStatusValue === StatusField.CATEGORY_TODO &&
          aStatusValue !== StatusField.CATEGORY_TODO
        ) {
          return 1;
        } else if (
          aStatusValue === StatusField.CATEGORY_IN_PROGRESS &&
          bStatusValue === StatusField.CATEGORY_DONE
        ) {
          return -1;
        } else if (
          bStatusValue === StatusField.CATEGORY_IN_PROGRESS &&
          aStatusValue === StatusField.CATEGORY_DONE
        ) {
          return 1;
        }
      }

      return 0;
    });

    sortedItems.forEach((item) => {
      const itemField = item.getFieldByType(
        FieldConfigType.status
      ) as StatusField;
    });

    return sortedItems;
  };

  const pushItemsByPriority = (
    statusCategory,
    priorityOrder,
    sortedItems,
    itemsByStatus
  ) => {
    priorityOrder.forEach((priority) => {
      if (
        itemsByStatus[statusCategory] &&
        itemsByStatus[statusCategory][priority]
      ) {
        itemsByStatus[statusCategory][priority].forEach((item) => {
          sortedItems.push(item);
        });
      }
    });
  };
  const sortItemsByStatusPriorityRank = (items: BlockItem[]) => {
    /*
     * Sorts an array of BlockItem objects based on the following order:
     * 1. Workflow status
     * 2. Priority within each status
     * 3. Jira rank within each priority
     *
     * @param {BlockItem[]} items - The array of BlockItem objects to be sorted.
     * @returns {BlockItem[]} - The sorted array of BlockItem objects.
     *
     * This function breaks items in to groups by status, then priority, then rank.
     * It then sorts the them by rank, then priority, then status.
     * It does this by creating an object with the following structure:
     * {
     *   statusCategory1: {
     *     priority1: [item1, item2, item3],
     *     priority2: [item4, item5, item6],
     *   },
     *   statusCategory2: {
     *     priority1: [item7, item8, item9],
     *     priority2: [item10, item11, item12],
     *   },
     * }
     * It sorts each priority array by rank each status category is added to the sortedItems array in order of priority.
     */

    const sortConfig = getConfigValueByName(WidgetConfigs.sortConfig);
    const sortedItems = [];
    const itemsByStatus = {};
    const priorityOrder = getPriorityOrderFromSortConfig(sortConfig);
    items.forEach((item) => {
      const itemField = item.getFieldByType(
        FieldConfigType.status
      ) as StatusField;
      const itemStatus = itemField.getStatusCategory();
      const itemPriority = item.getFieldByType(FieldConfigType.priority)
        ?.value[0];
      if (!itemsByStatus[itemStatus]) {
        itemsByStatus[itemStatus] = {};
      }
      if (!itemsByStatus[itemStatus][itemPriority]) {
        itemsByStatus[itemStatus][itemPriority] = [];
      }
      itemsByStatus[itemStatus][itemPriority].push(item);
    });

    Object.keys(itemsByStatus).forEach((status) => {
      Object.keys(itemsByStatus[status]).forEach((priority) => {
        itemsByStatus[status][priority] = sortItemsByJiraRank(
          itemsByStatus[status][priority]
        );
      });
    });

    pushItemsByPriority(
      StatusField.CATEGORY_TODO,
      priorityOrder,
      sortedItems,
      itemsByStatus
    );
    pushItemsByPriority(
      StatusField.CATEGORY_IN_PROGRESS,
      priorityOrder,
      sortedItems,
      itemsByStatus
    );
    pushItemsByPriority(
      StatusField.CATEGORY_DONE,
      priorityOrder,
      sortedItems,
      itemsByStatus
    );
    return sortedItems;
  };

  function sortItemsByRecentlyAdded(items) {
    /*
     * Sorts an array of BlockItem objects based on whether they were recently added to their parent.
     * @param {BlockItem[]}
     * @returns {BlockItem[]}
     */
    return items.sort((a, b) => {
      const aRecentlyAdded = a.changelogInfo?.addedToParentAt;
      const bRecentlyAdded = b.changelogInfo?.addedToParentAt;

      if (aRecentlyAdded && bRecentlyAdded) {
        const dateA = new Date(aRecentlyAdded);
        const dateB = new Date(bRecentlyAdded);

        if (dateA > dateB) {
          return -1; // Reverse the comparison
        } else if (dateA < dateB) {
          return 1; // Reverse the comparison
        }
      } else if (aRecentlyAdded && !bRecentlyAdded) {
        return -1;
      } else if (!aRecentlyAdded && bRecentlyAdded) {
        return 1;
      }

      return 0;
    });
  }

  return {
    sortObjectKeysByPosition,
    sortItemsByProgressCompletion,
    sortItemsByJiraRank,
    sortItemsByStatusPriorityRank,
    sortItemsByRecentlyAdded,
  };
};
