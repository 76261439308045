import { Grid } from "@material-ui/core";
import ChangeInField from "src/v2/components/filter/components/ChangeInField";
import { getFilterFieldFromURLByKey } from "src/v2/utils/url";
import { CustomFieldBase } from "./CustomFieldBase";

export class ChangelogByDateField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = field?.value ?? {};
  }

  getComponentForGrid(_) {
    const urlField = getFilterFieldFromURLByKey(this.fieldKey);
    const foundFieldValues = urlField?.values?.length && urlField.values[0];

    const [quantity, timeUnity] = foundFieldValues
      ? foundFieldValues.split(" ")
      : [1, "day"];

    const timeUnityInDays = {
      month: 30,
      months: 30,
      day: 1,
      days: 1,
      weeks: 7,
      week: 7,
    };

    const daysToGoBack =
      quantity && timeUnity && Number(quantity) * timeUnityInDays[timeUnity];

    const now = new Date();

    const dateToFilter = new Date(
      now.setDate(now.getDate() - daysToGoBack)
    ).getTime();

    const changesInRange = this.value?.last_changes_by_key?.filter(
      (change) => new Date(change.at).getTime() >= dateToFilter
    );

    return (
      <Grid
        style={{
          width: "14em",
          wordBreak: "break-word",
        }}
      >
        {changesInRange.map((change, index) => (
          <ChangeInField key={index} fieldChange={change} />
        ))}
      </Grid>
    );
  }
}
