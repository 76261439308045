import {
  Box,
  Container,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  BootstrapTooltipWithoutPortal,
  ContainerWithToolTip,
} from "src/components/GlobalComponents";
import { WidgetConfigs } from "src/v2/components/Widget/WidgetConfigs";
import { statusNotSetColor, getAccessibleColors } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";
import * as Sentry from "@sentry/react";
import { primaryTextColor } from "src/v2/helpers/colors";
import { StatusCategory } from "../../enum/StatusCategory";
import { isValidStringDate, transformDate } from "src/v2/utils/date";
import { formatDate } from "src/utils/stringUtils";
import { defaultProjectStatus } from "src/v3/utils/ProjectStatus/allProjectStatuses";
import { EditProjectStatusActionV2 } from "src/v2/components/EditProjectStatusActionV2";

const useStyles = makeStyles((theme) => ({
  root: { width: "100%", minWidth: 178, margin: "0 -12px" },
  projectStatusContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  projectedFinishContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1.5),
  },
  riskComparisonContainer: {
    overflow: "hidden",
    textAlign: "center",
    borderRadius: 4,
    marginTop: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  riskComparisonText: {
    fontSize: 13,
    color: theme.palette.secondary.light,
  },
}));

export class ProjectStatusField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.setProjectStatus(field);
    this.setProjectedFinish(field);
    this.setRiskComparison(field);
  }
  getProjectStatus() {
    const override = this.getLastAddedOverride();
    return override?.value?.projectStatus ?? this.projectStatus;
  }

  getStatusName() {
    return this.getProjectStatus()?.name;
  }

  getTeamsTargetDate() {
    const override = this.getLastAddedOverride();
    return override?.value?.teamsTargetDate ?? this.riskComparisonDate;
  }

  getPreviousProjectStatus() {
    return this.previousProjectStatus;
  }

  // Projected status fields
  private projectStatus;
  private previousProjectStatus;

  // Projected Finish fields
  private predictedText: string;
  private projectedDateText: string;
  private hideContent: boolean;
  private projectedFinish: any;

  // Risk Comparison fields
  private riskComparisonDate: string; // Yes, the risk comparison DATE is a string, you can judge me all you want...

  getValue() {
    return this.getProjectStatus();
  }

  getValueForProperty(property) {
    return this.getProjectStatus()[property];
  }
  hasOverride() {
    const override = this.getLastAddedOverride();
    // TODO: After deprecate the old views, change this to only check override.value.
    return (
      override?.value?.projectStatus && override?.value?.projectStatus !== ""
    );
  }

  // Risk Comparison Methods
  setRiskComparison(field) {
    this.riskComparisonDate = field.value.risk_comparison_date?.date ?? "";
  }
  // Project Status Methods
  setProjectStatus(field) {
    this.projectStatus = field.value.project_status || defaultProjectStatus;
    this.previousProjectStatus =
      field.value.project_status?.previous_status || null;
  }

  isTypeEqual(type) {
    return this.getProjectStatus()?.type === type;
  }

  getPropertiesForComponent({ fieldsState }) {
    const config = fieldsState.getConfigValueByName(
      WidgetConfigs.projectStatusInsights
    );
    const { name, backgroundColor, textColor, ballColor, tooltip } =
      this.getComponentPropertiesFromConfig({ config });
    const properties = {
      backgroundColor,
      textColor,
      ballColor,
      name,
      tooltipText: tooltip,
      hasUserOverride: false,
      tooltipTextUserOverride: "",
    };

    return properties;
  }

  getStatusByConfig({ config, statusType }) {
    const status = config.statuses?.find(({ type }) => type === statusType);
    if (!status) {
      Sentry.captureMessage(
        `Status of type ${statusType} not found in project status insight widget config`
      );
    }
    const name = status?.name;
    const color = status?.color;
    const tooltip = status?.tooltip;
    return { color, name, tooltip };
  }
  getComponentPropertiesFromConfig({ config }) {
    const {
      color: statusColor,
      name: statusName,
      tooltip,
    } = this.getStatusByConfig({
      config,
      statusType: this.getProjectStatus()?.type,
    });

    const name = this.getProjectStatus()?.name || statusName;
    const color = statusColor || `${statusNotSetColor}`;

    const accessibleColors = getAccessibleColors(color);
    return {
      textColor: accessibleColors.text,
      backgroundColor: accessibleColors.background,
      ballColor: color,
      name,
      tooltip,
    };
  }
  // Projected Finish  Methods
  setProjectedFinish(field) {
    const projectedFinish = field.value?.projected_finish;
    this.projectedFinish = projectedFinish;

    this.predictedText = projectedFinish?.prediction?.predicted_text;
    this.projectedDateText = projectedFinish?.prediction?.projected_date_text;
    this.hideContent = field.hide_content;
  }

  getProjectedDateText() {
    if (!this.projectedDateText) return null;
    const isValidDate = isValidStringDate(this.projectedDateText);
    return (
      <Box>
        <Typography
          style={{
            color: `${primaryTextColor}`,
            display: "block",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          {isValidDate ? "Projected:" : ""}
          <div
            style={{ fontWeight: 400 }}
            dangerouslySetInnerHTML={{ __html: this.projectedDateText }}
          />
        </Typography>
      </Box>
    );
  }

  getPredictedText() {
    if (this.predictedText) {
      return (
        <Typography
          style={{
            color: `${primaryTextColor}`,
            marginBottom: "16px",
            minWidth: 196,
            whiteSpace: "pre-line",
            textAlign: "center",
          }}
        >
          {this.predictedText}
        </Typography>
      );
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getNotPredictedYetText() {
    return (
      <Typography style={{ color: `${primaryTextColor}` }} noWrap>
        Not predicted yet
      </Typography>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getAlreadyComplete() {
    return (
      <Typography style={{ color: `${primaryTextColor}` }} noWrap>
        Already Complete
      </Typography>
    );
  }

  getProjectedFinishText(statusCategory) {
    if (statusCategory === StatusCategory.Done)
      return this.getAlreadyComplete();
    if (this.hideContent) {
      return "";
    }
    if (!this.predictedText && !this.projectedDateText)
      return this.getNotPredictedYetText();
    return <Box>{this.getProjectedDateText()}</Box>;
  }
  getProjectedDate() {
    return this.projectedFinish?.prediction?.projected_date;
  }

  // Render method for all combined fields
  getComponentForGrid({ fieldsState, item, updateColumn }) {
    const { statusCategory } = fieldsState;
    const properties = this.getPropertiesForComponent({ fieldsState });
    const { name, ballColor, tooltipText } = properties;
    const classes = useStyles();

    const override = this.getLastAddedOverride();

    const truncatedJustification = () => {
      if (override?.justification?.length <= 70)
        return (
          <Typography
            style={{
              fontSize: 12,
              textAlign: "center",
              margin: 8,
              color: "#224358",
            }}
          >
            {override?.justification}
          </Typography>
        );

      return (
        <ContainerWithToolTip tooltipText={override?.justification}>
          <Typography
            style={{
              fontSize: 12,
              textAlign: "center",
              margin: 8,
              color: "#224358",
            }}
          >
            {override?.justification?.substring(0, 70) + "..."}
          </Typography>
        </ContainerWithToolTip>
      );
    };

    return (
      <Box className={classes.root}>
        <Box className={classes.projectStatusContainer}>
          <Box
            style={{
              backgroundColor: ballColor,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 6,
              borderRadius: 6,
              flexWrap: "nowrap",
            }}
          >
            <BootstrapTooltipWithoutPortal
              title={
                override?.value?.projectStatus
                  ? `The team has overridden the status from ${this.projectStatus.name} to ${override?.value?.projectStatus?.name}. Expiration date: ${formatDate(override?.expirationDate)}`
                  : tooltipText
              }
            >
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: 12,
                  textTransform: "uppercase",
                  margin: "0 8px",
                }}
              >
                {name}
              </Typography>
            </BootstrapTooltipWithoutPortal>
          </Box>
        </Box>
        {override?.justification && override?.justification !== "" && (
          <Container>
            {truncatedJustification()}

            <Divider
              style={{
                margin: 4,
              }}
            />
          </Container>
        )}
        {!override?.value?.teamsTargetDate && (
          <Box className={classes.projectedFinishContainer}>
            <Box
              style={{
                maxWidth: 200,
              }}
            >
              {this.getProjectedFinishText(statusCategory)}
            </Box>
          </Box>
        )}

        {this.getTeamsTargetDate() && (
          <Box
            className={classes.riskComparisonContainer}
            data-testid="risk-comparison-date"
          >
            <Typography className={classes.riskComparisonText}>
              Team's Target:
              <br /> {transformDate(this.getTeamsTargetDate())}
            </Typography>
          </Box>
        )}
        <EditProjectStatusActionV2 item={item} label="Edit" />
      </Box>
    );
  }
}
