import { FieldOrigin } from "../../enum/FieldOrigin";
import { FieldConfigType } from "../../enum/FieldConfigType";
import { FieldDisplayConfig } from "../../interfaces/FieldDisplayConfig";
import { UserOverrideFields } from "../../interfaces/UserOverrideFields";

export class CustomFieldBase {
  id: string;

  displayName: string;

  type: FieldConfigType;

  fieldKey: string;

  origin: FieldOrigin;

  displayConfig: FieldDisplayConfig[];

  value: any[];

  userOverride: UserOverrideFields[];

  constructor(field) {
    this.displayName = field.display_name;
    this.fieldKey = field.field_key;
    this.origin = field.origin;
    this.type = field.type;
    this.displayConfig = [];
  }

  setDisplayConfig(displayConfig: FieldDisplayConfig[]) {
    this.displayConfig = displayConfig;
  }

  getFieldKey(): string {
    return this.fieldKey;
  }

  updateValue(value) {
    this.value = value;
  }

  isNameField(): boolean {
    return this.fieldKey === "Summary";
  }

  getComponentForGrid(useState): any {
    return this.displayName;
  }

  getFirstColumnComponent({ props }): any {
    return <div></div>;
  }

  setOverrideValue(override: UserOverrideFields) {
    if (!override) return;

    if (typeof override.createdAt === "string")
      override.createdAt = new Date(override.createdAt);

    if (typeof override.expirationDate === "string")
      override.expirationDate = new Date(override.expirationDate);

    if (!this.userOverride?.length) {
      this.userOverride = [];
    }
    this.userOverride?.push(override);
  }

  getOverrides(): UserOverrideFields[] {
    return this.userOverride;
  }

  getLastAddedOverride(): UserOverrideFields {
    const lastOverride = this.getOverrides()?.sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
    );

    if (!lastOverride || lastOverride.length === 0) return undefined;
    return lastOverride[0];
  }

  hasOverride(): boolean {
    const override = this.getLastAddedOverride();
    return override?.value?.context && override?.value?.context !== "";
  }

  getValueForProperty(property: string): any {
    if (
      Array.isArray(this[property]) &&
      this[property].length > 0 &&
      typeof this[property][0] === "object"
    ) {
      return [this[property][0]?.type];
    }
    return this[property];
  }

  getValue(): any {
    return this.value;
  }

  getValueForSort(valueProperty?: string): any {
    return valueProperty && this.value ? this.value[valueProperty] : this.value;
  }
}
