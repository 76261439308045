import {
  createContext,
  useContext,
  useCallback,
  useRef,
  useMemo,
  useEffect,
  useReducer,
} from "react";

enum EventTypes {
  PROJECT_STATUS_OVERRIDE = "PROJECT_STATUS_OVERRIDE",
  RISK_COMPARISON_DATE_OVERRIDE = "RISK_COMPARISON_DATE_OVERRIDE",
  AI_SUMMARY_OVERRIDE = "AI_SUMMARY_OVERRIDE",
  RISK_DELETED = "RISK_DELETED",
  RISK_ADDED = "RISK_ADDED",
}

const EventContext = createContext({
  emit: (eventType: EventTypes, detail: any) => {},
  addListener: (eventType: EventTypes, action: any) => () => {},
});

function useEventContext() {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error("useEventContext must be used within an EventProvider");
  }
  return context;
}

const EventProvider = ({ children }) => {
  const listeners = useRef({});

  const emit = useCallback((eventType, detail) => {
    console.log("Emitting event:", eventType, "with detail:", detail);
    if (listeners.current[eventType]) {
      console.log(
        `Found ${listeners.current[eventType].size} listeners for event type: ${eventType}`
      );
      listeners.current[eventType].forEach((listener) => listener(detail));
    } else {
      console.log("No listeners found for event type:", eventType);
    }
  }, []);

  const addListener = useCallback((eventType, listener) => {
    if (!listeners.current[eventType]) {
      listeners.current[eventType] = new Set();
    }
    listeners.current[eventType].add(listener);
    return () => {
      listeners.current[eventType].delete(listener);
    };
  }, []);

  const value = useMemo(() => ({ emit, addListener }), []);

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};

function useEventListener(eventType: EventTypes, componentName: string) {
  const [state, setState] = useReducer((x) => x + 1, 0);
  const { addListener } = useEventContext();

  useEffect(() => {
    const handleEvent = () => {
      console.log(
        `Event ${eventType} received. Updating ${componentName} component.`
      );
      setState();
    };
    const unsubscribe = addListener(eventType, handleEvent);
    return unsubscribe;
  }, [eventType, addListener]);

  return state;
}

export { EventProvider, useEventContext, useEventListener, EventTypes };
