import { Typography, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { formatDate, truncateStringInTheMiddle } from "src/utils/stringUtils";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { ProjectStatusInsightConfig } from "src/v2/domain/entities/insights/projectStatusInsightConfig";
import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";
import { useScroll } from "src/v2/hooks/useScroll";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { useSummaryContext } from "src/v2/providers/summary/SummaryContext";
import { handleScrollToItemInTable } from "src/v2/utils/aiSummary";
import { ProjectStatusInsightCalculator } from "src/v2/utils/projectStatusInsightCalculator";
import { manualUpdateProjectStatusMapper } from "../../ManualUpdatedComponents/ProjectStatusMapper";
import { EventTypes, useEventListener } from "src/providers/EventContext";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    zIndex: 1000,
    background: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "4px 8px",
    borderRadius: "8px",
  },
  header: {
    color: "var(--color-blacker-blue)",
    fontSize: 16,
    lineHeight: 1.6,
    letterSpacing: "0.6px",
    fontWeight: 500,
    width: "fit-content",
    padding: "4px 8px",
    borderRadius: 3,
    margin: "0 0 4px 0 ",
  },
}));

const ProjectsListOnHover = ({ items, parentRef, projectStatusTypes }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const listRef = useRef(null);

  const isMouseOverParent = useRef(false);
  const isMouseOverList = useRef(false);
  const hoverTimeoutId = useRef(null);

  const [projectStatusItems, setProjectStatusItems] = useState({
    [ProjectStatusType.COMPLETE]: [],
    [ProjectStatusType.ON_TRACK]: [],
    [ProjectStatusType.OFF_TRACK]: [],
    [ProjectStatusType.AT_RISK]: [],
    [ProjectStatusType.PAUSED]: [],
    [ProjectStatusType.NOT_SET]: [],
  });
  const { scrollToElementById } = useScroll();
  const { expandItemByKey } = useSummaryContext();
  const { currentViewData } = useGenericViewContext();
  const classes = useStyles();

  const { key: viewKey } = currentViewData;

  const handleClick = (e): void => {
    const clickedElement = e.target;
    const scrollToItemCallback = (key, options) => {
      e.preventDefault();
      expandItemByKey(key);
      scrollToElementById(key, options);
    };

    handleScrollToItemInTable(clickedElement, scrollToItemCallback);
  };
  const updateHoverState = () => {
    if (!isMouseOverParent.current && !isMouseOverList.current) {
      setIsHovering(false);
    }
  };

  const handleMouseEnter = (ref) => (e) => {
    e.stopPropagation();
    clearTimeout(hoverTimeoutId.current);
    ref.current = true;
    setIsHovering(true);
  };

  const handleMouseLeave = (ref) => (e) => {
    e.stopPropagation();
    ref.current = false;
    hoverTimeoutId.current = setTimeout(updateHoverState, 300);
  };
  useEffect(() => {
    const parentNode = parentRef.current;
    const listNode = listRef.current;

    if (parentNode) {
      parentNode.addEventListener(
        "mouseenter",
        handleMouseEnter(isMouseOverParent)
      );
      parentNode.addEventListener(
        "mouseleave",
        handleMouseLeave(isMouseOverParent)
      );
    }
    if (listNode) {
      listNode.addEventListener(
        "mouseenter",
        handleMouseEnter(isMouseOverList)
      );
      listNode.addEventListener(
        "mouseleave",
        handleMouseLeave(isMouseOverList)
      );
    }

    return () => {
      clearTimeout(hoverTimeoutId.current);
      if (parentNode) {
        parentNode.removeEventListener(
          "mouseenter",
          handleMouseEnter(isMouseOverParent)
        );
        parentNode.removeEventListener(
          "mouseleave",
          handleMouseLeave(isMouseOverParent)
        );
      }
      if (listNode) {
        listNode.removeEventListener(
          "mouseenter",
          handleMouseEnter(isMouseOverList)
        );
        listNode.removeEventListener(
          "mouseleave",
          handleMouseLeave(isMouseOverList)
        );
      }
    };
  }, [parentRef, listRef.current]);

  const projectStatusUpdated = useEventListener(
    EventTypes.PROJECT_STATUS_OVERRIDE,
    "ProjectListOnHover"
  );

  useEffect(() => {
    const config = new ProjectStatusInsightConfig({});
    const calculator = new ProjectStatusInsightCalculator(config);
    const projectStatusItems = calculator.getItemsByProjectStatus(items);
    setProjectStatusItems(projectStatusItems);
  }, [items, projectStatusUpdated]);

  useEffect(() => {
    if (parentRef.current && listRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      setPosition({
        top: parentRect.bottom + window.scrollY,
        left: parentRect.left + window.scrollX,
      });
    }
  }, [isHovering, parentRef]);
  const getDisplayProjectStatus = (status) => {
    return status
      .replace("_status", "")
      .replace("_", " ")
      .toLowerCase()
      .replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
  };
  const isStatusDisplayed = (status) => {
    return projectStatusTypes.includes(status);
  };
  const hasDataForStatusTypes = () => {
    return projectStatusTypes.some(
      (statusType) =>
        projectStatusItems[statusType] &&
        projectStatusItems[statusType].length > 0
    );
  };
  if (!isHovering || !hasDataForStatusTypes()) {
    return null;
  }

  return (
    <div
      className={classes.root}
      style={{ top: position.top, left: position.left }}
      ref={listRef}
      onClick={handleClick}
    >
      <div className="root">
        {Object.values(ProjectStatusType).map((statusType) => {
          if (
            isStatusDisplayed(statusType) &&
            projectStatusItems[statusType].length > 0
          ) {
            const projectStatusName = getDisplayProjectStatus(statusType);
            const { textColor, background } = manualUpdateProjectStatusMapper({
              type: statusType,
              name: projectStatusName,
            });
            return (
              <div key={statusType}>
                <Typography
                  className={classes.header}
                  style={{
                    backgroundColor: background,
                    color: textColor,
                  }}
                >
                  Projects {projectStatusName}
                </Typography>
                <ul className="arrowed">
                  {projectStatusItems[statusType].map(
                    (item: BlockItem, index) => {
                      const isLastItem =
                        index === projectStatusItems[statusType].length - 1;
                      const itemKey = item.getKey();
                      const projectedDateField = item.getProjectedDateField();
                      const projectedDate =
                        projectedDateField.getProjectedDate();
                      const formattedDate = formatDate(projectedDate);
                      const truncatedDisplayName = truncateStringInTheMiddle(
                        item.getDisplayName(),
                        45
                      );

                      return (
                        <li
                          key={itemKey}
                          style={{ marginBottom: isLastItem ? 12 : 0 }}
                        >
                          <a
                            href={`https://velma.work/v2/roadmap/${viewKey}?key=${itemKey}`}
                            target="_blank"
                          >
                            {truncatedDisplayName} ({itemKey})
                          </a>{" "}
                          {formattedDate && `- ${formattedDate}`}
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default ProjectsListOnHover;
