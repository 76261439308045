import { UserOverrideFields } from "src/v2/domain/interfaces/UserOverrideFields";
import api from "../../v2/services/api/apiV2";

export async function updateProjectStatus(
  override: UserOverrideFields,
  itemKey: string
) {
  const { data } = await api.post(`/v2/insights/project-status`, {
    itemKey,
    ...override,
  });
  return data;
}

export async function updateRiskComparisonDate(
  override: UserOverrideFields,
  itemKey: string
) {
  const { data } = await api.post(`/v2/insights/risk-comparison-date`, {
    itemKey,
    ...override,
  });
  return data;
}

export async function updateAISummaryValue(
  override: UserOverrideFields,
  itemKey: string
) {
  const { data } = await api.post(`/v2/insights/ai-summary`, {
    itemKey,
    ...override,
  });
  return data;
}

export async function updateRisks(
  override: UserOverrideFields,
  itemKey: string
) {
  const { data } = await api.post(`/v2/insights/risks`, {
    itemKey,
    ...override,
  });
  return data;
}
