import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import ItemsFilteredTeamAISummarySection from "src/v2/components/Section/TeamAISummary/ItemsFilteredTeamAISummarySection";
import CustomDialog from "src/v2/components/CustomDialog";
import "../../../../components/Section/RichTextSection.css";
import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { BlockItem } from "../../block/BlockItem";
import { CustomFieldBase } from "../CustomFieldBase";
import { formatDate } from "src/utils/stringUtils";
import { changesSubtitle, primaryTextColor } from "src/v2/helpers/colors";
import { getAllKeyValueFiltersFromURL } from "src/v2/utils/url";
import { EditSummaryOfWorkActionV2 } from "src/v2/components/EditSummaryOfWorkActionV2";

const useStyles = makeStyles({
  header: {
    color: primaryTextColor,
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: 550,
  },
  body: {
    color: "rgb(57, 94, 119)",
    fontSize: "1rem",
    lineHeight: "1.5",
    textAlign: "justify",
    letterSpacing: "0.00938em",
    marginBottom: 16,
  },
  lastUpdated: {
    color: changesSubtitle,
    fontSize: "12px",
    lineHeight: "1.5",
    textAlign: "left",
    letterSpacing: "0.00938em",
  },
  divider: {
    margin: "0px 0",
  },
  additionDetailButton: {
    color: primaryTextColor,
    fontSize: "14px",
    fontWeight: 400,
    textDecoration: "underline",
  },
});

type AiSummaryHTMLGridTypes = {
  item: BlockItem;
  aiSummaryExpands: {
    openTeamSummaryModal: boolean;
    setOpenTeamSummaryModal: (open: boolean) => void;
  };
  aiSummaryOverride: any;
};

export class AISlackSummaryHTML extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = field.value ?? {
      ai_summary: "",
      slack_messages: [],
    };
    this.aiSummary = field.value?.ai_summary ?? "";
    this.aiSummary = this.makeTeamSummaryClickable();
    this.slackMessages = field.value?.slack_messages ?? [];
  }

  private aiSummary: string;
  private slackMessages: SlackMessage[];

  makeTeamSummaryClickable() {
    const clickableText =
      '<span key="team_summary_link" style="color: #197bbd; cursor: pointer; text-decoration: underline;">Team Summary</span>';
    const clickableHtml = this.aiSummary.replace(
      /team summary/gi,
      clickableText
    );

    return clickableHtml;
  }

  getComponentForGrid({
    item,
    aiSummaryExpands,
    aiSummaryOverride,
  }: AiSummaryHTMLGridTypes) {
    const classes = useStyles();
    const { openTeamSummaryModal, setOpenTeamSummaryModal } = aiSummaryExpands;
    const summaryWidth =
      getAllKeyValueFiltersFromURL()?.find(
        ({ fieldKey }) => fieldKey === "summary_width"
      )?.values[0] ?? 600;

    const override = this.getLastAddedOverride();
    const overrideValue = override?.value;
    const hasOverride = overrideValue?.context && overrideValue?.context !== "";

    function handleClick(e) {
      const clickedElement = e.target;
      e.stopPropagation();
      if (clickedElement.getAttribute("key") === "team_summary_link") {
        setOpenTeamSummaryModal(true);
      }
    }

    const TeamSummaryModal = ({ item }) => {
      const team = item.getFieldByPossibleTypes([
        FieldConfigType.team,
        FieldConfigType.team_link,
      ]);

      if (!team) {
        return null;
      }

      const teamName = team.value[0] ?? "";

      return (
        <CustomDialog
          open={openTeamSummaryModal}
          onClose={(e) => {
            e.stopPropagation();
            setOpenTeamSummaryModal(false);
          }}
          title={`${teamName} Team Summary`}
        >
          <div id="team-summary-portal">
            <ItemsFilteredTeamAISummarySection
              items={[item]}
              showTeamBoardName={false}
              boardName={
                item.getFieldByPossibleTypes([
                  FieldConfigType.team,
                  FieldConfigType.team_link,
                ])?.value[0] ?? ""
              }
            ></ItemsFilteredTeamAISummarySection>
          </div>
        </CustomDialog>
      );
    };

    const AdditionalDetailsHeader = () => {
      return (
        <Grid container direction="column">
          <Header title="Additional Details" onClick={handleClick} style={{}} />
          <Typography className={classes.lastUpdated}>
            Last updated by {overrideValue?.author?.name} on{" "}
            {formatDate(overrideValue?.created_at)}
          </Typography>
        </Grid>
      );
    };

    const Header = ({ title, onClick, style }) => {
      return (
        <Typography
          onClick={(e) => onClick(e)}
          className={classes.header}
          style={style}
        >
          {title}
        </Typography>
      );
    };

    const Body = ({ content, onClick }) => {
      return (
        <Grid direction="column" container>
          <div
            onClick={(e) => onClick(e)}
            className={classes.body}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </Grid>
      );
    };

    return (
      <Grid
        container
        direction="column"
        style={{
          width: summaryWidth,
        }}
      >
        {hasOverride && (
          <Grid container direction="column">
            <AdditionalDetailsHeader />
            <Body onClick={handleClick} content={overrideValue.context}></Body>
            <Divider className={classes.divider} />
          </Grid>
        )}

        {this.aiSummary && hasOverride && (
          <Header
            title={"Velma Summary"}
            onClick={onclick}
            style={{ margin: "16px 0" }}
          />
        )}
        {this.aiSummary && (
          <Body onClick={handleClick} content={this.aiSummary}></Body>
        )}

        {this.slackMessages?.length > 0 && (
          <Grid container direction="column">
            <Divider className={classes.divider} />
            <Header
              title={"Team Updates"}
              onClick={onclick}
              style={{ margin: "16px 0" }}
            />
            <Body
              onClick={handleClick}
              content={this.slackMessages
                .map(
                  (message) =>
                    `<ul class='arrowed'><li>${message.summary}</li></ul>`
                )
                .join("<br>")}
            ></Body>
          </Grid>
        )}

        <EditSummaryOfWorkActionV2 item={item} />
        <TeamSummaryModal item={item}></TeamSummaryModal>
      </Grid>
    );
  }
}
