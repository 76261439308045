import { Grid, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { isValidStringDate, getCurrentDateMinusDays } from "src/v2/utils/date";
import { BlockItem } from "../block/BlockItem";
import EpicInfoBase from "./EpicInfoBase";
import { CommentRounded } from "@material-ui/icons";
import { ProjectStatusType } from "../../enum/ProjectStatusInsightType";

export default class NoRecentlyCommentsWarning extends EpicInfoBase {
  constructor(info) {
    super(info);
  }

  shouldDisplay({ item }: { item: BlockItem }): boolean {
    if (
      item.getProjectStatusFieldV2()?.isTypeEqual(ProjectStatusType.COMPLETE)
    ) {
      return false;
    }

    if (!item.changelogInfo?.lastComment) {
      return true;
    }

    const itemLastCommentDate = item.changelogInfo?.lastComment?.created;
    if (!isValidStringDate(itemLastCommentDate)) {
      return true;
    }

    const acceptableDate = getCurrentDateMinusDays(this.config.days_to_compare);
    const lastCommentDate = new Date(itemLastCommentDate).toISOString();

    return lastCommentDate < acceptableDate;
  }
  getComponent({}): JSX.Element {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignContent="center"
      >
        <CommentRounded
          color="primary"
          style={{
            height: 20,
            width: 20,
            marginRight: 10,
            color: primaryTextColor,
          }}
        ></CommentRounded>
        <Typography
          style={{
            fontSize: 16,
          }}
          data-test-id="no_comments_warning_info"
        >
          No comments in the past {this.config.days_to_compare} days.
        </Typography>
      </Grid>
    );
  }
}
