import { Grid, Typography } from "@material-ui/core";
import { getAccessibleColors, primaryTextColor } from "src/v2/helpers/colors";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useState, useEffect } from "react";
import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";
import { OutcomeStatus } from "src/v2/domain/entities/customField/Outcome/OutcomeStatus";
import { outcomeConfig } from "../../Widget/outcomeConfig";
import { useWidgetContext } from "../../Widget/WidgetContext";
import ProgressBar from "../../progressBar/ProgressBar";

function OutcomeProgressBar({ items }) {
  const { _section } = useWidgetContext();

  const [percentages, setPercentages] = useState([]);
  const customStyles = _section.options?.find(
    ({ name }) => name === "styles"
  )?.value;

  const title = _section.options?.find(
    ({ name }) => name === "child-title"
  )?.value;

  const { root, childTitle } = customStyles || {};

  const getColor = (color) => {
    const accessibleColors = getAccessibleColors(color, 0.5);
    return {
      textColor: accessibleColors.text,
      backgroundColor: accessibleColors.background,
      ballColor: color,
    };
  };

  useEffect(() => {
    const _percentages = [];
    items.forEach((item: BlockItem) => {
      const outcomeField = item.getFieldByFieldKey(
        "outcome_status"
      ) as OutcomeStatus;
      const statusFieldValue =
        outcomeField?.getOutcome()?.name ?? outcomeField?.getOutcome()?.type;

      const outcomeInConfig = outcomeConfig.statuses.find(
        ({ name, type }) =>
          name === statusFieldValue ||
          type === statusFieldValue ||
          type === ProjectStatusType.NOT_SET
      );

      const configName = outcomeInConfig?.name;
      const configColor = outcomeInConfig?.color;
      const configPosition = outcomeInConfig?.position;

      const statusInList = _percentages.find(({ name }) => name === configName);
      if (statusInList) {
        statusInList.totalItems += 1;
      } else {
        _percentages.push({
          name: configName,
          color: configColor,
          position: configPosition,
          percentage: 0,
          totalItems: 1,
        });
      }
    });

    // calculate percentages
    const totalItems = items.length;
    _percentages.forEach((status) => {
      status.percentage = (status.totalItems / totalItems) * 100;
    });

    setPercentages(_percentages.sort((a, b) => a.position - b.position));
  }, [items]);

  function renderLegends() {
    return (
      <Grid
        container
        direction="row"
        style={{
          gap: 20,
        }}
      >
        {percentages.map((item) => {
          const { text, background } = getAccessibleColors(item.color);
          return (
            <div
              style={{
                textTransform: "uppercase",
                textAlign: "left",
                display: "flex",
                borderRadius: 5,
                color: `${text}`,
                backgroundColor: `${background}`,
                padding: "8px",
              }}
            >
              <div
                style={{
                  fontWeight: "700",
                }}
              >
                {item.name}:
              </div>
              <div style={{ marginLeft: 12 }}>{`${item.totalItems} item${
                item.totalItems > 1 ? "s" : ""
              }`}</div>
              <div style={{ marginLeft: 4 }}>
                ({item.percentage.toFixed(0)}%)
              </div>
            </div>
          );
        })}
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      style={{ ...root }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {title && (
          <Typography
            variant="h6"
            style={{
              color: `${primaryTextColor}`,
              marginBottom: 8,
              marginRight: 16,
              ...childTitle,
            }}
          >
            {title}
          </Typography>
        )}
        <ProgressBar
          percentages={percentages.map((item) => ({
            ...item,
            color: getColor(item.color).backgroundColor,
          }))}
          backgroundColor={"lightGrey"}
          customHeight="32px"
          showProgressBarLegends={false}
          customBoxStyle={{
            marginBottom: 20,
            width: "50%",
          }}
        />
      </Grid>
      <div style={{ display: "flex" }}>{renderLegends()}</div>
    </Grid>
  );
}

export default OutcomeProgressBar;
