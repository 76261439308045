import { manualUpdateProjectStatusMapper } from "./ProjectStatusMapper";

export const ManualUpdateProjectStatus = ({
  title,
  titleBackgroundColor,
  status,
}) => {
  const accessibleColors = manualUpdateProjectStatusMapper(status);
  const { name, ballColor, textColor, background } = accessibleColors;
  return (
    <div className="MuiBox-root jss369 jss44">
      <div
        className="Rectangle434"
        style={{
          height: 38,
          backgroundColor: titleBackgroundColor,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h6
          className="MuiTypography-root jss43 MuiTypography-h6"
          style={{
            color: "white",
          }}
        >
          {title}
        </h6>
      </div>
      <div
        className="MuiBox-root jss370"
        style={{
          minWidth: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: background,
          padding: "12px 8px",
        }}
      >
        <div
          data-test-id="project_status_ball"
          style={{
            backgroundColor: ballColor,
            borderRadius: "100px",
            height: "20px",
            width: "20px",
            marginRight: "16px",
          }}
        ></div>
        <h6
          className="MuiTypography-root MuiTypography-h6"
          style={{
            fontSize: "16px",
            color: textColor,
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          {name}
        </h6>
      </div>
    </div>
  );
};
