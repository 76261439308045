import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import * as React from "react";
import { primaryTextColor } from "../helpers/colors";

export default function CustomDialog({
  children,
  title,
  open,
  onClose,
  onClick = (e) => e.stopPropagation(),
  customTitleStyle = {},
  customContentStyle = {},
  customCloseIconStyle = {},
}) {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClick={onClick}
        maxWidth="xl"
      >
        {title && (
          <DialogTitle
            style={{
              color: primaryTextColor,
              maxWidth: "95%",
              ...customTitleStyle,
            }}
            id="customized-dialog-title"
          >
            {title}
          </DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
            ...customCloseIconStyle,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent
          style={{
            ...customContentStyle,
          }}
          dividers
        >
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
