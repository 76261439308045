import { sentryCaptureException } from "src/utils/error";
import { WidgetConfigs } from "./WidgetConfigs";
import { isEmpty } from "src/v2/utils/object";

export const getSortConfig = ({ configs }) => {
  const configValue = configs?.find(
    ({ name }) => name === WidgetConfigs.sortConfig
  )?.value;

  return isEmpty(configValue) ? null : configValue;
};

const defaultSortConfigProjectStatus = [
  { value: "Off Track", position: 10 },
  { value: "Blocked", position: 20 },

  { value: "At Risk", position: 30 },

  { value: "On Track", position: 40 },
  { value: "Planning", position: 41 },
  { value: "Just Started", position: 43 },
  { value: "Longterm Project", position: 44 },
  { value: "Maintenance", position: 45 },

  { value: "Low Allocation", position: 48 },
  { value: "Discovery", position: 49 },

  { value: "Paused", position: 50 },
  { value: "Not in Sprint", position: 51 },
  { value: "Not Started", position: 52 },
  { value: "Not In Progress", position: 53 },

  { value: "Almost Complete", position: 70 },
  { value: "Almost Done", position: 71 },
  { value: "Likely Complete", position: 72 },

  { value: "Complete", position: 80 },

  { value: "Excluded", position: 90 },

  { value: "Not Set", position: 999 },
  { value: "", position: 1000 },
];

export const buildDefaultSortConfig = ({
  defaultSortConfig,
  rawPriorities,
}) => {
  const config = defaultSortConfig;

  if (!config) {
    sentryCaptureException("Default sort config is not defined");
    return null;
  }

  function updateConfig(node) {
    if (!node) {
      return;
    }
    if (!node.acceptedValues) {
      node.acceptedValues = [];
    }

    if (node.fieldKey === "Priority" && node?.acceptedValues?.length === 0) {
      node.acceptedValues = rawPriorities?.map(({ name, order }) => ({
        value: name,
        position: order,
      }));
    } else if (
      node.fieldKey === "project_status" &&
      node?.acceptedValues?.length === 0
    ) {
      node.acceptedValues = defaultSortConfigProjectStatus;
    }

    if (node?.acceptedValues?.length > 1) {
      node.acceptedValues.sort(
        (firstValue, secondValue) => firstValue.position - secondValue.position
      );
    }

    if (node.child) {
      updateConfig(node.child);
    }
  }

  updateConfig(config);

  return config;
};
