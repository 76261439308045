import { useState, useEffect } from "react";
import { calculateDiffInDays } from "src/v2/utils/date";
import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";
import { useGenericViewContext } from "../providers/genericView/GenericViewContext";
import { BlockItem } from "../domain/entities/block/BlockItem";
import { EventTypes, useEventListener } from "src/providers/EventContext";
import { formatDate } from "src/utils/stringUtils";
import { hasItemRecentTargetDateChange } from "src/utils/dateFormatter";

export const useProjectStatusChanges = ({
  items,
  hasAutomaticChanges,
  showChangesInTeamsTargetDate = false,
}) => {
  const [changesHTML, setChangesHTML] = useState(null);
  const { currentViewData, teamSummary } = useGenericViewContext();

  const projectStatusUpdated = useEventListener(
    EventTypes.PROJECT_STATUS_OVERRIDE,
    "useProjectStatusChanges"
  );

  const { key: viewKey } = currentViewData;

  const extractRelevantInfo = (summaryData) => {
    const relevantItems = [];
    const listItemRegex = /<li>(.*?)<\/li>/gs;
    const stoppedRegex = /<span class='stopped'>(.*?)<\/span>/gs; // Regex to match content inside <span class='stopped'>

    // Extracting 'Recently stopped working'
    let stoppedMatch;
    while ((stoppedMatch = stoppedRegex.exec(summaryData)) !== null) {
      let stoppedContent = stoppedMatch[1];
      let text = `<ul>${stoppedContent}</ul>`;
      text = text.replace(/"/g, "'"); // Replace double quotes with single quotes

      relevantItems.push({
        text,
        isJustStarted: false,
      });
    }

    // Extracting 'Just started' items
    let justStartedMatch;
    while ((justStartedMatch = listItemRegex.exec(summaryData)) !== null) {
      let liContent = justStartedMatch[1];

      if (liContent.includes("Just started")) {
        let text = `<ul>${liContent}</ul>`;
        text = text.replace(/"/g, "'"); // Replace double quotes with single quotes

        relevantItems.push({
          text,
          isJustStarted: true,
        });
      }
    }

    return relevantItems;
  };

  const getAiSummaries = (boards) => {
    const justStartedItems = [];
    const recentlyStoppedItems = [];

    boards.forEach((board) => {
      const data = board.ai_summary;
      if (!data) return;
      let items = [];
      if (data.roadmap) {
        const info = extractRelevantInfo(data.roadmap.body);
        if (info && info.length > 0) {
          items = items.concat(info);
        }
      }
      if (data["non-roadmap"]) {
        const info = extractRelevantInfo(data["non-roadmap"].body);
        if (info && info.length > 0) {
          items = items.concat(info);
        }
      }
      if (!data.roadmap && !data["non-roadmap"] && data.default) {
        items = extractRelevantInfo(
          typeof data.default === "string" ? data.default : data.default.body
        );
      } else if (typeof data === "string") {
        items = extractRelevantInfo(data);
      }

      items.forEach(({ text, isJustStarted }) => {
        if (isJustStarted) {
          justStartedItems.push(text);
        } else {
          recentlyStoppedItems.push(text);
        }
      });
    });
    if (justStartedItems.length > 0 && recentlyStoppedItems.length > 0) {
      // adds a <br/> between the two lists
      justStartedItems.push("<br/>");
    }
    const _items = justStartedItems.concat(recentlyStoppedItems);
    return _items;
  };

  // Function to check if the item is at risk or off track
  function isItemAtRiskOrOffTrack(item: BlockItem) {
    const statusField =
      item.getProjectStatusField() || item.getProjectStatusFieldV2();
    const statusType = statusField?.getProjectStatus()?.type;
    return (
      statusType === ProjectStatusType.OFF_TRACK ||
      statusType === ProjectStatusType.AT_RISK
    );
  }

  function getTeamsTargetDateChangesHTML(items: BlockItem[]) {
    return items.map((item: BlockItem) => {
      const targetDate = item.getRiskComparisonDateField()?.getValue();
      if (!targetDate) return "";
      const previous = formatDate(targetDate.previous?.date);
      const current = formatDate(targetDate.date);
      return `<li><a href=${item.getNameField()?.getURL()} target="_blank">${item.getDisplayName()} (${item.getKey()})</a> - ${targetDate.source?.field_key ? `${targetDate.source?.field_key}` : ""} from <b>${previous}</b> to <b>${current}</b></li>`;
    });
  }

  // Function to filter items with status changed in last 7 days
  function filterItemsWithRecentStatusChange(items) {
    return items.filter((item: BlockItem) => {
      const statusField =
        item.getProjectStatusField() || item.getProjectStatusFieldV2();
      const previousStatus = statusField?.getPreviousProjectStatus();
      if (!previousStatus) return false;

      const previousStatusCreatedAt = new Date(previousStatus.created_at);
      const today = new Date();
      return calculateDiffInDays(today, previousStatusCreatedAt) <= 7;
    });
  }
  const getProjectStatusName = (name, type) => {
    // if name is defined return name
    if (name) return name;
    // else return name based on type
    switch (type) {
      case ProjectStatusType.ON_TRACK:
        return "On Track";
      case ProjectStatusType.AT_RISK:
        return "At Risk";
      case ProjectStatusType.OFF_TRACK:
        return "Off Track";
      case ProjectStatusType.PAUSED:
        return "Paused";
      default:
        return "";
    }
  };
  // Function to generate HTML for project status changes
  function generateProjectStatusChangesHTML(items) {
    let html = `<div><h3 style="color:#5F8097;" class="mt-2">Project Status Changes</h3><ul class="arrowed">`;
    items.forEach((item: BlockItem) => {
      const statusField =
        item.getProjectStatusField() || item.getProjectStatusFieldV2();
      const previousProjectStatus = statusField?.getPreviousProjectStatus();
      const projectStatus = statusField?.getProjectStatus();

      const itemKey = item.getKey();
      html += `<li><a href="https://velma.work/v2/roadmap/${viewKey}?key=${itemKey}" target="_blank">${item.getDisplayName()} (${itemKey})</a> from ${getStatusEmoji(
        previousProjectStatus?.type
      )} <strong>${getProjectStatusName(
        previousProjectStatus?.name,
        previousProjectStatus?.type
      )}</strong> to ${getStatusEmoji(projectStatus?.type)} <strong>${getProjectStatusName(
        projectStatus?.name,
        projectStatus?.type
      )}</strong>.</li>`;
    });
    html += `</ul></div>`;
    return html;
  }

  // Utility function to get emoji for status
  function getStatusEmoji(statusType) {
    switch (statusType) {
      case ProjectStatusType.ON_TRACK:
        return "🟢";
      case ProjectStatusType.AT_RISK:
        return "🟡";
      case ProjectStatusType.OFF_TRACK:
        return "🔴";
      case ProjectStatusType.PAUSED:
        return "⚪️";
      default:
        return "";
    }
  }

  // Function to get teams data
  function getTeamsData(items, teamSummary) {
    const teams = new Set();
    items.forEach((item) => {
      const teamsValue = item.getTeamFieldValue();
      if (teamsValue) {
        teamsValue.forEach((team) => teams.add(team));
      }
    });

    return Array.from(teams)
      .map((team) => {
        return teamSummary?.data?.find(
          (teamSummary) => teamSummary.name === team
        );
      })
      .filter(Boolean);
  }

  useEffect(() => {
    let html = "";
    if (hasAutomaticChanges) {
      const epicsAtRiskOrOffTrack = items.filter(isItemAtRiskOrOffTrack);
      const epicsWithRecentStatusChange = filterItemsWithRecentStatusChange(
        epicsAtRiskOrOffTrack
      );

      if (epicsWithRecentStatusChange.length > 0) {
        const projectStatusChangesHTML = generateProjectStatusChangesHTML(
          epicsWithRecentStatusChange
        );
        html += projectStatusChangesHTML;
      }

      const teamsData = getTeamsData(items, teamSummary);
      const inProgressStoppedWorkData = getAiSummaries(teamsData);
      if (inProgressStoppedWorkData.length > 0) {
        const inProgressStoppedWorkHTML = `<div><h3 style="color:#5F8097;" class="mt-2">Work Starting / Stopping</h3><ul class="arrowed">${inProgressStoppedWorkData
          .map((item) => item)
          .join("")}</ul></div>`;
        html += inProgressStoppedWorkHTML;
      }
    }

    if (showChangesInTeamsTargetDate) {
      const weeklyChangesInTeamsTargetDate = items.filter(
        hasItemRecentTargetDateChange
      );

      if (weeklyChangesInTeamsTargetDate.length > 0) {
        const teamsTargetDateChangesHTML = `<div><h3 style="color:#5F8097;" class="mt-2">Teams Target Date Changes</h3><ul class="arrowed">${getTeamsTargetDateChangesHTML(
          weeklyChangesInTeamsTargetDate
        )
          .map((item) => item)
          .join("")}</ul></div>`;
        html += teamsTargetDateChangesHTML;
      }
    }

    if (html.length > 0) {
      setChangesHTML(html);
    }
  }, [
    items,
    teamSummary,
    hasAutomaticChanges,
    projectStatusUpdated,
    showChangesInTeamsTargetDate,
  ]);

  return { changesHTML };
};
