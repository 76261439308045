/* eslint-disable camelcase */
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, createContext, useMemo, useEffect, useState } from "react";
import { IssueCategories } from "src/pages/SprintView/models/enums/IssueCategories";
import { getAllBoardsPossibleIssueStatuses } from "src/services/planningService";
import parseStringToBoolean from "src/utils/stringUtils";

interface IClientStatusConfigContext {
  statuses: [];
}

const ClientStatusConfigContext =
  createContext<IClientStatusConfigContext>(null);

function useClientStatusConfigContext() {
  return useContext(ClientStatusConfigContext);
}

interface StatusCategory {
  name: string;
  colorName: string;
}

interface BoardStatusesProps {
  name: String;
  // eslint-disable-next-line camelcase
  status_category: StatusCategory;
  position: number;
}

export function ClientStatusConfigContextProvider({ children }: any) {
  const [boardStatuses, setBoardStatuses] = useState<BoardStatusesProps[]>();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) return;
    getAllBoardsPossibleIssueStatuses().then((response) => {
      setBoardStatuses(response);
    });
  }, []);

  const getStatusCategory = (statusName) => {
    const status = boardStatuses?.find(
      (_status) => _status.name === statusName
    );
    return status ? status.status_category.name : IssueCategories.TODO;
  };

  function isStatusInDoneCategory(status) {
    return getStatusCategory(status) === IssueCategories.DONE;
  }
  function isStatusInProgressCategory(status) {
    return getStatusCategory(status) === IssueCategories.IN_PROGRESS;
  }
  function getInProgressTickets(tickets) {
    return tickets.filter(
      ({ status, devComplete }) =>
        isStatusInProgressCategory(status) && !parseStringToBoolean(devComplete)
    );
  }

  function getDoneTickets(tickets) {
    return tickets.filter(({ status }) => isStatusInDoneCategory(status));
  }

  const value = useMemo(
    () => ({
      getInProgressTickets,
      getDoneTickets,
      isStatusInDoneCategory,
      isStatusInProgressCategory,
      statuses: boardStatuses
        ?.filter((status) => status.position !== null)
        ?.sort((a, b) => a.position - b.position),
    }),
    [boardStatuses, isStatusInDoneCategory, isStatusInProgressCategory]
  );

  return (
    <ClientStatusConfigContext.Provider value={value}>
      {children}
    </ClientStatusConfigContext.Provider>
  );
}

export default useClientStatusConfigContext;
