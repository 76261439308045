import { Box, MenuItem, Typography } from "@material-ui/core";
import BaseButton from "src/components/Buttons/BaseButton";
import { useBlockContext } from "src/v2/providers/block/BlockContext";
import * as CsvExporter from "src/v2/domain/entities/csvExport/CsvExporter";
import { CustomSizeLoader } from "src/components/v2/loader";
import { useState } from "react";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { useWidgetContext } from "../Widget/WidgetContext";

export const DownloadCsvButton = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getConfigValueByName } = useWidgetContext();
  const { filteredItems } = useBlockContext();
  const { currentViewData } = useGenericViewContext();
  const showDownloadButtonConfig = getConfigValueByName("show_download_csv");

  async function handleClick() {
    try {
      setIsLoading(true);
      await CsvExporter.execute(filteredItems, {
        id: currentViewData.id,
        key: currentViewData.key,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box onClick={handleClick}>
      {showDownloadButtonConfig &&
        (isLoading ? (
          <Box display="flex" justifyContent="center">
            <CustomSizeLoader size="24px" />
          </Box>
        ) : (
          <MenuItem>
            <BaseButton>
              <Typography>Download as csv</Typography>
            </BaseButton>
          </MenuItem>
        ))}
    </Box>
  );
};
