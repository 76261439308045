import { Typography } from "@material-ui/core";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class DropDownField extends CustomFieldBase {
  constructor(field) {
    super(field);
    if (field.value && field.value?.length > 0) {
      const firstItem = field.value[0];
      if (
        typeof firstItem === "object" &&
        firstItem.hasOwnProperty("display_name")
      ) {
        this.value = field.value.map(({ display_name }) => display_name);
      } else if (
        typeof firstItem === "object" &&
        firstItem.hasOwnProperty("value")
      ) {
        this.value = field.value.map(({ value }) => value);
      } else if (typeof firstItem === "string") {
        this.value = field.value;
      } else {
        this.value = [];
      }
    } else {
      this.value = [];
    }
  }

  getComponentForGrid(_) {
    return (
      <div
        style={{
          width: "200px",
        }}
      >
        {this.value.map((selection) => (
          <BootstrapTooltipWithoutPortal title={selection}>
            <Typography noWrap style={{ color: `${primaryTextColor}` }}>
              {selection}
            </Typography>
          </BootstrapTooltipWithoutPortal>
        ))}
      </div>
    );
  }
}
