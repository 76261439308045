import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { aggregateItemsByStatus, StatusConfig } from "../aggregateByStatus";

export const aggregateItemsByProjectStatus = ({
  items,
  property = "type",
}: {
  items: BlockItem[];
  property?: "name" | "type";
}) => {
  const projectStatusConfig: StatusConfig = {
    getStatusField: (item: BlockItem) => item.getProjectStatusField(),
    property,
  };

  const aggregation = aggregateItemsByStatus({
    items,
    statusConfig: projectStatusConfig,
  });
  return aggregation.sort(
    (a, b) => a.status.getPosition() - b.status.getPosition()
  );
};
