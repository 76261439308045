import { ReactComponent as ProductsLarge } from "../../assets/icons/v3/large/Products.svg";
import { ReactComponent as ProductsMedium } from "../../assets/icons/v3/medium/Products.svg";
import { ReactComponent as ProductsSmall } from "../../assets/icons/v3/small/Products.svg";

import { ReactComponent as ProjectsLarge } from "../../assets/icons/v3/large/Projects.svg";
import { ReactComponent as ProjectsMedium } from "../../assets/icons/v3/medium/Projects.svg";
import { ReactComponent as ProjectsSmall } from "../../assets/icons/v3/small/Projects.svg";

import { ReactComponent as TeamLarge } from "../../assets/icons/v3/large/Team.svg";
import { ReactComponent as TeamMedium } from "../../assets/icons/v3/medium/Team.svg";
import { ReactComponent as TeamSmall } from "../../assets/icons/v3/small/Team.svg";

import { ReactComponent as SubTaskLarge } from "../../assets/icons/v3/large/Sub-task.svg";
import { ReactComponent as SubTaskMedium } from "../../assets/icons/v3/medium/Sub-task.svg";
import { ReactComponent as SubTaskSmall } from "../../assets/icons/v3/small/Sub-task.svg";

import { ReactComponent as TaskLarge } from "../../assets/icons/v3/large/Task.svg";
import { ReactComponent as TaskMedium } from "../../assets/icons/v3/medium/Task.svg";
import { ReactComponent as TaskSmall } from "../../assets/icons/v3/small/Task.svg";

import { ReactComponent as QuickWinsLarge } from "../../assets/icons/v3/large/QuickWins.svg";
import { ReactComponent as QuickWinsMedium } from "../../assets/icons/v3/medium/QuickWins.svg";
import { ReactComponent as QuickWinsSmall } from "../../assets/icons/v3/small/QuickWins.svg";

import { ReactComponent as InvestigationLarge } from "../../assets/icons/v3/large/Investigation.svg";
import { ReactComponent as InvestigationMedium } from "../../assets/icons/v3/medium/Investigation.svg";
import { ReactComponent as InvestigationSmall } from "../../assets/icons/v3/small/Investigation.svg";

import { ReactComponent as SuggestionLarge } from "../../assets/icons/v3/large/Suggestion.svg";
import { ReactComponent as SuggestionMedium } from "../../assets/icons/v3/medium/Suggestion.svg";
import { ReactComponent as SuggestionSmall } from "../../assets/icons/v3/small/Suggestion.svg";

import { ReactComponent as BugLarge } from "../../assets/icons/v3/large/Bug.svg";
import { ReactComponent as BugMedium } from "../../assets/icons/v3/medium/Bug.svg";
import { ReactComponent as BugSmall } from "../../assets/icons/v3/small/Bug.svg";

import { ReactComponent as StoryLarge } from "../../assets/icons/v3/large/Story.svg";
import { ReactComponent as StoryMedium } from "../../assets/icons/v3/medium/Story.svg";
import { ReactComponent as StorySmall } from "../../assets/icons/v3/small/Story.svg";

import { ReactComponent as SupportRequestLarge } from "../../assets/icons/v3/large/SupportRequest.svg";
import { ReactComponent as SupportRequestMedium } from "../../assets/icons/v3/medium/SupportRequest.svg";
import { ReactComponent as SupportRequestSmall } from "../../assets/icons/v3/small/SupportRequest.svg";

import { ValidIssueTypes } from "../components/IssueTypeIcon/utils";
export const mapIssueTypeToIcon = {
  [ValidIssueTypes.Product]: {
    large: ProductsLarge,
    medium: ProductsMedium,
    small: ProductsSmall,
  },
  [ValidIssueTypes.Initiative]: {
    large: ProductsLarge,
    medium: ProductsMedium,
    small: ProductsSmall,
  },
  [ValidIssueTypes.Epic]: {
    large: ProjectsLarge,
    medium: ProjectsMedium,
    small: ProjectsSmall,
  },
  [ValidIssueTypes.Project]: {
    large: ProjectsLarge,
    medium: ProjectsMedium,
    small: ProjectsSmall,
  },
  [ValidIssueTypes.Team]: {
    large: TeamLarge,
    medium: TeamMedium,
    small: TeamSmall,
  },
  [ValidIssueTypes.SubTask]: {
    large: SubTaskLarge,
    medium: SubTaskMedium,
    small: SubTaskSmall,
  },
  [ValidIssueTypes.Task]: {
    large: TaskLarge,
    medium: TaskMedium,
    small: TaskSmall,
  },
  [ValidIssueTypes.QuickWins]: {
    large: QuickWinsLarge,
    medium: QuickWinsMedium,
    small: QuickWinsSmall,
  },
  [ValidIssueTypes.Investigation]: {
    large: InvestigationLarge,
    medium: InvestigationMedium,
    small: InvestigationSmall,
  },
  [ValidIssueTypes.Suggestion]: {
    large: SuggestionLarge,
    medium: SuggestionMedium,
    small: SuggestionSmall,
  },
  [ValidIssueTypes.Bug]: {
    large: BugLarge,
    medium: BugMedium,
    small: BugSmall,
  },
  [ValidIssueTypes.Story]: {
    large: StoryLarge,
    medium: StoryMedium,
    small: StorySmall,
  },
  [ValidIssueTypes.SupportRequest]: {
    large: SupportRequestLarge,
    medium: SupportRequestMedium,
    small: SupportRequestSmall,
  },
};

export { ReactComponent as ChevronDown } from "../../assets/icons/v3/medium/chevron-down.svg";
export { ReactComponent as ChevronUp } from "../../assets/icons/v3/medium/chevron-up.svg";
export { ReactComponent as ChevronRight } from "../../assets/icons/v3/medium/chevron-right.svg";
export { ReactComponent as InfoFilled } from "../../assets/icons/v3/medium/info-filled-blue.svg";
export { ReactComponent as MeetBallsVertical } from "../../assets/icons/v3/medium/meatballs-vertical.svg";
export { ReactComponent as FavoriteOffLarge } from "../../assets/icons/v3/large/Favorite-off.svg";
export { ReactComponent as FavoriteOffMedium } from "../../assets/icons/v3/medium/Favorite-off.svg";
export { ReactComponent as FavoriteOffSmall } from "../../assets/icons/v3/small/Favorite-off.svg";
export { ReactComponent as InfoFilledWarning } from "../../assets/icons/v3/medium/info-filled-red.svg";
export { ReactComponent as EmptyStateIcon } from "../../assets/icons/v3/large/Empty-state.svg";
export { ReactComponent as CloseButton } from "../../assets/icons/v3/medium/Close-button.svg";
export { ReactComponent as AIConfidenceHigh } from "../../assets/icons/v3/medium/AI-confidence-high.svg";
export { ReactComponent as AIConfidenceMedium } from "../../assets/icons/v3/medium/AI-confidence-medium.svg";
export { ReactComponent as AIConfidenceLow } from "../../assets/icons/v3/medium/AI-confidence-low.svg";
