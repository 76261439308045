export const spacing = {
  unit: 4,
  values: {
    none: 0,
    small: 2,
    medium: 8,
    large: 12,
    xlarge: 16,
    xxlarge: 24,
  },
};