import { useHistory } from "react-router-dom";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { getFilteredItems } from "src/v3/dataContainers/filterContainer";
import { aggregateItemsByStatusField } from "src/v3/utils/aggregateByStatus";
import { StatusCategory } from "src/v3/utils/aggregateByWorkflowStatus";

export function shouldSkipLoading(
  currentViewData: any,
  isLoadingBlocks: boolean,
  itemKeys: string
): boolean {
  return !currentViewData || isLoadingBlocks || !itemKeys;
}

export function buildBreadcrumbPaths(
  itemKeys: string,
  currentViewData: any,
  history: ReturnType<typeof useHistory>,
  labels: [
    {
      itemKey: string;
      label: string;
    },
  ]
): { label: string; url: string; navigate: () => void }[] {
  const itemKeyList = itemKeys?.split("/");

  return itemKeyList?.map((key, index) => {
    const itemKeyPath = itemKeyList.slice(0, index + 1).join("/");
    const url = `/v2/roadmap/${currentViewData.key}/details/item/${itemKeyPath}`;
    const label = labels?.find(({ itemKey }) => itemKey === key)?.label || key;
    const breadcrumbLabels = labels.slice(0, index + 1);

    return {
      label,
      url,
      navigate: () =>
        history.push({
          pathname: url,
          search: location.search,
          state: { breadcrumbLabels },
        }),
    };
  });
}

export function closeDrawer(history: ReturnType<typeof useHistory>): void {
  const searchParams = new URLSearchParams(window.location.search);
  const currentPath = location.pathname;
  const itemIndex = currentPath.lastIndexOf("/details/item/");
  const pathName =
    itemIndex !== -1 ? currentPath.substring(0, itemIndex) : currentPath;

  history.push({
    pathname: `${pathName}`,
    search: `?${searchParams}`,
  });
}

/**
 * Retrieves the children of a given `BlockItem` and aggregates them by status field.
 * First by ProjectStatusField (Velma status) if it exists, otherwise by WorkflowStatusField (Jira status)
 *
 * @param item - The `BlockItem` to retrieve the children from.
 * @returns An array of items aggregated by status field.
 */
export const getAggregatedItemsByStatus = ({
  items,
}: {
  items: BlockItem[];
}) => {
  if (items?.length === 0) return [];

  const statusField =
    items[0]?.getProjectStatusField() || items[0]?.getWorkflowStatusField();

  return aggregateItemsByStatusField({
    items,
    statusField,
  });
};

export const getInProgressItems = ({
  items,
}: {
  items: BlockItem[];
}): BlockItem[] => {
  if (!items?.length) return [];

  const excludedStatuses = [
    "block",
    "pause",
    "hold",
    "defer",
    "qa passed",
    "qa failed",
    "deploy",
    "accepted",
    "done",
    "closed",
  ];

  const filters = [
    [
      {
        type: "not_included_in",
        values: excludedStatuses,
        fieldKey: "Status",
        config: { consider_empty_field_value: false, objectKey: "name" },
      },
      items[0].isTask()
        ? {
            type: "by_status_category",
            values: [StatusCategory.InProgress],
            fieldKey: "Status",
          }
        : {
            type: "by_allocation",
            config: { min_allocation_value: 0.01 },
          },
    ],
  ];

  return getFilteredItems({
    filters,
    items,
  });
};

export const getRecentlyCompletedItems = ({
  items,
}: {
  items: BlockItem[];
}): BlockItem[] => {
  if (items?.length === 0) return [];
  return getFilteredItems({
    filters: [
      [
        {
          type: "by_status_category",
          values: [StatusCategory.Done],
          fieldKey: "Status",
          config: {
            categoryChangeDate: {
              days: 14,
              period: "before",
            },
          },
        },
      ],
    ],
    items,
  });
};

export const findEverythingElse = ({
  items,
  excludedItems,
}: {
  items: BlockItem[];
  excludedItems: BlockItem[];
}) => {
  if (items?.length === 0) return [];
  return items.filter(
    (child) =>
      !excludedItems?.some(
        (inProgressChild) => child.getKey() === inProgressChild.getKey()
      )
  );
};
