import {
  bigRockLevelRowBackgroundColor,
  deliverableLevelRowBackgroundColor,
  projectLevelRowBackgroundColor,
  taskRowBackgroundColor,
} from "src/v2/helpers/colors";

export const eachLevelTablePaddingLeft = 16;

export const getCurrentSchemaPosition = (viewSchema, currentItemsType) => {
  const currentLevelSchema = viewSchema.find(
    (schema) => schema.view_block_type === currentItemsType
  );
  return currentLevelSchema.desc_order;
};

export const getPaddingLeftForLevel = (currentSchemaPosition) => {
  const paddingLeftForCurrentLevel =
    eachLevelTablePaddingLeft * currentSchemaPosition;
  return paddingLeftForCurrentLevel;
};

export const getCurrentRowBackgroundColor = (type) => {
  const colors = {
    big_rock: `${bigRockLevelRowBackgroundColor}`,
    deliverable: `${deliverableLevelRowBackgroundColor}`,
    task: `${taskRowBackgroundColor}`,
    project: `${projectLevelRowBackgroundColor}`,
  };
  return colors[type];
};
