import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import { useEffect, useState } from "react";
import { DateFilterType } from "src/v2/domain/enum/DateFilterType";
import {
  borderColor,
  primaryTextColor,
  secondaryTextColor,
} from "src/v2/helpers/colors";
import { useSelectFilterOptionHook } from "src/v2/hooks/filter/useSelectFilterOptionHook";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";

export enum LastUpdatedInDaysOption {
  GREATER_THAN = "Greater than",
  LESS_THAN = "Less than",
}

export const LastUpdateInDaysFilterOption = ({ field }) => {
  const { lastUpdatedInDays, setSelectedFiltersBeforeApply } =
    useFilterContext();
  const { handleSelectDateOption } = useSelectFilterOptionHook();

  const [selectedOption, setSelectedOption] = useState("");
  const [days, setDays] = useState(null);

  useEffect(() => {
    setSelectedOption(lastUpdatedInDays.type);
    setDays(lastUpdatedInDays.daysAgo ?? 0);
  }, [lastUpdatedInDays]);

  function handleSelect({ selectedDays, selectedOption }) {
    handleSelectDateOption({
      field,
      selectedDates: {
        daysAgo: Number(selectedDays),
        type: selectedOption,
        dateFilterType: DateFilterType.LAST_UPDATE_IN_DAYS,
      },
      setSelectedFilterCallback: (newValue) => {
        setSelectedFiltersBeforeApply([...newValue]);
      },
    });

    setDays(selectedDays);
    setSelectedOption(selectedOption);
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        border: `1px solid ${borderColor}`,
        padding: "8px",
        marginBottom: "16px",
        marginTop: "16px",
      }}
    >
      <Typography
        style={{
          color: `${primaryTextColor}`,
          marginBottom: "16px",
          marginTop: "8px",
        }}
      >
        Last updated in days
      </Typography>
      <Grid
        alignContent="center"
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        style={{ gap: "8px" }}
      >
        <FormControl size="small" variant="outlined" style={{ width: "136px" }}>
          <Select
            value={selectedOption}
            onChange={(e) =>
              handleSelect({
                selectedOption: e.target.value,
                selectedDays: days,
              })
            }
          >
            {[
              LastUpdatedInDaysOption.GREATER_THAN,
              LastUpdatedInDaysOption.LESS_THAN,
            ].map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <TextField
            size="small"
            style={{ width: "64px" }}
            id="outlined-basic"
            variant="outlined"
            value={days}
            onInput={(e) =>
              handleSelect({ selectedDays: e.target.value, selectedOption })
            }
          />
        </FormControl>
        <Grid>
          <Typography style={{ color: `${secondaryTextColor}` }}>
            days
          </Typography>
          <Typography style={{ color: `${secondaryTextColor}` }}>
            ago
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
