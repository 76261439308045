import React from "react";
import {
  ItemRowHeader,
  ItemRowRoot,
  ItemRowFooter,
  Info,
  ItemRowMeddle,
  SideInfosRoot,
  ItemNameRoot,
  StatusActionRoot,
  ItemSummaryInfosRoot,
} from "./styles";
import {
  DateSideInfoProps,
  ItemBandwidthSideInfoProps,
  ItemRowProps,
} from "./types";
import { IssueTypeIcon } from "../IssueTypeIcon";
import { ItemName } from "../commomStyles/ItemName";
import { StatusChipBuilder } from "../StatusChip/builder";
import ActionMenu from "../ActionMenu";
import { useItemRow } from "./useItemRow";
import { formatDate, truncateStringInTheEnd } from "src/utils/stringUtils";
import { ItemSummary } from "../commomStyles/ItemSummary";

export const DateSideInfo = ({ date, label }: DateSideInfoProps) => {
  const formattedDate = date ? formatDate(date) : " -- ";
  return (
    <>
      {label}: {formattedDate}
    </>
  );
};

export const ItemBandwidthSideInfo = ({
  bandwidth,
}: ItemBandwidthSideInfoProps) => {
  return <>{bandwidth}% bandwidth used</>;
};

export const ItemRow = React.memo((props: ItemRowProps) => {
  const {
    statusField,
    issueType,
    displayName,
    sideInfos,
    footerInfos,
    statusChipType,
    actions,
    onNameClick,
  } = props;

  if (!displayName) {
    throw new Error("ItemRow: displayName is required");
  }

  const {
    summaryDisplay,
    isSummaryLong,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  } = useItemRow(props);

  return (
    <ItemRowRoot>
      <ItemRowHeader>
        <ItemNameRoot>
          <IssueTypeIcon issueType={issueType} size="small" />
          <ItemName onClick={onNameClick}>
            {truncateStringInTheEnd(displayName, 50)}
          </ItemName>
        </ItemNameRoot>
        <StatusActionRoot>
          {statusField && statusChipType && (
            <StatusChipBuilder
              type={statusChipType}
              size={"small"}
              status={statusField}
            />
          )}
          {actions?.length > 0 && <ActionMenu actions={actions} />}
        </StatusActionRoot>
      </ItemRowHeader>

      <ItemRowMeddle>
        <ItemSummaryInfosRoot>
          <ItemSummary
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
          >
            {summaryDisplay && (
              <div
                onMouseUp={isSummaryLong ? handleMouseUp : null}
                dangerouslySetInnerHTML={{
                  __html: summaryDisplay,
                }}
              />
            )}
          </ItemSummary>
          {footerInfos?.length > 0 && (
            <ItemRowFooter>
              {footerInfos.map((info, index) => (
                <Info key={index}>{info}</Info>
              ))}
            </ItemRowFooter>
          )}
        </ItemSummaryInfosRoot>

        {sideInfos?.length > 0 && (
          <SideInfosRoot>
            {sideInfos.map((info, index) => (
              <Info key={index}>{info}</Info>
            ))}
          </SideInfosRoot>
        )}
      </ItemRowMeddle>
    </ItemRowRoot>
  );
});
