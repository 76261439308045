import { useContext, createContext, useMemo, useState, useEffect } from "react";
import { WidgetConfigs } from "./WidgetConfigs";
import { getProjectStatusConfig } from "./WidgetProjectStatusConfig";
import { buildDefaultSortConfig, getSortConfig } from "./WidgetSortConfig";
import { useClientConfigContext } from "src/providers/ClientConfigContext";
import { updateWidgetConfig } from "src/services/v2/widget";

const WidgetContext = createContext<{
  getConfigValueByName: (_name) => any;
  getConfigByName: (_name) => any;
  handleSaveWidgetConfig: (_config) => void;
  _configs: any;
  _section: any;
}>({
  getConfigValueByName: (_name) => {},
  getConfigByName: (_name) => {},
  handleSaveWidgetConfig: (_config) => {},
  _configs: {},
  _section: {},
});

function useWidgetContext() {
  const context = useContext(WidgetContext);
  return context;
}

type GetWidgetConfigFunction = ({ _configs }: any) => any;

type WidgetConfig = {
  [key in WidgetConfigs]?: GetWidgetConfigFunction;
};

function WidgetProvider({ children, configs, items, section }: any) {
  const [_section, setSection] = useState(section);
  const [_configs, setConfigs] = useState(configs);

  const { defaultSortConfig, rawPriorities } = useClientConfigContext();

  const widgetConfigs: WidgetConfig = {
    [WidgetConfigs.projectStatusInsights]: getProjectStatusConfig,
    [WidgetConfigs.sortConfig]: ({ _configs }) => {
      return (
        getSortConfig({ configs: _configs }) ??
        buildDefaultSortConfig({ defaultSortConfig, rawPriorities })
      );
    },
  };

  useEffect(() => {
    setConfigs(configs);
  }, [configs, items]);

  function getConfigValueByName(_name) {
    const getWidgetConfigFunction = widgetConfigs[_name];
    if (getWidgetConfigFunction) {
      return getWidgetConfigFunction({ _configs });
    }

    return _configs?.find(({ name }) => name === _name)?.value;
  }

  function getConfigByName(_name) {
    const getWidgetConfigFunction = widgetConfigs[_name];
    if (getWidgetConfigFunction) {
      return getWidgetConfigFunction({ _configs });
    }

    return _configs?.find(({ name }) => name === _name) ?? {};
  }

  async function handleSaveWidgetConfig(newConfig) {
    updateWidgetConfig(newConfig).then((data) => {
      handleUpdateWidgetConfig(data);
    });
  }

  function handleUpdateWidgetConfig(newConfig) {
    const newConfigs = _configs.map((config) =>
      config.name === newConfig.name ? newConfig : config
    );
    setConfigs(newConfigs);
  }

  const value = useMemo(
    () => ({
      _configs,
      _section,
      getConfigValueByName,
      getConfigByName,
      handleSaveWidgetConfig,
    }),
    [_configs, section]
  );

  return (
    <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
  );
}

export { WidgetContext, WidgetProvider, useWidgetContext };
