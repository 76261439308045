import { Box, Dialog, Grid, IconButton, Typography } from "@material-ui/core";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { primaryTextColor } from "src/v2/helpers/colors";
import jiraFormatting from "jira2md";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { CustomFieldBase } from "./CustomFieldBase";
import { DisplayConfigSection } from "../../enum/DisplayConfigSection";

// eslint-disable-next-line no-extend-native
String.prototype.hashCode = function () {
  let hash = 0;
  let i;
  let chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export class ExpandableTextField extends CustomFieldBase {
  public hash: string;

  constructor(field) {
    super(field);
    this.value = Array.isArray(field.value) ? field.value : [field.value ?? ""];
    this.hash = this.value[0].hashCode();
  }

  getComponentForGrid({ fieldsState, setFieldsState }) {
    const isExpandedText = fieldsState[this.hash]?.isExpandedText;

    const formattedText = jiraFormatting.jira_to_html(this.value[0]);
    const canExpand = this.value[0].length > 46;
    const customStyle = this.displayConfig.find(
      (config) => config.section === DisplayConfigSection.grid
    )?.style;

    return (
      this.value[0] && (
        <>
          <Dialog
            open={isExpandedText}
            maxWidth="md"
            onClose={() =>
              setFieldsState((prevState) => ({
                ...prevState,
                [this.hash]: {
                  isExpandedText: false,
                },
              }))
            }
          >
            <Box
              p={8}
              style={{
                lineHeight: "28px",
                letterSpacing: ".04em",
                color: "#222",
              }}
            >
              <Typography
                dangerouslySetInnerHTML={{
                  __html: formattedText,
                }}
              />
            </Box>
          </Dialog>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            onClick={() => {
              setFieldsState((prevState) => ({
                ...prevState,
                [this.hash]: {
                  isExpandedText: canExpand && !isExpandedText,
                },
              }));
            }}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              minHeight: "100px",
              color: `${primaryTextColor}`,
              position: "relative",
              cursor: canExpand ? "zoom-in" : "text",
              ...customStyle,
            }}
          >
            {canExpand && (
              <BootstrapTooltipWithoutPortal title="See more" placement="right">
                <IconButton
                  style={{
                    position: "absolute",
                    width: "20px",
                    height: "20px",
                    top: -3,
                    right: 0,
                  }}
                  onClick={() => {
                    setFieldsState((prevState) => ({
                      ...prevState,
                      expandableText: {
                        isExpandedText: !isExpandedText,
                      },
                    }));
                  }}
                >
                  <ZoomOutMapIcon />
                </IconButton>
              </BootstrapTooltipWithoutPortal>
            )}
            <Typography
              data-test-id="expandable-text-content"
              style={{
                height: "142px",
                width: "420px",
                maxWidth: "440px",
              }}
              align="left"
              dangerouslySetInnerHTML={{
                __html: formattedText,
              }}
            />
          </Grid>
        </>
      )
    );
  }
}
