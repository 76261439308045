import { Box, Typography } from "@material-ui/core";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class AssigneeField extends CustomFieldBase {
  valueToGrid: any[];

  constructor(field) {
    super(field);
    this.valueToGrid = field.value
      ? field.value.map(
          ({ display_name, displayName, avatarUrls, self, accountId }) => ({
            // Assignee field returns display_name, but other fields return displayName
            displayName: display_name || displayName,
            avatarUrls,
            self,
            accountId,
          })
        )
      : [];
    this.value =
      field.value?.length > 0
        ? field.value.map(({ display_name: displayName }) => displayName)
        : [];
  }

  getAssignee() {
    if (this.valueToGrid?.length > 0) {
      return this.valueToGrid[0];
    }
    return null;
  }

  getComponentForGrid(_) {
    return (
      <div style={{ maxWidth: 100 }}>
        {this.valueToGrid.map(({ displayName, avatarUrls }) => (
          <BootstrapTooltipWithoutPortal title={displayName}>
            <Box
              data-testid="label-field"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginBottom: 8,
                maxWidth: 100,
                textAlign: "center",
                borderRadius: 4,
                color: primaryTextColor,
                zIndex: -1,
              }}
            >
              <img
                src={avatarUrls?.["48x48"]}
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  marginRight: 8,
                  verticalAlign: "middle",
                  marginLeft: 16,
                }}
              />
              <Typography style={{ margin: "4px 16px" }}>
                {displayName}
              </Typography>
            </Box>
          </BootstrapTooltipWithoutPortal>
        ))}
      </div>
    );
  }
}
