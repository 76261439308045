import { useMemo } from "react";
import { useWidgetContext } from "src/v3/providers/WidgetContext";
import WidgetConfig, { WidgetConfigType } from "src/v3/entities/WidgetConfig";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { getFilteredItems } from "src/v3/dataContainers/filterContainer";
import { getGroupedItems } from "src/v3/dataContainers/groupContainer";
import { isDevMode } from "src/services/sessionService";

export const useProductList = ({ items }: { items: BlockItem[] }) => {
  const { getConfigsByType } = useWidgetContext();
  const groups = getConfigsByType(WidgetConfigType.group) as WidgetConfig[];

  if (isDevMode()) {
    console.debug("useProductList - input - items", {
      itemsLength: items.length,
      groups,
    });
  }

  const filteredItems = useMemo(() => {
    return groups
      .map(({ filters, groupBy, name }) => {
        const seenKeys = new Set<string>();
        const filteredItems = getFilteredItems({ items, filters });
        const groupedItems = getGroupedItems({
          items: filteredItems,
          groupBy,
        });

        const uniqueGroupedItems = groupedItems.map(({ items, fieldValue }) => {
          const uniqueItems = items.filter((item) => {
            const itemKey = item.getKey();
            if (seenKeys.has(itemKey)) {
              return false;
            }
            seenKeys.add(itemKey);
            return true;
          });

          return {
            fieldValue,
            items: uniqueItems,
          };
        });

        return {
          name,
          groupedItems: uniqueGroupedItems,
          filteredItems,
        };
      })
      .filter(
        ({ groupedItems, filteredItems }) =>
          groupedItems?.length > 0 || filteredItems?.length > 0
      );
  }, [items, groups]);

  if (isDevMode()) {
    console.debug("useProductList - output - items", {
      items: filteredItems.length,
      groups,
    });
  }

  return { filteredItems, groups };
};
