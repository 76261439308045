export function hexToRGBA(hex, opacity) {
  // Remove the # symbol from the hexadecimal color string
  var hexValue = hex.replace("#", "");

  // Convert the hex values to decimal
  var r = parseInt(hexValue.substring(0, 2), 16);
  var g = parseInt(hexValue.substring(2, 4), 16);
  var b = parseInt(hexValue.substring(4, 6), 16);

  // Create the RGBA color string
  var rgba = "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";

  return rgba;
}
