import { ResolveChallengeModal } from "src/v3/components/Modals/ResolveChallenge";
import { IgnoreChallengeActionProps } from "./types";
import { useDialog } from "src/v3/components/Dialogs/Base/useDialog";
import { CustomMenuItem, ActionLabel } from "../../style";

export const ResolveChallengeAction = ({
  challenge,
  item,
}: IgnoreChallengeActionProps) => {
  const { open, toggle } = useDialog();
  return (
    <CustomMenuItem onClick={toggle}>
      <ActionLabel>Resolve</ActionLabel>
      <ResolveChallengeModal
        challenge={challenge}
        item={item}
        open={open}
        handleOpen={toggle}
      />
    </CustomMenuItem>
  );
};
