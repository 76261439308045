import { CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import BaseButton from "src/components/Buttons/BaseButton";
import DefaultCustomTextField from "src/components/TextField";
import { useUserPreferenceContext } from "src/v2/providers/userPreference/UserPreferenceContext";

export const FilterMenuSaveFilter = ({ applyFilters }) => {
  const [openSaveInput, setOpenSaveInput] = useState(false);
  const [saving, setSaving] = useState(false);
  const { saveFilter } = useUserPreferenceContext();
  const [filterName, setFilterName] = useState("");
  const handleChange = (e) => {
    setFilterName(e.target.value);
  };
  const handleSaveFilter = () => {
    setSaving(true);
    applyFilters({ closeFilter: false });
    const url = window.location.href;
    saveFilter({
      url,
      name: filterName,
      callback: (success) => {
        setSaving(false);
        if (success) {
          setFilterName("");
          setOpenSaveInput(false);
        }
      },
    });
  };

  if (!openSaveInput) {
    return (
      <Grid
        container
        justifyContent="space-between"
        direction="column"
        style={{ marginTop: 8 }}
      >
        <BaseButton
          id="save-filter-button" 
          onClick={() => setOpenSaveInput(true)
        }>
          <Typography>Save Filter</Typography>
        </BaseButton>
      </Grid>
    );
  }
  const tooltipText =
    filterName.length === 0 ? "Please add a name to the filter." : "";
  return (
    <>
      <Divider style={{ marginTop: 16 }} />
      <Grid
        container
        justifyContent="space-between"
        direction="column"
        style={{ marginTop: 16 }}
      >
        <DefaultCustomTextField
          variant="outlined"
          size="small"
          margin="dense"
          style={{ flexGrow: 1 }}
          value={filterName || ""}
          type="text"
          name="name"
          data-testid="filter-name"
          onChange={handleChange}
          label="Filter Name"
        />

        <Grid
          container
          justifyContent="space-between"
          direction="row"
          style={{ marginTop: 8 }}
        >
          <BaseButton
            secondary
            style={{
              textTransform: "capitalize",
              width: "48%",
            }}
            onClick={() => setOpenSaveInput(false)}
          >
            <Typography>Cancel</Typography>
          </BaseButton>
          <BaseButton
            style={{
              textTransform: "capitalize",
              width: "48%",
            }}
            tooltipText={tooltipText}
            disabled={filterName.length === 0}
            onClick={handleSaveFilter}
          >
            {(saving && (
              <CircularProgress style={{ color: "white" }} size={20} />
            )) || <Typography>Save Filter</Typography>}
          </BaseButton>
        </Grid>
      </Grid>
    </>
  );
};
