import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useAbsoluteLoaderStyles = makeStyles((theme) => ({
  root: {
    top: `50%`,
    left: `50%`,
    position: "absolute",
    display: "flex",
    transform: `translate(-50%, -50%)`,
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function Loader() {
  const classes = useAbsoluteLoaderStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}

export function CustomSizeLoader({ size }: { size: string }) {
  const useStyle = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyle();
  return <CircularProgress className={classes.root} size={size} />;
}
