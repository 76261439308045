import styled from "styled-components";
import { AlignedRow, Column } from "../GlobalComponents";

export const Logo = styled.h1`
  font-size: 3.8em;
  margin-right: 0.38em;
  font-family: PTSerif-Bold;
  color: var(--color-dark-blue);
`;

export const NavbarItems = styled(Column)`
  padding: 4em 15%;
  height: 100%;
  width: calc(100% - 3px);
  overflow-x: hidden;
`;

export const Divider = styled.div`
  width: 3px;
  height: 100%;
  background-color: var(--color-lighter-grey);
`;

export const CompanyTitle = styled.p`
  font-family: Quicksand-Bold;
  margin-top: 8px;
  margin-left: 0.7em;
  font-size: 1.6em;
  color: var(--color-dark-blue);
`;

interface MenuItemProps {
  isSelected: boolean;
}

export const MenuItemContainer = styled(AlignedRow)<MenuItemProps>`
  padding: 1em 0;
  padding-left: 1.3em;
  cursor: pointer;
  transition: all 0.3s ease-out;
  & svg {
    margin-right: 0.4em;
  }
  color: var(--color-semi-light-grey);

  &:hover {
    background-color: #f3f3f3;
    scale: 1.04;
    color: var(--color-blue) !important;
  }
  ${({ isSelected }) =>
    isSelected
      ? `
    fill: var(--color-blue);
    stroke: var(--color-blue);
    background-color: var(--color-lighter-blue);
      &:hover {
        background-color: #d0ddeb;
      }
    color: var(--color-blue) !important;

  `
      : `
    fill: var(--color-semi-light-grey);
    stroke: var(--color-semi-light-grey);
  `}
`;

export const MenuItemText = styled.a`
  font-family: Overpass;
  font-size: 1.1em;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
