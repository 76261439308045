import { getViewDataToExport } from "src/services/v2/budgetForecastingService";
import fileDownload from "js-file-download";
import { BlockItem } from "../block/BlockItem";
import { ViewsKeys as ViewKeys } from "../../enum/ViewsKeys";

function getProjectKeysOnBranches(blockItems: BlockItem[]) {
  let allKeysOnLevel = [];
  blockItems.forEach((viewBlock) => {
    if (viewBlock.isProject()) {
      const keyField = viewBlock.getFieldByFieldKey("key");
      if (keyField) {
        allKeysOnLevel.push(keyField.value[0]);
      }
    } else {
      const childKeys = getProjectKeysOnBranches(viewBlock.children);
      allKeysOnLevel = [...allKeysOnLevel, ...childKeys];
    }
  });
  return allKeysOnLevel;
}

function getPayloadByViewKey(filteredBlocks, viewKey) {
  if (viewKey === ViewKeys.budgetForecasting) {
    return { initiatives_keys: getProjectKeysOnBranches(filteredBlocks) };
  }
}

function getFileNameByViewKey(viewData) {
  if (viewData.key === ViewKeys.budgetForecasting) {
    const now = new Date();
    const yesterday = new Date(now.setDate(now.getDate() - 1));
    const yesterdayString = yesterday.toISOString().split("T")[0];
    return `velma-${viewData.key}-until-${yesterdayString}.csv`;
  }
  return `velma-${viewData.key}.csv`;
}

export async function execute(filteredBlocks, viewData) {
  const extraPayload = getPayloadByViewKey(filteredBlocks, viewData.key);
  const csvData = await getViewDataToExport({
    ...extraPayload,
    viewData,
  });
  fileDownload(csvData, getFileNameByViewKey(viewData));
}
