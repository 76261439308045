import CustomDialog from "src/v2/components/CustomDialog";
import { BlockItem } from "../../block/BlockItem";
import { Fragment } from "react";
import { Divider, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { formatDate, truncateStringInTheMiddle } from "src/utils/stringUtils";
import { SummaryOfWorkEdit } from "src/v3/components/Dialogs/SummaryOfWorkEdit";

export const AISummaryDetailsModal = ({
  item,
  override,
  fieldValue,
  open,
  onClose,
}: {
  item: BlockItem;
  override: any;
  fieldValue: string;
  open: boolean;
  onClose: (e) => void;
}) => {
  const hasOverride = override?.value?.context;
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={`(${item.getKey()}) ${truncateStringInTheMiddle(
        item.getNameField().displayName,
        100
      )} - Summary of Work`}
      customContentStyle={{
        width: "900px",
      }}
    >
      {hasOverride && (
        <Fragment>
          <Typography
            style={{
              color: primaryTextColor,
              fontSize: "16px",
            }}
          >
            Team Additional Details
          </Typography>
          <Typography
            style={{
              color: "grey",
              fontSize: "12px",
            }}
          >
            Last updated by {override?.value?.author?.name} on{" "}
            {formatDate(override.value?.created_at)}
          </Typography>
          <div
            className="rich-text-section"
            style={{
              padding: "16px",
            }}
            dangerouslySetInnerHTML={{
              __html: override?.value?.context ?? "",
            }}
          />
        </Fragment>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <SummaryOfWorkEdit item={item} open={false} handleOpen={() => {}} />
      </div>
      <Divider style={{ margin: "16px 0" }} />
      {fieldValue !== "" && (
        <div>
          <Typography
            gutterBottom
            variant="h5"
            style={{
              color: primaryTextColor,
            }}
          >
            Velma Summary of Work
          </Typography>
          <div
            className="rich-text-section"
            style={{
              padding: "16px",
              fontSize: 18,
              lineHeight: "28px",
              letterSpacing: "0.4px",
            }}
            dangerouslySetInnerHTML={{
              __html: fieldValue,
            }}
          />
        </div>
      )}
    </CustomDialog>
  );
};
