import { WidgetTitle } from "../../Widget/Widget";
import "../RichTextSection.css";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import AiSummaryRender from "./AiSummary";

const TeamAISummarySection = () => {
  const { teamSummary } = useGenericViewContext();

  if (
    teamSummary?.data?.length === 0 ||
    teamSummary?.data?.every((team) => !team.ai_summary)
  ) {
    return null;
  }

  return (
    <div>
      <WidgetTitle
        title={teamSummary?.title}
        tooltipText={undefined}
        titleStyles={undefined}
      />
      <div className="root">
        {teamSummary?.data?.map(({ name, ai_summary }, index) => {
          return (
            ai_summary && (
              <div key={name}>
                <h3 className={index === 0 ? "" : "mt-1"}>{name}</h3>
                <AiSummaryRender aiSummary={ai_summary} name={null} />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default TeamAISummarySection;
