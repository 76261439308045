import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useHistory } from "react-router-dom";
import { useLayout } from "src/providers/LayoutContext";
import { useClientConfigContext } from "src/providers/ClientConfigContext";

import { SectionViewType } from "./types";
import LastUpdatedFilteredBy from "./LastUpdatedFilteredBy";
import HeaderOptionsMenu from "./HeaderOptionsMenu";
import HeaderSavedFilters from "./HeaderSavedFilters";

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    "&:hover": {
      color: theme.palette.primary.secondary,
      scale: 1.1,
    },
    color: theme.palette.primary.main,
    width: 42,
    height: 42,
  },
}));

const FilterSection: React.FC<SectionViewType> = ({ section }) => {
  const classes = useStyles();

  const { velmaInfoUrl } = useClientConfigContext();
  const { setHeaderHeight, navBarSpace } = useLayout();
  const history = useHistory();
  const ref = useRef(null);
  const { options } = section;
  const _displayOptions = options?.find(
    (option) => option.name === "displayOptions"
  )?.value;
  const _displayFilterButtons = options?.find(
    (option) => option.name === "displayFilterButtons"
  )?.value;
  const displayOptions = _displayOptions !== undefined ? _displayOptions : true;
  const displayFilterButtons =
    _displayFilterButtons !== undefined ? _displayFilterButtons : true;

  useEffect(() => {
    if (ref.current) {
      const height = ref.current.offsetHeight;
      setHeaderHeight(height);
      const observer = new ResizeObserver(() => {
        const { height: newHeight } = ref.current.getBoundingClientRect();
        setHeaderHeight(newHeight);
      });

      observer.observe(ref.current);

      return () => observer.disconnect();
    }
    return null;
  }, []);

  useEffect(
    () => () => {
      if (history.action) {
        history.replace(
          `${history.location.pathname}${history.location.search}`
        );
      }
    },
    [history]
  );
  const infoUrl =
    velmaInfoUrl || "https://www.heyvelma.com/knowledge-base/velma-start";
  return (
    <div
      id="filter-section"
      ref={ref}
      data-test-id="filter-section"
      style={{
        display: "flex",
        marginLeft: parseInt(navBarSpace, 10) + 16,
        justifyContent: "space-between",
        alignItems: "center",
        width: "inherit",
      }}
    >
      <div style={{ width: "60%" }}>
        <LastUpdatedFilteredBy section={section} />
      </div>
      <Box
        style={{
          display: "flex",
          maxHeight: 64,
          width: "fit-content",
          alignItems: "center",
        }}
      >
        {displayOptions && <HeaderOptionsMenu />}
        {displayFilterButtons && (
          <Grid container alignItems="center">
            <HeaderSavedFilters />
            <Box
              style={{
                width: 48,
                marginLeft: 16,
              }}
            >
              <a
                href={infoUrl}
                target="_blank"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                rel="noreferrer"
              >
                <InfoOutlinedIcon id="info_icon" className={classes.infoIcon} />
              </a>
            </Box>
          </Grid>
        )}
      </Box>
    </div>
  );
};
export default FilterSection;
