import { DoughnutChartData } from "../Section/ChartFactory/DoughnutChart/DoughnutChartBuilder";
import { SolarwindsDoughnutChart } from "./SolarwindsDoughnutChart";

export const ManualUpdateDoughnutChart = ({ title, data, info }) => {
  const chartConfig = new DoughnutChartData({
    dataSet: data,
    title: "",
    info: "",
  });
  return (
    <div style={{ textAlign: "center" }}>
      <div
        className="CompositionOfWork"
        style={{
          color: "#395E77",
          fontSize: 20,
          fontFamily: "Overpass",
          fontWeight: "700",
          wordWrap: "break-word",
        }}
      >
        {title}
      </div>
      <div
        className="OctoberReleaseOct5Nov11"
        style={{
          color: "black",
          fontSize: 16,
          fontFamily: "Roboto",
          fontWeight: "500",
          wordWrap: "break-word",
        }}
      >
        {info}
      </div>
      <div style={{ marginTop: 20 }}>
        <SolarwindsDoughnutChart {...chartConfig}></SolarwindsDoughnutChart>
      </div>
    </div>
  );
};
