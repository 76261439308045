import {
  makeStyles,
  Container,
  Grid,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Column } from "src/components/GlobalComponents";
import { GroupedLegends } from "src/components/GroupedLegends";
import IconWithLegend from "src/components/IconWithLegend";
import useClientStatusConfigContext from "src/providers/v2/Business/ClientStatusConfigContext";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { statusNotSetColor } from "src/v2/helpers/colors";
import { groupItemsByStatus } from "src/v2/utils/status";
import { useWidgetContext } from "../Widget/WidgetContext";
import LargeProgressBar from "../progressBar/LargeProgressBar";
import { ProgressBarByStatus } from "../progressBar/ProgressBarByStatus";
import {
  getProjectStatusProgress,
  aggregateByStatusCategory,
} from "./ProjectStatusProgressSection/utils";
import { useDataSection } from "./hooks/useDataSection";
import {
  getAllKeyValueFiltersFromURLWithoutExtraParams,
  buildRedirectURLWithListOfFilters,
} from "src/v2/utils/url";
import { FilterFieldsFactory } from "src/v2/domain/entities/filter/factory";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { WidgetConfigs } from "../Widget/WidgetConfigs";
import { EventTypes, useEventListener } from "src/providers/EventContext";

const useStyles = makeStyles({
  legendText: { marginLeft: 8, marginRight: 8 },
  linkedItem: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  textTotal: {
    fontSize: 14,
    color: "#6F6C99",
  },
  projectsComplete: {
    fontSize: 16,
    color: "#055A92",
    fontWeight: 600,
  },
  projectsCompleteValue: {
    fontSize: 16,
    color: "#055A92",
    fontWeight: 600,
  },
  textTotalValue: {
    fontSize: 16,
    color: "#6F6C99",
  },
});

const TotalItemsSection = ({ section, items, parentSections }) => {
  const { filters, groupBy, options } = section;

  const [progressStatusProgress, setProjectStatusProgress] = useState({
    total: 0,
    aggregatedValues: [],
  });
  const [taskCompletion, setTaskCompletion] = useState({
    total: 0,
    aggregatedValues: [],
  });

  const showProgressBarTitle =
    options?.find((option) => option.name === "show_progress_bar_title")
      ?.value ?? true;

  const showProgressBarLegends =
    options?.find((option) => option.name === "show_progress_bar_legend")
      ?.value ?? false;

  const childrenType = options.find(
    (option) => option.name === "second_level"
  )?.value;

  const { filteredItems } = useDataSection({
    filters,
    groupBy,
    items,
  });
  const { getConfigValueByName } = useWidgetContext();
  const projectStatusConfig = getConfigValueByName(
    WidgetConfigs.projectStatusInsights
  );
  const [itemsGroupedByStatus, setItemsGroupedByStatus] = useState({});
  const { statuses } = useClientStatusConfigContext();

  const projectStatusUpdate = useEventListener(
    EventTypes.PROJECT_STATUS_OVERRIDE,
    "TotalItemsSection"
  );

  useEffect(() => {
    const projectStatusAggregation = getProjectStatusProgress({
      items: filteredItems,
      config: projectStatusConfig,
    });
    const statusCategoryAggregation = aggregateByStatusCategory({
      items: filteredItems,
    });
    const groupedStatus = groupItemsByStatus(filteredItems);
    setProjectStatusProgress(projectStatusAggregation);
    setTaskCompletion(statusCategoryAggregation);
    setItemsGroupedByStatus(groupedStatus);
  }, [section, items, projectStatusUpdate]);

  const customStyle =
    options?.find((option) => option.name === "styles")?.value || {};

  return (
    <Container
      style={{
        padding: customStyle?.padding ?? 4,
        width: customStyle.width ?? 360,
        ...customStyle,
      }}
    >
      <Grid container direction="row" justifyContent="space-between">
        <Grid container justifyContent="space-between" xs={11}>
          <TotalItemsHeader
            section={section}
            filteredItems={filteredItems}
          ></TotalItemsHeader>
          {childrenType && (
            <div style={{ marginTop: 16, width: "100%" }}>
              <TotalItemsHeader
                level="second_level"
                section={section}
                filteredItems={filteredItems}
              ></TotalItemsHeader>
            </div>
          )}
        </Grid>
        <div style={{ marginTop: 2 }}>
          <IconWithLegend
            iconStyle={{
              width: 18,
            }}
            content={
              <GroupedLegends section={section} blocks={filteredItems} />
            }
          />
        </div>
      </Grid>
      <Divider style={{ marginTop: 16, marginBottom: 16 }}></Divider>
      <Column>
        <Box mb={2}>
          <LargeProgressBar
            backgroundColor={`${statusNotSetColor}`}
            percentages={progressStatusProgress.aggregatedValues}
            showProgressBarLegends={showProgressBarLegends}
            title={
              showProgressBarTitle &&
              (projectStatusConfig?.display || "Project Status")
            }
          />
        </Box>
        <Box
          mb={2}
          style={{
            height: "80px",
            marginTop:
              progressStatusProgress.aggregatedValues.length &&
              showProgressBarLegends
                ? 32
                : 16,
          }}
        >
          <ProgressBarByStatus
            itemsGroupedByStatus={itemsGroupedByStatus}
            style={{
              height: 25,
            }}
            statuses={statuses}
            title={"Status Progress"}
            showPercentages={false}
          ></ProgressBarByStatus>
        </Box>
      </Column>
    </Container>
  );
};

const TotalItemsHeader = ({
  section,
  level = "first_level",
  filteredItems,
}) => {
  const { options } = section;

  const issueType =
    options.find((option) => option.name === level)?.value ?? "deliverable";

  const getTotalItems = ({ items }) => {
    let totalItems = [];
    let completedItems = [];
    items.forEach((item: BlockItem) => {
      if (item.type?.value !== issueType) {
        const { total, completed } = getTotalItems({ items: item.children });
        totalItems = totalItems.concat(...total);
        completedItems = completedItems.concat(...completed);
      }
      if (item.isCompleted()) {
        completedItems.push(item.getKey());
      }
      totalItems.push(item.getKey());
    });
    return { total: totalItems, completed: completedItems };
  };

  const { total, completed } = getTotalItems({
    items: filteredItems,
  });

  const totalItemsDivider = total.length === 0 ? 1 : total.length;
  const { viewSchema } = useGenericViewContext();

  const typeLabel =
    viewSchema.find(({ view_block_type }) => view_block_type === issueType)
      ?.display_name ?? "Epic";

  const totalTitle = `Total ${typeLabel}s`;
  const totalSub = `${total.length} item${total.length === 1 ? "" : "s"}`;

  const completedTitle = `${typeLabel}s  Completed`;
  const completedSub = `${completed.length} / ${(
    (completed.length / totalItemsDivider) *
    100
  ).toFixed(1)}%`;

  const _filterFields = getAllKeyValueFiltersFromURLWithoutExtraParams();

  const totalKeys = FilterFieldsFactory.buildBaseFilterField({
    fieldKey: "key",
    values: total.map((key) => key),
  });

  const completedKeys = FilterFieldsFactory.buildBaseFilterField({
    fieldKey: "key",
    values: completed.map((key) => key),
  });

  const redirectView = options.find(
    (option) => option.name === "redirect_view"
  )?.value;

  const totalItemsURL = buildRedirectURLWithListOfFilters({
    filters: [..._filterFields, totalKeys],
    redirectView,
  });
  const completedItemsURL = buildRedirectURLWithListOfFilters({
    filters: [..._filterFields, completedKeys],
    redirectView,
  });

  return (
    <Grid direction="row" container>
      <TotalItemsText
        title={totalTitle}
        section={section}
        subtitle={totalSub}
        url={totalItemsURL}
        customStyle={{ width: "48%" }}
      ></TotalItemsText>
      <TotalItemsText
        title={completedTitle}
        section={section}
        subtitle={completedSub}
        url={completedItemsURL}
        customStyle={{ width: "52%" }}
      ></TotalItemsText>
    </Grid>
  );
};

const TotalItemsText = ({
  title,
  subtitle,
  section,
  url,
  customStyle = {},
}) => {
  const { options, parentSections } = section;
  const optionsStyles =
    options?.find((option) => option.name === "styles")?.value || {};
  const titleStyle = optionsStyles?.title || {};

  const classes = useStyles();
  return (
    <Grid style={{ ...customStyle }}>
      <Typography
        variant="h6"
        style={{
          color: "#055A92",
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "30.38px",
          ...titleStyle,
        }}
      >
        {title}
      </Typography>
      <Link to={url} target="_blank">
        <Typography
          variant="body2"
          color="textSecondary"
          className={parentSections ? classes.linkedItem : null}
        >
          {subtitle}
        </Typography>
      </Link>
    </Grid>
  );
};

export default TotalItemsSection;
