import { BlockItem } from "../block/BlockItem";

export default class EpicInfoBase {
  constructor(info) {
    this.type = info.type;
    this.key = info.key;
    this.config = info.config;
    this.enabled = info.enabled;
  }
  protected type: string;
  protected key: string;
  protected config: any;
  protected show: boolean;
  protected enabled: boolean;

  getComponent(_): JSX.Element {
    return <div></div>;
  }
  shouldDisplay({ item }: { item: BlockItem }): boolean {
    return false;
  }
}
