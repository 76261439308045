import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";

export class TargetDateField extends CustomFieldBase {
  private targetDate: string;
  private source: string;
  private default: boolean;
  private previous: {
    date: string;
    source: string;
    default: boolean;
    created_at: string;
  };
  private created_at: string;

  constructor(field) {
    super(field);

    this.value = field.value ?? {
      date: "",
      source: "",
      default: false,
      previous: {
        date: "",
        source: "",
        default: false,
        created_at: "",
      },
      created_at: "",
    };

    this.targetDate = this.value.date;
    this.source = this.value.source;
    this.default = this.value.default;
    this.previous = this.value.previous;
    this.created_at = this.value.created_at;
  }

  getTargetDate() {
    const override = this.getLastAddedOverride();
    return override?.value ?? this.targetDate ?? "";
  }

  getValue() {
    return this.value;
  }

  getValueForProperty(property) {
    return this.value[property];
  }

  getPreviousTargetDate() {
    return this.previous;
  }

  getSource() {
    return this.source;
  }

  getCreatedAt() {
    return this.created_at;
  }

  isDefault() {
    this.default;
  }

  hasOverride() {
    const override = this.getLastAddedOverride();
    return override?.value && override?.value !== "";
  }
}
