import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useProjectStatusEdit } from "./useProjectStatusEdit";
import { ProjectStatusSelector } from "./ProjectStatusSelector";
import CustomDatePicker from "../../DatePicker";
import { SelectorGrid, SelectorTitles, StatusSelector } from "./styles";
import theme from "src/v3/theme/theme";
import CustomDialog from "../Base";
import { ProjectStatusEditProps } from "./types";
import {
  useRetainStatusSelector,
  RetainOverride,
} from "../../commomStyles/RetainOverride";

export const EditProjectStatusEdit = ({
  item,
  open,
  handleOpen,
}: ProjectStatusEditProps) => {
  const statusField = item.getProjectStatusField();
  const targetDateField = item.getTargetDateField();
  const {
    onSave,
    resetState,
    handleStateChange,
    state,
    enableSaveButton,
    stateChanged,
    isSaving,
  } = useProjectStatusEdit({ item, targetDateField, statusField });

  const { status, targetDate, justification, retainOverride, expirationDate } =
    state;

  const classes = useRetainStatusSelector();

  const disabledStyle = {
    opacity: 0.5,
    pointerEvents: "none",
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    resetState();
    handleOpen();
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await onSave();
    handleOpen();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      onSave={handleSave}
      isSaving={isSaving}
      title="Edit Predicted Status"
      enableSaveButton={enableSaveButton}
    >
      <Grid
        container
        direction="column"
        style={{
          gap: theme.spacing(6),
        }}
      >
        <SelectorGrid>
          <SelectorTitles>Predicted Status</SelectorTitles>
          <StatusSelector>
            <ProjectStatusSelector
              handleStatusChange={(status) =>
                handleStateChange("status", status)
              }
              status={status}
            />
          </StatusSelector>
        </SelectorGrid>

        <SelectorGrid>
          <SelectorTitles>Target Date</SelectorTitles>
          <CustomDatePicker
            selectedDate={targetDate}
            handleDateChange={(date) => handleStateChange("targetDate", date)}
          />
        </SelectorGrid>

        <SelectorGrid style={stateChanged ? {} : disabledStyle} width="100%">
          <SelectorTitles>Justification</SelectorTitles>
          <FormControl variant="outlined" required>
            <TextField
              id="outlined-multiline-static"
              multiline
              placeholder="Let reviewers know why you are changing the target date or the status."
              variant="outlined"
              value={justification}
              onChange={(e) =>
                handleStateChange("justification", e.target.value)
              }
              inputProps={{ maxLength: 250 }}
              required
              error={!justification && stateChanged}
              helperText={!justification && stateChanged && "Required"}
              disabled={!stateChanged}
              InputProps={{ className: classes.justificationFormControl }}
            />
          </FormControl>
        </SelectorGrid>

        <Grid style={stateChanged ? {} : disabledStyle}>
          <FormControlLabel
            control={
              <Checkbox
                key={`retainStatus-${Math.random()}`}
                checked={retainOverride}
                onChange={(e) =>
                  handleStateChange("retainOverride", e.target.checked)
                }
                color="primary"
                disabled={!stateChanged}
              />
            }
            label={
              <Typography>Retain this status for a certain duration</Typography>
            }
          />
          {retainOverride && (
            <RetainOverride
              stateChanged={stateChanged}
              expirationDate={state.expirationDate}
              handleExpirationDateChange={(e) =>
                handleStateChange("expirationDate", e.target.value)
              }
            />
          )}
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
