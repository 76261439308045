import { CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import BaseButton from "src/components/Buttons/BaseButton";
import CustomDialog from "../../CustomDialog";
import setErrorSnackbar, {
  setSuccessSnackbar,
} from "src/services/setErrorSnackbar";
import * as sessionService from "src/services/sessionService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { isEmpty } from "src/v2/utils/object";
import { formatDate } from "src/utils/stringUtils";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";

export const EditAISummary = ({ team }) => {
  const { handleSetTeamExtraContext, teamSummary } = useGenericViewContext();
  const [originalExtraContext, setOriginalExtraContext] = useState("");
  const [editedExtraContext, setEditedExtraContext] = useState("");

  const user = sessionService.getLoggedUser();

  useEffect(() => {
    if (!team) return;

    const extraContext = team?.extra_context;

    if (!extraContext || isEmpty(extraContext) || extraContext?.context === "")
      return;

    const isHtml = /<\/?[a-z][\s\S]*>/i.test(extraContext.context);

    if (isHtml || extraContext.context === "") {
      setOriginalExtraContext(extraContext.context);
      setEditedExtraContext(extraContext.context);
      return;
    }

    let extraContextHTML = "<ul class='arrowed'>";
    extraContextHTML += `<li>${extraContext.context}</li>`;
    extraContextHTML += "</ul>";
    setOriginalExtraContext(extraContextHTML);
    setEditedExtraContext(extraContextHTML);
  }, [team, teamSummary]);

  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const enableSaveButton = editedExtraContext !== originalExtraContext;

  const resetState = () => {
    setOpen(false);
    setIsSaving(false);
    setEditedExtraContext(originalExtraContext);
  };

  const noCHangesInState = () => {
    return editedExtraContext === originalExtraContext;
  };

  const HeaderHTML = () => {
    if (editedExtraContext === "") return <div></div>;
    return (
      <h2
        style={{
          fontSize: 22,
          backgroundColor: "#f0f7ff",
          padding: 8,
          paddingLeft: 16,
          width: "100%",
          color: "rgb(44, 72, 91)",
          borderBottom: "1px solid white",
        }}
      >
        Additional details
      </h2>
    );
  };

  const onSave = async (e) => {
    e.stopPropagation();
    if (noCHangesInState()) {
      setOpen(false);
      setIsSaving(false);
      return;
    }
    setIsSaving(true);

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editedExtraContext.trim(); // Use trim to remove any leading/trailing whitespace
    const cleanedContext =
      tempDiv.textContent.trim() === "" || tempDiv.innerHTML.trim() === "<br>"
        ? ""
        : editedExtraContext;

    try {
      const _extraContext = {
        context: cleanedContext,
        created_at: new Date().toISOString(),
        author: {
          name: user?.name,
          email: user?.email,
        },
      };
      setEditedExtraContext(cleanedContext);
      handleSetTeamExtraContext(team.id, _extraContext).then(() => {
        setSuccessSnackbar("Team Summary updated successfully!", 3000);
      });
    } catch (error) {
      console.error(error);
      setErrorSnackbar(
        "Failed to update team summary. Please try again later. If the issue persists, contact us.",
        3000
      );
      resetState();
    } finally {
      setIsSaving(false);
      setOpen(false);
    }
  };

  function handleUpdateExtraContext(e: any) {
    setEditedExtraContext(e);
  }

  return (
    <div>
      <HeaderHTML />
      {editedExtraContext !== "" && (
        <div
          style={{ padding: "16px", fontSize: 14, lineHeight: "24px" }}
          hidden={team?.extra_context?.length === 0}
          dangerouslySetInnerHTML={{ __html: editedExtraContext }}
        ></div>
      )}
      <Typography
        onClick={() => setOpen(true)}
        style={{
          color: "#0077cc",
          fontSize: 14,
          fontWeight: 500,
          marginBottom: "16px",
          marginLeft: "16px",
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {editedExtraContext !== ""
          ? "Edit additional details"
          : "Add additional details"}
      </Typography>
      {open && (
        <CustomDialog
          open={open}
          onClose={resetState}
          customContentStyle={{
            padding: "20px",
            width: "600px",
          }}
          title="Team Summary - Additional Details"
          customTitleStyle={{
            color: "#2C485B",
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: 700,
          }}
        >
          {team?.extra_context?.author && team?.extra_context?.created_at && (
            <Typography
              style={{
                fontSize: "12px",
                lineHeight: "14px",
                color: "grey",
                marginBottom: "16px",
              }}
            >
              Last updated by {team?.extra_context?.author?.name} on{" "}
              {formatDate(
                new Date(team?.extra_context?.created_at).toLocaleDateString()
              )}
            </Typography>
          )}
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
              ],
              clipboard: {
                matchVisual: false,
              },
            }}
            value={editedExtraContext || ""}
            onChange={handleUpdateExtraContext}
            placeholder={"Enter additional details here..."}
          />
          <Divider
            style={{
              marginTop: "20px",
            }}
          ></Divider>

          <Grid
            container
            justifyContent="flex-end"
            style={{
              marginTop: "20px",
            }}
          >
            <BaseButton
              style={{
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                border: "1px solid #1A7BBD",
                color: "#2C485B",
                marginRight: "10px",
              }}
              onClick={resetState}
            >
              Cancel
            </BaseButton>
            {isSaving ? (
              <div
                style={{
                  alignSelf: "center",
                  width: "50px",
                }}
              >
                <CircularProgress
                  style={{
                    alignSelf: "center",
                    width: "24px",
                    height: "24px",
                  }}
                ></CircularProgress>
              </div>
            ) : (
              <BaseButton
                style={{
                  backgroundColor: !enableSaveButton ? "#D9D9D9" : "#1A7BBD",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "#FFFFFF",
                }}
                disabled={!enableSaveButton}
                onClick={async (e) => {
                  await onSave(e);
                }}
              >
                Save
              </BaseButton>
            )}
          </Grid>
        </CustomDialog>
      )}
      <Divider />
    </div>
  );
};
