import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useMemo } from "react";

export const useOnTheRoadmap = ({ items }: { items: BlockItem[] }) => {
  const { roadmapItems, nonRoadmapItems } = useMemo(() => {
    const roadmapItems = items.filter((item) => item.isRoadmapItem);
    const nonRoadmapItems = items.filter((item) => !item.isRoadmapItem);

    return { roadmapItems, nonRoadmapItems };
  }, [items]);

  return {
    roadmapItems,
    nonRoadmapItems,
  };
};
