import { StatusCategory } from "../domain/enum/StatusCategory";

export const getPercentages = ({ aggregation, total }) => {
  const totalItems = total !== 0 ? total : 1;
  const done = aggregation[StatusCategory.Done]?.length ?? 0;
  const donePercentage = Math.round((done / totalItems) * 100);

  const inProgress = aggregation[StatusCategory.InProgress]?.length ?? 0;
  const inProgressPercentage = Math.round((inProgress / totalItems) * 100);

  const toDo = aggregation[StatusCategory.ToDo]?.length ?? 0;
  const toDoPercentage = Math.round((toDo / totalItems) * 100);

  return {
    toDo: toDoPercentage,
    inProgress: inProgressPercentage,
    done: donePercentage,
  };
};
