import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { createSession } from "src/services/sessionService";
import { logError } from "src/utils/error";
import CustomHistory from "src/utils/history";
import { isCurrentSessionOldThan1Day } from "src/utils/session";

export const ReAuth = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function reAuthenticate() {
      try {
        const token = await getAccessTokenSilently({
          timeoutInSeconds: 300,
        });
        await createSession(token);
        localStorage.setItem("last_session_date", JSON.stringify(Date.now()));
      } catch (error) {
        localStorage.removeItem("last_session_date");
        logError(
          error,
          "For security, you must re-authenticate. Please login again"
        );
        CustomHistory.push("/login");
      }
    }

    const isLastUpdatedOlderThan24Hours = isCurrentSessionOldThan1Day();
    const reAuthenticateUser = isAuthenticated && isLastUpdatedOlderThan24Hours;
    if (reAuthenticateUser) reAuthenticate();
  }, [isAuthenticated]);

  return null;
};
