import { useSummaryContext } from "../providers/summary/SummaryContext";

export const useScroll = () => {
  const scrollTo = ({ element, options = { padding: 72 } }) => {
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const targetPosition = elementPosition - options?.padding;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  /**
   * Scrolls to an element with the given ID.
   * @param id - The ID of the element to scroll to.
   * @param options - Optional object containing padding value.
   */
  const scrollToElementById = (id: string, options?: { padding: number }) => {
    const element = document.getElementById(id);
    if (element) {
      scrollTo({ element, options });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return {
    scrollToElementById,
    scrollToTop,
  };
};
