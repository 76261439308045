import { Box, Typography } from "@material-ui/core";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class TextField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = Array.isArray(field.value)
      ? field.value
      : [field.value?.value ?? field.value ?? ""];
  }

  getComponentForGrid(_) {
    if (typeof this.value[0] === "object") {
      console.error("TextField value is an object", this.value[0]);
      return <span />;
    }
    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "200px",
          minWidth: 72,
          padding: 8,
        }}
      >
        <BootstrapTooltipWithoutPortal title={this.value[0]}>
          <Typography style={{ color: primaryTextColor }} noWrap>
            {this.value[0]}
          </Typography>
        </BootstrapTooltipWithoutPortal>
      </Box>
    );
  }
}
