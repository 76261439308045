import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export const useItemDetailNavigation = ({ item }: { item: BlockItem }) => {
  const history = useHistory();
  const location = useLocation();

  const handleOpenItemDetails = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pathName = location.pathname.includes("details/item")
      ? location.pathname
      : `${location.pathname}/details/item`;
    history.push({
      pathname: `${pathName}/${item.getKey()}`,
      search: `?${searchParams}`,
      state: {
        background: location,
        breadcrumbLabels: [
          ...(location.state?.breadcrumbLabels || []),
          { itemKey: item.getKey(), label: item.displayName },
        ],
      },
    });
  }, [history, location, item]);

  return {
    handleOpenItemDetails,
  };
};
