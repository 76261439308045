import React, { useEffect } from "react";
import ProjectStatusReasonHTML from "./OverviewNavigation/ProjectStatusReasonHTML";
import { useProjectStatusChanges } from "src/v2/hooks/useRecentChanges";
import { WidgetConfigs } from "../Widget/WidgetConfigs";
import { useWidgetContext } from "../Widget/WidgetContext";
import { WidgetTitle } from "../Widget/Widget";

const AutoGeneratedChangesSection = ({ items, parentSections }) => {
  const [changesHtml, setChangesHtml] = React.useState("");
  const [localHeader, setLocalHeader] = React.useState(null);
  const { getConfigValueByName } = useWidgetContext();

  const section = parentSections ? parentSections[0] : null;

  const findOptionValue = (options, _name) =>
    options?.find(({ name }) => name === _name)?.value;

  const sectionOptions = section?.options || [];

  const hasAutomaticChanges = findOptionValue(
    sectionOptions,
    "has_automatic_changes"
  );

  const showChangesInTeamsTargetDate = findOptionValue(
    sectionOptions,
    "show_changes_in_teams_target_date"
  );

  const { changesHTML } = useProjectStatusChanges({
    items,
    hasAutomaticChanges,
    showChangesInTeamsTargetDate,
  });
  useEffect(() => {
    const { header } = getConfigValueByName(WidgetConfigs.sectionContent);
    setLocalHeader(header);
  }, []);
  useEffect(() => {
    let html = changesHTML;
    const topCustomReason = sectionOptions?.find(
      ({ name }) => name === "top_html_reason"
    )?.value;
    if (topCustomReason?.length > 0) html = topCustomReason + html;

    const customReason = sectionOptions?.find(
      ({ name }) => name === "bottom_html_reason"
    )?.value;

    if (customReason?.length > 0) {
      html += customReason;
    }
    setChangesHtml(html);
  }, [items, changesHTML]);

  return (
    <>
      <WidgetTitle
        title={localHeader}
        tooltipText={undefined}
        titleStyles={undefined}
      />
      <ProjectStatusReasonHTML
        reason={changesHtml}
        customStyles={{ backgroundColor: "transparent", marginTop: -24 }}
      />
    </>
  );
};

export default AutoGeneratedChangesSection;
