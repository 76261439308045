import api from "./api/apiV2";

export async function getUserPreferences(params) {
  const { data } = await api.get(`/v2/userPreference`, {
    params,
  });
  return data;
}

export async function createUserPreference(params) {
  const { data } = await api.post(`/v2/userPreference`, {
    ...params,
  });
  return data;
}

export async function deleteUserPreference(id) {
  const { data } = await api.post(`/v2/userPreference/delete`, { id });
  return data;
}
