import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";

type Comment = {
  id: string;
  comment: string;
  author: string;
  date: string;
  original_key: string;
};

type CommentDetails = {
  summary: string;
  comments: Comment[];
  total: number;
  total_filtered: number;
};
export class CommentsField extends CustomFieldBase {
  private allCommentsFiltered: Comment[];
  private summary: string;
  private childrenComments: CommentDetails;
  private dependenciesComments: CommentDetails;

  constructor(field) {
    super(field);
    this.value = field.value;
    this.allCommentsFiltered = this.value?.allCommentsFiltered ?? [];
    this.summary = this.value?.summary ?? "";
    this.childrenComments = this.value?.childrenComments ?? {
      summary: "",
      comments: [],
      total: 0,
      total_filtered: 0,
    };
    this.dependenciesComments = this.value?.dependenciesComments ?? {
      summary: "",
      comments: [],
      total: 0,
      total_filtered: 0,
    };
  }

  getComments() {
    return this.allCommentsFiltered;
  }

  getSummary() {
    return this.summary;
  }

  getChildrenKeys() {
    return [
      ...new Set(
        this.childrenComments?.comments.map((comment) => comment.original_key)
      ),
    ];
  }

  getDependenciesKeys() {
    return [
      ...new Set(
        this.dependenciesComments?.comments.map(
          (comment) => comment.original_key
        )
      ),
    ];
  }
}
