export const SWO_NOV_2023_DoughnutChart = {
  data: {
    labels: ["Bugs (Major & above)", "Client Issues", "Release"],
    datasets: [
      {
        data: [25, 19, 56], // Percentages
        quantity: [46, 35, 103],
        backgroundColor: [
          "rgb(204, 93, 19)",
          "rgb(8, 135, 134)",
          "rgb(28, 84, 149)",
        ],
      },
    ],
  },
  title: "Composition of Work",
  info: "SWO Nov Release (Oct 25 - Nov 21)",
};

// Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
export const SWO_NOV_2023_Release = {
  project_status: {
    status: {
      type: "at_risk_status",
      name: "At Risk",
    },
    title: "Release",
    titleBackgroundColor: "#197BBD",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 0,
    inProgressPercentage: 80,
    toDoPercentage: 20,
  },
  ai_summary: "",
};

export const SWO_NOV_2023_ClientIssues = {
  project_status: {
    status: {
      type: "on_track_status",
      name: "Within SLA",
    },
    title: "Client Issues",
    titleBackgroundColor: "#088786",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 29,
    inProgressPercentage: 20,
    toDoPercentage: 51,
  },
  ai_summary: `<div class="root"><h4><a target="_blank" href="https://swicloud.atlassian.net/issues/?jql=issuetype%3D%22Bug%22%20AND%20project%3D%22NH%22%20AND%20created%20%3E%3D%20%222023-10-25%22">35 total Client Issues in Nov Release</a></h4><ul class="arrowed"><br /><b>Callouts</b><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-66254,NH-66255,NH-66413,NH-66245,NH-65925,NH-66461,NH-66397)" target="_blank">Blocker, Critical and Major issues (17%)</a> NOT meeting SLAs</li><br /><b>Stats By Priority</b><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-66254)" target="_blank">Blocker (1 issue):</a> 16 days to start, 3 days in In Progress</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-67394,NH-66413,NH-67330,NH-67311,NH-67006,NH-66255,NH-67300,NH-67211)" target="_blank">Critical (8 issues):</a> ~5 days to resolve, 2 Unstarted (71%) ~11 days in To Do</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-67440,NH-67310,NH-67308,NH-66988,NH-66773,NH-66245,NH-65925,NH-66962,NH-66461,NH-66488,NH-66397,NH-66396)" target="_blank">Major (12 issues):</a> ~8 days to resolve, 7 Unstarted (58%) ~12 days in To Do</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-67533,NH-67376,NH-67146,NH-67125,NH-66753,NH-66409,NH-66143,NH-66043,NH-65916,NH-67229,NH-66487,NH-66902,NH-66463,NH-65735)" target="_blank">Other (14 issues):</a> ~14 days to resolve, 9 Unstarted (64%) ~13 days in To Do</li></ul></div>`,
};

// Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
export const SWO_NOV_2023_Bugs = {
  project_status: {
    status: {
      type: "at_risk_status",
      name: "At Risk",
    },
    title: "Bugs",
    titleBackgroundColor: "#CC5D13",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 35,
    inProgressPercentage: 10,
    toDoPercentage: 55,
  },
  ai_summary: `<div class="root"><h4><a target="_blank" href="https://swicloud.atlassian.net/issues/?jql=issuetype%3D%22Bug%22%20AND%20project%3D%22NH%22%20AND%20created%20%3E%3D%20%222023-10-25%22">46 priority Bugs in Nov Release</a></h4><ul class="arrowed"><br /><b>Callouts</b><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-65617,NH-65969,NH-66231,NH-66123,NH-65841,NH-65824,NH-65827,NH-65693,NH-66374,NH-66336,NH-66186,NH-66156,NH-66144,NH-66117,NH-66101,NH-65995,NH-65839,NH-65692)" target="_blank">41% of bugs</a> NOT meeting SLAs</li><br /><b>Stats By Priority</b><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-67174,NH-67033,NH-65617)" target="_blank">Blocker (3 issues):</a> ~27 days to resolve, 1 Unstarted (33%) ~7 days in To Do</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-67459,NH-66410,NH-67251,NH-67244,NH-66975,NH-66833,NH-66508,NH-66347,NH-65969,NH-66591,NH-66231,NH-66123,NH-65841,NH-65824)" target="_blank">Critical (14 issues):</a> ~4 days to resolve, 5 Unstarted (35%) ~21 days in To Do</li><li><a href="https://swicloud.atlassian.net/issues/?jql=key%20in%20(NH-67336,NH-67165,NH-66781,NH-66780,NH-66104,NH-65994,NH-65839,NH-65834,NH-65827,NH-65693,NH-67313,NH-65692,NH-67292,NH-67258,NH-67235,NH-67185,NH-67169,NH-67135,NH-66979,NH-66706,NH-66434,NH-66374,NH-66336,NH-66186,NH-66156,NH-66144,NH-66117,NH-66101,NH-65995)" target="_blank">Major (29 issues):</a> ~8 days to resolve, 17 Unstarted (58%) ~15 days in To Do</li><li>Other (111 issues): ~11 days to resolve, 63 Unstarted (56%) ~13 days in To Do</li></ul></div>`,
};

export const SWO_NOV_2023_Applications = {
  progressBarData: {
    items: 6,
    bugs: 0,
    itemType: "Epic",
    donePercentage: 0,
    inProgressPercentage: 80,
    toDoPercentage: 20,
  },
  // Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
  sprintHealthStatus: {
    type: "on_track_status",
    name: "On Track",
  },
  roadmapHealthStatus: {
    type: "at_risk_status",
    name: "At Risk",
  },
  sprintHealthReasoningHTML: ``,
  roadmapHealthReasoningHTML:
    "<div class='root'> <ul class='arrowed'><li> 3 epics are At Risk </li> <li> 3 epics are Paused </li> </ul> </div> ",
  widgetTitle: "Applications",
  widgetTitleColor: "rgb(25, 123, 189)",
  itemName: "APM",
  redirectURL:
    "https://velma.work/v2/roadmap/tracks?filter_level=Issue~Epic&custom_fields_changelog=1+week&fixVersions=SWO+Nov+2023",
  projectedFinishDate: "Dec 7, 2023",
};

export const SWO_NOV_2023_Database = {
  progressBarData: {
    items: 5,
    bugs: 0,
    itemType: "Epic",
    donePercentage: 0,
    inProgressPercentage: 80,
    toDoPercentage: 20,
  },
  // Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
  sprintHealthStatus: {
    type: "on_track_status",
    name: "On Track",
  },
  roadmapHealthStatus: {
    type: "on_track_status",
    name: "On Track",
  },
  sprintHealthReasoningHTML: "",
  roadmapHealthReasoningHTML:
    "<div class='root'> <ul class='arrowed'> <li> 3 epics are Completed or Likely Completed </li> <li> 2 unstarted epics </li> </ul> </div> ",
  widgetTitle: "Database",
  widgetTitleColor: "rgb(25, 123, 189)",
  itemName: "DBO",
  redirectURL:
    "https://velma.work/v2/roadmap/tracks?filter_level=Issue~Epic&custom_fields_changelog=1+week&fixVersions=SWO+Nov+2023",
  projectedFinishDate: "",
};
