import { ProjectStatusField } from "src/v3/entities/CustomFields/ProjectStatusField";
import { StatusField } from "src/v3/entities/CustomFields/StatusField";

export enum ProjectStatusType {
  OFF_TRACK = "off_track_status",
  AT_RISK = "at_risk_status",
  ON_TRACK = "on_track_status",
  COMPLETE = "complete",
  PAUSED = "paused",
  NOT_SET = "not_set_status",
}

export enum ProjectStatusPositionType {
  position = "position",
  sort_position = "sort_position",
}

export interface AggregationItem {
  identifier: string;
  percentage: number;
  totalItems: number;
  itemKeys: string[];
  status: ProjectStatusField | StatusField;
}
