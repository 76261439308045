import { ProjectStatusField } from "src/v3/entities/CustomFields/ProjectStatusField";
import { StatusField } from "src/v3/entities/CustomFields/StatusField";

export interface StatusChipBuilderProps {
  status: ProjectStatusField | StatusField;
  type: StatusChipTypes;
  size: "small" | "medium";
}

export interface ProjectStatusChipProps {
  status: ProjectStatusField;
  size: "small" | "medium";
}

export interface WorkflowStatusChipProps {
  status: StatusField;
  size: "small" | "medium";
}

export interface StatusChipBaseProps {
  label: any;
  size: "small" | "medium";
  textColor: string;
  backgroundColor: string;
}

export enum StatusChipTypes {
  ProjectStatus = "ProjectStatus",
  WorkflowStatus = "WorkflowStatus",
}
