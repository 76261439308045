import GoalModel from "src/pages/SprintView/models/GoalModel";
import TicketModel from "src/pages/SprintView/models/TicketModel";
import TicketSource from "src/pages/SprintView/models/TicketSource";
import Epic from "src/pages/SprintView/models/Epic";
import { VelmaViewStatus } from "src/pages/SprintView/models/enums/VelmaViewStatus";
import api from "./api";
import DayOff from "../pages/SprintView/models/DayOff";

export async function getAllTeamsForCompany() {
  const { data } = await api.get("/planning/teams-per-client");
  return data;
}
export async function getAssigneesCapacities(boardId: number) {
  const { data } = await api.get(
    `/planning/assignee-capacity?boardId=${boardId}`
  );
  return data;
}
export async function getSprintsAndGoals(boardId: number) {
  const { data } = await api.get(
    `/planning/sprints-and-goals?boardId=${boardId}`
  );
  return data;
}
export async function getTeamMembers(boardId: number) {
  const { data } = await api.get(
    `/planning/members?boardId=${boardId}&active=true`
  );
  return data;
}

export async function createTeamMember({ name, boardId }) {
  const { data } = await api.post("/planning/members", { name, boardId });
  return data;
}

export async function deleteTeamMember({ name, boardId }) {
  const { data } = await api.delete("/planning/members", {
    data: { name, boardId },
  });
}

export async function getEpicsForBoards(boardIds: number[]) {
  const { data } = await api.get("/planning/epics", {
    params: {
      boardIds,
    },
  });
  return data;
}

export async function getUncategorizedTicketsBySprint(
  sprint_id: number,
  boardId: number
) {
  const { data } = await api.get(
    `/planning/uncategorized?sprint_id=${sprint_id}&boardId=${boardId}`
  );
  return data;
}

export async function getActiveAndFutureSprints(teamBoardId: number) {
  const { data } = await api.get(
    `/planning/backlog-sprints?boardId=${teamBoardId}`
  );

  return data;
}

export async function getDaysOff(sprintId: number) {
  const { data } = await api.get(`/planning/days-off?sprintId=${sprintId}`);

  return data;
}

export async function newDaysOff(newDaysOff: DayOff[]) {
  const { data } = await api.post("/planning/new-day-off", newDaysOff);
  return data;
}

export async function removeDaysOff(daysOff: number[], sprintId: number) {
  const { data } = await api.delete("/planning/old-days-off", {
    data: { daysOff },
  });
  return data;
}

export async function getCloseSprintData({
  teamBoardId,
  currentSprintId,
}: {
  teamBoardId: number;
  currentSprintId: number;
}): Promise<{
  sprints: { name: string; id: number }[];
  amountOfDoneTickets: number;
  amountOfNotDoneTickets: number;
}> {
  const { data } = await api.get("/planning/close-sprint", {
    params: { boardId: teamBoardId, currentSprintId },
  });
  return data;
}

export async function getActiveSprintsWithIssues(teamBoardId: number) {
  const { data } = await api.get(
    `/planning/backlog-sprints/active?boardId=${teamBoardId}`
  );
  return data;
}

export async function getAllocation(teamBoardId: number) {
  const { data } = await api.get(
    `/planning/team-allocation?boardId=${teamBoardId}`
  );
  return data;
}

export async function getHighlightedEpics(
  teamName: string,
  sprintStartDate,
  sprintEndDate
) {
  const { data } = await api.post("/planning/highlighted-epics", {
    teamName,
    sprintEndDate,
    sprintStartDate,
  });
  return data;
}

export async function getTeamPlanningSprintsData(
  boardId: number,
  sprints: any
) {
  const { data } = await api.post(
    `/planning/backlog-view-data?boardId=${boardId}`,
    {
      active: sprints.active.map((x) => x.id),
      future: sprints.future.map((x) => x.id),
    }
  );

  return data;
}

export async function updateTickets(ticketData: any[]) {
  if (ticketData.every((x) => x.key)) {
    return await api.patch("/planning/ticket", ticketData);
  }
}

export async function createGoal(payload: {
  goal: GoalModel;
  tickets: TicketModel[];
  isCreatedOnTracking?: boolean;
  sprintId: number;
}) {
  const { data } = await api.post("/planning/goal", payload);
  return data;
}

export async function getSprintGoals(sprintId: number) {
  const { data } = await api.get(`/planning/goal?sprintId=${sprintId}`);
  return data;
}

export async function getPossibleEpicStatuses(epic: Epic) {
  let epicStatusKey = epic.key;
  let shownEpicType;
  if (epic.sisterEpicKey) {
    epicStatusKey = epic.sisterEpicKey;
    shownEpicType = epic.sisterEpicIssuetype;
  }
  const { data } = await api.get(
    `/planning/issue/transitions?ticketKey=${epicStatusKey}&issueType=${shownEpicType}`
  );
  return data;
}
export async function getPossibleEpicStatusesByProject(project: any) {
  const { data } = await api.get(`/planning/status/project?project=${project}`);
  return data;
}

export async function moveTicketsToSprint(body: {
  tickets: any[];
  targetSprintId: number;
  plannedSprintId: number;
  boardId;
}) {
  await api.put("/planning/issue/move-sprint", body);
}

export async function getPossibleIssueStatusesByBoardId(boardId: any) {
  const { data } = await api.get(`/planning/status/board?boardId=${boardId}`);
  return data;
}
export async function getAllBoardsPossibleIssueStatuses() {
  const { data } = await api.get(`/planning/status/all-boards`);
  return data;
}
export async function getTeamConfig(boardId: number, key) {
  const { data } = await api.get("/planning/config", {
    params: { key, boardId },
  });
  return data;
}
export async function setTeamConfig(boardId: number, key: string, value: any) {
  const { data } = await api.put("/planning/config", { key, value, boardId });
  return data;
}

export async function getSprint(sprintId: number) {
  const { data } = await api.get(`/planning/sprint?sprintId=${sprintId}`);
  return data;
}

export async function getAllBoardEpics(boardId: number) {
  const { data } = await api.get("/planning/epics", {
    params: { boardIds: [boardId] },
  });
  return data;
}

export async function addTicketsToGoal(
  ticketsKeys: string[],
  goalId: number,
  sprintId: number
) {
  const { data } = await api.post(
    `/planning/goal/ticket?goalId=${goalId}&sprintId=${sprintId}`,
    ticketsKeys
  );
  return data;
}

export async function removeTicketsFromGoal(
  ticketsKeys: string[],
  sprintId: number
) {
  const { data } = await api.delete(
    `/planning/issue/goal?sprintId=${sprintId}`,
    {
      data: { ticketsKeys, sprintId },
    }
  );
  return data;
}

export async function resetGoalTicketsInDb({
  goalId,
  sprintId,
  ticketsKeysToAdd,
}: {
  ticketsKeysToAdd: string[];
  sprintId: number;
  goalId: number;
}) {
  const { data } = await api.post("/planning/goal/reset", {
    ticketsKeysToAdd,
    sprintId,
    goalId,
  });
  return data;
}

export async function removeTicketsFromAllGoals(
  ticketsKeys: string[],
  sprintId: number
) {
  const { data } = await api.post("/planning/goal/ticket/all", {
    ticketsKeys,
    sprintId,
  });
  return data;
}

export async function finishPlanning(payload) {
  const { data } = await api.put(`/planning/sprint/finish`, payload);
  return data;
}

export async function triggerRoadmapProcessing() {
  const { data } = await api.post("roadmap/trigger-processing");
  return data;
}

export async function excludeGoal(goalId: number, sprintId: number) {
  const { data } = await api.delete(
    `/planning/goal?goalId=${goalId}&sprintId=${sprintId}`
  );
  return data;
}

export async function updateGoal(goal: any) {
  await api.put("/planning/goal", goal);
}

export async function moveIssueToUncategorized(
  ticketKeys: string[],
  sprintId: number
) {
  await api.post("/planning/goal/move-to-uncategorized", {
    ticketKeys,
    sprintId,
  });
}

export async function moveIssueToBacklog(args: {
  tickets: { key: string; source: TicketSource; fromSprintId: number }[];
  plannedSprintId: number;
}) {
  await api.post("/planning/move-to-backlog", args);
}

export async function getBacklog(boardId: number, sprintId: number) {
  const { data } = await api.get(
    `/planning/backlog?boardId=${boardId}&sprintId=${sprintId}`
  );
  return data;
}

export async function getSprintIssues(sprintId: number, boardId: number) {
  const { data } = await api.get(
    `/planning/sprint/issue?sprintId=${sprintId}&boardId=${boardId}`
  );
  return data;
}

export async function copyPreviousSprintGoals({
  currentSprintId,
  sourceSprintId,
}) {
  await api.get(
    `/planning/sprint/previous/goal/copy?sourceSprintId=${sourceSprintId}&currentSprintId=${currentSprintId}`
  );
}

export async function getSprintsDefaultsDates() {
  const { data } = await api.get("/planning/sprint/default-dates");
  return data;
}
export async function getEpicsDueDates(epicsKeys: string[], boardId: number) {
  const { data } = await api.get("/planning/epic-dates", {
    params: {
      boardId,
      epicsKeys,
    },
  });
  return data;
}
export async function updateEpicDatesInJira(
  payload: object,
  epicKey: string,
  goalId: number,
  sprintId: number
) {
  const { data } = await api.put("/planning/jira-issue", payload, {
    params: {
      epicKey,
      sprintId,
      goalId,
    },
  });
  return data;
}

export async function getRawActiveSprints(boardId: number) {
  const { data } = await api.get("/planning/active-sprints", {
    params: {
      boardId,
    },
  });
  return data;
}

export async function fetchSisterEpics(epicsKeys: string[], boardId: number) {
  const { data } = await api.post("/planning/sister-epics", {
    epicsKeys,
    boardId,
  });
  return data;
}

export async function fetchPastSprints(boardId: number) {
  const { data } = await api.get("/planning/sprint/past", {
    params: { boardId },
  });
  return data;
}
export async function createTeamQuestionAnswer(
  answers: {
    questionId: number;
    answer: any;
    sprintId: number;
    answeredIn: VelmaViewStatus;
  }[]
) {
  const { data } = await api.post("/planning/answers", {
    answers,
  });
  return data;
}

export async function createPortfolioQuestionAnswer([answers]) {
  const { data } = await api.post("/planning/portfolio-answers", answers);
  return data;
}

export async function fetchTeamQuestions({ sprintId, boardId, views, level }) {
  const { data } = await api.get("/planning/questions", {
    params: { boardId, sprintId, views, level },
  });
  return data;
}

export async function fetchConditionedQuestions(
  boardId: number,
  goalId: number
) {
  const { data } = await api.get("/planning/conditioned-questions", {
    params: { boardId, goalId },
  });
  return data;
}

export async function getBoardsForPullingEpicsFrom(sprintId: number) {
  const { data } = await api.get("/planning/boards-for-epics", {
    params: { sprintId },
  });
  return data;
}

export async function postSelectedBoardsForSprint({
  boardIds,
  sprintId,
}: {
  sprintId: number;
  boardIds: number[];
}) {
  const { data } = await api.post("/planning/boards-for-epics", {
    boardIds,
    sprintId,
  });
  return data;
}

export async function fetchAllSprintChanges({
  sprintId,
  boardId,
}: {
  sprintId: number;
  boardId: number;
}) {
  const { data } = await api.get(`planning/tickets-sprint-changes`, {
    params: {
      sprintId,
      boardId,
    },
  });
  return data;
}
export async function pushTicketsSprintChanges({
  sprintId,
  boardId,
}: {
  sprintId: number;
  boardId: number;
}) {
  const { data } = await api.post(`planning/tickets-sprint-changes`, {
    sprintId,
    boardId,
  });
  return data;
}

export async function deletePendingJiraChange(ticketKey: string) {
  const { data } = await api.delete(`planning/tickets-sprint-changes`, {
    params: {
      ticketKey,
    },
  });
  return data;
}

export async function pushRankChanges({
  sprintId,
  boardId,
  goalsIdsInOrderShownInUi,
}: {
  sprintId: number;
  boardId: number;
  goalsIdsInOrderShownInUi: number[];
}) {
  const { data } = await api.post(`planning/issues/rank`, {
    sprintId,
    boardId,
    goalsIdsInOrderShownInUi,
  });
  return data;
}

export async function reorderGoalsTickets({
  ticketKeys,
  goalId,
}: {
  ticketKeys: string[];
  goalId: number;
}) {
  const { data } = await api.post(`planning/goal/reorder`, {
    ticketKeys,
    goalId,
  });
  return data;
}
