import { getCurrentDateMinusDays, isValidStringDate } from "src/v2/utils/date";
import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";
import { LastJiraUpdatedDateField } from "../../customField/LastJiraUpdatedDateField";

class ByLastJiraUpdatedDate extends SectionFilter {
  constructor({ type, fieldKey, config }) {
    super({ type, fieldKey, config, values: [] });
    const days_ago = config?.days_ago ?? 30;
    this.lastUpdatedDateToCompare = getCurrentDateMinusDays(days_ago);
  }

  private lastUpdatedDateToCompare;

  public filterItem(item: BlockItem): boolean {
    const field = item.getFieldByFieldKey(
      this.fieldKey
    ) as LastJiraUpdatedDateField;

    if (
      !field.itemLastUpdatedDate ||
      field.itemLastUpdatedDate === "" ||
      !isValidStringDate(field.itemLastUpdatedDate)
    ) {
      return false;
    }

    return (
      new Date(field.itemLastUpdatedDate).toISOString() >=
      this.lastUpdatedDateToCompare
    );
  }
}

export default ByLastJiraUpdatedDate;
