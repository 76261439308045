import { dateIsGreaterThanDaysAgo } from "src/v2/utils/date";

export const isCurrentSessionOldThan1Day = () => {
  const lastSession = localStorage.getItem("last_session_date");
  if (!lastSession) return true;
  return !dateIsGreaterThanDaysAgo({
    date: new Date(JSON.parse(lastSession)),
    daysToCompare: 1,
  });
};
