import { Box } from "@material-ui/core";
import { useScroll } from "src/v2/hooks/useScroll";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";
import { useWidgetContext } from "../../Widget/WidgetContext";

const BoxToScrollToWidgetName = ({ children }) => {
  const { getConfigValueByName } = useWidgetContext();
  const customSectionData = getConfigValueByName(
    WidgetConfigs.customSectionData
  );

  const { scrollToElementById } = useScroll();
  return (
    <Box
      onClick={() =>
        scrollToElementById(`widget-${customSectionData.title}`, {
          padding: 105,
        })
      }
    >
      {children}
    </Box>
  );
};

export default BoxToScrollToWidgetName;
