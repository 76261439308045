const addArrowedClassToHtml = (htmlString) => {
  // Create a temporary div to parse the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString.trim();

  // Select all ul and li elements and add the arrowed class
  tempDiv.querySelectorAll("ul, li").forEach((element) => {
    element.classList.add("arrowed");
  });

  tempDiv.querySelectorAll("blockquote").forEach((element) => {
    element.classList.add("blockquote");
  });

  tempDiv.querySelectorAll("strong").forEach((element) => {
    element.style.fontWeight = "bold";
  });

  tempDiv.querySelectorAll("ol").forEach((element) => {
    element.classList.add("ordered-list");
  });

  // Serialize the modified HTML back to a string
  return tempDiv.innerHTML;
};

// Process the extra context
export const processExtraContext = (editedExtraContext) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = editedExtraContext.trim();

  const cleanedContext =
    tempDiv.textContent.trim() === "" || tempDiv.innerHTML.trim() === "<br>"
      ? ""
      : addArrowedClassToHtml(editedExtraContext);

  return cleanedContext;
};
