import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import BaseButton from "src/components/Buttons/BaseButton";
import CustomDialog from "../../CustomDialog";
import setErrorSnackbar, {
  setSuccessSnackbar,
} from "src/services/setErrorSnackbar";
import * as sessionService from "src/services/sessionService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatDate } from "src/utils/stringUtils";
import { addClassToHtml } from "src/utils/htmlUtils";
import { primaryTextColor } from "src/v2/helpers/colors";

export const EditRichTextSection = ({
  extraContext,
  onSaveCallback,
  localHeader,
  displayVelmaSummary,
}) => {
  const [originalExtraContext, setOriginalExtraContext] = useState(
    extraContext?.context
  );

  const [showVelmaSummary, setShowVelmaSummary] = useState(displayVelmaSummary);

  const [editedExtraContext, setEditedExtraContext] = useState("");

  const user = sessionService.getLoggedUser();

  useEffect(() => {
    setShowVelmaSummary(displayVelmaSummary);
  }, [displayVelmaSummary]);

  useEffect(() => {
    if (!extraContext?.context) {
      setOriginalExtraContext("");
      setEditedExtraContext("");
      return;
    }

    const { context } = extraContext;
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(context);

    if (isHtml) {
      setOriginalExtraContext(context);
      setEditedExtraContext(context);
    } else {
      const extraContextHTML = `<ul class='arrowed'><li>${context}</li></ul>`;
      setOriginalExtraContext(extraContextHTML);
      setEditedExtraContext(extraContextHTML);
    }
  }, [extraContext]);

  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const enableSaveButton =
    editedExtraContext !== originalExtraContext ||
    showVelmaSummary !== displayVelmaSummary;

  const resetState = () => {
    setOpen(false);
    setIsSaving(false);
    setEditedExtraContext(originalExtraContext);
    setShowVelmaSummary(displayVelmaSummary);
  };

  const noCHangesInState = () => {
    return (
      editedExtraContext === originalExtraContext &&
      showVelmaSummary === displayVelmaSummary
    );
  };

  const HeaderHTML = () => {
    if (!originalExtraContext || originalExtraContext === "")
      return <div></div>;
    return (
      <h2
        style={{
          fontSize: 16,
          backgroundColor: "#f0f7ff",
          padding: 8,
          paddingLeft: 16,
          color: "rgb(44, 72, 91)",
        }}
      >
        Team Additional Details - {localHeader}
      </h2>
    );
  };

  const processExtraContext = (editedExtraContext) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editedExtraContext.trim();

    const cleanedContext =
      tempDiv.textContent.trim() === "" || tempDiv.innerHTML.trim() === "<br>"
        ? ""
        : addClassToHtml(editedExtraContext);

    return cleanedContext;
  };

  const onSave = async (e) => {
    e.stopPropagation();
    if (noCHangesInState()) {
      setOpen(false);
      setIsSaving(false);
      return;
    }
    setIsSaving(true);
    const cleanedContext = processExtraContext(editedExtraContext);
    try {
      const _extraContext = {
        userInput: {
          context: cleanedContext,
          created_at: new Date().toISOString(),
          author: {
            name: user?.name,
            email: user?.email,
          },
        },
        displayVelmaSummary: showVelmaSummary,
      };
      onSaveCallback(_extraContext).then(() => {
        setOriginalExtraContext(cleanedContext);
        setSuccessSnackbar("Additional details updated successfully.", 3000);
      });
    } catch (error) {
      console.error(error);
      setErrorSnackbar(
        "Failed to update team summary. Please try again later. If the issue persists, contact us.",
        3000
      );
      resetState();
    } finally {
      setIsSaving(false);
      setOpen(false);
    }
  };

  function handleUpdateExtraContext(e: any) {
    setEditedExtraContext(e);
  }

  return (
    <div>
      <HeaderHTML />
      {originalExtraContext !== "" && (
        <div
          style={{
            padding: "16px",
            fontSize: 14,
            lineHeight: "24px",
          }}
          hidden={!extraContext}
          dangerouslySetInnerHTML={{ __html: originalExtraContext }}
        />
      )}
      <Typography
        onClick={() => setOpen(true)}
        style={{
          color: "#0077cc",
          fontSize: 14,
          fontWeight: 500,
          marginLeft: "16px",
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {originalExtraContext === ""
          ? "Add additional details"
          : "Edit additional details"}
      </Typography>
      {open && (
        <CustomDialog
          open={open}
          onClose={resetState}
          customContentStyle={{
            padding: "20px",
            width: "600px",
          }}
          title="Additional Details"
          customTitleStyle={{
            color: "#2C485B",
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: 700,
          }}
        >
          {extraContext?.author && extraContext?.created_at && (
            <Typography
              style={{
                fontSize: "12px",
                lineHeight: "14px",
                color: "grey",
                marginBottom: "16px",
              }}
            >
              Last updated by {extraContext?.author?.name} on{" "}
              {formatDate(
                new Date(extraContext?.created_at).toLocaleDateString()
              )}
            </Typography>
          )}
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
              ],
              clipboard: {
                matchVisual: false,
              },
            }}
            value={editedExtraContext || ""}
            onChange={handleUpdateExtraContext}
            placeholder={"Enter additional details here..."}
          />
          <FormControlLabel
            style={{ marginTop: 16 }}
            control={
              <Checkbox
                key={`retainStatus-${Math.random()}`}
                checked={showVelmaSummary}
                size="small"
                onChange={(e) => {
                  e.stopPropagation();
                  setShowVelmaSummary(!showVelmaSummary);
                }}
                color="primary"
              />
            }
            label={
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "16.8px",
                  color: primaryTextColor,
                  textAlign: "left",
                }}
              >
                {`Show Velma Summary`}
              </Typography>
            }
          />
          <Divider
            style={{
              marginTop: "20px",
            }}
          />

          <Grid
            container
            justifyContent="flex-end"
            style={{
              marginTop: "20px",
            }}
          >
            <BaseButton
              style={{
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                border: "1px solid #1A7BBD",
                color: "#2C485B",
                marginRight: "10px",
              }}
              onClick={resetState}
            >
              Cancel
            </BaseButton>
            {isSaving ? (
              <div
                style={{
                  alignSelf: "center",
                  width: "50px",
                }}
              >
                <CircularProgress
                  style={{
                    alignSelf: "center",
                    width: "24px",
                    height: "24px",
                  }}
                ></CircularProgress>
              </div>
            ) : (
              <BaseButton
                style={{
                  backgroundColor: !enableSaveButton ? "#D9D9D9" : "#1A7BBD",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "#FFFFFF",
                }}
                disabled={!enableSaveButton}
                onClick={async (e) => {
                  await onSave(e);
                }}
              >
                Save
              </BaseButton>
            )}
          </Grid>
        </CustomDialog>
      )}
    </div>
  );
};
