import React, { Fragment } from "react";
import { CustomAccordion } from "src/v3/components/Accordion";
import { IssueTypeIcon } from "src/v3/components/IssueTypeIcon";
import { ItemName } from "src/v3/components/commomStyles/ItemName";
import { AccordionItemsPadding } from "src/v3/components/commomStyles/AccordionItemsPadding";
import {
  ItemBandwidthSideInfo,
  ItemRow,
  DateSideInfo,
} from "src/v3/components/ItemRow";
import { StatusChipTypes } from "src/v3/components/StatusChip/types";
import EmptyState from "src/v3/components/EmptyState";
import {
  ProductListRoot,
  ProductHeaderProgressBar,
  RenderAccordionItemRoot,
} from "./styles";
import { SortContainer } from "src/v3/dataContainers/SortContainer";
import { aggregateItemsByProjectStatus } from "src/v3/utils/ProjectStatus/aggregateItemsByPS";
import { useProductList } from "./useProductList";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { ProductListAccordionHeaderProps, ProductListProps } from "./types";
import { BreadCrumbsBuilder } from "src/v3/components/Breadcrumbs/builder";
import { useOnTheRoadmap } from "src/v3/hooks/useOnTheRoadmap";
import { AccordionHeaderRoot } from "src/v3/components/commomStyles/AccordionHeaderRoot";
import { truncateStringInTheEnd } from "src/utils/stringUtils";
import { ProgressBar } from "src/v3/components/ProgressBar";
import { ActionType } from "src/v3/components/ActionMenu/Actions/Factory/types";
// import { useInView } from "react-intersection-observer";

const ProductListAccordionHeader = React.memo(
  ({
    itemChildren,
    issueType,
    displayName,
    itemKey,
  }: ProductListAccordionHeaderProps) => {
    const projectStatusAggregation = aggregateItemsByProjectStatus({
      items: itemChildren,
    });

    return (
      <AccordionHeaderRoot key={itemKey}>
        <IssueTypeIcon issueType={issueType} size="small" />
        <ItemName>{truncateStringInTheEnd(displayName, 70)}</ItemName>
        <ProductHeaderProgressBar>
          <ProgressBar aggregation={projectStatusAggregation} />
        </ProductHeaderProgressBar>
      </AccordionHeaderRoot>
    );
  }
);

export const RenderItemRow = ({
  item,
  index,
  itemsBandwidthMap,
}: {
  item: BlockItem;
  index: number;
  itemsBandwidthMap: Record<string, number>;
}) => {
  const targetDate = item.getTargetDateField()?.getTargetDate();
  const bandwidth = itemsBandwidthMap[item.getKey()] || 0;

  const actions = [];
  const statusField = item.getProjectStatusField();
  if (statusField) {
    actions.push(statusField.getEditStatusAction(item));
  }

  const summaryOfWorkField = item.getSummaryOfWorkField();
  if (summaryOfWorkField) {
    actions.push(summaryOfWorkField.getEditSummaryOfWorkAction(item));
  }

  return (
    <ItemRow
      key={index}
      displayName={item.displayName}
      itemKey={item.getKey()}
      summaryField={summaryOfWorkField}
      statusField={statusField}
      issueType={item.type}
      statusChipType={StatusChipTypes.ProjectStatus}
      sideInfos={[
        <DateSideInfo label={"Team's target"} date={targetDate} />,
        <ItemBandwidthSideInfo bandwidth={bandwidth} />,
      ]}
      actions={actions}
    />
  );
};

const RenderAccordionItem = React.memo(({ item }: { item: BlockItem }) => {
  const { itemsBandwidthMap } = useOnTheRoadmap({ items: item.children });
  const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  // const { ref, inView } = useInView({
  //   triggerOnce: true,
  //   threshold: 0.5,
  //   rootMargin: "20% 0% 20% 0%",
  // });

  const handleToggleAccordion = React.useCallback(() => {
    setIsAccordionExpanded((prev) => !prev);
  }, []);

  return (
    <RenderAccordionItemRoot ref={ref}>
      <CustomAccordion
        header={
          <ProductListAccordionHeader
            itemChildren={item.children}
            issueType={item.type}
            displayName={item.displayName}
            itemKey={item.getKey()}
            isAccordionExpanded={isAccordionExpanded}
          />
        }
        isExpanded={isAccordionExpanded}
        onToggle={handleToggleAccordion}
        hasChildren={!!item.children && item.children.length > 0}
      >
        {isAccordionExpanded && (
          <AccordionItemsPadding>
            {SortContainer({ items: item.children }).map(
              (childItem: BlockItem, index: number) => {
                return (
                  <RenderItemRow
                    key={index}
                    item={childItem}
                    index={index}
                    itemsBandwidthMap={itemsBandwidthMap}
                  />
                );
              }
            )}
          </AccordionItemsPadding>
        )}
      </CustomAccordion>
    </RenderAccordionItemRoot>
  );
});

const Products = React.memo(({ items }: ProductListProps) => {
  const sortedItems = React.useMemo(() => SortContainer({ items }), [items]);
  return (
    <>
      {sortedItems.map((item: BlockItem, index: number) => (
        <ProductListRoot key={index}>
          <RenderAccordionItem item={item} />
        </ProductListRoot>
      ))}
    </>
  );
});

export const ProductList = React.memo(({ items }: ProductListProps) => {
  const { filteredItems, groups } = useProductList({ items });

  if (!items?.length || (groups?.length && !filteredItems?.length)) {
    return (
      <EmptyState message="No items match the given filter" icon={undefined} />
    );
  }

  if (filteredItems?.length) {
    const hasGroup = filteredItems.some(
      ({ groupedItems }) => groupedItems?.length
    );
    return (
      <>
        {filteredItems.map(({ name, groupedItems, filteredItems }) => {
          if (hasGroup) {
            if (!groupedItems?.length) {
              return null;
            }
            return (
              <Fragment key={name}>
                <BreadCrumbsBuilder type="group" groups={[name]} />
                {groupedItems.map(({ items, fieldValue }) => {
                  return <Products key={fieldValue} items={items} />;
                })}
              </Fragment>
            );
          }
          return <Products key={name} items={filteredItems} />;
        })}
      </>
    );
  }

  return <Products items={items} />;
});
