export enum WidgetConfigs {
  sortConfig = "sort_config",
  projectStatus = "project_status",
  projectStatusInsights = "project_status_insights",
  levelsToHideHeaderInTable = "levels_to_hide_header_in_table",
  customUrlForItemsWithoutURL = "custom_url_for_items_without_url",
  sectionContent = "section_content",
  linearChartConfig = "linear_chart_config",
  daysInProgressData = "days_in_progress_data",
  extraInsights = "extra_insights",
  customSectionData = "custom_section_data",
}
