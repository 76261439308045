import React from "react";
import { useStylesCustomTooltip } from "./styles";
import { Tooltip } from "@material-ui/core";
import { CustomTooltipContainerProps } from "./types";

export const CustomTooltipContainer: React.FC<CustomTooltipContainerProps> = ({
  tooltipContent,
  position = "top",
  children,
  enabled = true,
}) => {
  const classes = useStylesCustomTooltip();

  return (
    <Tooltip
      title={tooltipContent}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      arrow
      placement={position}
      disableHoverListener={!enabled}
    >
      {children}
    </Tooltip>
  );
};
