import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import {
  doneStatusProgressBarColor,
  inProgressStatusProgressBarColor,
  statusNotSetColor,
  todoStatusProgressBarColor,
} from "src/v2/helpers/colors";
import { StatusField } from "src/v2/domain/entities/customField/StatusField";
import { useWidgetContext } from "src/v2/components/Widget/WidgetContext";
import { WidgetConfigs } from "src/v2/components/Widget/WidgetConfigs";

const getStatusCategoryDataset = ({ groupedItems, labels }) => {
  const dataSets = [
    {
      label: "To Do",
      data: Array.from({ length: labels.length }, () => 0),
      backgroundColor: todoStatusProgressBarColor,
      stack: "Stack 0",
    },
    {
      label: "In Progress",
      data: Array.from({ length: labels.length }, () => 0),
      backgroundColor: inProgressStatusProgressBarColor,
      stack: "Stack 0",
    },
    {
      label: "Done",
      data: Array.from({ length: labels.length }, () => 0),
      backgroundColor: doneStatusProgressBarColor,
      stack: "Stack 0",
    },
  ];

  labels.forEach((x, i) => {
    groupedItems[i].items.forEach((item: BlockItem) => {
      const statusField = item.getStatusField() as StatusField;
      const statusCategory = statusField?.getStatusCategory();
      const dataset = dataSets.find(({ label }) => label === statusCategory);
      dataset.data[i] = dataset.data[i] + 1;
    });
  });

  return dataSets;
};

const getProjectStatusDataset = ({ groupedItems, labels }) => {
  const { getConfigValueByName } = useWidgetContext();
  const projectStatusConfig = getConfigValueByName(
    WidgetConfigs.projectStatusInsights
  );

  const dataSets = [];

  const projectStatusData = {};

  labels.forEach((x, i) => {
    groupedItems[i].items.forEach((item: BlockItem) => {
      const projectStatusField = item.getFieldByFieldKey(
        projectStatusConfig?.fieldKey
      );
      const value = projectStatusField?.value
        ? projectStatusField.value[0]
        : "Not Set";

      const config = projectStatusConfig.statuses.find(
        ({ type }) => type === value
      );

      const configName = config ? config.name : "Not Set";
      const configColor = config ? config.color : statusNotSetColor;

      if (!projectStatusData[configName]) {
        projectStatusData[configName] = {
          data: Array.from({ length: labels.length }, () => 0),
          color: configColor,
        };
      }
      projectStatusData[configName].data[i] += 1;
    });
  });

  Object.keys(projectStatusData).forEach((key) => {
    dataSets.push({
      label: key,
      data: projectStatusData[key].data,
      backgroundColor: projectStatusData[key].color,
      stack: "Stack 0",
    });
  });

  return dataSets;
};

export const getBarDataSet = ({ groupedItems, labels, subtype }) => {
  switch (subtype) {
    case "by_status_category":
      return getStatusCategoryDataset({ groupedItems, labels });
    case "by_project_status":
      return getProjectStatusDataset({ groupedItems, labels });
    default:
      //default to by_project_status
      return getProjectStatusDataset({ groupedItems, labels });
  }
};
