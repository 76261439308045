import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: { main: "#197bbd", secondary: "#3a5c75" },
    secondary: {
      main: "#527f9dab",
      secondary: "#3a5c75",
      light: "#55768D",
    },
    backgroundLight: "rgb(240 247 255)",
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
      },
    },
    MuiTab: {
      root: {
        backgroundColor: "rgb(244 248 251)",
        "&$selected": {
          backgroundColor: "#638197",
        },
      },
    },
  },
  typography: {
    body2: {
      fontFamily: ["Overpass", "sans-serif"].join(","),
    },
  },
});

export default theme;
