export class ChartData {
  labels;
  percentages;
  quantity;
  colors;

  constructor({ labels, percentages, quantity, colors }) {
    this.labels = labels;
    this.percentages = percentages;
    this.quantity = quantity;
    this.colors = colors;
  }

  getFormattedChartData() {
    return {
      labels: this.labels,
      datasets: [
        {
          data: this.percentages,
          quantity: this.quantity,
          backgroundColor: this.colors,
        },
      ],
    };
  }
}
