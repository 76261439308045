import { event } from "@fullstory/browser";

export const logViewEvent = (view: string, args: Object = {}) => {
  event("view", { "selected-view": view, ...args });
};

export const logSprintSelectEvent = (sprint: string, args: Object = {}) => {
  event("sprint-select", { "selected-sprint": sprint, ...args });
};

export const logTeamSelectEvent = (team: string, args: Object = {}) => {
  event("team-select", { "selected-team": team, ...args });
};

export const logCreateGoalEvent = (args: Object = {}) => {
  event("goal-create", { ...args });
};

export const logRefreshFromJiraEvent = (args: Object = {}) => {
  event("refresh-jira", { ...args });
};

export const logCustomEvent = (eventName: string, args: Object = {}) => {
  event(eventName, args);
};
