import { isEmailVersion } from "src/v2/utils/url";

export const getSummaryDisplay = ({
  aiSummary,
  override,
  showFullSummary,
  styles,
}: {
  aiSummary: string;
  override: string;
  showFullSummary: boolean;
  styles: Record<string, string>;
}) => {
  const hasOverride =
    !!override && override !== "<div><br/></div>" && override !== "";
  const _override = hasOverride ? `${override}<div><br/></div>` : "";

  const summary = _override + aiSummary;

  if (isEmailVersion()) {
    return { summaryDisplay: summary, isSummaryLong: false };
  }

  const isHTML = /<[a-z][\s\S]*>/i.test(summary);
  const truncateLimit = isHTML ? 80 : 210;

  const strippedSummary = stripHTML(summary);
  const isSummaryLong = strippedSummary.length > truncateLimit;

  const buttonLabel = showFullSummary ? "Show less" : "Show more";
  let summaryDisplay = summary;

  if (isSummaryLong) {
    summaryDisplay = showFullSummary
      ? summaryDisplay
      : truncateHTML(summary, truncateLimit);
    summaryDisplay =
      summaryDisplay +
      `${showFullSummary ? "" : "..."}` +
      `<button class="${styles.showMoreButton}">${buttonLabel}</button>`;
  }

  return { summaryDisplay, isSummaryLong };
};

function stripHTML(html) {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
}

function truncateHTML(html, truncateLimit) {
  let count = 0;
  let isTag = false;
  let truncated = "";

  if (html?.length === 0) {
    return "";
  }

  if (html?.length <= truncateLimit) {
    return html;
  }

  for (let i = 0; i < html.length; i++) {
    const char = html[i];

    if (char === "<") {
      isTag = true;
    } else if (char === ">") {
      isTag = false;
    } else if (!isTag) {
      count++;
    }

    truncated += char;

    if (count >= truncateLimit) {
      // Find the next closing tag to avoid truncating in the middle of a tag
      const nextCloseTagPos = html.indexOf(">", i);
      if (nextCloseTagPos !== -1) {
        // Include the entire tag in the truncated string
        truncated += html.slice(i + 1, nextCloseTagPos + 1);
      }

      // Insert ellipsis before the last closing tag if we're at the end of truncated content
      const lastCloseTagPos = truncated.lastIndexOf(">");
      const isLastCharClosingTag =
        lastCloseTagPos !== -1 && lastCloseTagPos === truncated.length - 1;
      if (isLastCharClosingTag) {
        // Place the ellipsis before the closing tag
        truncated =
          truncated.substring(0, lastCloseTagPos) +
          "..." +
          truncated.substring(lastCloseTagPos);
      } else {
        // Append the ellipsis at the end if there's no closing tag
        truncated += "...";
      }

      break;
    }
  }

  return truncated;
}
