import { BlockItem } from "../block/BlockItem";

class SectionGroupBy {
  public readonly fieldKey: string;

  public readonly fieldType: string;

  public readonly acceptedValues: string[];

  public readonly acceptedValuesObjects: {
    value: string;
    position: number;
  }[];

  public child: SectionGroupBy;

  public position: number;

  public acceptEmptyValue: Boolean;

  public valueProperty: string;

  public config: any;

  constructor({
    fieldKey,
    acceptedValues,
    child,
    position,
    fieldType,
    acceptEmptyValue,
    valueProperty = "",
    config,
  }) {
    this.fieldKey = fieldKey;
    this.fieldType = fieldType;
    this.acceptedValuesObjects = acceptedValues;
    this.acceptedValues =
      acceptedValues?.map((value) => value.value ?? value) || [];
    this.child = child;
    this.position = position;
    this.acceptEmptyValue = !!acceptEmptyValue;
    this.valueProperty = valueProperty;
    this.config = config || {};
  }

  getPositionForValue(value) {
    return (
      this.acceptedValuesObjects.find(
        (acceptedValue) => acceptedValue.value === value
      )?.position || this.position
    );
  }

  public groupItem({
    groupedItems,
    item,
  }: {
    groupedItems: any;
    item: BlockItem;
  }) {
    let fieldValues =
      item
        .getField({
          fieldKey: this.fieldKey,
          fieldType: this.fieldType,
        })
        ?.getValue() || [];
    if (!Array.isArray(fieldValues)) {
      fieldValues = [fieldValues];
    }
    if (fieldValues.length === 0) {
      fieldValues = [""]; // empty string is a default value for empty field
    }

    const values = fieldValues.map((value) => {
      return value[this.valueProperty] ?? value?.type ?? value;
    });

    const acceptOther = this.config?.accept_other ?? true;

    values.forEach((value) => {
      if (this.acceptedValues.length > 0) {
        if (this.acceptedValues.includes(value)) {
          if (!groupedItems[value]) {
            // eslint-disable-next-line no-param-reassign
            groupedItems[value] = {
              items: [],
              position: this.getPositionForValue(value),
            };
          }
          groupedItems[value].items.push(item);
        } else {
          if (!acceptOther) return;
          if (!groupedItems["Other"]) {
            // eslint-disable-next-line no-param-reassign
            groupedItems["Other"] = {
              items: [],
              position: 999,
            };
          }
          groupedItems["Other"].items.push(item);
        }
      } else {
        if (!groupedItems[value]) {
          // eslint-disable-next-line no-param-reassign
          groupedItems[value] = {
            items: [],
            position: this.getPositionForValue(value),
          };
        }
        groupedItems[value].items.push(item);
      }
    });
  }
}

export default SectionGroupBy;
