import { Box, Typography } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";
import "./teamField.css";

const maxTeams = 3;

export class TeamField extends CustomFieldBase {
  constructor(field) {
    super(field);
    const teams = {};

    if (field.value?.length > 0) {
      field.value.forEach(({ display_name }) => {
        teams[display_name] = null;
      });
    } else {
      teams["Not Set"] = null;
    }
    this.value = Object.keys(teams);
  }

  updateValue(value: any): void {
    const teams = {};

    if (value?.length > 0) {
      value.forEach((team) => {
        teams[team] = null;
      });
    } else {
      teams["Not Set"] = null;
    }
    this.value = Object.keys(teams);
  }

  getExtraInfo({ fieldsState, setFieldsState }) {
    const isExpanded = fieldsState.teamField?.isExpanded;
    const showExtraTeams = this.value.length > maxTeams;

    if (!showExtraTeams) {
      return null;
    }
    if (isExpanded) {
      return (
        <Box style={{ width: "256px !important" }}>
          {this.value.slice(maxTeams).map((team) => (
            <Box className="TeamContainer">
              <Typography
                noWrap
                align="center"
                style={{ color: `${primaryTextColor}` }}
              >
                {team}
              </Typography>
            </Box>
          ))}
          <Box
            className="ExpandableButton"
            onClick={() => {
              setFieldsState((prevState) => ({
                ...prevState,
                teamField: {
                  isExpanded: !isExpanded,
                },
              }));
            }}
          >
            <ArrowDropUpIcon />
            <Typography
              noWrap
              align="center"
              style={{ color: `${primaryTextColor}` }}
            >
              see less
            </Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Box
        className="ExpandableButton"
        onClick={() => {
          setFieldsState((prevState) => ({
            ...prevState,
            teamField: {
              isExpanded: !isExpanded,
            },
          }));
        }}
      >
        <Typography
          noWrap
          align="center"
          style={{ color: `${primaryTextColor}` }}
        >
          +{this.value.length - maxTeams} more
        </Typography>
      </Box>
    );
  }

  getComponentForGrid({ fieldsState, setFieldsState }) {
    return (
      <>
        {this.value.slice(0, maxTeams).map((team) => {
          const teamToolTip = team.length > 20 ? team : "";
          return (
            <Box className="TeamContainer" data-test-id="team_container">
              <BootstrapTooltipWithoutPortal title={teamToolTip}>
                <Typography
                  noWrap
                  align="center"
                  data-test-id="team_text"
                  style={{ color: `${primaryTextColor}`, fontSize: "14px" }}
                >
                  {team}
                </Typography>
              </BootstrapTooltipWithoutPortal>
            </Box>
          );
        })}
        {this.getExtraInfo({ fieldsState, setFieldsState })}
      </>
    );
  }
}
