import { getSortedProjectStatuses } from "src/v3/utils/ProjectStatus/getSortedProjectStatuses";
import { AcceptedValue, SortConfigItem } from "./types";
import { ProjectStatusPositionType } from "src/v3/utils/types";

export const defaultSortConfig: SortConfigItem[] = [
  {
    fieldKey: "project_status",
    position: 1,
    valueProperty: "name",
    acceptedValues: getSortedProjectStatuses(
      ProjectStatusPositionType.sort_position
    ).map(
      (status): AcceptedValue => ({
        position: status.sort_position,
        value: status.name,
      })
    ),
  },
  {
    fieldKey: "allocation",
    position: 2,
    valueProperty: "bandwidth",
    type: "number",
    direction: "desc",
  },
  {
    fieldKey: "risk_comparison_date",
    position: 3,
    type: "date",
    direction: "asc",
  },
  {
    fieldKey: "Status",
    position: 4,
    valueProperty: "category",
  },
  {
    fieldKey: "Summary",
    position: 5,
    direction: "asc",
  },
];
