import { filterDuplicatedInList } from "src/utils/listUtils";
import { FilterFieldBase } from "src/v2/domain/entities/filter/FilterFieldBase";
import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { useGenericViewContext } from "../genericView/GenericViewContext";

export const useFilterByLevel = () => {
  const { viewSchema } = useGenericViewContext();
  const buildFilterLevelFilterField = (selectedLevels) => {
    const filterLevelField = {
      displayName: "filter_level",
      type: FieldConfigType.text,
      fieldKey: "filter_level",
      origin: "velma",
      values: selectedLevels,
      valuesCopy: selectedLevels,
    };
    return new FilterFieldBase(filterLevelField);
  };

  function getFilterLevelField(
    filters,
    selectedFilterLevels,
    filterLevelOptions,
    setSelectedFilterLevelsCallback
  ) {
    const selectedFiltersHaveFilterLevel = filters.find(
      ({ fieldKey }) => fieldKey === "filter_level"
    );
    let filterLevelBase = null;

    // This case is to fill the URL with all possible values
    // when the user clicks on the total items button in executive view
    // Setting this in ProjectStatusProgressSection.tsx
    if (selectedFiltersHaveFilterLevel) {
      filterLevelBase = selectedFiltersHaveFilterLevel.values;
    }

    // This case is to keep the levels selected in the URL when
    // the user come to the current page from a external/bookmarked link
    if (selectedFilterLevels.length > 0) {
      filterLevelBase = selectedFilterLevels;
    }

    // This case is to check all levels again if the user
    // unchecked it in the filter and apply
    if (!filterLevelBase) {
      filterLevelBase = filterLevelOptions;
      setSelectedFilterLevelsCallback(filterLevelOptions);
    }

    return buildFilterLevelFilterField(filterLevelBase);
  }

  const getAllItemTypes = (items, allTypes = []) => {
    items.forEach(({ type, children }) => {
      const { value } = type;
      const schema = viewSchema.find(
        ({ view_block_type }) => view_block_type === value
      );
      if (schema) {
        allTypes.push(schema.display_name);
      }
      if (children) {
        getAllItemTypes(children, allTypes);
      }
    });
    return allTypes;
  };

  // This function gets the possible levels of each filter item
  // that is passed in. It does this by checking each item's
  // filterLevel to see if it is in the list of all possible levels.
  // If it is, it is added to the list of possible levels.
  // It returns the list of possible levels.
  function getPossibleLevelsByItems(items) {
    const possibleLevelsToFilterByItems = [];
    const allTypes = filterDuplicatedInList({
      items: getAllItemTypes(items),
    });
    allTypes.forEach((item) => {
      if (!possibleLevelsToFilterByItems.includes(item)) {
        possibleLevelsToFilterByItems.push(item);
      }
    });
    return possibleLevelsToFilterByItems;
  }

  return {
    getFilterLevelField,
    getPossibleLevelsByItems,
    buildFilterLevelFilterField,
  };
};
