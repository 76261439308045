import { styled, Box, Theme } from "@material-ui/core";

export const IssueTypeIconRoot = styled(Box)(
  ({
    theme,
    backgroundColor,
    size,
  }: {
    theme: Theme;
    backgroundColor: string;
    size: "small" | "medium" | "large";
  }) => ({
    backgroundColor: backgroundColor,
    display: "flex",
    padding: theme.spacing(1),
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    borderRadius: size === "large" ? theme.spacing(1.25) : theme.spacing(0.75),
  })
);
