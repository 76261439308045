import Widget from "./Widget";

export enum WidgetConfigType {
  group = "group",
  currentQuarterFilter = "current_quarter_filter",
}

class WidgetConfig {
  constructor(
    private id: string,
    private widget: Widget,
    public name: string,
    public type: WidgetConfigType,
    public groupBy: {},
    public filters: [],
    private value: [
      {
        name: string;
        value: {};
      },
    ]
  ) {
    this.id = id;
    this.widget = widget;
    this.name = name;
    this.value = value;
    this.type = type;
    this.groupBy = groupBy;
    this.filters;
  }

  public getId(): string {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getValue(): {} {
    return this.value;
  }
}
export default WidgetConfig;
