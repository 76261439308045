import { useContext, createContext, useMemo, useState, useEffect } from "react";
import { FieldConfig } from "src/v2/domain/entities/fieldConfig/FieldConfig";
import { getFieldsConfigs } from "src/v2/services/fieldsConfigs";
import { isEmpty } from "src/v2/utils/object";
import {
  insightsBackground,
  insightsHeaderBackground,
} from "src/v2/helpers/colors";
import { useGenericViewContext } from "../genericView/GenericViewContext";
import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";
import { WidgetConfigs } from "src/v2/components/Widget/WidgetConfigs";
import { useWidgetContext } from "src/v2/components/Widget/WidgetContext";
import { defaultProjectStatusConfig } from "src/v2/components/Widget/WidgetProjectStatusConfig";

const FieldsContext = createContext<{
  fieldsConfig: FieldConfig[];
  fieldsToShowOnGrid;
  isFieldInVelmaInsights;
  getInsightsHeaderStyles;
  getHeaderFields;
  velmaInsightsFields;
  setFieldsToShowOrUseInFilter;
  fieldsToShowOrUseInFilter;
  getFieldStyles;
  getGridFieldsByItemTarget;
}>({
  fieldsConfig: [],
  fieldsToShowOnGrid: [],
  isFieldInVelmaInsights: () => {},
  getInsightsHeaderStyles: () => {},
  getHeaderFields: () => {},
  setFieldsToShowOrUseInFilter: () => {},
  fieldsToShowOrUseInFilter: [],
  velmaInsightsFields: [],
  getFieldStyles: () => {},
  getGridFieldsByItemTarget: () => {},
});

function useFieldsContext() {
  const context = useContext(FieldsContext);
  return context;
}

function FieldsContextProvider({ children }: any) {
  const [fieldsConfig, setFieldsConfig] = useState([]);
  const [fieldsToShowOnGrid, setFieldsToShowOnGrid] = useState([]);
  const [fieldsToShowOrUseInFilter, setFieldsToShowOrUseInFilter] = useState(
    []
  );
  const { currentViewData, showInsights } = useGenericViewContext();

  const [velmaInsightsFields, setVelmaInsightsFields] = useState([]);
  const { getConfigValueByName } = useWidgetContext();

  const isFieldInVelmaInsights = (field) => field?.origin === "velma_insights";

  function getGridFieldsByItemTarget({ target }) {
    return fieldsToShowOnGrid.filter((field) => field.target === target);
  }

  const getHeaderFields = (target): FieldConfig[] => {
    const headers = getGridFieldsByItemTarget({
      target,
    });
    if (!showInsights) {
      return headers.filter((field) => !isFieldInVelmaInsights(field));
    }
    return headers;
  };

  const getInsightsFieldStyles = (field: CustomFieldBase) => {
    if (!field) {
      return { display: "none", opacity: 0, visibility: "none", zIndex: -1 };
    }
    if (field.getFieldKey() === "project_status_insights") {
      const config =
        getConfigValueByName(WidgetConfigs.projectStatusInsights) ||
        defaultProjectStatusConfig;

      const { backgroundColor } = field.getComponentPropertiesFromConfig({
        config,
      });
      return {
        backgroundColor: backgroundColor,
        maxWidth: "100%",
      };
    }
    if (showInsights) {
      return {
        backgroundColor: insightsBackground,
        maxWidth: "100%",
      };
    }
    if (currentViewData?.options?.styles?.[field.type]) {
      return currentViewData?.options?.styles[field.type];
    }
    return { display: "none", opacity: 0, visibility: "none", zIndex: -1 };
  };
  const getFieldStyles = ({ field }) => {
    if (isFieldInVelmaInsights(field)) {
      return getInsightsFieldStyles(field);
    }
    return {};
  };

  const getInsightsHeaderStyles = (field) => {
    if (currentViewData?.options?.styles?.[field.type]) {
      return currentViewData?.options?.styles[`${field.type}Header`];
    }

    const defaultStyle = { backgroundColor: insightsHeaderBackground };
    if (field.key === "risks") {
      return { ...defaultStyle, width: "256" };
    }
    return defaultStyle;
  };
  function getGridFields(_fieldsConfig: any) {
    const _gridFields = _fieldsConfig
      .filter((field) => field.showOnGrid())
      .sort((a, b) => a.getGridConfig().position - b.getGridConfig().position);
    if (!showInsights) {
      return _gridFields.filter((field) => !isFieldInVelmaInsights(field));
    }
    return _gridFields;
  }
  useEffect(() => {
    if (showInsights === undefined) return;
    const gridFields = getGridFields(fieldsConfig);
    setFieldsToShowOnGrid(gridFields);
  }, [showInsights]);

  useEffect(() => {
    if (isEmpty(currentViewData)) {
      return;
    }
    getFieldsConfigs({ viewId: currentViewData.id }).then((result) => {
      const _fieldsConfig = result.map((field) => new FieldConfig(field));
      setFieldsConfig(_fieldsConfig);
    });
  }, [currentViewData]);

  useEffect(() => {
    if (fieldsConfig) {
      const gridFields = getGridFields(fieldsConfig);
      const _velmaInsightsFields = fieldsConfig.filter((field) =>
        isFieldInVelmaInsights(field)
      );
      setVelmaInsightsFields(_velmaInsightsFields);
      setFieldsToShowOnGrid(gridFields);
    }
  }, [fieldsConfig]);

  const value = useMemo(
    () => ({
      fieldsConfig,
      fieldsToShowOnGrid,
      isFieldInVelmaInsights,
      getInsightsHeaderStyles,
      getHeaderFields,
      velmaInsightsFields,
      getFieldStyles,
      fieldsToShowOrUseInFilter,
      setFieldsToShowOrUseInFilter,
      getGridFieldsByItemTarget,
    }),
    [fieldsConfig, fieldsToShowOnGrid]
  );

  return (
    <FieldsContext.Provider value={value}>{children}</FieldsContext.Provider>
  );
}

export { FieldsContext, FieldsContextProvider, useFieldsContext };
