import { DrawerFooterRoot, FooterCloseButton } from "./styles";

export const ItemDetailerDrawerFooter = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  return (
    <DrawerFooterRoot>
      <FooterCloseButton onClick={handleClose}>Close</FooterCloseButton>
    </DrawerFooterRoot>
  );
};
