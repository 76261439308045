import { Grid, Typography, IconButton } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useState } from "react";

interface DropdownItem {
  displayName?: string;
  title?: string;
}

export const Dropdown = ({
  item,
  children,
  expandable = true,
  startExpanded = false,
  classesStyle = {},
  customHeaderStyle = {},
}: {
  item: DropdownItem;
  children: any;
  classesStyle?: ClassNameMap<any>;
  customHeaderStyle?: {};
  expandable?: boolean;
  startExpanded?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(startExpanded);

  const handleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const ExpandIcon = isExpanded ? <ExpandLess /> : <ExpandMore />;

  return (
    <Grid container direction="column" className={classesStyle.root}>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        onClick={(e) => (expandable ? handleExpand(e) : null)}
        className={classesStyle.header}
        style={{ ...customHeaderStyle }}
      >
        <Typography className={classesStyle.headerText}>
          {item.displayName ?? item.title}
        </Typography>
        {expandable && ExpandIcon}
      </Grid>
      {isExpanded && expandable && children}
    </Grid>
  );
};
