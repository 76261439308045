import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { CostField } from "src/v2/domain/entities/customField/CostField";
import { MoneyField } from "src/v2/domain/entities/customField/MoneyField";
import Insight from "./Insight";

const getCostAggregationByFieldKey = ({ items, fieldKey }) => {
  return items.reduce((acc, item: BlockItem) => {
    const cost = item.getFieldByFieldKey(fieldKey) as MoneyField;
    const value = cost?.getIntegerValue() || 0;
    return acc + value;
  }, 0);
};

const getCostFieldAggregation = ({ items }: { items: BlockItem[] }) => {
  return items.reduce(
    ({ costToDate = 0, projectedCost = 0 }, item: BlockItem) => {
      const cost = item.getFieldByFieldKey("cost") as CostField;
      const _costToDate = cost?.getCostToDate() || {};
      const _projectedCost = cost?.getProjectedCost() || {};

      return {
        costToDate: costToDate + cost?.getIntegerValue(_costToDate?.value) || 0,
        projectedCost:
          projectedCost + cost?.getIntegerValue(_projectedCost?.value) || 0,
      };
    },
    {
      costToDate: 0,
      projectedCost: 0,
    }
  );
};

export const CostInsightAggregation = ({ items }: { items: BlockItem[] }) => {
  if (!items || items.length === 0) {
    return null;
  }

  const _costField = items[0].getFieldByFieldKey("cost") as CostField;
  const _projectedCostField = items[0].getFieldByFieldKey(
    "projected_total_cost"
  ) as MoneyField;
  const _costToDateField = items[0].getFieldByFieldKey(
    "cost_to_date"
  ) as MoneyField;

  if (!_costField && !_projectedCostField && !_costToDateField) {
    return null;
  }

  const fieldValue = [];

  if (_costField) {
    const _costFieldAggregation = getCostFieldAggregation({ items });
    const cost_to_date = _costFieldAggregation.costToDate;
    const projected_total_cost = _costFieldAggregation.projectedCost;

    if (cost_to_date) {
      fieldValue.push({ field_key: "cost_to_date", value: cost_to_date });
    }
    if (projected_total_cost) {
      fieldValue.push({
        field_key: "projected_total_cost",
        value: projected_total_cost,
      });
    }
  } else {
    if (_costToDateField) {
      const _costToDateAggregation = getCostAggregationByFieldKey({
        items,
        fieldKey: "cost_to_date",
      });
      fieldValue.push({
        field_key: "cost_to_date",
        value: _costToDateAggregation,
      });
    }
    if (_projectedCostField) {
      const _projectedTotalCostAggregation = getCostAggregationByFieldKey({
        items,
        fieldKey: "projected_total_cost",
      });
      fieldValue.push({
        field_key: "projected_total_cost",
        value: _projectedTotalCostAggregation,
      });
    }
  }

  const __costField = new CostField({
    fieldKey: "cost",
    value: fieldValue,
  });

  return (
    <Insight title="Cost">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 8,
        }}
      >
        {__costField.getComponentForInsightAggregation({})}
      </div>
    </Insight>
  );
};
