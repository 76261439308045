import { Grid } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";

export const ExpandTableTag = ({ children, customStyle = {} }) => (
  <Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    style={{
      background: primaryTextColor,
      borderBottomRightRadius: 6,
      height: "22px",
      padding: "0px 2px",
      ...customStyle,
    }}
  >
    {children}
  </Grid>
);
