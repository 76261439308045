import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import BaseButton from "src/components/Buttons/BaseButton";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { EventTypes } from "src/providers/EventContext";
import { useOverrideContext } from "src/providers/OverrideContext";

import setErrorSnackbar, {
  setSuccessSnackbar,
} from "src/services/setErrorSnackbar";
import * as sessionService from "src/services/sessionService";
import CustomDialog from "src/v2/components/CustomDialog";
import ReactQuill from "react-quill";
import { updateRisks } from "src/services/v2/insightsService";
import { getHashCode } from "src/utils/stringUtils";
import { addClassToHtml } from "src/utils/htmlUtils";
import { ContainerWithToolTip } from "src/components/GlobalComponents";
import { mapWeeksToDate } from "src/v3/components/Dialogs/ProjectStatusEdit/utils";

export const AddRiskModal = ({ item }: { item: BlockItem }) => {
  const { saveOverride } = useOverrideContext();

  const user = sessionService.getLoggedUser();

  const [open, setOpen] = useState(false);

  const [riskName, setRiskName] = useState("");
  const [nameInputTouched, setNameInputTouched] = useState(false);
  const [validName, setValidName] = useState(false);

  const [riskDetails, setRiskDetails] = useState("");
  const [detailsInputTouched, setDetailsInputTouched] = useState(false);
  const [validDetails, setValidDetails] = useState(false);

  const [retainOverride, setRetainOverride] = useState(false);
  const [expirationDate, setExpirationDate] = useState("2 weeks");

  const defaultExpirationDateInDays = 10;
  const expirationWeekOptions = ["1 week", "2 weeks", "3 weeks", "4 weeks"];
  const handleExpirationDateChange = (e) => {
    setExpirationDate(e.target.value);
  };

  const handleRetainOverrideChange = (event) => {
    setRetainOverride(event.target.checked);
  };

  const [isSaving, setIsSaving] = useState(false);

  const handleRiskNameChange = (event) => {
    setRiskName(event.target.value);
    if (!nameInputTouched) {
      setNameInputTouched(true);
    }
    setValidName(event.target.value.trim() !== "");
  };

  const handleRiskDetailsChange = (content, delta, source, editor) => {
    const text = editor.getText();
    setRiskDetails(content);
    if (!detailsInputTouched) {
      setDetailsInputTouched(true);
    }
    setValidDetails(text.trim() !== "");
  };

  const enableSaveButton = validName && validDetails;

  const resetState = (e) => {
    e.stopPropagation();
    setRiskName("");
    setValidName(false);
    setNameInputTouched(false);
    setRiskDetails("");
    setValidDetails(false);
    setDetailsInputTouched(false);
    setIsSaving(false);
    setOpen(false);
  };

  const noCHangesInState = () => {
    return riskName === "" && riskDetails === "";
  };

  const onSave = async (e) => {
    e.stopPropagation();
    if (noCHangesInState()) {
      setOpen(false);
      setIsSaving(false);
      return;
    }

    if (riskName.trim() === "") {
      setValidName(false);
      if (!nameInputTouched) {
        setNameInputTouched(true);
      }
      return;
    }

    if (riskDetails.trim() === "") {
      setValidDetails(false);
      if (!detailsInputTouched) {
        setDetailsInputTouched(true);
      }
      return;
    }

    const _expirationDate = retainOverride
      ? mapWeeksToDate(expirationDate)
      : new Date(
          new Date().setDate(new Date().getDate() + defaultExpirationDateInDays)
        );

    const formattedTitle = riskName?.replace(/\s*\(\d+\)$/, "");
    const uniqueId = getHashCode(
      new Date().getTime().toString() + item.getRiskField().fieldKey
    );
    const value = {
      id: uniqueId,
      title: formattedTitle,
      group: formattedTitle,
      message: formattedTitle,
      severity: 0,
      enabled: true,
      options: [
        {
          type: "html",
          value: addClassToHtml(riskDetails),
        },
      ],
      action: "add",
      author: {
        name: user?.name,
        email: user?.email,
      },
    };

    const override = {
      retainOverride: retainOverride,
      expirationDate: _expirationDate,
      justification: "",
      fieldKey: item.getRiskField().fieldKey,
      value,
      blockColumn: item.getRiskField().fieldKey,
      createdAt: new Date(),
      id: uniqueId,
    };

    try {
      await updateRisks(override, item.getKey());

      saveOverride({
        item,
        overrides: [override],
        event: {
          type: EventTypes.RISK_ADDED,
          message: `Risk has being added by ${user?.name} on ${new Date().toLocaleDateString()} for ${item.getKey()}`,
        },
      });
      setSuccessSnackbar("Risk added successfully!");
      resetState(e);
    } catch (error) {
      console.error(error);
      setErrorSnackbar(
        "Failed to added this risk, please try again later, if the issue persists, contact us."
      );
      resetState(e);
    } finally {
      setOpen(false);
      setIsSaving(false);
    }
  };

  const requiredInputStyle = {
    borderColor: "red",
    borderWidth: "2px",
  };

  return (
    <div>
      <Typography
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
        style={{
          color: `#1A7BBD`,
          textAlign: "center",
          marginTop: 8,
          marginBottom: 8,
          textUnderlineOffset: 4,
          textDecoration: "underline",
          fontWeight: 600,
          fontSize: "15px",
          cursor: "pointer",
          lineHeight: "16px",
          font: "inherit",
        }}
      >
        Add
      </Typography>
      {open && (
        <CustomDialog
          open={open}
          onClose={resetState}
          customContentStyle={{
            padding: "20px",
            width: "600px",
          }}
          title="Add Risk"
          customTitleStyle={{
            color: "#2C485B",
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: 700,
          }}
        >
          {
            <Grid container direction="column">
              <Grid container direction="column">
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "16.8px",
                    marginBottom: "10px",
                  }}
                >
                  Name
                </Typography>
                <FormControl variant="outlined" required>
                  <TextField
                    variant="outlined"
                    placeholder="Enter risk name"
                    value={riskName}
                    onChange={handleRiskNameChange}
                    error={!validName && nameInputTouched}
                    helperText={
                      !validName && nameInputTouched
                        ? "This field is required."
                        : ""
                    }
                    inputProps={{ maxLength: 70 }}
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                    }}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid
                container
                direction="column"
                style={{
                  marginTop: "20px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "16.8px",
                    textAlign: "left",

                    marginBottom: "10px",
                  }}
                >
                  Details
                </Typography>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                    ],
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                  value={riskDetails || ""}
                  onChange={handleRiskDetailsChange}
                  placeholder={"Enter risk details here..."}
                  style={{
                    border:
                      !validDetails && detailsInputTouched
                        ? `1px solid ${requiredInputStyle.borderColor}`
                        : "none",
                  }}
                />
                {!validDetails && detailsInputTouched && (
                  <Typography
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "8px",
                    }}
                  >
                    This field is required.
                  </Typography>
                )}
              </Grid>
              <Grid
                container
                direction="column"
                style={{
                  width: "100%",
                  marginTop: 16,
                }}
              >
                <FormControlLabel
                  style={{
                    marginTop: "8px",
                  }}
                  control={
                    <Checkbox
                      key={`retainStatus-${Math.random()}`}
                      checked={retainOverride}
                      onChange={handleRetainOverrideChange}
                      color="primary"
                    />
                  }
                  label={
                    <ContainerWithToolTip
                      tooltipText={`If unselected, the risk will be deleted after ${defaultExpirationDateInDays} days.`}
                    >
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16.8px",
                          textAlign: "left",
                        }}
                      >
                        Keep this risk for a certain duration
                      </Typography>
                    </ContainerWithToolTip>
                  }
                />
                {retainOverride && (
                  <FormControl variant="outlined">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={expirationDate}
                      onChange={handleExpirationDateChange}
                      required
                      style={{
                        height: "33px",
                        marginTop: "8px",
                        borderRadius: "4px",
                        width: "208px",
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {expirationWeekOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          }
          <Divider
            style={{
              marginTop: "20px",
            }}
          ></Divider>
          <Grid container justifyContent="flex-end">
            <BaseButton
              style={{
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                border: "1px solid #1A7BBD",
                color: "#2C485B",
                marginRight: "10px",
                marginTop: "20px",
              }}
              onClick={resetState}
            >
              Cancel
            </BaseButton>
            {isSaving ? (
              <div
                style={{
                  alignSelf: "center",
                  width: "50px",
                }}
              >
                <CircularProgress
                  style={{
                    alignSelf: "center",
                    marginTop: "20px",
                    width: "24px",
                    height: "24px",
                  }}
                ></CircularProgress>
              </div>
            ) : (
              <BaseButton
                style={{
                  backgroundColor: !enableSaveButton ? "#D9D9D9" : "#1A7BBD",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "#FFFFFF",
                  marginTop: "20px",
                }}
                disabled={!enableSaveButton}
                onClick={async (e) => {
                  setIsSaving(true);
                  await onSave(e);
                }}
              >
                Save
              </BaseButton>
            )}
          </Grid>
        </CustomDialog>
      )}
    </div>
  );
};
