import SectionGroupBy from "src/v2/domain/entities/section/SectionGroupBy";

export const getGroupedItems = ({ groupBy, items }) => {
  const groupedItems = {};
  if (!groupBy || (!groupBy.fieldKey && !groupBy.fieldType)) {
    return [];
  }
  const groupByObject = new SectionGroupBy(groupBy);

  items?.forEach((item) => {
    groupByObject.groupItem({ groupedItems, item });
  });
  const groupedItemsList = [];
  Object.entries(groupedItems).forEach(([fieldValue, { position, items }]) => {
    if (fieldValue !== "" || groupBy.acceptEmptyValue) {
      groupedItemsList.push({
        fieldValue,
        items,
        position,
        fieldKey: groupBy.fieldKey,
      });
    }
  });
  return groupedItemsList.sort(
    (a, b) =>
      a.position - b.position || a.fieldValue.localeCompare(b.fieldValue)
  );
};
