import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { ContainerWithToolTip } from "src/components/GlobalComponents";
import {
  defaultProgressBarBackgroundColor,
  getAccessibleColors,
  progressBarTitleColor,
} from "src/v2/helpers/colors";
import Tooltip from "@material-ui/core/Tooltip";
import { InfoOutlined } from "@material-ui/icons";

const useStylesLegends = makeStyles((theme) => ({
  progress_percentage: {
    padding: "2px 6px",
    textTransform: "uppercase",
    marginLeft: 0,
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    marginTop: 0,
    fontSize: 11,
    width: "100%",
  },
  tooltipContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  customTooltip: {
    backgroundColor: "white",
    border: "1px solid rgba(0, 0, 0, 0.1)", // Optional, to give it a subtle border.
    padding: 8,
  },
}));

const useStyles = makeStyles({
  progress_percentage: {
    "@supports ( -moz-appearance:none )": {
      marginTop: 16,
      marginBottom: 16,
    },
  },
});

const ProgressBar = ({
  backgroundColor,
  percentages: initialPercentages,
  title = "",
  showProgressBarLegends = true,
  customBorderRadius = "5px",
  customHeight = "10px",
  customBoxStyle = {},
  customBarsGridStyle = {},
}) => {
  const percentages = [...initialPercentages];

  let aggregatedValues = 0;
  if (percentages.length > 1) {
    const sum = percentages.reduce((a, b) => a + b.percentage, 0);
    const amountToSubtract = sum - 100;
    if (sum > 100) {
      percentages[percentages.length - 1].percentage -= amountToSubtract;
    }
  }

  const classes = useStyles();
  return (
    <Box style={{ ...customBoxStyle }}>
      <Typography
        style={{
          color: `${progressBarTitleColor}`,
          marginBottom: 12,
          letterSpacing: 0.33,
        }}
      >
        {title}
      </Typography>
      <Grid
        container
        style={{
          marginBottom: 0,
          marginTop: 0,
          backgroundColor,
          maxWidth: "100%",
          width: "100%",
          borderRadius: `${customBorderRadius}`,
          ...customBarsGridStyle,
        }}
      >
        {percentages.length === 0 ? (
          <div
            style={{
              borderRadius: 15,
              backgroundColor: defaultProgressBarBackgroundColor,
              overflow: "hidden",
              width: "100%",
              height: 24,
            }}
          />
        ) : (
          renderBars()
        )}
      </Grid>
      {showProgressBarLegends && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {renderLegends()}
        </div>
      )}
    </Box>
  );

  function renderLegends() {
    const classes = useStylesLegends();
    const tooltipContent = (
      <div className={classes.tooltipContent}>
        {percentages.map((item) => {
          const { text, background } = getAccessibleColors(item.color);
          return (
            <div
              className={classes.progress_percentage}
              style={{
                color: `${text}`,
                backgroundColor: `${background}`,
              }}
            >
              <div>{item.name}:</div>
              <div style={{ marginLeft: 4 }}>{item.percentage.toFixed(0)}%</div>
            </div>
          );
        })}
      </div>
    );

    return (
      <Box
        display="flex"
        justifyContent="right"
        style={{ marginTop: -55, marginLeft: 260, position: "absolute" }}
      >
        <Tooltip
          title={tooltipContent}
          placement="bottom"
          classes={{ tooltip: classes.customTooltip }}
        >
          <InfoOutlined fontSize="small" style={{ color: "grey" }} />
        </Tooltip>
      </Box>
    );
  }
  function renderBars() {
    return percentages.map((item, index) => {
      const isFirst = index === 0 || aggregatedValues === 0;
      const previousAggregatedValues = aggregatedValues;
      aggregatedValues += parseFloat(item.percentage);

      let borderRadius = "0";
      if (isFirst || item.percentage >= 100) {
        borderRadius = `${customBorderRadius} 0 0 ${customBorderRadius}`;
      }
      if (aggregatedValues >= 99.97 || previousAggregatedValues >= 99.97) {
        borderRadius = `0 ${customBorderRadius} ${customBorderRadius} 0`;
      }
      if (
        (isFirst && aggregatedValues === 100) ||
        parseInt(item.percentage, 10) === 100
      ) {
        borderRadius = `${customBorderRadius}`;
      }
      const itemsLabel = item.totalItems === 1 ? "item" : "items";
      const text = `${item.name}: ${item.percentage.toFixed(0)}% (${
        item.totalItems
      } ${itemsLabel})`;
      return (
        <ContainerWithToolTip tooltipText={text}>
          <Box
            key={index}
            style={{
              width: `${item.percentage}%`,
              height: `${customHeight}`,
              backgroundColor: item.color,
              borderRadius,
            }}
          ></Box>
        </ContainerWithToolTip>
      );
    });
  }
};
export default ProgressBar;
