import { createContext, useContext, useMemo } from "react";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { EventTypes, useEventContext } from "./EventContext";
import { UserOverrideFields } from "src/v2/domain/interfaces/UserOverrideFields";

const OverrideContext = createContext({
  saveOverride: ({
    item,
    overrides,
    event,
  }: {
    item: BlockItem;
    overrides: any[];
    event: {
      type: EventTypes;
      message: string;
    };
  }) => {},
});

function useOverrideContext() {
  const context = useContext(OverrideContext);
  if (!context) {
    throw new Error(
      "useOverrideContext must be used within an OverrideProvider"
    );
  }
  return context;
}

const OverrideProvider = ({ children }) => {
  const { emit } = useEventContext();
  async function saveOverride({ item, overrides, event }) {
    updateItemWithOverrideValues(item, overrides);
    emit(event.type, {
      message: event.message,
    });
  }

  function updateItemWithOverrideValues(
    item: BlockItem,
    overrides: UserOverrideFields[]
  ) {
    overrides?.forEach((override) => {
      item.updateOverrideValues(override);
    });

    return item;
  }

  const value = useMemo(
    () => ({
      saveOverride,
    }),
    []
  );

  return (
    <OverrideContext.Provider value={value}>
      {children}
    </OverrideContext.Provider>
  );
};

export { OverrideProvider, useOverrideContext };
