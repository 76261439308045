export function isObjectEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

export function filterKeysObjectByReference(
  obj: object,
  predicate: (key: string) => boolean
) {
  if (obj) {
    Object.keys(obj)
      .filter(predicate)
      .forEach((x) => delete obj[x]);
  }
}

export const deepClone = (obj: object) => JSON.parse(JSON.stringify(obj));

export const deepCloneClassInstance = (item: any) =>
  Object.assign(Object.create(Object.getPrototypeOf(item)), item);
