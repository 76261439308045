export const filterDuplicateItemsByKey = ({ items, key = "key" }) =>
  items.filter(
    (value, index, self) =>
      index === self.findIndex((item) => item[key] === value[key])
  );

export const filterDuplicatedInList = ({ items }) =>
  items.filter(
    (value, index, self) => index === self.findIndex((item) => item === value)
  );

export const findValueFromMultipleArrayLevels = (input) => {
  if (Array.isArray(input)) {
    for (const item of input) {
      const found = findValueFromMultipleArrayLevels(item);
      if (found !== undefined) {
        return found;
      }
    }
  } else if (typeof input === "object" && input !== null) {
    const values = Object.values(input);
    for (const value of values) {
      const found = findValueFromMultipleArrayLevels(value);
      if (found !== undefined) {
        return found;
      }
    }
  } else {
    return input;
  }
  return undefined;
};

export const sortArrayByDate = ({ array, key }) => {
  return array.sort((a, b) => {
    const dateA = new Date(a[key]);
    const dateB = new Date(b[key]);
    return dateB - dateA;
  });
};

interface GroupByOrder {
  [key: number]: string;
}

export function groupArrayOfObjectsByKeys<T>(
  data: T[],
  groupByConfig: GroupByOrder
): any {
  if (Object.keys(groupByConfig).length === 0) {
    console.warn("No groupByOrder config provided. Returning data as is.");
    return data;
  }

  const keys = Object.keys(groupByConfig)
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map((key) => groupByConfig[parseInt(key)]);

  function recursiveGroupBy(data: T[], keys: string[]): any {
    if (keys.length === 0) {
      return data;
    }

    const key = keys[0];
    const grouped: { [key: string]: T[] } = {};

    data.forEach((item) => {
      // Check if the item has the key, if not use 'key_not_set'
      const keyValue = key in item ? item[key] : `${key}_not_set`;
      if (!grouped[keyValue]) {
        grouped[keyValue] = [];
      }
      grouped[keyValue].push(item);
    });

    if (keys.length > 1) {
      Object.keys(grouped).forEach((groupKey) => {
        grouped[groupKey] = recursiveGroupBy(grouped[groupKey], keys.slice(1));
      });
    }

    return grouped;
  }

  return recursiveGroupBy(data, keys);
}
