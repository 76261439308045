import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import {
  getEmailReportsInstance,
  shouldUseReportsFilter,
} from "src/v2/domain/entities/emailReports/EmailReportsFactory";
import { FilterLogic } from "src/v2/domain/enum/FilterLogic";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";

export const useFilterHook = () => {
  const { selectedFilterLevels } = useFilterContext();
  const { viewSchema } = useGenericViewContext();
  function handleFilterData({ selectedFilters, items }) {
    if (shouldUseReportsFilter()) {
      const emailReportsInstance = getEmailReportsInstance();
      return emailReportsInstance.handleFilter({ items, selectedFilters });
    }
    const hasFiltersSelected = selectedFilters?.length > 0;
    const hasFilterLevel = selectedFilterLevels?.length > 0;
    const shouldFilter = hasFiltersSelected || hasFilterLevel;
    if (!shouldFilter) return items;

    const orFilterLogic = selectedFilters.filter(
      ({ filterLogic }) => filterLogic === FilterLogic.OR
    );
    const andFilterLogic = selectedFilters.filter(
      ({ filterLogic }) => filterLogic === FilterLogic.AND
    );

    return items.filter((item: BlockItem) => {
      const itemMatchANDFilters = andFilterLogic.every((field) =>
        field.matchItem(item)
      );

      const itemMatchORFilters = orFilterLogic.length
        ? orFilterLogic.some((field) => field.matchItem(item))
        : true;

      const { value } = item.type;
      const selectedSchemas = selectedFilterLevels.map((level) =>
        viewSchema.find(({ display_name }) => display_name === level)
      );
      const selectedItemLevel = selectedSchemas.some(
        ({ view_block_type }) => view_block_type === value
      );

      if (itemMatchANDFilters && itemMatchORFilters && selectedItemLevel) {
        return true;
      }

      if (item.children?.length > 0) {
        const filteredChildren = handleFilterData({
          items: item.children,
          selectedFilters,
        });

        if (filteredChildren?.length > 0) {
          item.setChildren(filteredChildren);
          return true;
        }
      }

      return false;
    });
  }
  return {
    handleFilterData,
  };
};
