import { Button, makeStyles, MenuItem, styled, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  menuOpen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    borderRadius: "8px",
    border: "1px solid #D3D5D7",
    background: "#FFF",
    width: "max-content",
    boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.16)",
  },
}));

export const StatusSelector = styled("div")(({ theme }: { theme: Theme }) => ({
  height: "32px",
  display: "flex",
}));

export const ProjectStatusSelectorRoot = styled(Button)(
  ({ theme, backgroundColor }: { theme: Theme; backgroundColor: string }) => ({
    display: "flex",
    padding: theme.spacing(0, 2),
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    flexShrink: 0,
    borderRadius: theme.spacing(1),
    backgroundColor: backgroundColor,
    minWidth: "167px",
    "&:hover": {
      backgroundColor: backgroundColor,
    },
  })
);

export const ProjectStatusSelectorMenuItem = styled(MenuItem)({
  display: "flex",
  justifyContent: "flex-end",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

export const SelectorTitles = styled("div")(({ theme }: { theme: Theme }) => ({
  ...theme.typography.body1,
  fontSize: "16px",
  width: "100%",
  fontWeight: 500,
  color: theme.palette.text.secondary,
}));

export const SelectorGrid = styled("div")(
  ({ theme, width }: { theme: Theme; width: string }) => ({
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
    width: width ?? "30%",
    minWidth: "167px",
  })
);
