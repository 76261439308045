import { useEffect, useState } from "react";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import {
  AIConfidenceHigh,
  AIConfidenceLow,
  AIConfidenceMedium,
} from "src/v3/theme/icons";
import theme from "src/v3/theme/theme";
import { ItemDetailAccordion } from "../../commonStyles/ItemDetailAccordion";
import { ChallengeContent } from "./styles";
import { Grid, Typography } from "@material-ui/core";
import { EventTypes, useEventListener } from "src/providers/EventContext";
import ActionMenu from "src/v3/components/ActionMenu";
import { ResolveChallengeAction } from "src/v3/components/ActionMenu/Actions/ResolveChallengeAction";

const getAIConfidenceIcon = (confidence: "high" | "medium" | "low") => {
  if (confidence === "high") {
    return <AIConfidenceHigh />;
  } else if (confidence === "medium") {
    return <AIConfidenceMedium />;
  } else {
    return <AIConfidenceLow />;
  }
};

const ShowDetailsButton = ({
  toggleAllDetails,
  areAllRisksCollapsed,
  showAllDetails,
}: {
  toggleAllDetails: () => void;
  areAllRisksCollapsed: boolean;
  showAllDetails: boolean;
}) => {
  const _label =
    !showAllDetails || areAllRisksCollapsed ? "Show details" : "Hide details";

  return (
    <Typography
      onClick={(e) => {
        e.stopPropagation();
        toggleAllDetails();
      }}
      style={{
        color: theme.palette.primary.main,
        ...theme.typography.body1,
        fontStyle: "normal",
        lineHeight: "normal",
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
      }}
    >
      {_label}
    </Typography>
  );
};

export const TopChallenges = ({
  item,
  title,
}: {
  item: BlockItem;
  title: string;
}) => {
  const [expandedRisks, setExpandedRisks] = useState<{
    [key: string]: boolean;
  }>({});
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const [showAllDetails, setShowAllDetails] = useState(false);

  const handleToggleAccordion = () => {
    setIsAccordionExpanded((prev) => !prev);
    if (showAllDetails) {
      toggleAllDetails();
    }
  };

  const toggleRiskDetails = (riskId: string) => {
    setExpandedRisks((prev) => ({
      ...prev,
      [riskId]: !prev[riskId],
    }));
  };

  const toggleAllDetails = () => {
    setShowAllDetails((prev) => !prev);
    setExpandedRisks(
      risks.reduce((acc: { [key: string]: boolean }, risk) => {
        acc[risk.title] = !showAllDetails;
        return acc;
      }, {})
    );
    if (!showAllDetails && !isAccordionExpanded) {
      setIsAccordionExpanded(true);
    }
  };

  const riskDeletedOverride = useEventListener(
    EventTypes.RISK_DELETED,
    "TopChallenges"
  );

  useEffect(() => {
    console.log("Risk deleted override", riskDeletedOverride);
  }, [riskDeletedOverride]);

  const risksField = item.getRiskField();
  const risks = risksField?.getValue();
  const hasRisks = risks?.length > 0;

  const areAllRisksCollapsed = risks?.every(
    (risk) => !expandedRisks[risk.title]
  );

  const actionComponents = [(props) => <ResolveChallengeAction {...props} />];

  return (
    <div
      style={{
        border: `1px solid ${theme.palette.grey[600]}`,
        borderRadius: theme.shape.borderRadius,
        width: "100%",
      }}
    >
      <ItemDetailAccordion
        title={title}
        expanded={isAccordionExpanded}
        setExpanded={handleToggleAccordion}
        actions={[
          hasRisks && (
            <ShowDetailsButton
              toggleAllDetails={toggleAllDetails}
              areAllRisksCollapsed={areAllRisksCollapsed}
              showAllDetails={showAllDetails}
            />
          ),
        ]}
      >
        {hasRisks && (
          <>
            {risks?.map((risk, index) => {
              const isLast = index === risks?.length - 1;
              const isExpanded = expandedRisks[risk.title] || false;

              const actions = actionComponents.map((ActionComponent, idx) => (
                <ActionComponent key={idx} challenge={risk} item={item} />
              ));

              return (
                <Grid
                  container
                  key={risk.title}
                  direction="column"
                  onClick={() => toggleRiskDetails(risk.title)}
                  style={{
                    cursor: "pointer",
                    borderBottom: isLast
                      ? "none"
                      : `1px solid ${theme.palette.grey[300]}`,
                    padding: theme.spacing(3),
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(2),
                      flex: "1 0 0",
                    }}
                  >
                    {getAIConfidenceIcon(risk.confidence || "medium")}
                    <div
                      style={{
                        flex: 1,
                        color: theme.palette.grey[900],
                        ...theme.typography.body1,
                        fontStyle: "normal",
                        lineHeight: "normal",
                      }}
                    >
                      {risk.title}
                    </div>
                    <div
                      style={{
                        color: theme.palette.grey[400],
                        ...theme.typography.body2,
                        fontStyle: "normal",
                        lineHeight: "normal",
                        textAlign: "right",
                      }}
                    >
                      {risk.status}
                    </div>
                    {hasRisks && <ActionMenu actions={actions} />}
                  </div>
                  {isExpanded && risk.options && (
                    <div
                      style={{
                        alignSelf: "stretch",
                        display: "flex",
                        margin: theme.spacing(1, 8),
                        color: theme.palette.grey[900],
                      }}
                    >
                      {risk.options.map(({ value }) => (
                        <ChallengeContent
                          key={value}
                          onClick={(e) => {
                            if (e.target.tagName === "A") {
                              e.stopPropagation();
                            }
                          }}
                          dangerouslySetInnerHTML={{ __html: value }}
                        />
                      ))}
                    </div>
                  )}
                </Grid>
              );
            })}
          </>
        )}
      </ItemDetailAccordion>
    </div>
  );
};
