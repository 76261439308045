import { WidgetBuilder } from "./builder";
import { ThemeProvider } from "@material-ui/core";
import theme from "src/v3/theme/theme";
import { WidgetProvider } from "src/v3/providers/WidgetContext";
import _Widget from "src/v3/entities/Widget";
import { WidgetProps } from "./types";

export const WidgetV3 = ({ widget, items }: WidgetProps) => {
  return (
    <ThemeProvider theme={theme}>
      <WidgetProvider _widget={widget}>
        <WidgetBuilder items={items} />
      </WidgetProvider>
    </ThemeProvider>
  );
};
