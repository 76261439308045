import {
  getCurrentDateMinusDays,
  getCurrentDatePlusDays,
} from "src/v2/utils/date";
import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";
import { RiskComparisonDateField } from "../../customField/RiskComparisonDateField";

class ByDateFilter extends SectionFilter {
  public readonly from: string;
  public readonly to: string;

  constructor({ type, from, to, fieldKey, config }) {
    super({ type, fieldKey, config, values: [] });

    if (
      !from &&
      !to &&
      !config?.days_to_sum_in_current_date?.to &&
      !config?.days_to_sum_in_current_date?.from
    ) {
      throw new Error(
        `ByDateFilter requires at least one of the following properties: from, to, days_to_sum_in_current_date.to, days_to_sum_in_current_date.from.`
      );
    }

    /* Getting from the config.days_to_sum_in_current_date "to" and "from"
      "to" will be used to sum days to the current date
      "from" will be used to subtract days to the current date

      Example:
      "days_to_sum_in_current_date": {
         "from": 0,
        "to": 30
      }

      This will sum 30 days to the current date
      So the "from" date will be the current date
      And the "to" date will be the current date + 30 days

      Using it as the default values for the filter dates if the user doesn't specify the "from" and "to" properties
     */

    let fromDate = new Date().toISOString();
    let toDate = new Date().toISOString();

    if (config?.days_to_sum_in_current_date) {
      const fromDays = config.days_to_sum_in_current_date.from ?? 0;
      const toDays = config.days_to_sum_in_current_date.to ?? 0;
      fromDate = getCurrentDateMinusDays(fromDays);
      toDate = getCurrentDatePlusDays(toDays);
    }

    this.from = from ? new Date(from).toISOString() : fromDate;
    this.to = to ? new Date(to).toISOString() : toDate;
  }

  public filterItem(item: BlockItem): boolean {
    const field = item.getRiskComparisonDateField() as RiskComparisonDateField;
    if (!field) {
      return false;
    }

    const isFieldValueEmpty = !field.getRiskComparisonDate();
    const considerEmptyField =
      this.config["consider_empty_field_value"] ?? false;

    if (considerEmptyField && isFieldValueEmpty) {
      return true;
    }

    const fieldDate = new Date(field.getRiskComparisonDate());
    if (
      !isNaN(fieldDate) &&
      fieldDate instanceof Date &&
      !isNaN(fieldDate.valueOf())
    ) {
      const dateString = fieldDate.toISOString();
      return dateString >= this.from && dateString <= this.to;
    }
    return false;
  }
}

export default ByDateFilter;
