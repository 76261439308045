import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { CustomFieldBase } from "./CustomFieldBase";

export class PriorityField extends CustomFieldBase {
  private iconUrl: string;

  constructor(field) {
    super(field);
    this.value = [field.value?.display_name ?? ""];
    this.iconUrl = field.value?.iconUrl ?? "";
  }

  onError = (e) => {
    const priorityDefaultImageMap = {
      default:
        "https://heyvelma.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10320?size=small",
    };
    e.target.onerror = null; // Prevents infinite loop in case defaultImage also has an error
    let defaultURL =
      priorityDefaultImageMap[this.value[0]?.toLocaleLowerCase()];
    if (!defaultURL) {
      defaultURL = priorityDefaultImageMap["default"];
    }
    e.target.src = defaultURL;
  };
  getComponentForGrid(_) {
    return (
      <BootstrapTooltipWithoutPortal title={this.value[0]} placement="right">
        <img
          style={{ width: "20px", height: "20px" }}
          alt={this.value[0]}
          src={this.iconUrl}
          onError={this.onError}
        />
      </BootstrapTooltipWithoutPortal>
    );
  }
}
