export default function parseStringToBoolean(value: boolean | string): boolean {
  if (typeof value === "string") return value === "true";
  return value;
}

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const truncateStringInTheEnd = (str: string, maxLength: number) => {
  if (str?.length <= maxLength) {
    return str;
  }

  const lastThreeChars = str.slice(-3);
  const truncatedStr = str.slice(0, maxLength - 6);

  return `${truncatedStr}...${lastThreeChars}`;
};

export const truncateStringInTheMiddle = (str: string, maxLength: number) => {
  if (str?.length <= maxLength) {
    return str;
  }

  const pieceLength = Math.floor((maxLength - 3) / 2); // -3 for the ellipsis
  const startPiece = str.slice(0, pieceLength);
  const endPiece = str.slice(-pieceLength);

  return `${startPiece}...${endPiece}`;
};
export function formatDate(inputDate) {
  const date = new Date(inputDate);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.log("Invalid date");
    return null;
  }

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
export function pluralize({ word, count }) {
  return count === 1 ? word : `${word}s`;
}
export const getHashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return Math.abs(hash);
};
