import { ProjectStatusType } from "../../enum/ProjectStatusInsightType";

export class ProjectStatusInsightConfig {
  // Configurations for thresholds
  private offTrackThresholdPercent: number;
  private atRiskThresholdPercent: number;
  private minCombinedCountForOffTrack: number;
  private maxCombinedCountForAtRisk: number;
  private minPercentForAtRisk: number;
  private maxPercentForAtRisk: number;

  constructor(clientConfig: any) {
    this.offTrackThresholdPercent =
      clientConfig["off_track_threshold_percent"] || 25;
    this.atRiskThresholdPercent =
      clientConfig["at_risk_threshold_percent"] || 10;
    this.minCombinedCountForOffTrack =
      clientConfig["min_combined_count_for_off_track"] || 3;
    this.maxCombinedCountForAtRisk =
      clientConfig["max_combined_count_for_at_risk"] || 2;
    this.minPercentForAtRisk = clientConfig["min_percent_for_at_risk"] || 10;
    this.maxPercentForAtRisk = clientConfig["max_percent_for_at_risk"] || 24;
  }

  // Getters
  getOffTrackThresholdPercent() {
    return this.offTrackThresholdPercent;
  }

  getAtRiskThresholdPercent() {
    return this.atRiskThresholdPercent;
  }

  getMinCombinedCountForOffTrack() {
    return this.minCombinedCountForOffTrack;
  }

  getMaxCombinedCountForAtRisk() {
    return this.maxCombinedCountForAtRisk;
  }

  getMinPercentForAtRisk() {
    return this.minPercentForAtRisk;
  }

  getMaxPercentForAtRisk() {
    return this.maxPercentForAtRisk;
  }
}
