/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from "react";
import { useWidgetContext } from "src/v2/components/Widget/WidgetContext";
import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { useFieldsContext } from "../fields/FieldsContext";
import { BlockItemLevel } from "src/v2/domain/enum/BlockItemLevel";

const SummaryContext = React.createContext({
  expandAllItems: false,
  handleExpandAllItems: () => {},
  expandItemByKey: (key: string) => {},
  tableStyle: {},
  getHeaderStyle: (header, index) => {},
  getFirstColumnWidth: (itemType: string) => {},
});

function useSummaryContext() {
  const context = useContext(SummaryContext);
  return context;
}

export function SummaryContextProvider({ children }: any) {
  const [expandAllItems, setExpandAllItems] = useState<boolean>(false);
  function handleExpandAllItems() {
    setExpandAllItems(!expandAllItems);
  }

  const expandItemByKey = (key: string) => {
    const expandIconButton = document.getElementById(
      `expand-icon-button-${key}`
    );
    const isExpanded = expandIconButton?.getAttribute("aria-expanded");
    if (expandIconButton && isExpanded === "false") {
      expandIconButton.click();
    }
  };

  const { _section } = useWidgetContext();
  const [tableStyle, setTableStyle] = useState<any>({});

  useEffect(() => {
    const { options } = _section;
    const customSectionStyle =
      options?.find((option) => option.name === "styles")?.value || {};

    if (customSectionStyle) {
      setTableStyle(customSectionStyle);
    }
  }, [_section]);

  const { isFieldInVelmaInsights, getInsightsHeaderStyles } =
    useFieldsContext();

  const firstColumnWidth = tableStyle?.firstColumnWidth ?? 320;

  function getFirstColumnWidth(itemType: string) {
    if (itemType === BlockItemLevel.TASK) {
      return 250;
    }
    return firstColumnWidth;
  }

  const getHeaderStyle = (header, index) => {
    if (isFieldInVelmaInsights(header)) {
      return getInsightsHeaderStyles(header);
    }
    if (index === 0) {
      return { width: firstColumnWidth };
    }
    if (header?.type === FieldConfigType.expandable_text) {
      return { minWidth: 242 };
    }
    return {};
  };

  const value = React.useMemo(
    () => ({
      expandAllItems,
      handleExpandAllItems,
      expandItemByKey,
      tableStyle,
      getHeaderStyle,
      getFirstColumnWidth,
    }),
    [expandAllItems, handleExpandAllItems, expandItemByKey, _section]
  );

  return (
    <SummaryContext.Provider value={value}>{children}</SummaryContext.Provider>
  );
}

export { useSummaryContext };
