import { statusNotSetColor, getAccessibleColors } from "src/v2/helpers/colors";
import * as Sentry from "@sentry/react";
import { outcomeConfig } from "src/v2/components/Widget/outcomeConfig";
import { Grid, Typography } from "@material-ui/core";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";
import { OutcomeField } from "./OutcomeField";
import { isEmpty } from "src/v2/utils/object";

export class OutcomeStatus extends OutcomeField {
  constructor(field) {
    super(field);
    const defaultValue = {
      name: "Not Set",
      type: ProjectStatusType.NOT_SET,
      color: statusNotSetColor,
    };

    this.value =
      field.value && !isEmpty(field.value) ? [field.value] : [defaultValue];
  }

  getOutcome() {
    return this.value[0];
  }

  getPropertiesForComponent() {
    const { name, backgroundColor, textColor, ballColor } =
      this.getComponentPropertiesFromConfig();

    const properties = {
      backgroundColor,
      textColor,
      ballColor,
      name,
      tooltipText: "",
    };

    return properties;
  }

  getComponentForGrid() {
    const properties = this.getPropertiesForComponent();
    const { name, textColor, ballColor, tooltipText } = properties;
    return (
      <BootstrapTooltipWithoutPortal title={tooltipText}>
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
          style={{
            gap: 8,
            margin: "auto",
            padding: 6,
            borderRadius: 6,
            flexWrap: "nowrap",
            width: "max-content",
          }}
        >
          <div
            data-test-id="project_status_ball"
            style={{
              backgroundColor: `${ballColor}`,
              borderRadius: "100px",
              height: "18px",
              width: "18px",
            }}
          />
          <Typography
            style={{
              color: textColor,
              textAlign: "center",
              fontWeight: 600,
              fontSize: 14,
              textTransform: "uppercase",
            }}
          >
            {name}
          </Typography>
        </Grid>
      </BootstrapTooltipWithoutPortal>
    );
  }

  getStatusByConfig() {
    const status = this.getOutcome()?.name ?? this.getOutcome()?.type;

    const statusInConfig = outcomeConfig.statuses?.find(
      ({ name, type }) => name === status || type === status
    );
    if (!statusInConfig) {
      Sentry.captureMessage(
        `Status with name ${status} not found in outcome config`
      );
    }
    const name = statusInConfig?.name ?? "Not Set";
    const color = statusInConfig?.color ?? statusNotSetColor;
    return { color, name };
  }

  getComponentPropertiesFromConfig() {
    const statusInConfig = this.getStatusByConfig();
    const name = this.getOutcome()?.name || statusInConfig?.name;
    const color = statusInConfig?.color || `${statusNotSetColor}`;

    const accessibleColors = getAccessibleColors(color);
    return {
      textColor: accessibleColors.text,
      backgroundColor: accessibleColors.background,
      ballColor: color,
      name,
    };
  }
}
