import { ProjectStatusChip, WorkflowStatusChip } from ".";
import { StatusChipBuilderProps, StatusChipTypes } from "./types";

export const StatusChipBuilder = ({
  type,
  size,
  status,
}: StatusChipBuilderProps) => {
  switch (type) {
    case StatusChipTypes.ProjectStatus:
      return <ProjectStatusChip status={status} size={size} />;
    case StatusChipTypes.WorkflowStatus:
      return <WorkflowStatusChip status={status} size={size} />;
    default:
      throw new Error("Invalid status chip type");
  }
};
