import { ButtonProps, makeStyles } from "@material-ui/core";

import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import { BootstrapTooltip } from "../GlobalComponents";

const Button = withStyles({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
})(MuiButton);

export const NoBorderButton = withStyles((theme) => ({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
    textTransform: "none",
    color: theme.palette.primary.main,
  },
}))(MuiButton);

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.secondary.secondary,
      color: "white",
    },
  },
}));
interface BaseButtonProps extends ButtonProps {
  secondary?: boolean;
  tooltipText?: string;
}

function BaseButton({
  children,
  secondary,
  tooltipText,
  disabled,
  onClick,
  propRef,
  ...props
}: BaseButtonProps) {
  const classes = useStyles();
  const className = clsx(classes.root, { [classes.secondary]: secondary });
  const adjustedButtonProps = {
    disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick,
  };
  return (
    <BootstrapTooltip title={tooltipText || ""}>
      <Button
        className={className}
        variant="contained"
        size="small"
        ref={propRef}
        {...props}
        {...adjustedButtonProps}
      >
        {children}
      </Button>
    </BootstrapTooltip>
  );
}
BaseButton.defaultProps = {
  secondary: false,
  tooltipText: "",
};

export default BaseButton;
