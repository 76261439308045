import EpicInfoBase from "../EpicInfoBase";
import { EpicInfoType } from "src/v2/domain/enum/EpicInfoType";
import NoRecentlyCommentsWarning from "../NoRecentlyComments";

export class EpicInfoFactory {
  static getEpicInfoInstance(info): EpicInfoBase {
    switch (info.key) {
      case EpicInfoType.noRecentlyComments:
        return new NoRecentlyCommentsWarning(info);
      default:
        throw new Error(`Epic info type ${info.key} not found`);
    }
  }
}
