import { Grid, Button, Typography } from "@material-ui/core";
import { applyFilterColor, clearFilterColor } from "src/v2/helpers/colors";

export const FilterMenuBottomButtons = ({ applyFilters, clearFilters }) => (
  <Grid container justifyContent="space-between" direction="column">
    <Button
      aria-controls="simple-menu"
      aria-haspopup="true"
      style={{
        backgroundColor: `${applyFilterColor}`,
        color: " white",
        marginBottom: "10px",
        textTransform: "capitalize",
      }}
      onClick={applyFilters}
    >
      <Typography>Apply</Typography>
    </Button>
    <Button
      aria-controls="simple-menu"
      aria-haspopup="true"
      style={{
        backgroundColor: `${clearFilterColor}`,
        color: " white",
        textTransform: "capitalize",
      }}
      onClick={clearFilters}
    >
      <Typography>Clear</Typography>
    </Button>
  </Grid>
);
