import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";

import { SnackbarContext } from "src/providers/SnackbarContext";
import { Alert } from "@material-ui/lab";

const SnackbarNotification = () => {
  const { notification, clearNotification } = useContext(SnackbarContext);
  if (!notification?.message) return null;
  const duration = notification.options?.hideDuration ?? 3000;
  return (
    <Snackbar
      open={!!notification.message}
      autoHideDuration={duration}
      onClose={clearNotification}
    >
      <Alert
        severity={notification.type}
        action={notification.options?.renderButtonAction}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarNotification;
