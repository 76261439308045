import { styled, Box, Theme, Typography } from "@material-ui/core";

export const ProgressBarRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  borderRadius: theme.spacing(3),
  overflow: "hidden",
  height: "100%",
  width: "100%",
  display: "flex ",
}));

export const Percentage = styled(Box)(
  ({
    theme,
    value,
    color,
  }: {
    theme: Theme;
    value: number;
    color: string;
  }) => ({
    width: `${value}%`,
    float: "left",
    backgroundColor: color,
    height: "100%",
  })
);

export const EmptyPercentage = styled(Box)(({ theme }: { theme: Theme }) => ({
  width: "100%",
  float: "left",
  backgroundColor: theme.palette.grey[200],
  height: "100%",
}));

export const Divider = styled(Box)(({ theme }: { theme: Theme }) => ({
  width: theme.spacing(0.2),
  height: "100%",
  backgroundColor: theme.palette.background.default,
}));

export const ProgressBarLegendColor = styled(Box)(
  ({ theme, color }: { theme: Theme; color: string }) => ({
    width: "14px",
    height: "14px",
    backgroundColor: color,
    borderRadius: theme.spacing(1),
  })
);

export const ProgressBarLegendText = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    ...theme.typography.body2,
    lineHeight: "200%",
  })
);

export const TooltipContentRoot = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    gap: theme.spacing(2),
  })
);
