import { dateIsGreaterThanDaysAgo } from "src/v2/utils/date";
import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";

class ByRecentlyChangeInFieldFilter extends SectionFilter {
  public filterItem(item: BlockItem): boolean {
    const field = item.getFieldByFieldKey(this.fieldKey);
    if (
      this.shouldReturnFalse(field) ||
      !(field.value[0]?.type || field.value[0]?.name)
    ) {
      return false;
    }

    let fieldValue = [];
    if (typeof field.value === "object") {
      fieldValue = [field.value];
    }
    if (Array.isArray(field.value)) {
      fieldValue = field.value;
    }

    const fieldValueHasCreatedAt = fieldValue.some(
      (_value) => _value.created_at
    );

    if (!fieldValueHasCreatedAt) {
      throw new Error(
        `ByRecentlyChangeInField filter requires a field which value is an object with the created_at property. Field key: ${this.fieldKey}`
      );
    }

    const valueProperty = this.config.valueProperty || "type";
    const fieldHasValueProperty = fieldValue.some(
      (_value) => _value[valueProperty]
    );

    if (!fieldHasValueProperty) {
      throw new Error(
        `Trying to filter using a ByRecentlyChangeInField filter but the value in the field ${this.fieldKey} is not an object with the ${valueProperty} property. Please specify the correct valueProperty in the filter config.`
      );
    }

    const createdAtInDays = this.config.createdAtInDays ?? 30;

    return fieldValue.some((_value) => {
      const itemValueIsInFilterAcceptedValues = this.values
        .map((value) => value.toLowerCase())
        .includes(_value[valueProperty].toLowerCase());

      const recentlyChanged = dateIsGreaterThanDaysAgo({
        date: new Date(_value.created_at),
        daysToCompare: createdAtInDays,
      });

      return itemValueIsInFilterAcceptedValues && recentlyChanged;
    });
  }
}

export default ByRecentlyChangeInFieldFilter;
