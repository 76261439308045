import { Box, Typography } from "@material-ui/core";
import { CustomFieldBase } from "./CustomFieldBase";
import {
  formatTimeDifference,
  isValidStringDate,
  transformDate,
} from "src/v2/utils/date";

export class LastJiraUpdatedDateField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = field.value ?? {};

    const itemLastUpdated = field.value?.item_last_updated_date;
    this.itemLastUpdatedDate = isValidStringDate(itemLastUpdated)
      ? itemLastUpdated
      : "";

    const childLastUpdated = field.value?.max_child_last_updated_date;
    this.childLastUpdatedDate = isValidStringDate(childLastUpdated)
      ? childLastUpdated
      : "";

    this.lastUpdatedDate = this.getLastUpdated();
  }

  itemLastUpdatedDate;
  childLastUpdatedDate;

  // lastUpdatedDate is the most recent date between itemLastUpdatedDate and childLastUpdatedDate
  lastUpdatedDate;

  getLastUpdated() {
    if (!this.itemLastUpdatedDate && !this.childLastUpdatedDate) {
      return "";
    }
    const itemDate = new Date(this.itemLastUpdatedDate);
    const childDate = new Date(this.childLastUpdatedDate);

    if (itemDate > childDate) {
      return this.itemLastUpdatedDate;
    } else {
      return this.childLastUpdatedDate;
    }
  }

  getFirstColumnComponent({ props }: { props: any }) {
    return (
      this.lastUpdatedDate !== "" && (
        <Typography
          style={{
            fontSize: 12,
            color: "rgba(59, 94, 119, 0.6)",
          }}
        >
          Last updated: {formatTimeDifference(this.lastUpdatedDate)}
        </Typography>
      )
    );
  }

  getComponentForGrid(_) {
    return (
      <Box
        data-testid="date-field"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          minWidth: "120px",
          backgroundColor: this.itemLastUpdatedDate === "" ? null : "#C2DCFC",
          padding: 4,
          textAlign: "center",
          borderRadius: 4,
          color: "#395E77",
          zIndex: -1,
        }}
      >
        <Typography style={{ fontSize: 14 }}>
          {transformDate(this.itemLastUpdatedDate)}
        </Typography>
      </Box>
    );
  }
}
