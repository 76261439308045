import React from "react";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import "../RichTextSection.css";
import { useScroll } from "src/v2/hooks/useScroll";
import { useSummaryContext } from "src/v2/providers/summary/SummaryContext";
import { handleScrollToItemInTable } from "src/v2/utils/aiSummary";
import { isEmpty } from "src/v2/utils/object";
const AiSummaryRender = ({ aiSummary, name }) => {
  const [aiSummaryToRender, setAiSummaryToRender] = React.useState(null);

  const { scrollToElementById } = useScroll();
  const { expandItemByKey } = useSummaryContext();
  React.useEffect(() => {
    // checks if aiSummary is a dict or a string
    let _aiSummary = "";
    if (aiSummary && typeof aiSummary === "object") {
      // mostly means that the aiSummary is coming from the board
      // checks if aiSummary is a dict with a key "roadmap"
      _aiSummary += `<div style='display: block'>`;

      if (aiSummary.roadmap && aiSummary.roadmap.body.length > 40) {
        _aiSummary += `<div>`;
        if (aiSummary.roadmap.header) {
          _aiSummary += `<h2 style='color: rgb(44, 72, 91);background-color: #f0f7ff;padding-left: 12px; margin: 12px 0'>${aiSummary.roadmap.header}</h2>`;
        } else {
          _aiSummary += `<h2 style='color: rgb(44, 72, 91); background-color: #f0f7ff;padding-left: 12px; margin: 12px 0'>On the Roadmap</h2>`;
        }
        _aiSummary += `<div style='padding-left: 32px'>${aiSummary.roadmap.body}</div></div>`;
      }
      if (
        aiSummary["non-roadmap"] &&
        aiSummary["non-roadmap"].body.length > 40
      ) {
        if (_aiSummary.length > 40) {
          // add a div with boarder to act as a divider
          _aiSummary += `<div style='border-bottom: 1px solid #e0e0e0; margin: 16px 0'></div>`;
        }
        if (aiSummary["non-roadmap"].header) {
          _aiSummary += `<h2 style='color: rgb(44, 72, 91); background-color: #f0f7ff;padding-left: 12px; margin: 12px 0'>${aiSummary["non-roadmap"].header}</h2>`;
        } else {
          _aiSummary += `<h2 style='color: rgb(44, 72, 91); background-color: #f0f7ff;padding-left: 12px;margin: 12px 0'>On the Roadmap</h2>`;
        }
        _aiSummary += `<div style='padding-left: 32px'>${aiSummary["non-roadmap"].body}<div><div>`;
      }
      if (
        !aiSummary["non-roadmap"] &&
        !aiSummary.roadmap &&
        !isEmpty(aiSummary.default)
      ) {
        // try to get default and use it
        setAiSummaryToRender(`<div>${aiSummary.default}</div>`);
      } else {
        if (_aiSummary.length > 40) {
          _aiSummary += `</div>`;
        } else {
          _aiSummary += `<p style='width: 100%; padding: 16px ;font-size: 18px; '>No major changes or updates.</p></div>`;
        }
        setAiSummaryToRender(_aiSummary);
      }
    } else if (aiSummary && typeof aiSummary === "string") {
      setAiSummaryToRender(
        `<div style="padding-left: 32px">${aiSummary}</div>`
      );
    } else {
      _aiSummary += `<div style='display: block'><p style='width: 100%; padding: 16px ;font-size: 18px; '>No major changes or updates.</p></div>`;
      setAiSummaryToRender(_aiSummary);
    }
  }, [aiSummary]);
  /*
    @MateusMetzVelma: 
    A version of this function is also used in:
    OverviewNavigationSection.tsx, TeamAISummarySection.tsx, ItemsFilteredTeamAISummarySection.tsx and RichTextSection.tsx.
    All places that have HTML content that can contain links to items in the table.
    TODO: Refactor this function to a single place.
    */
  const handleClick = (e): void => {
    const clickedElement = e.target;
    const scrollToItemCallback = (key, options) => {
      e.preventDefault();
      expandItemByKey(key);
      scrollToElementById(key, options);
    };

    handleScrollToItemInTable(clickedElement, scrollToItemCallback);
  };
  return (
    aiSummaryToRender && (
      <div key={name}>
        {name && (
          <h4
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              paddingLeft: 16,
              fontSize: 18,
              color: "#2c485b",
              display: "flex",
              alignItems: "center",
            }}
          >
            <GroupWorkIcon style={{ marginRight: 8 }} /> {name}
          </h4>
        )}
        <div
          onClick={handleClick}
          dangerouslySetInnerHTML={{ __html: aiSummaryToRender }}
        />
      </div>
    )
  );
};

export default AiSummaryRender;
