import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { calculateDiffInDays } from "src/v2/utils/date";

export function getDateInMonthDay(date: Date): string {
  return `${date.getMonth() + 1}/${date.getDate()}`;
}
export function getDateInMonthDayAndYear(date: Date): string {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}
export function getDateInYearMonthAndDay(date: Date): string {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

export function getDatesInOrder(dates: Date[]) {
  return dates.sort((a: Date, b: Date) => a.getTime() - b.getTime());
}

export function getDatesDifferenceInDays(greaterDate: Date, date2: Date) {
  return Math.floor(
    (greaterDate.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
  );
}

export function getDatesDifferenceInDaysCeiling(
  greaterDate: Date,
  date2: Date
) {
  return Math.ceil(Math.abs(greaterDate - date2) / (1000 * 60 * 60 * 24));
}
export function transformMinutesInHours(minutes: number) {
  const number = minutes / 60;
  const unit = Math.floor(number);
  const fraction = ((number - unit) * 60).toFixed(0);
  return `${unit} ${unit > 1 ? "hours" : "hour"} and ${fraction} minutes`;
}

export function getDifferenceInMinutes(date1: Date, date2: Date) {
  return Math.round((date1.getTime() - date2.getTime()) / 60000);
}

export function getLatestDateKeyInObject(object: {
  [key: string]: Date;
}): string {
  let latestDateKey: string;
  for (const key in object) {
    if (key !== "Update Date" && key !== "Actual Start Date") {
      if (!latestDateKey || object[key] > object[latestDateKey])
        latestDateKey = key;
    }
  }
  return latestDateKey;
}

export function getEarliestDateKeyInObject(object: {
  [key: string]: Date;
}): string {
  let earliestDateKey: string;
  for (const key in object) {
    if (!earliestDateKey || object[key] < object[earliestDateKey])
      earliestDateKey = key;
  }
  return earliestDateKey;
}

export const getUtcDateFromString = (string: string) =>
  new Date(new Date(string.split(" ")[0]).toUTCString().substr(0, 25));

export function getOnlyUTCDateInString(date: Date) {
  return `${date.getUTCFullYear()}-${
    date.getUTCMonth() + 1
  }-${date.getUTCDate()}`;
}

export function addDaysWithoutReference(date: Date, days: number) {
  const newDate = new Date(date.getTime());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function getLastUpdatedString(dateInUtc: string): string {
  if (!dateInUtc) return undefined;
  const minutesAgo = Math.round(
    (new Date().getTime() - new Date(dateInUtc).getTime()) / 60000
  );
  if (minutesAgo > 60) {
    const { unit, fraction } = splitDoubleUnitAndFraction(minutesAgo / 60);
    return `${unit} ${unit > 1 ? "hours" : "hour"} and ${fraction} minutes ago`;
  }
  return `${minutesAgo.toFixed(0)} minutes ago`;
}

function splitDoubleUnitAndFraction(number: number) {
  const unit = Math.floor(number);
  return { unit, fraction: ((number - unit) * 60).toFixed(0) };
}

export function getLastUpdatedInDays(updatedAt) {
  const millissecondsPerDay = 1000 * 60 * 60 * 24;
  if (updatedAt) {
    return Math.round(
      (new Date().getTime() - new Date(updatedAt).getTime()) /
        millissecondsPerDay
    );
  }
  return undefined;
}
export const todaysDate = new Date().getTime();
export const toDate = (date) => new Date(date).getTime();
export const oneDay = 1000 * 3600 * 24;
export const oneWeek = 1000 * 60 * 60 * 24 * 7;
export const getFullCurrentYear = () => new Date().getFullYear();
export const getLastDateInYear = (fullYear: number) =>
  new Date(fullYear, 11, 31);
export const getFirstDateInYear = (fullYear) => new Date(fullYear, 0, 1);
export const getBusinessDatesCount = (startDate, endDate) => {
  let count = 0;
  let curDate = +startDate;
  while (curDate <= +endDate) {
    const dayOfWeek = new Date(curDate).getDay();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    if (!isWeekend) {
      count += 1;
    }
    curDate += 24 * 60 * 60 * 1000;
  }
  return count;
};

export function getDaysInMonth(year: number, monthIndex: number) {
  return new Date(year, monthIndex + 1, 0).getDate();
}

function isWeekend(date: Date) {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 6 || dayOfWeek === 0;
}

function getAmountOfWeekendsInRange(greaterDate: Date, smallerDate: Date) {
  const smallerDateForComparing = new Date(smallerDate.toISOString());
  let amountOfWeekendsInRange = 0;
  while (smallerDateForComparing.getTime() < greaterDate.getTime()) {
    smallerDateForComparing.setDate(smallerDateForComparing.getDate() + 1);
    if (isWeekend(smallerDateForComparing)) {
      amountOfWeekendsInRange += 1;
    }
  }
  return amountOfWeekendsInRange;
}

export function getDaysUntilSprintFinishesExcludingWeekends(
  greaterDate: Date,
  smallerDate: Date
) {
  const greaterDateWithoutHours = new Date(getOnlyUTCDateInString(greaterDate));
  const smallerDateWithoutHours = new Date(getOnlyUTCDateInString(smallerDate));
  const amountOfWeekendsInRange = getAmountOfWeekendsInRange(
    greaterDateWithoutHours,
    smallerDate
  );
  const rawAmountOfDaysInRange = Math.ceil(
    (greaterDateWithoutHours.getTime() - smallerDateWithoutHours.getTime()) /
      (1000 * 60 * 60 * 24)
  );
  return rawAmountOfDaysInRange - amountOfWeekendsInRange;
}
export function getStringDateFromDate(date: Date) {
  if (!date) return "";
  if (date instanceof Date) {
    return date.toISOString().split("T")[0];
  }
  return date;
}

/**
 * Checks if there have been recent target date changes for a given item.
 * @param item - The BlockItem to check for recent target date changes.
 * @returns A boolean indicating whether there have been target date changes less or equal 7 days.
 */
export function hasItemRecentTargetDateChange(item: BlockItem) {
  const field = item.getRiskComparisonDateField();
  const fieldValue = field?.getValue();
  const current = fieldValue;
  const previous = fieldValue?.previous;

  return (
    fieldValue &&
    previous &&
    current.date !== previous.date &&
    !current.default &&
    !previous.default &&
    calculateDiffInDays(
      new Date(previous.created_at),
      new Date(fieldValue.created_at)
    ) <= 7
  );
}
