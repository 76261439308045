import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  track: {
    position: "fixed",
    bottom: 56,
    zIndex: 10,
    right: 32,
    width: "100%",
    maxWidth: 112,
    height: 16,
    backgroundColor: "rgb(119,196,255, 0.38)",
    borderRadius: 8,
    cursor: "pointer",
    transition: "background-color 0.3s, transform 0.3s, box-shadow 0.3s",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    animation: "$pulse 2s infinite",
    "&:hover": {
      backgroundColor: "rgba(119,196,255,0.5)",
      animation: "none", // Stop the pulse animation on hover
      transform: "scale(1)", // Reset any scale transformation
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", // Reset the box-shadow
    },
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
  },
  thumb: {
    position: "absolute",
    top: 0,
    height: 16,
    width: 16,
    backgroundColor: "rgb(25, 123, 189)",
    borderRadius: "50%",
    cursor: "grab",
    transition: "left 0.2s",
    "&:active": {
      cursor: "grabbing",
    },
  },
  "@keyframes pulse": {
    "0%, 100%": {
      transform: "scale(1)",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    "50%": {
      transform: "scale(1.05)",
      boxShadow: "0 4px 12px 0 rgba(0,0,0,0.3)",
    },
  },
  gradient: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 16,
    pointerEvents: "none",
    zIndex: 1,
  },
  gradientLeft: {
    left: 0,
    background: "linear-gradient(to left, transparent, rgb(119,196,255, 0.38))",
  },
  gradientRight: {
    right: 0,
    background:
      "linear-gradient(to right, transparent, rgb(119,196,255, 0.38))",
  },
}));

export default function HorizontalScrollNavigator() {
  const classes = useStyles();
  const [showNavigator, setShowNavigator] = useState(false);
  const [thumbPosition, setThumbPosition] = useState("0%");
  const [isDragging, setIsDragging] = useState(false);
  const navigatorRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(true);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    let tooltipTimeout;

    if (showTooltip && !isHovering && !isDragging) {
      tooltipTimeout = setTimeout(() => {
        setShowTooltip(false);
      }, 10000); // 10 seconds
    }

    return () => clearTimeout(tooltipTimeout);
  }, [showTooltip, isHovering, isDragging]);

  const updateNavigator = () => {
    const totalWidth = document.body.scrollWidth;
    const visibleWidth = window.innerWidth;
    const scrollLeft = window.scrollX;
    const thumbRadiusPixels = 8;

    if (totalWidth > visibleWidth) {
      setShowNavigator(true);
      const thumbPositionPercentage =
        (scrollLeft / (totalWidth - visibleWidth)) *
          (100 - thumbRadiusPixels * 2) +
        thumbRadiusPixels;
      setThumbPosition(`${thumbPositionPercentage}%`);
    } else {
      setShowNavigator(false);
    }
  };

  const handleScroll = () => {
    if (!isDragging) {
      updateNavigator();
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setShowTooltip(false); // Hide the tooltip when dragging starts
    updateThumbPosition(e);
  };
  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      updateThumbPosition(e);
    }
  };
  const updateThumbPosition = (e) => {
    const navigatorRect = navigatorRef.current.getBoundingClientRect();
    const thumbRadiusPixels = 8; // half of the thumb's diameter

    const newThumbPosition =
      ((e.clientX - navigatorRect.left) / navigatorRect.width) * 100;

    // Ensure the thumb never goes past the track
    const clampedThumbPosition = Math.min(
      Math.max(newThumbPosition, thumbRadiusPixels),
      100 - thumbRadiusPixels
    );

    setThumbPosition(`${clampedThumbPosition}%`);

    const totalWidth = document.body.scrollWidth;
    const visibleWidth = window.innerWidth;
    const newScrollPosition =
      ((clampedThumbPosition - thumbRadiusPixels) /
        (100 - thumbRadiusPixels * 2)) *
      (totalWidth - visibleWidth);
    window.scrollTo({ left: newScrollPosition });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);
    updateNavigator();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragging]);

  return (
    showNavigator && (
      <Tooltip title="Drag to scroll horizontally" placement="top" arrow>
        <div
          className={classes.track}
          ref={navigatorRef}
          onMouseDown={handleMouseDown}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <div className={classes.gradientLeft} />
          <div className={classes.gradientRight} />
          <div
            className={classes.thumb}
            style={{
              left: `calc(${thumbPosition} - 8px)`, // Adjusted to center the thumb
            }}
          />
        </div>
      </Tooltip>
    )
  );
}
