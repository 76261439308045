import React, { useContext, useState, useEffect } from "react";
import { getRawPriorities } from "src/services/roadmapService";
import { getDoneEpics } from "src/services/v2/epicService";
import { getLastUpdatedInDays } from "src/utils/dateFormatter";
import { deepClone } from "src/utils/objectUtils";
import { useClientConfigContext } from "../ClientConfigContext";

const RoadmapContext = React.createContext({
  rawPriorities: [],
  setAvailablePriorities: (priorities: string[]) => {},
  doneEpics: [],
  originalDoneEpics: [],
  setDoneEpics: (doneEpics: any[]) => {},
});

function useRoadmapContext() {
  const context = useContext(RoadmapContext);
  return context;
}

export function RoadmapContextProvider({ children }: any) {
  const [rawPriorities, setRawPriorities] = useState();
  const [availablePriorities, setAvailablePriorities] = useState([]);
  const [doneEpics, setDoneEpics] = useState([]);
  const [originalDoneEpics, setOriginalDoneEpics] = useState([]);
  const { roadmapUseDoneStatusAsCompleted } = useClientConfigContext();

  useEffect(() => {
    getRawPriorities().then((priorities) => setRawPriorities(priorities));
  }, []);

  useEffect(() => {
    if (roadmapUseDoneStatusAsCompleted) {
      // getDoneEpics().then((epics) => {
      //   const formattedEpics = epics.map((epic) => ({
      //     ...epic,
      //     last_updated_in_days: getLastUpdatedInDays(epic.Updated),
      //     status: epic.Status.name,
      //   }));
      //   setOriginalDoneEpics(formattedEpics);
      //   setDoneEpics(deepClone(formattedEpics));
      // });
    }
  }, [roadmapUseDoneStatusAsCompleted]);

  const value = React.useMemo(
    () => ({
      rawPriorities,
      availablePriorities,
      doneEpics,
      originalDoneEpics,
      setDoneEpics,
      setAvailablePriorities,
    }),
    [
      rawPriorities,
      availablePriorities,
      doneEpics,
      originalDoneEpics,
      setDoneEpics,
    ]
  );

  return (
    <RoadmapContext.Provider value={value}>{children}</RoadmapContext.Provider>
  );
}

export { useRoadmapContext };
