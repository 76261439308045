import { BarChartBuilder } from "./BarChart/BarChartBuilder";
import DoughnutChartBuilder from "./DoughnutChart/DoughnutChartBuilder";
import { LineChart } from "./LineChart";

const ChartBuilder = ({ section, parentSections, items }) => {
  const { options } = section;
  const config = options?.find(({ name }) => name === "config").value;
  switch (config.type) {
    case "doughnut":
      return (
        <DoughnutChartBuilder section={section} items={items} config={config} />
      );
    case "bar":
      return (
        <BarChartBuilder section={section} items={items} config={config} />
      );
    case "line":
      return (
        <LineChart
          section={section}
          items={items}
          parentSections={parentSections}
        ></LineChart>
      );
    default:
      console.log("Chart type not found: ", config.type);
      return null;
  }
};
export default ChartBuilder;
