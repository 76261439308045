import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";

class ByProjectStatusFilter extends SectionFilter {
  public filterItem(item: BlockItem): boolean {
    const projectStatusField =
      item.getProjectStatusField() || item.getProjectStatusFieldV2();
    const shouldSkipItem =
      !projectStatusField || !projectStatusField.getProjectStatus();

    if (shouldSkipItem) {
      return false;
    }

    const projectStatus = projectStatusField.getProjectStatus();

    const valueProperty = this.config.valueProperty || "type";
    return this.values
      .map((value) => value.toLowerCase())
      .includes(projectStatus[valueProperty]?.toLowerCase());
  }
}

export default ByProjectStatusFilter;
