import { Grid, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class CostField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = field.value;
  }

  getProjectedCost() {
    return this.value?.find(
      ({ field_key }) => field_key === "projected_total_cost"
    );
  }

  getCostToDate() {
    return this.value?.find(({ field_key }) => field_key === "cost_to_date");
  }

  isValidValue(value) {
    const nullValue = !value;
    const emptyString = value === "";
    const zero = [0, "0"].includes(value);
    return (!nullValue && !emptyString) || zero;
  }

  getIntegerValue(value) {
    if (!this.isValidValue(value)) {
      return null;
    }
    return parseInt(value, 10);
  }

  getLabelByFieldKey(fieldKey) {
    return fieldKey === "cost_to_date" ? "Cost to date:" : "Projected cost:";
  }

  getFormattedValue(cost) {
    const costValue = this.getIntegerValue(cost.value);

    if (this.isValidValue(costValue)) {
      return `$${costValue
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
    }

    return "Not Predicted Yet";
  }

  getComponentForInsightAggregation(_) {
    const hasSomeValidValue = this.value?.some((costObj) =>
      this.isValidValue(this.getIntegerValue(costObj.value))
    );
    return (
      <Grid>
        {hasSomeValidValue ? (
          this.value?.map((costObj) => {
            return (
              <Grid container>
                <Typography
                  style={{
                    color: primaryTextColor,
                    marginRight: "8px",
                  }}
                  noWrap
                >
                  {this.getLabelByFieldKey(costObj.field_key)}
                </Typography>
                <Typography
                  style={{
                    color: primaryTextColor,
                  }}
                  noWrap
                >
                  {this.getFormattedValue(costObj)}
                </Typography>
              </Grid>
            );
          })
        ) : (
          <Typography
            style={{
              color: primaryTextColor,
            }}
            noWrap
          >
            Not Predicted Yet
          </Typography>
        )}
      </Grid>
    );
  }

  getComponentForGrid(_) {
    const hasSomeValidValue = this.value?.some((costObj) =>
      this.isValidValue(this.getIntegerValue(costObj.value))
    );
    return (
      <Grid>
        {hasSomeValidValue ? (
          this.value?.map((costObj) => {
            return (
              <Grid container>
                <Typography
                  style={{
                    color: primaryTextColor,
                    marginRight: "8px",
                  }}
                  noWrap
                >
                  {this.getLabelByFieldKey(costObj.field_key)}
                </Typography>
                <Typography
                  style={{
                    color: primaryTextColor,
                  }}
                  noWrap
                >
                  {this.getFormattedValue(costObj)}
                </Typography>
              </Grid>
            );
          })
        ) : (
          <Typography
            style={{
              color: primaryTextColor,
            }}
            noWrap
          >
            Not Predicted Yet
          </Typography>
        )}
      </Grid>
    );
  }
}
