import React from "react";
import {
  CustomAccordionDetails,
  CustomAccordionRoot,
  CustomAccordionSummary,
  Details,
  NoExpandIcon,
} from "./styles";
import { AccordionProps } from "./types";

import theme from "src/v3/theme/theme";
import { ChevronUp, ChevronDown } from "src/v3/theme/icons";

export const CustomAccordion = React.memo(
  ({
    header,
    children,
    customStyle,
    isExpanded,
    onToggle,
    hasChildren,
  }: AccordionProps) => {
    const handleToggle = React.useCallback(() => {
      if (hasChildren) {
        onToggle(!isExpanded);
      }
    }, [hasChildren, isExpanded, onToggle]);

    const expandIcon = hasChildren ? (
      isExpanded ? (
        <ChevronUp />
      ) : (
        <ChevronDown />
      )
    ) : null;

    return (
      <CustomAccordionRoot
        expanded={isExpanded && hasChildren}
        style={{
          boxShadow: "none",
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.spacing(2),
          ...customStyle,
        }}
      >
        <CustomAccordionSummary
          expandIcon={hasChildren ? expandIcon : <NoExpandIcon />}
          onClick={handleToggle}
          style={{
            cursor: hasChildren ? "pointer" : "default",
          }}
        >
          {header}
        </CustomAccordionSummary>
        {hasChildren && isExpanded && (
          <CustomAccordionDetails>
            <Details>{children}</Details>
          </CustomAccordionDetails>
        )}
      </CustomAccordionRoot>
    );
  }
);
