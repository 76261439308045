import TicketModel from "src/pages/SprintView/models/TicketModel";
import TicketSource from "src/pages/SprintView/models/TicketSource";
import { deepClone } from "./objectUtils";

export function compareStrings(stringOne, stringTwo) {
  if (!stringOne && !stringTwo) return 0;
  if (!stringOne) return -1;
  if (!stringTwo) return 1;
  const cleanStringOne = stringOne.toUpperCase().replace(/\W/g, "");
  const cleanStringTwo = stringTwo.toUpperCase().replace(/\W/g, "");
  if (cleanStringOne < cleanStringTwo) {
    return -1;
  }
  if (cleanStringOne > cleanStringTwo) {
    return 1;
  }
  return 0;
}
export function compareString(stringOne, stringTwo) {
  return compareStrings(stringOne, stringTwo);
}
export function sortTickets(
  goalTickets: {
    ticket: TicketModel;
    sprintId?: number;
    ticketSource: TicketSource;
  }[]
) {
  return goalTickets.sort((a, b) =>
    compareString(a.ticket.rank, b.ticket.rank)
  );
}

export const sortListById = (items) =>
  items.sort((item1, item2) => item1.identifier - item2.identifier);

export function compareRank(rankOne, rankTwo) {
  if (!rankOne && !rankTwo) return 0;
  if (!rankOne) return -1;
  if (!rankTwo) return 1;
  return rankOne.localeCompare(rankTwo, undefined, { sensitivity: "base" });
}

export function sortTicketsByRank(tickets: TicketModel[]) {
  return tickets.sort((a, b) => compareRank(a.rank, b.rank));
}

export function sortObjectListByStringKey<
  ObjectType,
  Key extends keyof ObjectType
>(objects: ObjectType[], key: Key) {
  return objects.sort((object1, object2) =>
    compareStrings(object1[key], object2[key])
  );
}

export function getUniqueListItemsByKeys(list: any[], keys: string[]) {
  return list.filter((item, index, array) => {
    const itemValue = findValueInObjectByKeys(item, keys);
    return (
      array.findIndex(
        (anotherItem) =>
          itemValue === findValueInObjectByKeys(anotherItem, keys)
      ) === index
    );
  });
}

function findValueInObjectByKeys(object: any, keys: string[]) {
  return keys.reduce((prev, curr) => prev[curr], object);
}

export const getUniqueItemsByCustomUniqueKey = (items, getUniqueKey) => {
  if (items) {
    const keys = items.map((item) => getUniqueKey(item));
    return items.filter((item, index) => {
      const key = getUniqueKey(item);
      return !keys.includes(key, index + 1);
    });
  }
  return items;
};

export const sortRoadmapFilters = (customFilters) => {
  let sortedCustomFilters = deepClone(customFilters);
  sortedCustomFilters = sortedCustomFilters.sort((a, b) =>
    a?.custom_field?.localeCompare(b?.custom_field)
  );
  sortedCustomFilters.forEach((field) => {
    field.values = field?.values?.sort();
  });
  return sortedCustomFilters;
};

export const filterDuplicateItemsByKey = ({ items, key = "key" }) =>
  items.filter(
    (value, index, self) =>
      index === self.findIndex((item) => item[key] === value[key])
  );

export const filterNestedDuplicatedTicketByKey = ({ items, key = "key" }) =>
  items.filter(
    (value, index, self) =>
      index === self.findIndex((item) => item.ticket[key] === value.ticket[key])
  );

export const filterDuplicatedInList = ({ items }) =>
  items.filter(
    (value, index, self) => index === self.findIndex((item) => item === value)
  );
