import { Typography, Theme, styled } from "@material-ui/core";

export const ItemName = styled(Typography)(
  ({ theme, onClick }: { theme: Theme; onClick?: () => void }) => ({
    overflow: "hidden",
    color: theme.palette.primary.main,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...theme.typography.h2,
    width: "100%",
    cursor: onClick ? "pointer" : "default",
    "&:hover": {
      textDecoration: onClick ? "underline" : "none",
    },
    display: "-webkit-box",
    flex: "1 0 0",
  })
);

export const ItemKey = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: "black",
  width: "15%",
  padding: theme.spacing(1),
  ...theme.typography.h2,
}));
