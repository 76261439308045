import { ChangelogByDateFilterField } from "src/v2/domain/entities/filter/ChangelogByDateFilterField";
import { CheckboxFilterField } from "src/v2/domain/entities/filter/CheckboxFilterField";
import { DateFilterField } from "src/v2/domain/entities/filter/DateFilterField";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";

export const useSelectFilterOptionHook = () => {
  const { selectedFiltersBeforeApply, setSelectedFiltersBeforeApply } =
    useFilterContext();
  function getSelectedFilterFieldByKey(fieldKey) {
    return selectedFiltersBeforeApply.find(
      (selected) => selected.fieldKey === fieldKey
    );
  }

  const isSelectedOption = ({ checked, fieldKey }) => {
    const filterField = getSelectedFilterFieldByKey(fieldKey);
    return filterField
      ? filterField?.values?.some((value) => value === checked)
      : false;
  };

  const handleSelectChangelogDateOption = ({ selectedValues, field }) => {
    const filterField = getSelectedFilterFieldByKey(field.fieldKey);
    if (!filterField) {
      const filterFieldInstance = new ChangelogByDateFilterField({
        ...field,
        values: [selectedValues],
      });
      setSelectedFiltersBeforeApply([
        ...selectedFiltersBeforeApply,
        filterFieldInstance,
      ]);
      return;
    }

    filterField.values = [selectedValues];
    setSelectedFiltersBeforeApply([...selectedFiltersBeforeApply]);
  };

  const handleSelectDateOption = ({
    selectedDates,
    field,
    setSelectedFilterCallback,
  }) => {
    if (selectedDates.daysAgo === 0) return;
    const filterField = getSelectedFilterFieldByKey(field.fieldKey);
    if (!filterField) {
      const filterFieldInstance = new DateFilterField({
        ...field,
        values: [selectedDates],
      });
      setSelectedFilterCallback([
        ...selectedFiltersBeforeApply,
        filterFieldInstance,
      ]);
      return;
    }

    filterField.values = [selectedDates];
    setSelectedFilterCallback([...selectedFiltersBeforeApply]);
  };

  const handleSelectCheckboxOption = ({
    checked,
    field,
    setSelectedFilterCallback,
  }) => {
    const filterField = getSelectedFilterFieldByKey(field.fieldKey);
    const valueAlreadySelected = isSelectedOption({
      checked,
      fieldKey: field.fieldKey,
    });
    if (!filterField) {
      setSelectedFilterCallback([
        ...selectedFiltersBeforeApply,
        new CheckboxFilterField({
          ...field,
          values: [checked],
        }),
      ]);
    }

    if (filterField && !valueAlreadySelected) {
      filterField?.values.push(checked);
      setSelectedFilterCallback([...selectedFiltersBeforeApply]);
    }

    if (filterField && valueAlreadySelected) {
      filterField.values = filterField.values.filter(
        (selectedValue) => selectedValue !== checked
      );
      const filteredFilterFieldsWithoutValue =
        selectedFiltersBeforeApply.filter(
          (selectedFilter) => selectedFilter?.values?.length !== 0
        );
      setSelectedFilterCallback([...filteredFilterFieldsWithoutValue]);
    }
  };

  return {
    handleSelectDateOption,
    handleSelectCheckboxOption,
    isSelectedOption,
    handleSelectChangelogDateOption,
    selectedFiltersBeforeApply,
  };
};
