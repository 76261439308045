import {
  doneStatusProgressBarColor,
  inProgressStatusProgressBarColor,
  todoStatusProgressBarColor,
} from "src/v2/helpers/colors";
import { hexToRGBA } from "src/v2/utils/colors";
import { isEmpty } from "src/v2/utils/object";
import { BlockItem } from "../domain/entities/block/BlockItem";
import { StatusField } from "../domain/entities/customField/StatusField";

const stacks = {
  Done: { color: doneStatusProgressBarColor, position: 0 },
  "In Progress": { color: inProgressStatusProgressBarColor, position: 1 },
  "To Do": { color: todoStatusProgressBarColor, position: 2 },
};

export const groupItemsByStatus = (items: BlockItem[]) => {
  const groupedStatus = items.reduce((acc, item) => {
    const statusField = item.getStatusField() as StatusField;
    const status = statusField.value[0];
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push({
      key: item.getKey(),
      category: statusField.getStatusCategory(),
    });
    return acc;
  }, {});
  return groupedStatus;
};

export const groupItemsByStatusCategory = (items: BlockItem[]) => {
  return items.reduce((acc, item) => {
    const statusField = item.getStatusField() as StatusField;
    const category = statusField.getStatusCategory();
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({
      key: item.getKey(),
      status: statusField.getStatusValue(),
    });
    return acc;
  }, {});
};

export const createStatusDataSetsForProgressBar = (group: {}) => {
  // This group is an object with the status as key and an array of items as value {key: string, category: string}[]
  if (isEmpty(group)) return [];
  return Object.keys(group).map((key) => {
    const statusCategory = group[key][0]["category"];
    return {
      label: key,
      category: statusCategory,
      data: [group[key].length],
      backgroundColor: "",
    };
  });
};

export const sortDatasetsByStatusPosition = ({ statuses, data }) => {
  const datasets = [...data];

  // Sort by category Done -> In Progress -> To Do
  const sortedDatasets = datasets
    .sort((a, b) => {
      const nameA = a.label.toLowerCase().replace(" ", "");
      const nameB = b.label.toLowerCase().replace(" ", "");
      const positionA =
        statuses?.find(
          (status) => status.name.toLowerCase().replace(" ", "") === nameA
        )?.position || Infinity;
      const positionB =
        statuses?.find(
          (status) => status.name.toLowerCase().replace(" ", "") === nameB
        )?.position || Infinity;

      return positionA - positionB;
    })
    .sort((a, b) => stacks[a.category].position - stacks[b.category].position);

  return sortedDatasets;
};

export const fillDatasetColors = (item, totalItems, index) => {
  let opacity = 1 - index / totalItems;
  if (!item.category) console.log("Item does not have a category", item);
  const category = item.category || "To Do";
  let color = hexToRGBA(stacks[category].color, opacity);
  return (item.backgroundColor = color);
};
