import { Grid, Typography, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { primaryTextColor } from "src/v2/helpers/colors";

export const FilterMenuHeader = ({ handleClose }) => (
  <Grid container justifyContent="space-between" alignItems="center">
    <Typography
      variant="h6"
      style={{ color: primaryTextColor, paddingLeft: 8 }}
    >
      Filters
    </Typography>

    <IconButton onClick={handleClose}>
      <Clear />
    </IconButton>
  </Grid>
);
