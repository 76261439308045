import { useContext, createContext, useMemo, useState, useEffect } from "react";
import { useSnackbar } from "src/providers/SnackbarContext";
import { isLogged } from "src/services/sessionService";
import {
  createUserPreference,
  deleteUserPreference,
  getUserPreferences,
} from "src/v2/services/userPreference";
import { useGenericViewContext } from "../genericView/GenericViewContext";

const USER_FILTER = "user_filter";

const UserPreferenceContext = createContext({
  userPreference: [],
  saveFilter: ({ url, name, callback }) => {},
});

function useUserPreferenceContext() {
  const context = useContext(UserPreferenceContext);
  return context;
}

function UserPreferenceProvider({ children }: any) {
  // state
  const [userPreferences, setUserPreferences] = useState([]);
  const { currentViewData } = useGenericViewContext();
  const { setNotification } = useSnackbar();

  const userIsLogged = isLogged();
  const deleteUserFilter = (id) => {
    deleteUserPreference(id)
      .then(() => {
        setUserPreferences(userPreferences.filter((item) => item.id !== id));
        setNotification("Filter archived successfully!");
      })
      .catch(() => {
        setNotification("Error archiving filter!");
      });
  };
  const saveFilter = ({ url, name, callback }) => {
    const data = {
      viewId: currentViewData.id,
      key: USER_FILTER,
      value: { name, url },
    };
    createUserPreference(data)
      .then((_data) => {
        setUserPreferences(_data);
        setNotification("Filter saved successfully!");
        callback(true);
      })
      .catch(() => {
        callback(false);
      });
  };

  const getVelmaSavedFilters = () =>
    userPreferences
      .filter(
        (item) =>
          item.key === USER_FILTER &&
          item.generic_view_id === currentViewData.id &&
          item.user_id === null
      )
      .map((item) => ({ id: item.id, ...item.value }));

  const getUserSavedFilters = () =>
    userPreferences
      .filter(
        (item) =>
          item.key === USER_FILTER &&
          item.generic_view_id === currentViewData.id &&
          item.user_id !== null
      )
      .map((item) => ({ id: item.id, ...item.value }));

  // effects
  useEffect(() => {
    if (userIsLogged && currentViewData) {
      getUserPreferences({ viewId: currentViewData.id }).then((data) => {
        setUserPreferences(data);
      });
    }
  }, [currentViewData, userIsLogged]);

  const value = useMemo(
    () => ({
      userPreferences,
      saveFilter,
      userSavedFilters: getUserSavedFilters(userPreferences),
      velmaSavedFilters: getVelmaSavedFilters(userPreferences),
      deleteUserFilter,
    }),
    [userPreferences]
  );

  return (
    <UserPreferenceContext.Provider value={value}>
      {children}
    </UserPreferenceContext.Provider>
  );
}

export {
  UserPreferenceContext,
  UserPreferenceProvider,
  useUserPreferenceContext,
};
