import { makeStyles, Tooltip, TooltipProps } from "@material-ui/core";
import styled, { css } from "styled-components";
import { CSSProperties } from "react";
import React from "react";

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AlignedRow = styled(Row)`
  align-items: center;
`;

export const AlignedRowWithHover = styled(AlignedRow)`
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: var(--color-light-grey);
`;
export const CentralizedRow = styled(Row)`
  justify-content: center;
`;

export const AlignedColumn = styled(Column)`
  justify-content: center;
`;

export const CentralizedColum = styled(Column)`
  align-items: center;
`;

export const Center = styled(Column)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageTitle = styled.h1`
  font-family: Overpass-SemiBold;
  font-size: 3.1em;
  padding-bottom: 0.15em;
`;

export const PageSubtitle = styled.p`
  font-family: OverPass;
  font-size: 1.4em;
  color: var(--color-grey);
`;

export const SmallSubtitle = styled.p`
  font-family: OverPass;
  font-size: 0.9em;
  color: var(--color-dark-grey);
`;

export const tooltipCss = css<{ tooltipText: string; centralize?: boolean }>`
  position: relative;
  &::after {
    content: ${({ tooltipText }) => tooltipText && `"${tooltipText}";`};
    white-space: pre;
    background-color: #000;
    color: #fff;
    top: -1.8em;
    position: absolute;
    font-family: Overpass;
    z-index: 100000000000;
    display: none;
    border-radius: 3px;
    width: max-content;
    ${({ centralize }) =>
      centralize && `width: auto; left: -15em; text-align: center;`}
  }
  &:hover::after {
    padding: 2px;
    padding-top: 5px;
    animation-name: example;
    animation-duration: 1.2s;
    display: unset;
  }
`;

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  tooltip: {
    fontSize: 15,
    whiteSpace: "pre-line",
    padding: 10,
    fontFamily: "Yantramanav",
    letterSpacing: 1.1,
    zIndex: 99999,
  },
}));

export function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();
  const title = props.title ?? "";
  return <Tooltip arrow classes={classes} {...props} title={title} />;
}
export function BootstrapTooltipWithoutPortal(props: TooltipProps) {
  return (
    <BootstrapTooltip
      PopperProps={{
        disablePortal: true,
      }}
      {...props}
    />
  );
}
export const ContainerWithToolTip = ({ tooltipText, children, ...props }) => (
  <BootstrapTooltip title={tooltipText} {...props}>
    {children}
  </BootstrapTooltip>
);
export const ContainerWithoutTooltipBody = styled.div`
  ${tooltipCss}
  &::after {
    display: none;
  }
  &:hover::after {
    display: unset;
  }
`;

export const HighlightContainer = ({ children, tooltipText, ...props }) => {
  const classes = useStylesBootstrap();
  const title = tooltipText ? (
    <span style={{ whiteSpace: "pre-line" }}>{tooltipText}</span>
  ) : (
    ""
  );
  return (
    <Tooltip
      placement="left-start"
      title={title}
      arrow
      classes={classes}
      PopperProps={{
        disablePortal: true,
      }}
      {...props}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export const TextWithTooltip = ({ children, tooltipText, style, ...props }) => (
  <BootstrapTooltip placement="top" title={tooltipText}>
    <p style={{ ...style }} {...props}>
      {children}
    </p>
  </BootstrapTooltip>
);
export const DefaultSelect = styled.select`
  & option[disabled] {
    color: blue;
  }
  & option {
    color: black;
  }
`;

export const NullLabel = styled.div`
  background-color: var(--color-light-grey);
  padding: 2px 0.6em;
  margin-bottom: 2em;
`;

export const MediumHeading = styled.span`
  font-family: "Mulish-SemiBold";
  color: var(--color-black-blue);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.2em;
`;

export const LargeHeading = styled.span`
  font-family: "Lato-Bold";
  color: var(--color-blacker-blue);
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.2em;
`;

export const FooterPadding = styled.div`
  margin: 3em;
`;

export const EmphasizedLabel = styled.span`
  font-family: "Lato-Bold";
  color: var(--color-blacker-blue);
  margin-right: 1em;
  font-size: 1em;
`;

export const HighlightedSummary = styled.p`
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const HighlightedSummaryContainerWithTooltip = styled(
  ContainerWithToolTip
)`
  max-width: 50%;
  width: fit-content;
  padding: 5px 1em 5px 5px;
  background-color: var(--color-light-yellow);
`;

export const defaultMaterialButtonStyle: CSSProperties = {
  fontSize: 14,
  fontFamily: "Mulish-SemiBold",
  textTransform: "capitalize",
};
