import { getFilteredItems } from "src/v3/dataContainers/filterContainer";
import { getGroupedItems } from "src/v3/dataContainers/groupContainer";
import WidgetConfig, { WidgetConfigType } from "src/v3/entities/WidgetConfig";
import { useWidgetContext } from "src/v3/providers/WidgetContext";
import { TeamListProps } from "./types";
import { isDevMode } from "src/services/sessionService";

export const useGroupByTeam = ({ items }: TeamListProps) => {
  const { getConfigsByType } = useWidgetContext();
  const groups = getConfigsByType(WidgetConfigType.group) as WidgetConfig[];

  if (isDevMode()) {
    console.debug("useTeamsList - input - items", {
      itemsLength: items.length,
      groups,
    });
  }

  const groupedItems = groups
    .map(({ filters, groupBy, name }) => {
      const filteredItems = getFilteredItems({ items, filters });
      const groupedItems = getGroupedItems({
        items: filteredItems,
        groupBy,
      });
      return {
        name,
        groupedItems,
      };
    })
    .filter(({ groupedItems }) => groupedItems?.length > 0);

  if (isDevMode()) {
    console.debug("useTeamsList - output - items", { groupedItems, groups });
  }

  return {
    groupedItems,
  };
};
