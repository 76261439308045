import { CustomFieldBase } from "./CustomFieldBase";
import { getPercentages } from "src/v2/utils/progress";
import {
  groupItemsByStatus,
  groupItemsByStatusCategory,
} from "src/v2/utils/status";
import { BlockItem } from "../block/BlockItem";

export class ProgressField extends CustomFieldBase {
  protected childrenGroupedByStatus = {};
  protected percentages = {
    toDo: 0,
    inProgress: 0,
    done: 0,
  };
  protected total = 0;
  protected totalBugs;
  protected totalNotBugs;

  constructor(field) {
    super(field);
    if (field.value) {
      const { children_grouped_by_status, percentages } = field.value;
      this.childrenGroupedByStatus = children_grouped_by_status;
      this.percentages = percentages;
      this.total = percentages?.total || 0;
    }
  }

  updateProgress(items: BlockItem[]) {
    this.childrenGroupedByStatus = groupItemsByStatus(items);
    const statusCategoryGroup = groupItemsByStatusCategory(items);
    const percentages = getPercentages({
      aggregation: statusCategoryGroup,
      total: items.length,
    });

    this.percentages = percentages;
    this.total = items.length;
    this.totalBugs = items.filter((item) => item.isBug()).length;
    this.totalNotBugs = items.filter((item) => !item.isBug()).length;
  }

  getDonePercentage() {
    return this.percentages?.done;
  }

  getInProgressPercentage() {
    return this.percentages?.inProgress;
  }

  getComponentForGrid({ fieldsState }) {
    return <div></div>;
  }
}
