import { LinearProgress, Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as VelmaIcon } from "src/assets/icons/velma.svg";

const useStyles = makeStyles(() => ({
  loadingWidget: {
    zoom: 0.9,
    display: "grid",
    alignContent: "center",
    justifyItems: "center",
    height: "100vh",
    transition: "all 0.3s ease-in-out 0s",
  },
  velmaIcon: {
    width: 96,
    height: 96,
    marginBottom: 32,
    marginTop: 64,
    animation: "$pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%, 100%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.1)",
      opacity: 0.5,
    },
  },
  mainText: {
    fontWeight: 600,
    lineHeight: 1.6,
    textAlign: "center",
    color: "#4a4a4a",
  },
  tipText: {
    marginTop: 16,
    color: "#8a8a8a",
    fontStyle: "italic",
    textAlign: "center",
    fontSize: 20,
  },
}));

const LoadingViewWidget = () => {
  const classes = useStyles();
  return (
    <div className={classes.loadingWidget}>
      <Typography variant="h3" className={classes.mainText}>
        Velma is working hard to load your data.
        <br /> We appreciate your patience - it&apos;ll be worth the wait!
      </Typography>
      <VelmaIcon className={classes.velmaIcon} />
      <LinearProgress style={{ width: 350 }} />
      <Typography variant="subtitle1" className={classes.tipText}>
        Pro tip: To ensure the best experience, avoid refreshing the page.
      </Typography>
    </div>
  );
};

export default LoadingViewWidget;
