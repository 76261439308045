function processOverSixtyMinutes(number: number) {
  const unit = Math.floor(number);
  const minutesInHour = 60;
  return { unit, fraction: ((number - unit) * minutesInHour).toFixed(0) };
}
export function calculateDiffInDays(date1, date2) {
  if (!date1 || !date2) return 0;
  if (typeof date1 === "string") date1 = new Date(date1);
  if (typeof date2 === "string") date2 = new Date(date2);
  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) return 0;
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
const nowInUTC = () => {
  const date = new Date();
  const nowUTC = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(nowUTC);
};

function getMinutesAgo(date: string) {
  const now = nowInUTC();
  const compTime = new Date(date);
  const minutesInMs = 1000 * 60;
  return Math.round((now.getTime() - compTime.getTime()) / minutesInMs);
}

const handleMinutesAgoFormat = (minutesAgo: number) => {
  const minutesInHour = 60;
  if (minutesAgo > 60) {
    const { unit, fraction } = processOverSixtyMinutes(
      minutesAgo / minutesInHour
    );
    return `${unit} ${unit > 1 ? "hours" : "hour"} and ${fraction} minutes ago`;
  }
  return `${minutesAgo.toFixed(0)} minutes ago`;
};
export function getLastUpdatedString(dateInUtc: string): string {
  if (!dateInUtc) return undefined;
  const minutesAgo = getMinutesAgo(dateInUtc);
  const formattedMinutesAgo = handleMinutesAgoFormat(minutesAgo);
  return formattedMinutesAgo;
}

export function dateIsGreaterThanDaysAgo({
  date,
  daysToCompare,
}: {
  date: Date;
  daysToCompare: number;
}) {
  const daysAgo = new Date(Date.now() - daysToCompare * 24 * 60 * 60 * 1000);
  return date > daysAgo;
}
export function getCurrentQuarterWeek() {
  const today = new Date();
  const quarter = Math.ceil((today.getMonth() + 1) / 3);
  const year = today.getFullYear();
  const weeksInQuarter = 13;
  const firstWeekInQuarter = (quarter - 1) * weeksInQuarter + 1;
  const weekOfYearStart =
    new Date(year, 0, 1).getDay() === 0 ? 1 : 8 - new Date(year, 0, 1).getDay();
  const millisecondsInDay = 86400000;
  const currentWeekOfYear = Math.ceil(
    ((new Date() - new Date(year, 0, weekOfYearStart)) / millisecondsInDay +
      1) /
      7
  );
  const currentWeekInQuarter = currentWeekOfYear - firstWeekInQuarter + 1;
  return currentWeekInQuarter;
}

export function transformDate(dateString: string) {
  if (!dateString) return undefined;
  if (isNaN(new Date(dateString))) {
    return dateString;
  }
  const param = Array.isArray(dateString) ? dateString[0] : dateString;
  try {
    // Parse the date string manually
    let parts = param.split("-");
    let year = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1; // month is zero-based
    let day = parseInt(parts[2], 10);

    // Create a new Date object
    let date = new Date(year, month, day);
    // Check if date is valid
    if (
      date.getFullYear() !== year ||
      date.getMonth() != month ||
      date.getDate() !== day
    ) {
      return undefined;
    }
    // Get the day and year
    day = date.getDate();
    year = date.getFullYear();
    month = date.getMonth();

    // An array that stores all months
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Return the date in the new format
    return months[month] + " " + day + ", " + year;
  } catch (error) {
    return dateString;
  }
}

export function getCurrentDatePlusDays(days) {
  const daysToPlusInCurrentDate = parseInt(days);
  const currentDateInMs = new Date().getTime();
  const time = currentDateInMs + daysToPlusInCurrentDate * 24 * 60 * 60 * 1000;
  return new Date(time).toISOString();
}

export function getCurrentDateMinusDays(days) {
  const daysToPlusInCurrentDate = parseInt(days);
  const currentDateInMs = new Date().getTime();
  const time = currentDateInMs - daysToPlusInCurrentDate * 24 * 60 * 60 * 1000;
  return new Date(time).toISOString();
}

export function isValidStringDate(date) {
  const dateFromString = new Date(date);
  return (
    !isNaN(dateFromString) &&
    dateFromString instanceof Date &&
    !isNaN(dateFromString.valueOf())
  );
}

export function formatTimeDifference(dateString) {
  if (!isValidStringDate(dateString)) return dateString;

  const date = new Date(dateString);
  const now = new Date();
  const diffInMillis = now - date;
  const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));

  if (diffInDays < 1) {
    const diffInMinutes = Math.floor(diffInMillis / (1000 * 60));
    const diffInHours = Math.floor(diffInMillis / (1000 * 60 * 60));
    return diffInHours === 0
      ? `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"} ago`
      : `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  } else if (diffInDays < 7) {
    return diffInDays === 1
      ? "yesterday"
      : `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  } else if (diffInDays < 14) {
    const remainingDays = diffInDays - 7;
    return `1 week ${
      remainingDays === 0
        ? ""
        : `and ${remainingDays} ${remainingDays === 1 ? "day" : "days"}`
    } ago`;
  } else if (diffInDays < 30) {
    const diffInWeeks = Math.floor(diffInDays / 7);
    const remainingDays = diffInDays % 7;
    return `${diffInWeeks} ${diffInWeeks === 1 ? "week" : "weeks"} ${
      remainingDays === 0
        ? ""
        : `and ${remainingDays} ${remainingDays === 1 ? "day" : "days"}`
    } ago`;
  } else {
    return transformDate(dateString);
  }
}
