import { logCustomEvent } from "src/utils";
import { Container, BackToAppButton } from "./styles";

export default function ErrorFallback({ error, resetError }) {
  logCustomEvent("error", { error });
  return (
    <Container>
      <p>We're sorry - something went wrong.</p>
      <p>
        If you continue experiencing issues, please let us know at{" "}
        <a href="mailto:support@heyvelma.com">support@heyvelma.com</a>.
      </p>
      <p>We're here to help!</p>
      <BackToAppButton onClick={resetError}>Go back</BackToAppButton>
    </Container>
  );
}
