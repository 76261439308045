import { GroupBreadcrumb, NavigationBreadcrumb } from ".";
import { BreadcrumbProps } from "./types";

export const BreadCrumbsBuilder = ({
  groups,
  paths,
  type,
}: BreadcrumbProps) => {
  switch (type) {
    case "group":
      return <GroupBreadcrumb groups={groups} type={"group"} />;
    case "navigation":
      return <NavigationBreadcrumb paths={paths} type={"navigation"} />;
    default:
      throw new Error("Invalid type");
  }
};
