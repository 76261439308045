import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { FilterFieldBase } from "src/v2/domain/entities/filter/FilterFieldBase";
import { ProjectStatusType } from "src/v2/domain/enum/ProjectStatusInsightType";
import { StatusCategory } from "src/v2/domain/enum/StatusCategory";
import {
  todoStatusProgressBarColor,
  inProgressStatusProgressBarColor,
  doneStatusProgressBarColor,
  statusNotSetColor,
} from "src/v2/helpers/colors";
import { roundExact } from "src/v2/utils";
import {
  getAllKeyValueFiltersFromURLWithoutExtraParams,
  buildRedirectURLWithListOfFilters,
} from "src/v2/utils/url";

const getProgress = ({ items, statuses, fieldKey, property = "value" }) => {
  const progress = [];
  items.forEach((item: BlockItem) => {
    const statusField = item.getFieldByFieldKey(fieldKey);
    let statusFieldValue = statusField?.getValueForProperty(property) || [];
    if (!Array.isArray(statusFieldValue)) statusFieldValue = [statusFieldValue];
    const statusInConfig = statuses.find(
      ({ type }) => type === statusFieldValue[0]
    );

    const configName = statusInConfig?.name || "Not Set";
    const configColor = statusInConfig?.color || `${statusNotSetColor}`;
    const configPosition = statusInConfig?.position || 999;
    const configType = statusInConfig?.type || ProjectStatusType.NOT_SET;

    const statusInList = progress.find(({ name }) => name === configName);
    if (statusInList) {
      statusInList.totalItems += 1;
      statusInList.itemKeys.push(item.getKey());
    } else {
      progress.push({
        name: configName,
        color: configColor,
        type: configType,
        position: configPosition,
        percentage: 0,
        totalItems: 1,
        itemKeys: [item.getKey()],
      });
    }
  });
  progress.sort((a, b) => a.position - b.position);
  progress.forEach((progress) => {
    const percentage = progress.totalItems / items.length;
    progress.percentage = roundExact(percentage * 100);
  });

  return {
    total: items.length,
    aggregatedValues: progress,
  };
};

export const getProjectStatusProgress = ({ items, config }) => {
  return getProgress({
    items,
    statuses: config.statuses,
    fieldKey: config.fieldKey,
    property: "type",
  });
};

export const aggregateByStatusCategory = ({ items }) => {
  const fieldKey = "Status";
  const statuses = [
    {
      name: StatusCategory.ToDo,
      type: StatusCategory.ToDo,
      color: `${todoStatusProgressBarColor}`,
      position: 3,
    },
    {
      name: StatusCategory.InProgress,
      type: StatusCategory.InProgress,
      color: `${inProgressStatusProgressBarColor}`,
      position: 2,
    },
    {
      name: StatusCategory.Done,
      type: StatusCategory.Done,
      color: `${doneStatusProgressBarColor}`,
      position: 1,
    },
  ];
  return getProgress({
    items,
    statuses,
    fieldKey,
    property: "category",
  });
};

export const getURLForFiltersFromParentSections = ({
  parentSections,
  groupedByValue,
  redirectView = "",
  extraFilters = [],
}) => {
  const parentFields =
    parentSections
      ?.map((section, index) => {
        const values = parentSections[index + 1]
          ? [parentSections[index + 1].groupedByValue]
          : [groupedByValue];
        return new FilterFieldBase({
          fieldKey: section.groupBy.fieldKey,
          values,
        });
      })
      ?.filter((field) => field) ?? [];

  const _filterFields = getAllKeyValueFiltersFromURLWithoutExtraParams();
  const filterFields = [..._filterFields, ...parentFields, ...extraFilters];
  return buildRedirectURLWithListOfFilters({
    filters: filterFields,
    redirectView,
  });
};
