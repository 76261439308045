import { Typography } from "@material-ui/core";

export const ManualUpdateAISummary = ({ html }) => {
  return (
    <Typography
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
