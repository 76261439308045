import { styled, Box, Theme, Button } from "@material-ui/core";

export const DrawerFooterRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  padding: theme.spacing(6),
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(3),
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
}));

export const FooterCloseButton = styled(Button)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    padding: theme.spacing(3, 6),
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[900]}`,
    ...theme.typography.h2,
    color: theme.palette.grey[900],
    fontSize: "14px",
    textTransform: "none",
  })
);
