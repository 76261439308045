export const HCO_2023_DoughnutChart = {
  data: {
    labels: ["Bugs (Major & above)", "Client Issues", "Roadmap"],
    datasets: [
      {
        data: [5, 0, 95], // Percentages
        quantity: [38, 0, 786],
        backgroundColor: [
          "rgb(204, 93, 19)",
          "rgb(8, 135, 134)",
          "rgb(28, 84, 149)",
        ],
      },
    ],
  },
  title: "Composition of Work",
  info: "HCO Release (Krypton 2024.1)",
};

// Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
export const HCO_2023_Release = {
  project_status: {
    status: {
      type: "off_track_status",
      name: "Off Track",
    },
    title: "Release",
    titleBackgroundColor: "#197BBD",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 63,
    inProgressPercentage: 13,
    toDoPercentage: 24,
  },
  ai_summary: "",
};

export const HCO_2023_ClientIssues = {
  project_status: {
    status: {
      type: "not_set_status",
      name: "No Issues",
    },
    title: "Client Issues",
    titleBackgroundColor: "#088786",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 0,
    inProgressPercentage: 0,
    toDoPercentage: 0,
  },
  ai_summary: "",
};

// Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
export const HCO_2023_Bugs = {
  project_status: {
    status: {
      type: "at_risk_status",
      name: "At Risk",
    },
    title: "Bugs",
    titleBackgroundColor: "#CC5D13",
  },
  progress: {
    items: 0,
    bugs: 0,
    donePercentage: 49,
    inProgressPercentage: 12,
    toDoPercentage: 39,
  },
  ai_summary:
    "<div class='root'><h4><a href='https://swicloud.atlassian.net/issues/?jql=issuetype%3D%22Bug%22%20AND%20project%3D%22OO%22%20AND%20created%20%3E%3D%20%222023-9-01%22' target='_blank'>348 total Bugs in Sept & Oct</a></h4><ul class='arrowed'><br/><b>Callouts</b><li>38 Bugs with priority “Major” and above</li><li>The team is not properly using “Priority”</li><li>A majority of Bugs (84%) do not have a priority associated</li><li>There is very similar completion time between “Critical”, “Major”, and “Moderate” Bugs</li><li>“Minor” and Bugs without a priority have faster completion times than “Major” and “Critical” Bugs</li><br/><b>Stats by Priority</b><li>Blocker (9 total): 8 days to resolve, 0% Unstarted</li><li>Critical (7 total): 21 days to resolve, 29% Unstarted</li><li>Major (22 total): 21 days to resolve, 41% Unstarted</li><li>Moderate (12 total): 29 days to resolve, 33% Unstarted</li><li>Minor (2 total): 4 days to resolve, 50% Unstarted</li><li>None (293 total): 14 days to resolve, 40%% Unstarted</li></ul>",
};

export const HCO_2023_Table = {
  progressBarData: {
    items: 32,
    bugs: 0,
    itemType: "Epic",
    donePercentage: 28,
    inProgressPercentage: 45,
    toDoPercentage: 27,
  },
  // Possible project status types: on_track_status, at_risk_status, off_track_status, not_set_status, paused
  sprintHealthStatus: {
    type: "on_track_status",
    name: "Complete",
  },
  roadmapHealthStatus: {
    type: "off_track_status",
    name: "Off Track",
  },
  projectedFinishDate: "Dec 12, 2023",
  sprintHealthReasoningHTML: "",
  roadmapHealthReasoningHTML:
    '<div class="root"><ul class="arrowed"><li>3 High priority bugs "In Progress"</li><li>1 epic is the primary cause for delay: <a target="_blank" href="https://velma.work/v2/roadmap/tracks?filter_level=Epic~Issue&key=OO-21508">[SecObs] - Krypton Risk score enhancements</a>, projected to finish Dec 12</li><li>1 epic has a mix of "Feature Complete" and "Regression" items: <a target="_blank" href="https://velma.work/v2/roadmap/tracks?filter_level=Epic~Issue&key=OO-21848">NetApp E/EF-series transition to RESTful API support</a>, projected to finish Jan 4</li><li><a target="_blank" href="https://velma.work/v2/roadmap/tracks?filter_level=Epic%7EIssue&fixVersions=Krypton+%282024.1%29&custom_fields_changelog=1+week">13 epics are At Risk</a></li></ul></div>',
  widgetTitleColor: "rgb(25, 123, 189)",
  itemName: "HCO Release",
  redirectURL:
    "https://velma.work/v2/roadmap/tracks?filter_level=Epic%7EIssue&fixVersions=Krypton+%282024.1%29&custom_fields_changelog=1+week",
};
