import ByDateFilter from "./ByDateFilter";
import ByProjectStatusFilter from "./ByProjectStatusFilter";
import FilterByType from "./FilterByType";
import NotIncludedInFilter from "./NotIncludedInFilter";
import SectionFilter from "./SectionFilter";
import ByStatusCategoryFilter from "./ByStatusCategoryFilter";
import FieldNotNullFilter from "./FieldNotNullFilter";
import ByRiskComparisonDate from "./ByRiskComparisonDate";
import ByRecentlyChangeInFieldFilter from "./ByRecentlyChangedInFieldFilter";
import ByLastJiraUpdatedDate from "./ByLastJiraUpdatedDate";
import BySimilarText from "./BySimilarText";
import ByAllocationFilter from "./ByAllocation";

export class SectionFilterFactory {
  static createFilter(filter): SectionFilter {
    const { type } = filter;
    switch (type) {
      case "by_type":
        return new FilterByType(filter);
      case "by_risk_comparison_date":
        return new ByRiskComparisonDate(filter);
      case "not_included_in":
        return new NotIncludedInFilter(filter);
      case "by_status_category":
        return new ByStatusCategoryFilter(filter);
      case "by_date":
        return new ByDateFilter(filter);
      case "by_project_status":
        return new ByProjectStatusFilter(filter);
      case "field_not_null":
        return new FieldNotNullFilter(filter);
      case "by_recently_change_in_field":
        return new ByRecentlyChangeInFieldFilter(filter);
      case "by_last_jira_updated_date":
        return new ByLastJiraUpdatedDate(filter);
      case "by_similar_text":
        return new BySimilarText(filter);
      case "by_allocation":
        return new ByAllocationFilter(filter);
      default:
        return new SectionFilter(filter);
    }
  }
}
