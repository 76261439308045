import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { ProjectStatusField } from "../entities/CustomFields/ProjectStatusField";
import { StatusField } from "../entities/CustomFields/StatusField";
import { AggregationItem } from "./types";
import { aggregateByWorkflowStatus } from "./aggregateByWorkflowStatus";
import { aggregateItemsByProjectStatus } from "./ProjectStatus/aggregateItemsByPS";

export type StatusConfig = {
  getStatusField: (item: BlockItem) => ProjectStatusField | StatusField;
  property: "category" | "name" | "type";
};

export const aggregateItemsByStatusField = ({
  statusField,
  items,
}: {
  statusField: ProjectStatusField | StatusField;
  items: BlockItem[];
}) => {
  if (!statusField || items?.length === 0) {
    return [];
  }

  if (statusField instanceof ProjectStatusField) {
    return aggregateItemsByProjectStatus({
      items,
    });
  }

  if (statusField instanceof StatusField) {
    return aggregateByWorkflowStatus({
      items,
    });
  }
  return [];
};

export const aggregateItemsByStatus = ({
  items,
  statusConfig,
}: {
  items: BlockItem[];
  statusConfig: StatusConfig;
}) => {
  const aggregation: AggregationItem[] = [];

  items?.forEach((item: BlockItem) => {
    const field = statusConfig.getStatusField(item);
    updateProgress(aggregation, field, item.getKey(), statusConfig.property);
  });

  calculatePercentages(aggregation, items?.length);

  return aggregation;
};

const updateProgress = (
  progress: AggregationItem[],
  field: ProjectStatusField | StatusField,
  itemKey: string,
  property: "category" | "name" | "type"
): void => {
  const existingProgressItem = progress.find(
    ({ identifier }) => identifier === field?.getValueForProperty(property)
  );
  if (existingProgressItem) {
    existingProgressItem.totalItems += 1;
    existingProgressItem.itemKeys.push(itemKey);
  } else {
    progress.push({
      identifier: field?.getValueForProperty(property),
      status: field,
      percentage: 0,
      totalItems: 1,
      itemKeys: [itemKey],
    });
  }
};

const calculatePercentages = (
  progress: AggregationItem[],
  totalItems: number
): void => {
  progress.sort((a, b) => a.status.getPosition() - a.status.getPosition());
  progress.forEach((progressItem) => {
    const percentage = (progressItem.totalItems / totalItems) * 100;
    progressItem.percentage = parseFloat(
      percentage?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    );
  });
};
