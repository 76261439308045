import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { useSelectFilterOptionHook } from "src/v2/hooks/filter/useSelectFilterOptionHook";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";

const useStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "var(--color-light-grey)",
    },
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "11rem",
    color: primaryTextColor,
  },
}));

export const InsightsCheckboxFilterOption = ({ value, field }) => {
  const classes = useStyles();
  const { setSelectedFiltersBeforeApply } = useFilterContext();
  const { isSelectedOption, handleSelectCheckboxOption } =
    useSelectFilterOptionHook();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.root}
      onClick={() =>
        handleSelectCheckboxOption({
          checked: value,
          field,
          setSelectedFilterCallback: (newData) =>
            setSelectedFiltersBeforeApply([...newData]),
        })
      }
    >
      <Checkbox
        checked={isSelectedOption({
          checked: value,
          fieldKey: field.fieldKey,
        })}
      />
      <Typography className={classes.text} noWrap>
        {value}
      </Typography>
    </Grid>
  );
};
