import React from "react";
import { Typography } from "@material-ui/core";

import { useBlockContext } from "src/v2/providers/block/BlockContext";
import { SummaryTable } from "src/v2/views/Summary/components/table/SummaryTable";
import TableSkeleton from "src/v2/views/Summary/components/table/TableSkeleton";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

interface TableSectionProps {
  items: BlockItem[];
}

const TableSection: React.FC<TableSectionProps> = (props) => {
  const { items } = props;
  const { isLoadingBlocks } = useBlockContext();

  if (isLoadingBlocks) {
    return <TableSkeleton />;
  }
  return (
    <>
      {items?.length === 0 ? (
        <Typography
          style={{
            fontSize: "24px",
            color: "#055A92",
            fontWeight: "600",
            marginTop: "24px",
          }}
        >
          No Items Match
        </Typography>
      ) : (
        <SummaryTable items={items} />
      )}
    </>
  );
};
export default TableSection;
