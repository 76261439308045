import { Box, Grid, Link, Typography } from "@material-ui/core";
import {
  BootstrapTooltip,
  ContainerWithToolTip,
} from "src/components/GlobalComponents";
import {
  expandTableTagColorsByItemType,
  getAccessibleColors,
  primaryTextColor,
} from "src/v2/helpers/colors";
import { NameField } from "./NameField";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { isEmpty } from "src/v2/utils/object";
import { ParentLinkField } from "./ParentLinkField";
import WarningEpicInfoBadge from "src/v2/components/buttons/Badge/WarningEpicInfoBadge";
import UpdatesEpicInfoBadge from "src/v2/components/buttons/Badge/UpdatesEpicInfoBadge";
import EpicInfoBase from "../epicInfo/EpicInfoBase";
import { LastJiraUpdatedDateField } from "./LastJiraUpdatedDateField";
import { ExpandTableTag } from "src/v2/components/buttons/ExpandTableTag";

export class CondensedNameField extends NameField {
  getComponentForGrid({ props }) {
    const {
      firstColumnWidth,
      getConfigValueByName,
      item,
      sectionRedirectViewSlug = "",
    } = props;

    const twoLinesMaxStyle = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    };

    const url = this.buildURL(
      getConfigValueByName,
      item,
      sectionRedirectViewSlug
    );

    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: firstColumnWidth - 32,
          display: "flex",
        }}
      >
        <BootstrapTooltip title={this.displayName}>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            variant="body2"
            href={url}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography
              style={{
                color: `${primaryTextColor}`,
                fontSize: 18,
                ...twoLinesMaxStyle,
              }}
              data-test-id="deliverable_name"
            >
              {this.displayName}
            </Typography>
          </Link>
        </BootstrapTooltip>
      </Box>
    );
  }

  getFirstColumnComponent({ props }: { props: any }): JSX.Element {
    const {
      item,
      expanded,
      firstColumnWidth,
      isExpandable,
      epicInfos,
      viewSchema,
    } = props;
    const itemParentField = item.getParentLinkField() as ParentLinkField;

    const itemLastUpdatedDateField =
      item.getLastJiraUpdatedDateField() as LastJiraUpdatedDateField;

    const warnings = epicInfos
      ?.filter(({ enabled }) => enabled)
      ?.filter(({ type }) => type === "warning")
      ?.filter((warn: EpicInfoBase) => warn.shouldDisplay({ item }));

    const updates = epicInfos
      ?.filter(({ enabled }) => enabled)
      ?.filter(({ type }) => type === "update")
      ?.filter((warn: EpicInfoBase) => warn.shouldDisplay({ item }));

    const itemTypeSchema = viewSchema?.find(
      ({ view_block_type }) => view_block_type === item.type.value
    );

    const defaultColors = expandTableTagColorsByItemType;

    const { background, originalColor } = getAccessibleColors(
      itemTypeSchema?.color ??
        defaultColors[item.type.value] ??
        defaultColors["default"]
    );

    return (
      <Grid
        container
        direction="row"
        style={{
          width: firstColumnWidth,
        }}
      >
        <Grid
          container
          direction="column"
          style={{ width: "8%" }}
          id={`expand-icon-button-${this.key}`}
          aria-expanded={expanded}
        >
          <ContainerWithToolTip title={item?.type?.displayName ?? ""}>
            <div>
              <ExpandTableTag
                customStyle={{
                  width: "100%",
                  background: originalColor,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {isExpandable &&
                  (expanded ? (
                    <ExpandLess
                      style={{ color: background, height: 16, width: 16 }}
                    />
                  ) : (
                    <ExpandMore
                      style={{ color: background, height: 16, width: 16 }}
                    />
                  ))}
              </ExpandTableTag>
            </div>
          </ContainerWithToolTip>
          <Grid
            container
            direction="column"
            alignContent="flex-start"
            justifyContent="space-evenly"
            alignItems="flex-start"
            style={{
              marginTop: 8,
            }}
          >
            {warnings && warnings?.length > 0 && (
              <div>
                <WarningEpicInfoBadge
                  warnings={warnings}
                ></WarningEpicInfoBadge>
              </div>
            )}
            {updates && updates?.length > 0 && (
              <div
                style={{
                  marginTop: 16,
                }}
              >
                <UpdatesEpicInfoBadge
                  updates={updates}
                  item={item}
                ></UpdatesEpicInfoBadge>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: "4px 16px",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {this.getKeyComponentForGrid({
              customStyle: {
                fontSize: 14,
                color: "rgba(59, 94, 119, 0.7)",
              },
            })}
            {this.getComponentForGrid({ props })}
          </div>
          {this.progressField &&
            this.progressField.getComponentForGrid({
              fieldsState: { ...props },
            })}
          {itemParentField && !isEmpty(itemParentField.value) && (
            <div
              style={{
                marginRight: 8,
                marginTop: 12,
              }}
            >
              {itemParentField.getFirstColumnComponent({ props })}
            </div>
          )}
          {itemLastUpdatedDateField && (
            <div
              style={{
                width: "100%",
                marginTop: 8,
              }}
            >
              {itemLastUpdatedDateField.getFirstColumnComponent({ props })}
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}
