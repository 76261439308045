import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { SectionViewType } from "./types";
import useClientStatusConfigContext from "src/providers/v2/Business/ClientStatusConfigContext";
import {
  fillDatasetColors,
  sortDatasetsByStatusPosition,
} from "src/v2/utils/status";
import { useWidgetContext } from "../Widget/WidgetContext";
import { WidgetConfigs } from "../Widget/WidgetConfigs";
import { ContainerWithToolTip } from "src/components/GlobalComponents";
import { defaultProgressBarBackgroundColor } from "src/v2/helpers/colors";

const DaysInStatus: React.FC<SectionViewType> = ({ section, items }) => {
  const { getConfigValueByName } = useWidgetContext();
  const daysInProgressData = getConfigValueByName(
    WidgetConfigs.daysInProgressData
  );

  if (!daysInProgressData) {
    throw new Error(
      "Days in progress data is not defined in the widget config"
    );
  }

  const { statuses } = useClientStatusConfigContext();
  const sortedStatuses = sortDatasetsByStatusPosition({
    data: daysInProgressData.data,
    statuses,
  }).reverse();
  const dataLength = sortedStatuses.length;

  return (
    <Grid container>
      <Box
        style={{ marginBottom: 24 }}
        dangerouslySetInnerHTML={{ __html: daysInProgressData.header }}
      />
      <Grid
        container
        style={{
          borderRadius: 15,
          backgroundColor: defaultProgressBarBackgroundColor,
          overflow: "hidden",
          height: 32,
        }}
      >
        {sortedStatuses.map((status, index) => {
          const sum = sortedStatuses.reduce((a, b) => a + b.average, 0);
          const percentage = (status.average / sum) * 100;
          const text = `${status.label} \nAverage: ${status.average.toFixed(
            0
          )} days\nMax: ${status.max.toFixed(0)} days`;

          return (
            <ContainerWithToolTip tooltipText={text}>
              <div
                key={index}
                style={{
                  width: `${percentage}%`,
                  height: "100%",
                  float: "left",
                  background: fillDatasetColors(status, dataLength, index),
                }}
              />
            </ContainerWithToolTip>
          );
        })}
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 8 }}>
        <Typography style={{ fontSize: 14, color: "grey" }}>Done</Typography>
        <Typography style={{ fontSize: 14, color: "grey" }}>To Do</Typography>
      </Grid>
    </Grid>
  );
};
export default DaysInStatus;
