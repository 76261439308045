import { TextField, withStyles } from "@material-ui/core";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `0`,
      },
    },
  },
})(TextField);

const HighlightTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `0`,
      },
      color: "var(--color-dark-orange) !important",
    },
    "& .MuiInputLabel-outlined": {
      color: "var(--color-dark-orange) !important",
    },
  },
})(CustomTextField);

const DefaultCustomTextField = (props) => {
  const { disabled } = props;
  return (
    <CustomTextField
      disabled={disabled}
      variant="outlined"
      size="small"
      margin="dense"
      {...props}
    />
  );
};

export const HighlightCustomTextField = (props) => (
  <HighlightTextField
    variant="outlined"
    size="small"
    margin="dense"
    {...props}
  />
);

export default DefaultCustomTextField;
