import EmailReportsKeys from "./EmailReportsKeys";
import InitiativeEmailReports from "./InitiativeEmailReports";

export function getEmailReportsInstance() {
  const emailReportType = new URLSearchParams(window.location.search).get(
    EmailReportsKeys.emailReportType
  );
  if (emailReportType === EmailReportsKeys.initiativeReport) {
    return new InitiativeEmailReports();
  }
  return null;
}

export function shouldUseReportsFilter() {
  const emailReportType = new URLSearchParams(window.location.search).get(
    EmailReportsKeys.useReportsFilter
  );
  return emailReportType === "true";
}
