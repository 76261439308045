import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { defaultSortConfig } from "./utils";
import { SortConfigItem } from "./types";

type fieldValue = string | number | Date;

/**
 * Extracts the value of a specific field from a BlockItem.
 * @param item - The BlockItem object.
 * @param fieldKey - The key of the field to extract the value from.
 * @param valueProperty - Optional property to extract from the field's value object.
 * @returns The value of the specified field, or null if the field does not exist.
 */
const getFieldValue = (
  item: BlockItem,
  fieldKey: string,
  valueProperty?: string
): fieldValue => {
  const field = item.getFieldByFieldKey(fieldKey);
  if (!field) return null;

  return field.getValueForSort(valueProperty);
};

/**
 * Parses a value based on its type.
 * @param value - The value to be parsed.
 * @param type - The type to parse the value as (string, number, or date).
 * @returns The parsed value.
 */
const parseValue = (
  value: fieldValue,
  type: "string" | "number" | "date" = "string"
) => {
  switch (type) {
    case "number":
      return Number(value);
    case "date":
      return new Date(value).getTime();
    default:
      return value?.toString() ?? "";
  }
};

/**
 * Compares two BlockItems based on an array of SortConfigItem.
 * @param a - The first BlockItem to compare.
 * @param b - The second BlockItem to compare.
 * @param configs - The array of SortConfigItem defining the sorting configuration.
 * @returns A number indicating the sort order: negative if a < b, positive if a > b, and 0 if they are equal.
 */
const compareItems = (
  a: BlockItem,
  b: BlockItem,
  configs: SortConfigItem[]
): number => {
  for (const config of configs) {
    const {
      fieldKey,
      valueProperty,
      acceptedValues = [],
      direction = "asc",
      type = "string",
    } = config;

    const aValue = parseValue(getFieldValue(a, fieldKey, valueProperty), type);
    const bValue = parseValue(getFieldValue(b, fieldKey, valueProperty), type);

    if (acceptedValues.length > 0) {
      const aPos =
        acceptedValues.find((v) => v.value === aValue)?.position || 9999;
      const bPos =
        acceptedValues.find((v) => v.value === bValue)?.position || 9999;

      if (aPos !== bPos) {
        return (aPos - bPos) * (direction === "asc" ? 1 : -1);
      }
    } else if (aValue !== bValue) {
      return (aValue > bValue ? 1 : -1) * (direction === "asc" ? 1 : -1);
    }
  }

  return 0;
};

/**
 * SortContainer component that sorts and renders items based on a provided sorting configuration.
 * @param items - The array of BlockItems to be sorted and rendered.
 * @param sortConfig - The array of SortConfigItem defining the sorting configuration.
 * @param renderItem - A function to render each sorted BlockItem.
 * @returns A React fragment containing the sorted and rendered items.
 */
export const SortContainer = ({
  items,
  sortConfig = defaultSortConfig,
}): BlockItem[] => {
  if (!items?.length) return [];
  const _sortConfig = [...sortConfig].sort((a, b) => a.position - b.position);
  return items.slice().sort((a, b) => compareItems(a, b, _sortConfig));
};
