import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { CheckboxFilterField } from "../CheckboxFilterField";
import { FilterFieldBase } from "../FilterFieldBase";
import { FieldConfig } from "../../fieldConfig/FieldConfig";
import { DateFilterField } from "../DateFilterField";
import { TextToReadOnFilter } from "../TextToReadOnFilter";
import { ChangelogByDateFilterField } from "../ChangelogByDateFilterField";
import { ProjectStatusFilterField } from "../ProjectStatusFilterField";
import { RiskComparisonDateFilterField } from "../RiskComparisonDateFilterField";
import { OutcomeFilterField } from "../OutcomeFilterField";

export class FilterFieldsFactory {
  static buildBaseFilterField(field: {
    fieldKey: string;
    values: any[];
  }): FilterFieldBase {
    return new FilterFieldBase(field);
  }
  static getFilterFieldInstance(field: FieldConfig): FilterFieldBase {
    if (field instanceof FieldConfig) {
      switch (field.type) {
        case FieldConfigType.date:
          return new DateFilterField(field);
        case FieldConfigType.risk_comparison_date:
          return new RiskComparisonDateFilterField(field);
        case FieldConfigType.read_on_filter:
          return new TextToReadOnFilter(field);
        case FieldConfigType.changelog:
          return new ChangelogByDateFilterField(field);
        case FieldConfigType.team:
          return new CheckboxFilterField(field);
        case FieldConfigType.team_link:
          return new CheckboxFilterField(field);
        case FieldConfigType.name:
          return new CheckboxFilterField(field);
        case FieldConfigType.condensed_name:
          return new CheckboxFilterField(field);
        case FieldConfigType.status:
          return new CheckboxFilterField(field);
        case FieldConfigType.project_status:
          return new ProjectStatusFilterField(field);
        case FieldConfigType.project_status_insights:
          return new ProjectStatusFilterField(field);
        case FieldConfigType.condensed_project_status_insights:
          return new ProjectStatusFilterField(field);
        case FieldConfigType.detailed_project_status_insights:
          return new ProjectStatusFilterField(field);
        case FieldConfigType.assignee:
          return new CheckboxFilterField(field);
        case FieldConfigType.text:
          return new CheckboxFilterField(field);
        case FieldConfigType.label:
          return new CheckboxFilterField(field);
        case FieldConfigType.priority:
          return new CheckboxFilterField(field);
        case FieldConfigType.dropdown:
          return new CheckboxFilterField(field);
        case FieldConfigType.projected_finish:
          return new CheckboxFilterField(field);
        case FieldConfigType.outcome_status:
          return new OutcomeFilterField(field);

        // V3 fields
        case FieldConfigType.workflow_status:
          return new CheckboxFilterField(field);
        case FieldConfigType.project_status_v3:
          return new ProjectStatusFilterField(field);

        default: {
          console.log(
            `Can not instantiate Filter Field of type: ${field.type}. - ${field}`
          );
          throw new Error(
            `Can not instantiate Filter Field of type: ${field.type}.`
          );
        }
      }
    } else {
      throw new Error(
        `Use an instance of Field Config to create a Filter Field`
      );
    }
  }
}
