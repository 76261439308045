import React from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fade from "@material-ui/core/Fade";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";
import { useScroll } from "src/v2/hooks/useScroll";

const useStyles = makeStyles((theme) => ({
  toTop: {
    position: "fixed",
    top: 64,
    left: "50%",
    transform: "translateX(-50%) scale(1)",
    zIndex: 1000,
    animation: "$pulse 1.5s infinite",
  },
  "@keyframes pulse": {
    "0%, 100%": {
      transform: "translateX(-50%) scale(1)",
    },
    "50%": {
      transform: "translateX(-50%) scale(1.05)",
    },
  },
}));

function ScrollTop() {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const { scrollToTop } = useScroll();

  return (
    <>
      <Fade in={trigger}>
        <div
          onClick={scrollToTop}
          role="presentation"
          className={classes.toTop}
        >
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </div>
      </Fade>
    </>
  );
}

export default ScrollTop;
