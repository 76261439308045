import { Box, Grid, Link, Typography } from "@material-ui/core";
import { BootstrapTooltip } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class ParentLinkField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.url = field.value?.url ?? "";
    this.displayName = field.value?.displayName ?? "";
    this.key = field.value?.key ?? "";
    this.issueType = field.value?.issueType ?? {};
    this.value = field.value ?? {};
    const value = field.value ?? {};
  }

  key: string;

  url: string;

  issueType: {
    displayName: string;
    iconUrl: string;
  };

  getKeyComponentForGrid(_) {
    return (
      <Grid item>
        <Link
          rel="noopener noreferrer"
          target="_blank"
          variant="body2"
          href={this.url}
        >
          <Typography
            style={{
              color: `${primaryTextColor}`,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {this.key}
          </Typography>
        </Link>
      </Grid>
    );
  }

  getFirstColumnComponent({ props }) {
    const twoLinesMaxStyle = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    };

    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <BootstrapTooltip title={this.displayName}>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            variant="body2"
            href={this.url}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography
              style={{
                color: `${primaryTextColor}`,
                fontSize: 12,
                ...twoLinesMaxStyle,
              }}
              data-test-id="deliverable_name"
            >
              {this.issueType?.displayName}: {this.displayName}
            </Typography>
          </Link>
        </BootstrapTooltip>
      </Box>
    );
  }

  getComponentForGrid({ firstColumnWidth }) {
    const twoLinesMaxStyle = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    };

    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: firstColumnWidth - 32,
        }}
      >
        <BootstrapTooltip title={this.displayName}>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            variant="body2"
            href={this.url}
          >
            <Typography
              style={{
                color: `${primaryTextColor}`,
                fontSize: 14,
                ...twoLinesMaxStyle,
              }}
              data-test-id="deliverable_name"
            >
              {this.displayName}
            </Typography>
          </Link>
        </BootstrapTooltip>
      </Box>
    );
  }
}
