import React, { useState } from "react";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { DateSideInfo, ItemRow } from "src/v3/components/ItemRow";
import { StatusChipTypes } from "src/v3/components/StatusChip/types";
import { ItemDetailAccordion } from "../commonStyles/ItemDetailAccordion";
import theme from "src/v3/theme/theme";
import { SortContainer } from "src/v3/dataContainers/SortContainer";
import { useItemDetailNavigation } from "src/v3/providers/useItemDetailNavigation";

interface RenderItemRowProps {
  item: BlockItem;
  index: number;
}

const RenderItemRow: React.FC<RenderItemRowProps> = ({ item, index }) => {
  const targetDate = item.getTargetDateField()?.getTargetDate();
  const projectedDate = item.getProjectedDateField()?.getProjectedDate();
  const { handleOpenItemDetails } = useItemDetailNavigation({ item });
  return (
    <ItemRow
      key={index}
      displayName={item.displayName}
      itemKey={item.getKey()}
      summaryField={item.getSummaryOfWorkField()}
      issueType={item.type}
      statusField={
        item.getProjectStatusField() || item.getWorkflowStatusField()
      }
      statusChipType={StatusChipTypes.WorkflowStatus}
      sideInfos={[
        <DateSideInfo label={"Projected"} date={projectedDate} />,
        <DateSideInfo label={"Team's target"} date={targetDate} />,
      ]}
      onNameClick={handleOpenItemDetails}
    />
  );
};

export const ItemsAccordion = ({
  items,
  title,
  action,
}: {
  items: BlockItem[];
  title: string;
  action?: {
    label: string;
    onClick: () => void;
  };
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const handleToggleAccordion = React.useCallback(() => {
    setIsAccordionExpanded((prev) => !prev);
  }, []);

  return (
    <ItemDetailAccordion
      title={title}
      action={""}
      expanded={isAccordionExpanded}
      setExpanded={handleToggleAccordion}
    >
      {items?.length > 0 && (
        <div
          style={{
            display: "flex",
            padding: theme.spacing(3),
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: theme.spacing(3),
            alignSelf: "stretch",
          }}
        >
          {SortContainer({ items }).map((item: BlockItem, index: number) => (
            <RenderItemRow item={item} index={index} />
          ))}
        </div>
      )}
    </ItemDetailAccordion>
  );
};
