import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  datePicker: {
    "& .MuiInputBase-root": {
      display: "flex",
      padding: "6px 10px",
      borderRadius: "8px",
      border: "1px solid #D3D5D7",
      background: "#FFF",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiSvgIcon-root": {
      color: "#000",
    },
    "& .MuiInputBase-input": {
      padding: 0,
      fontSize: "14px",
      color: "#000",
    },
  },
  popover: {
    display: "inline-flex",
    padding: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "8px",
    border: "1px solid #D3D5D7",
    background: "#FFF",
    boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.16)",
  },
  selectedDate: {
    "&.Mui-selected": {
      backgroundColor: "#197BBD",
      color: "#FFF",
    },
  },
}));
