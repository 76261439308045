import { Box, Link, Typography } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { BootstrapTooltipWithoutPortal } from "src/components/GlobalComponents";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";
import "./teamField.css";
import { FilterFieldsFactory } from "../filter/factory";
import { buildRedirectURLWithListOfFilters } from "src/v2/utils/url";

const maxTeams = 3;

export class TeamLinkField extends CustomFieldBase {
  teamRedirectUrl: string;
  constructor(field) {
    super(field);
    const teams = {};
    if (field.value?.length > 0) {
      field.value.forEach(({ display_name }) => {
        teams[display_name] = null;
      });
    } else {
      teams["Not Set"] = null;
    }
    this.value = Object.keys(teams);
  }

  updateValue(value: any): void {
    const teams = {};

    if (value?.length > 0) {
      value.forEach((team) => {
        teams[team] = null;
      });
    } else {
      teams["Not Set"] = null;
    }
    this.value = Object.keys(teams);
  }

  getExtraInfo({ fieldsState, setFieldsState }) {
    const isExpanded = fieldsState.teamField?.isExpanded;
    const showExtraTeams = this.value.length > maxTeams;

    if (!showExtraTeams) {
      return null;
    }
    if (isExpanded) {
      return (
        <Box style={{ width: "256px !important" }}>
          {this.value.slice(maxTeams).map((team) => (
            <Box className="TeamContainer">
              <Typography
                noWrap
                align="center"
                style={{ color: `${primaryTextColor}` }}
              >
                {team}
              </Typography>
            </Box>
          ))}
          <Box
            className="ExpandableButton"
            onClick={(e) => {
              setFieldsState((prevState) => ({
                ...prevState,
                teamField: {
                  isExpanded: !isExpanded,
                },
              }));
              e.stopPropagation();
            }}
          >
            <ArrowDropUpIcon />
            <Typography
              noWrap
              align="center"
              style={{ color: `${primaryTextColor}` }}
            >
              see less
            </Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Box
        className="ExpandableButton"
        onClick={(e) => {
          setFieldsState((prevState) => ({
            ...prevState,
            teamField: {
              isExpanded: !isExpanded,
            },
          }));
          e.stopPropagation();
        }}
      >
        <Typography
          noWrap
          align="center"
          style={{ color: `${primaryTextColor}` }}
        >
          +{this.value.length - maxTeams} more
        </Typography>
      </Box>
    );
  }

  getComponentForGrid({ fieldsState, setFieldsState }) {
    const { sectionRedirectViewSlug, genericViewRedirectViewSlug } =
      fieldsState;
    return (
      <Box display="flex" justifyContent="center" flexDirection="column">
        {this.value.slice(0, maxTeams).map((team) => {
          const teamToolTip = team.length > 20 ? team : "";
          const teamFilter = FilterFieldsFactory.buildBaseFilterField({
            fieldKey: "team_config",
            values: [team],
          });

          const redirectViewSlug =
            sectionRedirectViewSlug ?? genericViewRedirectViewSlug;
          const url = buildRedirectURLWithListOfFilters({
            filters: [teamFilter],
            redirectView: redirectViewSlug,
          });
          return (
            <>
              <Box className="TeamContainer" data-test-id="team_container">
                <BootstrapTooltipWithoutPortal title={teamToolTip}>
                  <Typography
                    noWrap
                    align="center"
                    data-test-id="team_text"
                    style={{ color: `${primaryTextColor}`, fontSize: "14px" }}
                  >
                    {team}
                  </Typography>
                </BootstrapTooltipWithoutPortal>
              </Box>
              <Box display="flex" justifyContent="center">
                <Link
                  target="_blank"
                  href={url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  + See details
                </Link>
              </Box>
            </>
          );
        })}
        {this.getExtraInfo({ fieldsState, setFieldsState })}
      </Box>
    );
  }
}
