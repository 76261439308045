import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import "../RichTextSection.css";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { WidgetTitle } from "../../Widget/Widget";
import AiSummaryRender from "./AiSummary";
import { EditAISummary } from "./EditAISummary";
import { Card } from "@material-ui/core";

const ItemsFilteredTeamAISummarySection = ({
  items,
  showTeamBoardName = true,
  boardName = null,
}: {
  items: BlockItem[];
  showTeamBoardName: boolean;
  boardName: string;
}) => {
  const { teamSummary } = useGenericViewContext();
  const teamInItems = items
    ?.flatMap((item) => item.getFieldByType(FieldConfigType.team)?.value)
    .filter((team) => team)
    .filter((team) => !boardName || team === boardName);
  if (
    teamSummary?.data?.length === 0 &&
    !teamSummary?.data.some(({ extra_context }) => extra_context?.context)
  ) {
    return null;
  }

  const titleStyles = {
    fontSize: 22,
    backgroundColor: "#f4f4f4",
    padding: 12,
    paddingLeft: 16,
    width: "100%",
    borderBottom: "1px solid white",
  };

  return (
    <div
      style={{
        border: "1px solid #e0e0e0",
        width: "100%",
      }}
    >
      <WidgetTitle
        title={teamSummary?.title}
        tooltipText={undefined}
        titleStyles={titleStyles}
      />
      <div className="root" style={{ padding: 0 }}>
        {teamSummary?.data
          ?.map((team, index) => {
            if (!teamInItems.includes(team.name)) {
              return null;
            }
            return (
              <Card
                style={{
                  padding: 16,
                  margin: 16,
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <EditAISummary team={team} />
                <AiSummaryRender
                  aiSummary={team.ai_summary}
                  name={showTeamBoardName && team.name}
                />
              </Card>
            );
          })
          .filter((summary) => summary)}
      </div>
    </div>
  );
};

export default ItemsFilteredTeamAISummarySection;
