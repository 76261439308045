export const ManualUpdateProgressbar = ({
  items,
  bugs,
  donePercentage,
  inProgressPercentage,
  toDoPercentage,
  itemType = "",
}) => {
  const itemLabel = (itemType || "Item") + (items > 1 ? "s" : "");

  return (
    <div data-test-id="false" style={{ padding: "15px" }}>
      <div className="MuiBox-root jss112">
        <div style={{ display: "flex" }}>
          {items > 0 && (
            <div style={{ marginRight: 16 }}>
              {items} {itemLabel}
            </div>
          )}
          {bugs > 0 && <div>{bugs} Bugs</div>}
        </div>
        <ProgressBar
          donePercentage={donePercentage}
          inProgressPercentage={inProgressPercentage}
          toDoPercentage={toDoPercentage}
        ></ProgressBar>
        <ProgressLegend
          donePercentage={donePercentage}
          inProgressPercentage={inProgressPercentage}
          toDoPercentage={toDoPercentage}
        ></ProgressLegend>
      </div>
    </div>
  );
};

const ProgressBar = ({
  donePercentage,
  inProgressPercentage,
  toDoPercentage,
}) => {
  return donePercentage + inProgressPercentage + toDoPercentage > 0 ? (
    <div
      style={{
        borderRadius: "15px",
        backgroundColor: "white",
        overflow: "hidden",
        height: "16px",
        marginTop: "16px",
        marginBottom: "16px",
      }}
    >
      <div
        style={{
          width: `${donePercentage}%`,
          height: "100%",
          float: "left",
          background: "rgb(3, 54, 119)",
        }}
      ></div>
      <div
        style={{
          width: `${inProgressPercentage}%`,
          height: "100%",
          float: "left",
          background: "rgba(78, 114, 159, 0.667)",
        }}
      ></div>
      <div
        className=""
        style={{
          width: `${toDoPercentage}%`,
          height: "100%",
          float: "left",
          background: "rgba(154, 174, 200, 0.333)",
        }}
      ></div>
    </div>
  ) : (
    <div
      style={{
        borderRadius: "15px",
        backgroundColor: "white",
        overflow: "hidden",
        height: "16px",
        marginTop: "16px",
        marginBottom: "16px",
      }}
    >
      <div
        className=""
        style={{
          width: `100%`,
          height: "100%",
          float: "left",
          background: "lightGrey",
        }}
      ></div>
    </div>
  );
};

const ProgressLegend = ({
  donePercentage,
  inProgressPercentage,
  toDoPercentage,
}) => {
  return (
    <div
      className="MuiBox-root jss162"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div
        className="MuiBox-root jss163"
        style={{
          padding: "2px 6px",
          borderRadius: "3px",
          backgroundColor: "rgb(3, 54, 119)",
          color: "white",
          display: "flex",
        }}
      >
        <p
          className="MuiTypography-root MuiTypography-body1"
          style={{ fontSize: "11px" }}
        >
          Done:
        </p>
        <p
          className="MuiTypography-root MuiTypography-body1"
          style={{ marginLeft: "4px", fontSize: "11px" }}
        >
          {donePercentage}%
        </p>
      </div>
      <div
        className="MuiBox-root jss164"
        style={{
          padding: "2px 6px",
          borderRadius: "3px",
          backgroundColor: "rgb(78, 114, 159)",
          color: "white",
          display: "flex",
        }}
      >
        <p
          className="MuiTypography-root MuiTypography-body1"
          style={{ fontSize: "11px" }}
        >
          In Progress:
        </p>
        <p
          className="MuiTypography-root MuiTypography-body1"
          style={{ marginLeft: "4px", fontSize: "11px" }}
        >
          {inProgressPercentage}%
        </p>
      </div>
      <div
        className="MuiBox-root jss165"
        style={{
          padding: "2px 6px",
          borderRadius: "3px",
          backgroundColor: "rgba(154, 174, 200, 0.5)",
          color: "rgb(3, 54, 119)",
          display: "flex",
        }}
      >
        <p
          className="MuiTypography-root MuiTypography-body1"
          style={{ fontSize: "11px" }}
        >
          To Do:
        </p>
        <p
          className="MuiTypography-root MuiTypography-body1"
          style={{ marginLeft: "4px", fontSize: "11px" }}
        >
          {toDoPercentage}%
        </p>
      </div>
    </div>
  );
};
