import { Box, Theme, styled } from "@material-ui/core";

export const AccordionItemsPadding = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(3),
    padding: theme.spacing(3, 0, 0, 8),
    alignSelf: "stretch",
    width: "100%",
  })
);
