import { Box, Typography } from "@material-ui/core";
import { transformDate } from "src/v2/utils/date";
import { OutcomeField } from "./OutcomeField";
import { isEmpty } from "src/v2/utils/object";

export class OutcomeExpectedCompletion extends OutcomeField {
  constructor(field) {
    super(field);
    this.value =
      field.value &&
      !isEmpty(field.value) &&
      field.value["expected_completion_date"]
        ? [field.value["expected_completion_date"]]
        : [""];
  }

  getOutcomeExpectedCompletion() {
    return this.value[0];
  }

  getComponentForGrid() {
    return (
      <Box
        data-testid="date-field"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          minWidth: "120px",
          padding: 4,
          textAlign: "center",
          borderRadius: 4,
          color: "#395E77",
          backgroundColor:
            this.getOutcomeExpectedCompletion() === "" ? null : "#C2DCFC",
          zIndex: -1,
        }}
      >
        <Typography style={{ fontSize: 14 }}>
          {transformDate(this.getOutcomeExpectedCompletion())}
        </Typography>
      </Box>
    );
  }
}
