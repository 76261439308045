import {
  Typography,
  Grid,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";

import { primaryTextColor } from "src/v2/helpers/colors";
import { ReactComponent as VelmaIcon } from "src/assets/icons/velma.svg";
import { ReactComponent as JiraIcon } from "src/assets/icons/jira-small.svg";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: "14px",
    color: `${primaryTextColor}`,
    fontFamily: "Overpass",
  },
  velmaIcon: {
    width: 32,
    height: 32,
    marginBottom: 0,
    marginRight: 16,
    marginTop: 0,
    animation: "$pulse 3s infinite",
  },
  jiraIcon: {
    marginLeft: 16,
  },
  "@keyframes pulse": {
    "0%, 100%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.1)",
      opacity: 0.5,
    },
  },
}));

const GettingData = ({ showLinearProgress = true }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} data-test-id="getting-data">
      {showLinearProgress && <VelmaIcon className={classes.velmaIcon} />}
      <div>
        <Typography className={classes.text}>Syncing data</Typography>
        {showLinearProgress && (
          <LinearProgress
            style={{
              width: "68px",
              margin: "0 8px",
              transform: "rotate(180deg)",
            }}
          />
        )}
      </div>
      {showLinearProgress && <JiraIcon className={classes.jiraIcon} />}
    </Grid>
  );
};

export default GettingData;
