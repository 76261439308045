import { BlockItem } from "../block/BlockItem";
import { FilterFieldBase } from "./FilterFieldBase";

export class TextToReadOnFilter extends FilterFieldBase {
  matchItem(item: BlockItem) {
    const itemField = item.getFieldByFieldKey(this.fieldKey);
    if (!itemField) return false;

    const customFieldHasSelectedFilterValue = itemField.value.some((it) =>
      this.values.includes(it)
    );

    return customFieldHasSelectedFilterValue;
  }
}
