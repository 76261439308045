import { EmptyStateIcon } from "src/v3/theme/icons";
import {
  IconContainer,
  MessageContainer,
  EmptyStateRoot,
  TitleContainer,
} from "./style";

const EmptyState = ({ message = "No items available", icon }) => {
  return (
    <EmptyStateRoot>
      <IconContainer>{EmptyStateIcon}</IconContainer>
      <TitleContainer>Nothing to see here yet</TitleContainer>
      <MessageContainer>{message}</MessageContainer>
    </EmptyStateRoot>
  );
};

export default EmptyState;
