import { Grid, makeStyles, Typography } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { Tr, Th, Thead } from "react-super-responsive-table";
import { primaryTextColor, tableHeaderColor } from "src/v2/helpers/colors";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useFieldsContext } from "src/v2/providers/fields/FieldsContext";
import { useLayout } from "src/providers/LayoutContext";
import { FieldConfig } from "src/v2/domain/entities/fieldConfig/FieldConfig";
import { useSummaryContext } from "src/v2/providers/summary/SummaryContext";

const useStyles = makeStyles({
  thead: {
    backgroundColor: tableHeaderColor,
    borderRadius: "0",
    boxShadow: "0px 1px 4px 1px rgb(212 212 212 / 67%)",
    transition: "all 0.3s ease-out",
    position: "sticky",
    top: 64,
    zIndex: 2,
  },
  tr: {
    height: "42px",
  },
  thText: {
    color: `${primaryTextColor}`,
    fontSize: "15px",
    margin: "0 16px",
    textTransform: "capitalize",
  },
});

interface TableHeaderProps {
  items;
}

export const TableHeader: FC<TableHeaderProps> = (props) => {
  const { items } = props;
  const classes = useStyles();
  const { normalizedHeaderHeight } = useLayout();
  const { getHeaderStyle } = useSummaryContext();

  const { fieldsToShowOnGrid, getHeaderFields } = useFieldsContext();
  const [headers, setHeaders] = useState<any>([]);
  const itemType = items[0].type.value;

  useEffect(() => {
    const headers = getHeaderFields(itemType).filter(
      (field: FieldConfig) =>
        items[0].getFieldByFieldKey(field.fieldKey) &&
        field.getGridConfig()?.config.level === 1
    );
    setHeaders(headers);
  }, [fieldsToShowOnGrid, items]);

  return (
    <Thead
      style={{ top: normalizedHeaderHeight }}
      className={classes.thead}
      data-test-id="summary_table_header"
    >
      <Tr className={classes.tr}>
        {headers?.map((header, index) => (
          <Th style={getHeaderStyle(header, index)}>
            <Grid
              container
              direction="row"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography align="left" className={classes.thText}>
                {header.displayName}
              </Typography>
            </Grid>
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};
