import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { Column } from "src/components/GlobalComponents";
import { statusNotSetColor } from "src/v2/helpers/colors";

import LargeProgressBar from "../../progressBar/LargeProgressBar";
import { ProgressBarByStatus } from "../../progressBar/ProgressBarByStatus";
import { useProjectStatusProgressData } from "./useProjectStatusProgressData";
import ItemsFilteredTeamAISummarySection from "../TeamAISummary/ItemsFilteredTeamAISummarySection";
import { useWidgetContext } from "../../Widget/WidgetContext";
import { WidgetConfigs } from "../../Widget/WidgetConfigs";

const useStyles = makeStyles({
  legendText: { marginLeft: 8, marginRight: 8 },
  linkedItem: {
    cursor: "pointer",
    textDecoration: "underline",
  },
});

const TeamSummaryProjectStatusProgressCard = ({
  section,
  items,
  parentSections,
}) => {
  const { groupedByValue } = section;
  const {
    titleStyle,
    filteredItems,
    redirectURL,
    progressStatusProgress,
    showProgressBarTitle,
    itemsGroupedByStatus,
    showPercentages,
    itemsGroupedByStatusCategory,
    statuses,
  } = useProjectStatusProgressData({ items, section, parentSections });

  const enableRedirect =
    section.options?.find(({ name }) => name === "enable_redirect")?.value ??
    true;
  return (
    <Card
      style={{
        padding: 8,
        display: "flex",
      }}
    >
      <div
        style={{
          padding: 8,
          width: 300,
        }}
      >
        <Typography variant="h6" style={{ ...titleStyle }}>
          {groupedByValue}
        </Typography>
        <CardName
          filteredItems={filteredItems}
          parentSections={parentSections}
          redirectURL={redirectURL}
          enableRedirect={enableRedirect}
        />
        <ProgressBars
          items={filteredItems}
          progressStatusProgress={progressStatusProgress}
          showProgressBarTitle={showProgressBarTitle}
          itemsGroupedByStatus={itemsGroupedByStatus}
          showPercentages={showPercentages}
          itemsGroupedByStatusCategory={itemsGroupedByStatusCategory}
          statuses={statuses}
        />
      </div>
      <Divider
        orientation="vertical"
        flexItem
        style={{
          marginLeft: 16,
          marginRight: 16,
        }}
      />
      <Grid
        container
        item
        style={{
          flex: 2,
        }}
      >
        <ItemsFilteredTeamAISummarySection
          items={filteredItems}
          showTeamBoardName={false}
          boardName={groupedByValue}
        />
      </Grid>
    </Card>
  );
};

const CardName = ({
  filteredItems,
  parentSections,
  redirectURL,
  enableRedirect,
}) => {
  const classes = useStyles();
  if (!enableRedirect) {
    return (
      <Typography variant="body2" color="textSecondary">
        {filteredItems.length} item
        {filteredItems.length === 1 ? "" : "s"}
      </Typography>
    );
  }
  return (
    <div role="button" tabIndex={0} onClick={() => {}}>
      {filteredItems.length > 0 && (
        <Link to={redirectURL} target="_blank">
          <Typography
            variant="body2"
            color="textSecondary"
            className={parentSections ? classes.linkedItem : null}
          >
            {filteredItems.length} item
            {filteredItems.length === 1 ? "" : "s"}
          </Typography>
        </Link>
      )}
    </div>
  );
};

const ProgressBars = ({
  items,
  progressStatusProgress,
  showProgressBarTitle,
  itemsGroupedByStatus,
  showPercentages,
  itemsGroupedByStatusCategory,
  statuses,
}) => {
  const { getConfigValueByName } = useWidgetContext();
  const projectStatusConfig = getConfigValueByName(
    WidgetConfigs.projectStatusInsights
  );
  return (
    <Column>
      {items.length > 0 ? (
        <>
          <Box mb={1} mt={1}>
            <LargeProgressBar
              backgroundColor={`${statusNotSetColor}`}
              percentages={progressStatusProgress?.aggregatedValues}
              showProgressBarLegends={true}
              title={
                showProgressBarTitle &&
                (projectStatusConfig?.display || "Project Status")
              }
            />
          </Box>
          <Box mb={2} mt={1}>
            <Typography
              style={{
                color: `grey`,
                marginBottom: 12,
                letterSpacing: 0.33,
              }}
            >
              Workflow Status
            </Typography>
            <ProgressBarByStatus
              itemsGroupedByStatus={itemsGroupedByStatus}
              style={{
                height: 18,
                marginTop: 8,
                marginBottom: 8,
              }}
              showPercentages={showPercentages}
              itemsGroupedByStatusCategory={itemsGroupedByStatusCategory}
              status={statuses}
            />
          </Box>
        </>
      ) : (
        <Box p={1}>
          <Typography variant="body2" color="textSecondary">
            No Matching Work
          </Typography>
        </Box>
      )}
    </Column>
  );
};
export default TeamSummaryProjectStatusProgressCard;
