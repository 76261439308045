import React, { useContext, useState } from "react";
import { NOTIFICATION_TYPES } from "src/utils/constants";

/**
 * Snackbar Context that will store current notification information and setters.
 */
const SnackbarContext = React.createContext();

function useSnackbar() {
  const context = useContext(SnackbarContext);
  return context;
}

interface NotificationProps {
  message: string;
  type: string;
  options: any;
}

/**
 * SnackbarContextProvider which will provide the current notification and
 * setters to update that notification to child components.
 */
function SnackbarContextProvider({ children }: any) {
  const [notification, setNotification] = useState<NotificationProps>();

  const clearNotification = () =>
    setNotification({ message: null, type: null, options: {} });

  const setErrorNotification = (message, options) =>
    setNotification({ message, type: NOTIFICATION_TYPES.ERROR, options });

  const handleSetNotification = (
    message,
    options,
    type = NOTIFICATION_TYPES.SUCCESS
  ) => {
    setNotification({ message, type, options });
  };

  const value = React.useMemo(
    () => ({
      notification,
      setNotification: handleSetNotification,
      setErrorNotification,
      clearNotification,
    }),
    [notification]
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
}

export { SnackbarContext, SnackbarContextProvider, useSnackbar };
