import { Box, Theme, Typography, styled } from "@material-ui/core";

export const StatusChipRoot = styled(Box)(
  ({ theme, backgroundColor }: { theme: Theme; backgroundColor: string }) => ({
    backgroundColor: backgroundColor,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    height: "100%",
    width: "max-content",
    justifyContent: "center",
    borderRadius: theme.spacing(1),
    whiteSpace: "nowrap",
  })
);

export const SmallStatusChip = styled(StatusChipRoot)(
  ({ theme }: { theme: Theme }) => ({
    height: theme.spacing(5),
    padding: theme.spacing(0, 1.3),
  })
);

export const MediumStatusChip = styled(StatusChipRoot)(
  ({ theme }: { theme: Theme }) => ({
    height: theme.spacing(6),
    padding: theme.spacing(0, 2),
  })
);

export const StatusName = styled(Typography)(
  ({ theme, color }: { theme: Theme; color: string }) => ({
    ...theme.typography.body2,
    fontWeight: 700,
    color: color,
    textTransform: "uppercase",
    textAlign: "center",
  })
);

export const EmptyStatusChip = styled(Box)(({ theme }) => ({
  height: theme.spacing(6),
  padding: theme.spacing(0, 2),
}));
