import { styled, Typography, Theme, MenuItem } from "@material-ui/core";
import theme from "src/v3/theme/theme";

export const MenuPaperProps = {
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.default,
  display: "flex",
  padding: theme.spacing(1, 0.5),
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1.3),
  width: "135px",
};

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignSelf: "stretch",
  minWidth: "130px",
  height: "100%",
}));

export const ActionLabel = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    color: theme.palette.grey[900],
    cursor: "pointer",
    width: "100%",
  })
);
