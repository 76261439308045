import React from "react";
import { getSummaryDisplay } from "./utils";
import useClickDragToggle from "src/v3/hooks/useClickDragToggle";
import { ItemRowProps } from "./types";
import { useStyles } from "./styles";

export const useItemRow = (props: ItemRowProps) => {
  const { summaryField } = props;
  const classes = useStyles();

  const aiSummary = summaryField?.getAiSummary() || "";
  const override = summaryField?.getLastAddedOverride()?.value?.context || "";

  const {
    isToggled: showFullSummary,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  } = useClickDragToggle();

  const { summaryDisplay, isSummaryLong } = React.useMemo(
    () =>
      getSummaryDisplay({
        aiSummary,
        override,
        showFullSummary,
        styles: classes,
      }),
    [aiSummary, override, showFullSummary]
  );

  return {
    summaryDisplay,
    isSummaryLong,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
};
