import { Box } from "@material-ui/core";
import { IssueTypeIconRoot } from "./styles";
import { getIssueTypeIconProperties } from "./utils";
import { IssueTypeIconProps } from "./types";

export const IssueTypeIcon = ({
  issueType,
  size = "medium",
  customClassName,
}: IssueTypeIconProps) => {
  const { icon, backgroundColor } = getIssueTypeIconProperties(issueType, size);
  return (
    <IssueTypeIconRoot
      backgroundColor={backgroundColor}
      size={size}
      className={customClassName}
    >
      <Box>{icon}</Box>
    </IssueTypeIconRoot>
  );
};
