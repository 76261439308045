import { ThemeProvider } from "@material-ui/core";
import theme from "src/v3/theme/theme";
import { DrawerContent, DrawerRoot, StyledDrawerPaper } from "./styles";
import { ItemDetailsDrawerHeader } from "./DrawerHeader";
import { ItemDetailerDrawerFooter } from "./DrawerFooter";
import { useItemDetails } from "./useItemDetails";
import { ItemsAccordion } from "./widgets/ItemsAccordion";
import { SummaryOfWorkAccordion } from "./widgets/SummaryOfWork";
import { TopChallenges } from "./widgets/TopChallenges";
import {
  findEverythingElse,
  getAggregatedItemsByStatus,
  getInProgressItems,
  getRecentlyCompletedItems,
} from "./utils";

export const ItemDetails = () => {
  const { item, paths, handleClose, isLoading } = useItemDetails();

  if (isLoading) {
    return <p>Loading item details...</p>;
  }

  const inProgressChildren = getInProgressItems({ items: item.children });
  const recentlyCompleteChildren = getRecentlyCompletedItems({
    items: item.children,
  });

  const everythingElseChildren = findEverythingElse({
    items: item.children,
    excludedItems: [...inProgressChildren, ...recentlyCompleteChildren],
  });

  const statusAggregation = getAggregatedItemsByStatus({
    items: item.children,
  });

  return (
    <ThemeProvider theme={theme}>
      <DrawerRoot
        open={true}
        onClose={handleClose}
        anchor="right"
        PaperProps={{
          component: StyledDrawerPaper,
        }}
      >
        <ItemDetailsDrawerHeader
          item={item}
          handleClose={handleClose}
          breadCrumbPaths={paths}
          childrenStatusAggregation={statusAggregation}
        />
        <DrawerContent>
          <TopChallenges item={item} title={"Top Challenges"} />
          <SummaryOfWorkAccordion item={item} />
          <ItemsAccordion items={inProgressChildren} title={"In progress"} />
          <ItemsAccordion
            items={recentlyCompleteChildren}
            title={"Recently completed"}
          />
          <ItemsAccordion
            items={everythingElseChildren}
            title={"Everything else"}
          />
        </DrawerContent>
        <ItemDetailerDrawerFooter handleClose={handleClose} />
      </DrawerRoot>
    </ThemeProvider>
  );
};
