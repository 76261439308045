import api from "./api/apiV2";

export async function getAllBlocks(params: { viewId }) {
  const { data } = await api.get(`/v2/roadmap-blocks/all`, {
    params,
  });
  return data;
}

export async function getFirstLoadBlocks(params: { viewId }) {
  const { data } = await api.get(`/v2/roadmap-blocks/first-load`, {
    params,
  });
  return data;
}

export async function getUpdatedBlocks(params: {
  viewId;
  blocksDataUpdatedAt;
}) {
  const response = await api.get(`/v2/roadmap-blocks/updated`, {
    params,
  });
  return response;
}
