import api from "./api";

export async function getConfigByKeys(keys: string[]) {
  const { data } = await api.get("/client/config/keys", {
    params: {
      keys,
    },
  });
  return data;
}

export async function getConfigByKeyAndBoard({
  key,
  boardId,
}: {
  key: string;
  boardId: number;
}) {
  const { data } = await api.get("/client/config/keys", {
    params: {
      keys: [key],
      boardId,
    },
  });
  return data;
}

export async function upsertConfigByKey({
  configKey,
  configValue,
}: {
  configKey: string;
  configValue: any;
}) {
  const { data } = await api.post(`/client/config/upsert-by-key`, {
    configKey,
    configValue,
  });
  return data;
}

export async function getNotAllowedFeaturesForClient() {
  const { data } = await api.get(`/client/config/not-allowed-features`);
  return data;
}

export async function getCustomFieldsConfigs() {
  const { data } = await api.get(`/roadmap/custom-fields-config`);
  return data;
}
