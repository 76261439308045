import { Box } from "@material-ui/core";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { isEmpty } from "src/v2/utils/object";

import LoadingViewWidget from "./LoadingWidget";
import { RoadmapGenericView } from "./RoadmapGenericView";
import theme from "src/v3/theme/theme";

export const RoadmapGenericViewBuilder = () => {
  const { currentViewData } = useGenericViewContext();

  const new_ui =
    currentViewData?.options?.find((option) => option.name === "new_ui")
      ?.value || false;

  return (
    <div
      style={{
        backgroundColor: new_ui ? theme.palette.background.paper : null,
        width: "100%",
        height: "100%",
      }}
    >
      {(!isEmpty(currentViewData) && (
        <RoadmapGenericView viewData={currentViewData} />
      )) || (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ width: "100vw", height: "100vh", margin: "0 auto" }}
        >
          <LoadingViewWidget />
        </Box>
      )}
    </div>
  );
};
