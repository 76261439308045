import { Box } from "@material-ui/core";
import { CheckboxFilterOption } from "src/v2/components/filter/components/CheckboxFilterOption";
import { SearchFieldOptions } from "src/v2/components/filter/components/SearchFieldOptions";
import { FilterExpandableField } from "src/v2/components/filter/FilterExpandableField";
import { BlockItem } from "../block/BlockItem";
import { FilterFieldBase } from "./FilterFieldBase";

export class CheckboxFilterField extends FilterFieldBase {
  matchItem(item: BlockItem) {
    const itemField = item.getFieldByFieldKey(this.fieldKey);
    if (!itemField) return false;

    const customFieldHasSelectedFilterValue = itemField.value.some((it) =>
      this.values.includes(it)
    );

    return customFieldHasSelectedFilterValue;
  }

  getFilterComponent({ expandAll, store, setStore }) {
    const values = store[this.fieldKey]?.values ?? this.values;
    return (
      <FilterExpandableField field={this} shouldExpandAll={expandAll}>
        {this.values.length > 6 && (
          <SearchFieldOptions field={this} setStore={setStore} />
        )}
        <Box style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {values.map((value) => (
            <CheckboxFilterOption value={value} field={this} />
          ))}
        </Box>
      </FilterExpandableField>
    );
  }
}
