import { CustomFieldBase } from "../CustomFieldBase";

export class OutcomeField extends CustomFieldBase {
  constructor(field) {
    super(field);
  }

  getComponentForGrid() {
    return <div></div>;
  }
}
