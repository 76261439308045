import {
  Grid,
  Typography,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import CustomDialog from "../../Dialogs/Base";
import { useChallengeAction } from "./useChallengeAction";
import { RetainOverride } from "../../commomStyles/RetainOverride";
import theme from "src/v3/theme/theme";

export const ResolveChallengeModal = ({
  challenge,
  item,
  open,
  handleOpen,
}: {
  challenge: any;
  item: BlockItem;
  open: boolean;
  handleOpen: () => void;
}) => {
  const { state, isSaving, handleStateChange, onSave, resetState } =
    useChallengeAction({
      item,
      challenge,
    });

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    resetState();
    handleOpen();
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await onSave();
    handleOpen();
  };

  return (
    <CustomDialog
      open={open}
      onSave={handleSave}
      onClose={handleClose}
      title="Resolve Challenge"
      enableSaveButton={true}
      isSaving={isSaving}
    >
      <Grid container direction="column">
        <Grid container alignItems="center">
          <WarningRounded
            style={{
              color: "green",
              height: "50px",
              width: "50px",
            }}
          />
          <Typography
            style={{
              ...theme.typography.h2,
              lineHeight: "16.8px",
              fontWeight: "700",
            }}
          >
            Are you sure you want to resolve this challenge?
          </Typography>
        </Grid>
        <Grid container direction="column">
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "16.8px",
              marginTop: 32,
            }}
          >
            Justification (optional)
          </Typography>
          <FormControl variant="outlined" required>
            <TextField
              id="outlined-multiline-static"
              multiline
              placeholder="Let Velma know why you are resolving the challenge."
              variant="outlined"
              value={state.justification}
              onChange={(e) => {
                handleStateChange("justification", e.target.value);
              }}
              inputProps={{ maxLength: 250 }}
              style={{
                width: "100%",
                marginTop: "8px",
                borderRadius: "4px",
                border: `1px solid "#D9D9D9"`,
              }}
            />
          </FormControl>
        </Grid>

        <Grid
          container
          direction="column"
          style={{
            width: "100%",
            marginTop: 16,
          }}
        >
          <FormControlLabel
            style={{
              marginTop: "8px",
            }}
            control={
              <Checkbox
                key={`retainStatus-${Math.random()}`}
                checked={state.retainOverride}
                onChange={(e) =>
                  handleStateChange("retainOverride", e.target.checked)
                }
                color="primary"
              />
            }
            label={
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "16.8px",
                  textAlign: "left",
                }}
              >
                Resolve this challenge for a certain duration
              </Typography>
            }
          />
          {state.retainOverride && (
            <RetainOverride
              stateChanged={true}
              expirationDate={state.expirationDate}
              handleExpirationDateChange={(e) =>
                handleStateChange("expirationDate", e.target.value)
              }
            />
          )}
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
