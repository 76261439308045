import {
  LastUpdatedInDaysOption,
  LastUpdateInDaysFilterOption,
} from "src/v2/components/filter/components/LastUpdatedInDaysOption";
import { BlockItem } from "../block/BlockItem";
import { FilterFieldBase } from "./FilterFieldBase";
import { DateFilterType } from "../../enum/DateFilterType";

export class DateFilterField extends FilterFieldBase {
  matchItem(item: BlockItem): boolean {
    const itemField = item.getFieldByFieldKey(this.fieldKey);
    if (!itemField) {
      return false;
    }

    const customFieldDate = new Date(itemField.value[0]).getTime();
    if (!customFieldDate) {
      return false;
    }

    const { dateFilterType } = this.values[0];

    if (dateFilterType === DateFilterType.LAST_UPDATE_IN_DAYS) {
      const { daysAgo, type } = this.values[0];
      const today = new Date(Date.now());
      const selectedDaysBeforeToday = new Date(
        new Date().setDate(today.getDate() - daysAgo)
      );

      if (type === LastUpdatedInDaysOption.LESS_THAN) {
        return customFieldDate >= selectedDaysBeforeToday.getTime();
      }
      if (type === LastUpdatedInDaysOption.GREATER_THAN) {
        return customFieldDate <= selectedDaysBeforeToday.getTime();
      }
      return false;
    }

    if (dateFilterType === DateFilterType.DAYS_BETWEEN) {
      const { from, to } = this.values[0];

      const fromDate = new Date(from).getTime();
      const toDate = new Date(to).getTime();
      return customFieldDate >= fromDate && customFieldDate <= toDate;
    }

    return false;
  }

  getLastUpdatedInDaysUrlSearchParams() {
    if (this.values[0].daysAgo === 0) {
      return "";
    }
    return `${this.fieldKey}=${this.values[0].type}:${this.values[0].daysAgo} days ago`;
  }

  getUrlSearchParams() {
    if (
      this.values &&
      this.values.length > 0 &&
      typeof this.values[0] === "object"
    ) {
      const type = this.values[0].dateFilterType;
      if (type === DateFilterType.LAST_UPDATE_IN_DAYS) {
        return this.getLastUpdatedInDaysUrlSearchParams();
      }
      if (type === DateFilterType.DAYS_BETWEEN) {
        return `${this.fieldKey}=${this.values[0].from}to${this.values[0].to}`;
      }
    }
    return "";
  }

  setValues(values: any): void {
    if (typeof values[0] === "string" && values[0].includes("to")) {
      const dates = values[0].split("to");
      this.values = [
        {
          from: dates[0],
          to: dates[1],
          dateFilterType: DateFilterType.DAYS_BETWEEN,
        },
      ];
      return;
    }

    if (typeof values[0] === "string" && values[0].includes(":")) {
      const daysAgo = parseInt(values[0].split(":")[1].split(" ")[0]);
      const type = values[0].split(":")[0];
      this.values = [
        {
          type,
          daysAgo,
          dateFilterType: DateFilterType.LAST_UPDATE_IN_DAYS,
        },
      ];
      return;
    }

    if (values[0] && typeof values[0] === "object") {
      if (values[0].daysAgo) {
        values[0].dateFilterType = DateFilterType.LAST_UPDATE_IN_DAYS;
      } else if (values[0].from || values[0].to) {
        values[0].dateFilterType = DateFilterType.DAYS_BETWEEN;
      }
    }

    this.values = values;
  }

  getFilteredByString() {
    if (this.values[0].dateFilterType === DateFilterType.LAST_UPDATE_IN_DAYS) {
      const { type, daysAgo } = this.getValuesIfString();
      return `${this.displayName}: ${type} ${daysAgo} day${
        daysAgo > 1 ? "s" : ""
      } ago`;
    }

    if (this.values[0].dateFilterType === DateFilterType.DAYS_BETWEEN) {
      const { from, to } = this.getValuesIfString();
      return `${this.displayName}: from ${from} to ${to}`;
    }

    return "";
  }

  getFilterComponent() {
    return this.fieldKey === "Updated" ? (
      <LastUpdateInDaysFilterOption field={this} />
    ) : (
      <div></div>
    );
  }
}
