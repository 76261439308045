import React from "react";
import { SectionBuilder } from ".";
import { SectionViewType } from "./types";

const OriginalItemsSection: React.FC<SectionViewType> = ({
  section,
  items,
  parentSections,
}) => {
  const { child } = section;
  return (
    <SectionBuilder
      section={child}
      items={items}
      parentSections={parentSections}
    />
  );
};

export default OriginalItemsSection;
