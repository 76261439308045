export default class CustomHistory {
  static historyInstance = null;

  static paramsInstance = null;

  static locationInstance = null;

  static push(page) {
    CustomHistory.historyInstance.push(page);
  }
}
