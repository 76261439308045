import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useClientConfigContext } from "src/providers/ClientConfigContext";
import {
  useFeaturePermissions,
  CompanyLevelFeatures,
} from "src/providers/FeaturePermissionContext";
import * as sessionService from "src/services/sessionService";

interface PrivateRouteV2Props {
  path: string;
  featureNameForPermissions: CompanyLevelFeatures;
  children;
}

export const PrivateRouteV2: React.FC<PrivateRouteV2Props> = (props) => {
  const { children, path, featureNameForPermissions } = props;
  const userIsLogged = sessionService.isLogged();
  const { defaultUrlConfig } = useClientConfigContext();
  const { isFeatureAllowedForRoute } = useFeaturePermissions();
  const isFeatureAllowed = isFeatureAllowedForRoute(featureNameForPermissions);
  const user = sessionService.getLoggedUser();
  const [defaultUrl, setDefaultUrl] = useState("/v2/roadmap/summary");

  useEffect(() => {
    if (defaultUrlConfig) {
      const defaultUrlForUser =
        Object.keys(defaultUrlConfig ?? {})?.find((url) => {
          if (Array.isArray(defaultUrlConfig[url])) {
            return defaultUrlConfig[url].includes(user.email);
          }
          return false;
        }) ?? defaultUrlConfig?.default_url;

      setDefaultUrl(defaultUrlForUser);
    }
  }, [defaultUrlConfig]);

  const getRoute = () => {
    if (!userIsLogged) {
      return <Redirect to="/login">{children}</Redirect>;
    }
    if (!isFeatureAllowed) {
      return <Redirect to={defaultUrl ?? "/roadmap"}>{children}</Redirect>;
    }
    return children;
  };

  return (
    <Route exact path={path}>
      {getRoute}
    </Route>
  );
};
