import React, { useContext, useEffect, useState } from "react";
import { getNotAllowedFeaturesForClient } from "src/services/clientService";
import { isLogged } from "src/services/sessionService";

export enum CompanyLevelFeatures {
  businessView = "business_view",
  planningView = "planning_view",
  timelineView = "timeline_view",
  teamsView = "teams_view",
  roadmapSummaryV2 = "roadmap_summary_v2",
  roadmapSummaryV1 = "roadmap_summary_v1",
  itemDetails = "item_details",
}

interface IFeaturePermissions {
  isFeatureAllowedForComponent: (featureName: CompanyLevelFeatures) => boolean;
  isFeatureAllowedForRoute: (featureName: CompanyLevelFeatures) => boolean;
}

const FeaturePermissionsContext =
  React.createContext<IFeaturePermissions>(undefined);

function useFeaturePermissions() {
  const context = useContext(FeaturePermissionsContext);
  return context;
}

function FeaturePermissionsContextProvider({ children }: any) {
  const [notAllowedFeatures, setNotAllowedFeatures] =
    useState<CompanyLevelFeatures[]>();
  const isAdmin = !!new URLSearchParams(window.location.search).get("admin");
  const userIsLogged = isLogged();

  useEffect(() => {
    if (userIsLogged) {
      getNotAllowedFeaturesForClient().then((allowedFeatures) => {
        setNotAllowedFeatures(allowedFeatures.map(({ name }) => name));
      });
    }
  }, []);

  function isFeatureAllowedForComponent(featureName) {
    if (!notAllowedFeatures) return false;
    return !notAllowedFeatures.includes(featureName) || isAdmin;
  }
  function isFeatureAllowedForRoute(featureName) {
    if (!notAllowedFeatures) return true;
    const result = !notAllowedFeatures.includes(featureName) || isAdmin;
    return result;
  }

  const value = React.useMemo(
    () => ({
      isFeatureAllowedForComponent,
      isFeatureAllowedForRoute,
    }),
    [notAllowedFeatures]
  );

  return (
    <FeaturePermissionsContext.Provider value={value}>
      {children}
    </FeaturePermissionsContext.Provider>
  );
}

export { useFeaturePermissions, FeaturePermissionsContextProvider };
