import { Box, Typography } from "@material-ui/core";
import { CustomFieldBase } from "./CustomFieldBase";
import { transformDate } from "src/v2/utils/date";

export class DateField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = [field.value ?? ""];
  }

  getComponentForGrid(_) {
    return (
      <Box
        data-testid="date-field"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          minWidth: "120px",
          backgroundColor: this.value[0] === "" ? null : "#C2DCFC",
          padding: 4,
          textAlign: "center",
          borderRadius: 4,
          color: "#395E77",
          zIndex: -1,
        }}
      >
        <Typography style={{ fontSize: 14 }}>
          {transformDate(this.value[0])}
        </Typography>
      </Box>
    );
  }
}
