import { Box, Link, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { CustomFieldBase } from "./CustomFieldBase";

export class LinkField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = Array.isArray(field.value)
      ? field.value
      : [field.value?.value ?? field.value ?? ""];
  }

  getComponentForGrid(_) {
    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "200px",
        }}
      >
        <Link
          rel="noopener noreferrer"
          target="_blank"
          variant="body2"
          href={this.value[0]}
          underline="hover"
        >
          <Typography style={{ color: primaryTextColor }} noWrap>
            {this.value[0]}
          </Typography>
        </Link>
      </Box>
    );
  }
}
