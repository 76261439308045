import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";

class FilterByType extends SectionFilter {
  public filterItem(item: BlockItem): boolean {
    const typeProperty = Object.keys(this.values)[0];
    return this.values[typeProperty].some(
      (value) =>
        value.toLocaleLowerCase() === item.type[typeProperty].toLowerCase()
    );
  }
}
export default FilterByType;
