import { StatusField } from "src/v3/entities/CustomFields/StatusField";
import { BlockItem } from "../../block/BlockItem";
import SectionFilter from "./SectionFilter";
import { calculateDiffInDays, isValidStringDate } from "src/v2/utils/date";
import * as Sentry from "@sentry/react";

class ByStatusCategoryFilter extends SectionFilter {
  public filterItem(item: BlockItem): boolean {
    const statusField = item.getWorkflowStatusField();
    if (!statusField) return false;

    const statusCategory = this.normalizeString(
      statusField.getStatusCategory()
    );
    const categoryChangeDateMatchCriteria =
      this.matchCategoryChangeDateCriteria(statusField, item.getKey());

    return (
      this.values.map(this.normalizeString).includes(statusCategory) &&
      categoryChangeDateMatchCriteria
    );
  }

  private normalizeString(value: string): string {
    return value.replace(/\s/g, "").toLowerCase();
  }

  private matchCategoryChangeDateCriteria(
    statusField: StatusField,
    itemKey
  ): boolean {
    const criteria = this.config["categoryChangeDate"];
    if (!criteria) return true;

    const { days = 0, period = "" } = criteria;
    if (!days || !period) {
      Sentry.captureMessage(
        `${itemKey} - Invalid criteria for category change date: ${JSON.stringify(criteria)}`
      );
      console.error(
        `${itemKey} - Invalid criteria for category change date: ${JSON.stringify(criteria)}`
      );

      return true;
    }

    const categoryChangeDate = statusField.getCategoryChangeDate();
    const categoryChangeInDays = calculateDiffInDays(
      new Date(),
      categoryChangeDate
    );

    return (
      (period === "before" && categoryChangeInDays <= days) ||
      (period === "after" && categoryChangeInDays >= days)
    );
  }
}

export default ByStatusCategoryFilter;
