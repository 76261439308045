import CustomHistory from "src/utils/history";
import { logError } from "src/utils/error";
import { logout } from "./sessionService";

export const requestInterceptor = (config) => {
  const params = new URLSearchParams(CustomHistory.locationInstance.search);
  const teamName = params.get("admin");
  if (teamName) {
    config.headers.adminCheckingTeam = teamName;
  }
  return config;
};

export const responseErrorInterceptor = async (error) => {
  let errorMessage =
    "Sorry, please try again in a few minutes. If the problem persists, please contact us.";
  if (error?.response?.status === 401) {
    errorMessage =
      "For security, you must re-authenticate every 30 days. Please login again";
    await logout();
    CustomHistory.push("/login");
  }
  return logError(error, errorMessage);
};
