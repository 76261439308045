import { LayoutContextProvider } from "./LayoutContext";
import { SnackbarContextProvider } from "./SnackbarContext";

/**
 * Global context provider component combining all global context providers together
 */
export const GlobalContextProviders = ({ children }) => (
  <SnackbarContextProvider>
    <LayoutContextProvider>{children}</LayoutContextProvider>
  </SnackbarContextProvider>
);
