import { styled, Theme } from "@material-ui/core";

export const ChallengeContent = styled("div")(({ theme }: { theme: Theme }) => {
  return {
    color: theme.palette.grey[900],
    ...theme.typography.body2,
    fontStyle: "normal",
    lineHeight: "normal",
    "& a": {
      color: theme.palette.primary.main,
    },
  };
});
